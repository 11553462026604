// Imports
// ------
import { observable } from "mobx";
import {dateToString} from "../lib/Util/TimeUtil";

// Exports
// ------
export const scheduleFilters = observable({
    date: dateToString(new Date()),
    fulldayPass: false,
    timeStart: -1,
    timeEnd: -1,
    seats: 0,
});

export const sidebarState = observable({
    hasDetails: false,
});