// Imports
// ------
import React from 'react';
import Card from 'components/lokations/list/card/card';
import Empty from './empty';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket } from './listStyles';
import {withFirebase} from "../../../lib/Firebase";

// Component
// -------
class BookingsList extends React.Component {
    
    favToggle = (location) => {
        this.props.firebase.favToggle(location, this.props.authUser)
    }
    
    render() {
        return (
            <Jacket>
                <Row isExpanded>
                    {this.props.list.length > 0 ?
                        <>
                            {this.props.list.map((block, index) =>
                                <Column key={index} small={12} medium={6} large={4} xlarge={3}>
                                    <Card
                                        isFavorite={block.isFavorite}
                                        onlyFavorite={block.onlyFavorite}
                                        slug={block.slug}
                                        title={block.title}
                                        subTitle={block.subTitle}
                                        address={block.address}
                                        hasRatings={block.hasRatings}
                                        ratingValue={block.ratingValue}
                                        images={block.images}
                                        
                                        location={block.location}
                                        onFavToggle={e => {this.favToggle(block.location)}}

                                        // Has Details
                                        hasDetails={block.hasDetails}
                                        scheduleDate={block.scheduleDate}
                                        scheduleStart={block.scheduleStart}
                                        scheduleEnd={block.scheduleEnd}
                                        schedulePass={block.schedulePass}
                                        scheduleSeats={block.scheduleSeats}

                                        // Has Past Details
                                        hasPastDetails={block.hasPastDetails}

                                        booking={block.booking}
                                    />
                                </Column>
                            )}
                        </>
                        :
                        <Column small={12}>
                            <Empty isFav={this.props.isFav} />
                        </Column>
                    }
                </Row>
            </Jacket>
        )
    }
}

export default withFirebase(BookingsList)