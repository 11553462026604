// Imports
// ------
import styled from 'styled-components';
import { breakup } from 'components/global/theme/breakpoints';
import { Div, H2, Span } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Div)`
    position: relative;
    display: block;
`;

export const Avatar = styled(Div)`
    position: relative;
    display: inline-block;
    width: 6.4rem;
    height: 6.4rem;

    overflow: hidden;
    border-radius: 100%;
    background: ${props => props.theme.bc2};

    ${breakup.large`
        width: 9.2rem;
        height: 9.2rem;
    `}

    img {
        position: relative;
        object-fit: contain;
        width: 100%;
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 3rem;
        height: 3rem;
    }
`;

export const Name = styled(H2)`
    color: ${props => props.theme.bc3};
`;

export const Label = styled(Span)`
    ${props => props.theme.animate('0.3s', 'ease-in-out')}
    display: inline-block;
    vertical-align: middle;
    margin-right: 1rem;
`;

export const UploadWrapper = styled(Div)`
    position: relative;
    overflow: hidden;
    dispay: inline-block;

    input[type=file] {
        display: block;
        width: 15rem;
        font-size: 10rem !important;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        margin: 0;
        padding: 0;
        cursor: pointer;
    }

    &:hover {
        ${Label} {
            opacity: 0.6;
        }
    }
`;

export const Upload = styled.button`
    position: relative;
    display: inline-block;
    cursor: pointer;

    width: max-content;
    background: none;

    svg {
        display: inline-block;
        vertical-align: middle;
    }
`;