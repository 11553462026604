import React from 'react';
import { compose } from 'recompose';

import { AuthUserContext, withAuthorization } from '../../lib/Session';

import * as ROLES from '../../constants/roles';
import {Jacket} from "./contentStyles";
import Icon from "../../components/global/icons/icons";
import {Column, Row} from "../../components/global/theme/grid/grid";
import {Div} from "../../components/global/theme/reusables/reusables";

const IconTest = (props) => (
    <AuthUserContext.Consumer>
        {authUser => (
        <Jacket>
            <Row isExpanded showOnLarge>
                <Column small={12} large={10} pushOnLarge={1}>
                    <Div relative pad={10} padTop>
                        <Row isExpanded isCollapsed>
                            <Column large={12}>
            {
                Icon.all.map(icon => <span><Icon type={icon} /> <Icon hasGradient type={icon} /> { icon } </span>)
            }
                            </Column>
                        </Row>
                    </Div>
                </Column>
            </Row>
        </Jacket>
        )}
    </AuthUserContext.Consumer>
);

const condition = authUser => authUser && authUser.roles && !!authUser.roles[ROLES.ADMIN];

export default compose(
    withAuthorization(condition),
)(IconTest);
