import { css } from 'styled-components';

// *** Theme Styling *** //
// --------------
// 1. Social Colours
// --------------
// 2. Notification Colours
// --------------
// 3. Brand Colours
// --------------
// 4. Padding Sizes
// --------------
// 5. Margin Sizes
// --------------
// 6. Fonts
// --------------
// 7. Breakpoints
// --------------
// 8. Cubic Bezier
// --------------
// 9. Magic Number
// --------------
// 10. Mixins
// --------------

// Theme Styles
export const themeStyles = {
	// 1. Social Colours
	// --------------
	facebook: '#3b5998',
	twitter: '#1da1f2',
	creativeMarket: '#8ba753',
	slack: '#e9a820',
	skype: '#00aff0',
	instagram: '#833ab4',
	dribbble: '#ea4c89',
	behance: '#1769ff',
	linkedin: '#0077b5',

	// 2. Notification Colours
	// --------------
	pos: '#3adb76', // Green (Success / Positive)
	neg: '#cc4b37', // Red (Error / Negative)
	warn: '#ffae00', // Yellow (Warning / Neutral)

	// 3. Brand Colours
	// --------------
	bc1: '#1F2F3E', // Dark Blue
	bc2: '#172634', // Darker Blue
	bc3: '#ffffff', // White
	bc4: '#F8F9F9', // Light Grey
	bc5: '#FC0084', // Pink
	bc6: '#FE4902', // Orange
	bc7: '#848D95', // Mid Grey
	bc8: '#dedfdf', // Light Grey


	// 4. Padding Sizes
	// --------------
	mpad: '1.5rem', // Mobile Padding
	padS: '4rem', // Small
	padM: '8rem', // Medium
	padL: '10rem', // Large

	// 5. Margin Sizes
	// --------------
	marS: '12rem', // Small
	marM: '15rem', // Medium
	marL: '20rem', // Large

	// 6. Fonts
	// --------------
	heading: "'Moderat', 'Helvetica', 'Arial', Serif;",
	body: "'Moderat', 'Helvetica', 'Arial', Sans-Serif;",
	reg: 'normal',

	// 7. Grid
	// --------------
	small: '0',
	smedium: '370px',
	medium: '640px',
	large: '1024px',
	xlarge: '1200px',
	xxlarge: '1440px',
	huge: '1600px',
	uber: '2000px',

	gutterSmall: '20px',
	gutterLarge: '40px',
	maxGridSize: '1440px',

	// 8. Cubic Bezier
	// --------------
	bezzy: 'cubic-bezier(0.8, 0, 0.2, 1)',

	// 9. Magic Number
	// --------------
	magic: '1.2rem',

	// 10. Mixins
	// --------------
	// Vertical Align
	valign: css`
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
	`,

	// Vertical Align Cancel
	valignCancel: css`
		position: relative;
		top: auto;
		transform: none;
		width: auto;
	`,

	// Total Align
	talign: css`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	`,

	// Total Align Cancel
	talignCancel: css`
		position: relative;
		top: auto;
		left: auto;
		transform: none;
	`,

	// ^^^^^^^^^^^^
	// How to use a standard Mixin:
	// ${props => props.theme.nameOfMixin}

	// Magic Cubic Bezier Animation
	cubic: (time, bezier) => css`
		transition: all ${time} ${bezier};
	`,

	// Simplified Animation
	animate: (time, ease) => css`
		transition: all ${time} ${ease};
	`,

	// Simplified Animation Delay
	delay: time => css`
		transition-delay: ${time};
	`,

	// Icon Sizer
	sizer: size => css`
		width: ${size};
		height: ${size};
	`,

	// ^^^^^^^^^^^^
	// How to use a custom Mixin:
	// ${props => props.theme.nameOfMixin(12rem, 'red')}
};
