// Imports
// ------
import React from 'react';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';
import { observer } from 'mobx-react';

// Styles
// ------
import {Widget, WidgetJacket, WidgetTitle, Title, ArrowJacket} from '../filterModalStyles';
import { CalendarJacket, Month, DaysList, Day } from './dateStyles';

// States
// ------
import { scheduleFilters } from 'states/lokationSidebar';
import moment from "moment";
import Icon from "../../../global/icons/icons";
import {humanDate} from "../../../../lib/Util/TimeUtil";

// Component
// ------
class filterModalDate extends React.Component {
    constructor(props) {
        super(props)

        let max = 7;
        let dates = [];
        let days = [];
        let selected = null;
        let left = '';
        let right = '';

        for (let i = 0; i < max; i++) {

            let date = new Date();
            date.setDate(date.getDate() + i);

            let month = (date.getMonth() + 1);
            if (month < 10) {
                month = `0${month}`;
            }

            let day = date.getDate();
            if (day < 10) {
                day = `0${day}`;
            }
            let formatted = `${date.getFullYear()}-${month}-${day}`;

            let label = `${month}/${day}/${date.getFullYear()}`;
            let short = `${day}`;
            let mnt = moment(date).format('MMMM');
            if (left === '') {
                left = mnt;
            }
            right = mnt;
            let obj = {
                index: i,
                date: formatted,
                label: label,
                short: short
            };
            if (scheduleFilters.date === formatted) {
                selected = obj;
            }
            dates.push(obj);
            days.push(['S','M','T','W','T','F','S'][date.getDay()])

        }

        if (left === right) {
            right = '';
        }
        

        this.state = {
            isActive: false,
            selectedDate: selected,
            dates: dates,
            days: days,
            leftMonth: left,
            rightMonth: right,
        }
    }

    handleSetDate = (e) => {
        // Set the date and save to global state
        this.setState({selectedDate: this.state.dates[e]});
        
        scheduleFilters.date = this.state.dates[e].date;
        
        this.props.onChange();
    }
    
    dateName = () => {
        let name = "Date";
        if (!this.props.open && scheduleFilters.date !== null) {
            name = humanDate(scheduleFilters.date);
        }
        return name;
    }

    render() {
        return (
            <Widget>
                <WidgetJacket>
                    <WidgetTitle onClick={e => this.props.onToggleOpen(this.props.open ? -1 : 0)}>
                        <Row isExpanded equal>
                            <Column small={10}>
                                <Title light={!this.props.open}>{this.dateName()}</Title>
                            </Column>
                            <Column small={2}>
                                <ArrowJacket big isOpen={this.props.open}>
                                    <Icon type="small-arrow-down" />
                                </ArrowJacket>
                            </Column>
                        </Row>
                    </WidgetTitle>
                    { this.props.open && <CalendarJacket>
                        <Row isExpanded>
                            <Column small={6}>
                                <Month>{this.state.leftMonth}</Month>
                            </Column>
                            <Column small={6}>
                                <Div text="right">
                                    <Month>{this.state.rightMonth}</Month>
                                </Div>
                            </Column>
                        </Row>
                        <Row isExpanded>
                            <Column small={12}>
                                <DaysList>
                                    {
                                        this.state.days.map((day, index) => (
                                            <Day key={index}><span>{day}</span></Day>
                                        ))
                                    }
                                </DaysList>
                            </Column>
                        </Row>
                        <Row isExpanded>
                            <Column small={12}>
                                <DaysList>
                                    {
                                        this.state.dates.map(date => (
                                            <Day key={date.index} onClick={(e) => this.handleSetDate(date.index)} value={date.index} selected={this.state.selectedDate && this.state.selectedDate.index === date.index}><span>{date.short}</span></Day>
                                        ))
                                    }
                                </DaysList>
                            </Column>
                        </Row>
                    </CalendarJacket> }
                </WidgetJacket>
            </Widget>
        )
    }
}

export default observer(filterModalDate);