// Imports
// ------
import React from 'react';
import Button from 'components/global/buttons/primary';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket } from './extenderStyles';

// Component
// ------
export default class SidebarExtender extends React.Component {
    

    endBookingTitle = () => {
        // const {booking} = this.props;
        // if (booking.is_table) {
        //     let now = new Date();
        //     let minutes = now.getHours() * 60;
        //     minutes += now.getMinutes();
        //     if (booking.end_time > minutes) {
        //         return "End Booking Early";
        //     }
        //    
        // }
        
        return "Check-out Now";
        
    }
    
    render() {
        return (
            <Jacket>
                <Row isExpanded>
                    {/*<Column small={12}>*/}
                    {/*    <Button button type="full" text="Extend Booking" onClick={this.handleExtension} />*/}
                    {/*</Column>*/}
                    <Column small={12}>
                        <Button button textColor="dark" type="ghost" text={this.endBookingTitle()} onClick={this.props.handleEndBooking} />
                    </Column>
                </Row>
            </Jacket>
        )
    }
}