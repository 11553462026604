// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';

// Styles
// ------
import { Jacket, Slider, Slide, SliderControls, Button } from './imagesliderStyles';

// Component
// ------
export default class CardImageSlider extends React.Component {
    initiateFlickity = () => {
        const flickity = require('flickity');

        const slider = this.slider;
        const ImageCarousel = new flickity(slider, {
            contain: false,
            wrapAround: true,
            freeScroll: false,
            prevNextButtons: false,
            pageDots: false,
            dragThreshold: 30,
            infinite: true,
        });

        // Slider Controls
        // ------
        const nextSlide = this.nextSlide;
        if (nextSlide) {
            nextSlide.addEventListener('click', () => {
                ImageCarousel.next();
            });
        }

        const prevSlide = this.prevSlide;
        if (prevSlide) {
            prevSlide.addEventListener('click', () => {
                ImageCarousel.previous();
            });
        }
    }

    render() {
        const images = this.props.images;
        
        return (
            <Jacket>
                <Slider ref={slider => (this.slider = slider)}>
                    {images.map((block, index) => (
                        <Slide key={index}>
                            <img src={block.image} alt="" />
                        </Slide>
                    ))}
                </Slider>
                {
                    images.length > 1 && <SliderControls>
                        <Button prev ref={prevSlide => (this.prevSlide = prevSlide)}>
                            <Icon type="small-arrow-left"/>
                        </Button>
                        <Button next ref={nextSlide => (this.nextSlide = nextSlide)}>
                            <Icon type="small-arrow-right"/>
                        </Button>
                    </SliderControls>
                }
            </Jacket>
        )
    }

    componentDidMount() {
        this.initiateFlickity();
        // window.addEventListener('resize', this.initiateFlickity);
    }

    componentWillUnmount() {
        // window.removeEventListener("resize", this.initiateFlickity);
    }
}