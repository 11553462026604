// Imports
// ------
import React from 'react';
import Button from 'components/global/buttons/primary';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket } from './checkinStyles';
import {minutesToTime} from "../../../../lib/Util/TimeUtil";
import {notificationState} from "../../../../states/notifications";

// Components
// ------
export default class SidebarCheckin extends React.Component {
    
    handleCancelBooking = () => {
            
        notificationState.generalTitle = "Cancel Booking?"
        notificationState.generalText = "Are you sure you want to cancel your booking? This action can't be reversed. Bookings made within 24-hours of the booking start date/time are non-refundable."
      

        notificationState.generalButtons = [
            {
                title: "Cancel Booking",
                url: "#",
                onClick: () => {
                    this.props.handleCancelBooking();
                }
            },
            {
                title: "Keep Booking",
                url: "#",
            }
        ]
        notificationState.generalOpen = true
    }
    
    checkInNotReadyTitle = () => {

        let {booking} = this.props;
        let comps = ["Check-in Available @"];

        if (booking) {
            let time = booking.start_time;
            if (booking.is_table === false) {
                time = 8 * 60;
            }
            comps.push(minutesToTime(time - 15));
        }
        
        return comps.join(" ");
    }

    render() {
        let title = this.checkInNotReadyTitle();
        return (
            <Jacket>
                <Row isExpanded>
                    { this.props.canCheckIn && <Column small={12}>
                        <Button button type="full" text="Check-In Now" onClick={this.props.handleCheckIn} />
                    </Column>}
                    <Column small={12}>
                        <Button button type="ghost" textColor="dark" text="Cancel Booking" onClick={this.handleCancelBooking} />
                    </Column>

                    { this.props.canCheckIn === false && this.props.canCancelCheckIn === false &&
                    <Column small={12}>
                        <Button button type="ghost" textColor="dark" text={title} disabled />
                    </Column>
                    }

                    {/*{ this.props.canCheckIn && <Column small={12}>*/}
                    {/*    <Div text="center">*/}
                    {/*        <Link>*/}
                    {/*            <Button navlink type="text" text="Seat/Table Not Vacant" href="/" />*/}
                    {/*        </Link>*/}
                    {/*    </Div>*/}
                    {/*</Column>}*/}
                </Row>
            </Jacket>
        )
    }
}