// Imports
// ------
import styled from "styled-components";
import { breakup } from 'components/global/theme/breakpoints';
import { Section } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    z-index: 10;
    display: block;

    margin: 2rem;
    margin-top: -2rem;

    ${breakup.large`margin: 0;`}
`;