// Imports
// ------
import React from 'react';
import User from './user/user';
import Edit from './edit/editButton';
import EditProfile from './edit/editProfile';
import EditPassword from './password/editPasword';
import Notifications from '../notifications/notifications';
import Menu from './menu/menu';
import Button from 'components/global/buttons/primary';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';
import { observer } from 'mobx-react';

// Styles
// ------
import { LokusUser, LokusProfileContent, ButtonJacket } from './profileStyles';

// States
// ------
import { userState } from 'states/user';
import {withFirebase} from "../../../lib/Firebase";
import {Bullet} from "../../global/footer/mobileFooter/mobileFooterStyles";

// Component
// ------
class Profile extends React.Component {


    handleSignout = () => {
        this.props.firebase.doSignOut();    
    }
    
    render() {
        const editProfileChecker = userState.editProfileOpen;
        const editPasswordChecker = userState.editPasswordOpen;
        const notificationChecker = userState.notificationsIsOpen;
        
        return (
            <>
                {/* Modals */}
                <EditProfile authUser={this.props.authUser} isActive={editProfileChecker} />
                <EditPassword isActive={editPasswordChecker} />
                <Notifications authUser={this.props.authUser} isActive={notificationChecker} />


                {/* Profile Content */}
                <LokusUser>
                    <Div relative mpad mpadTop>
                        <Row isExpanded isCenter>
                            <Column small={8} medium={6} pushOnLarge={1}>
                                <User
                                    avatar={this.props.avatar}
                                    name={this.props.name}
                                />
                            </Column>
                            <Column small={4} medium={4} pushOnLarge={1} relative>
                                {!this.props.authUser.profileComplete && <Bullet flush></Bullet>}
                                <Div text="right">
                                    <Edit />
                                </Div>
                            </Column>
                        </Row>
                    </Div>
                </LokusUser>
                <LokusProfileContent>
                    <Div relative pad>
                        <Row isExpanded isCollapsed>
                            <Column small={12} medium={5} pushOnMedium={1}>
                                <Row isExpanded isCollapsed isNotCollapsedOnLarge>
                                    <Column small={12} mpadLarge>
                                        <Menu
                                            label="Bookings"
                                            list={[
                                                {
                                                    link: "/bookings",
                                                    text: "Upcoming"
                                                },
                                                {
                                                    link: "/bookings/past",
                                                    text: "Past"
                                                },
                                                {
                                                    link: "/bookings/favorites",
                                                    text: "Favorites"
                                                },
                                            ]}
                                        />
                                    </Column>
                                    <Column small={12}>
                                        <Menu
                                            label="Account Information"
                                            list={[
                                                {
                                                    isProfile: true
                                                },
                                                {
                                                    isPassword: true,
                                                },
                                                // {
                                                //     isNotifications: true,
                                                // }
                                            ]}
                                        />
                                    </Column>
                                </Row>
                            </Column>
                            <Column small={12} medium={5} pushOnMedium={1}>
                                <Row isExpanded isCollapsed isNotCollapsedOnLarge>
                                    <Column small={12}>
                                        <Menu
                                            label="Support"
                                            list={[
                                                {
                                                    isFaqs: true,
                                                },
                                                {
                                                    isLegal: true,
                                                },
                                                {
                                                    isHelp: true,
                                                },
                                            ]}
                                        />
                                    </Column>
                                </Row>
                            </Column>
                        </Row>
                        <Row isExpanded isCollapsed>
                            <Column small={12} medium={10} pushOnMedium={1}>
                                <Row isExpanded isCollapsed isNotCollapsedOnLarge>
                                    <Column small={12}>
                                        <Div mpad>
                                            <ButtonJacket>
                                                <Button button type="ghost" text="Sign Out" onClick={this.handleSignout} />
                                            </ButtonJacket>
                                        </Div>
                                    </Column>
                                </Row>
                            </Column>
                        </Row>
                    </Div>
                </LokusProfileContent>
            </>
        )
    }
}

export default withFirebase(observer(Profile))