import React from 'react';
import { compose } from 'recompose';

import { AuthUserContext, withAuthorization } from '../lib/Session';
import LokationsList from "../components/lokations/list/list";
import {observer} from "mobx-react";

const Locations = (props) => (
    <AuthUserContext.Consumer>
        
        {authUser => (
            <LokationsList
                authUser={authUser}
                title={"Hello, " + (authUser.firstName ? authUser.firstName : authUser.fullName) + "!"}
                />
        )}
    </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default compose(
    observer,
    withAuthorization(condition)
)(Locations);
