// Imports
// ------
import styled, { css } from "styled-components";
import { breakup } from 'components/global/theme/breakpoints';
import { Div, Gradient, Span } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Label = styled(Span)`
    font-size: 1.4rem !important;

    ${props => props.light && css`
        color: ${props => props.theme.bc3};
    `}

    ${props => props.dark && css`
        color: ${props => props.theme.bc1};
    `}
`;

export const Select = styled.select`
display: block;
    width: 100%;
    margin-bottom: 1rem;

    font: normal 16px 'Moderat','Helvetica','Arial', sans-serif !important;
    ${breakup.medium`font: normal 18px 'Moderat','Helvetica','Arial', sans-serif !important;`}
    ${breakup.medium`font: normal 20px 'Moderat','Helvetica','Arial', sans-serif !important;`}
    color: ${props => props.theme.bc1};

    text-align: ${props => props.talign ? props.talign : 'left'};

    border: none;
    padding-bottom: 1rem;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        background: none !important;
        -webkit-text-fill-color: ${props => props.theme.bc3};
        transition: background-color 10000s ease-in-out 0s;
    }

    &:focus {
        outline: none;
        background: none !important;
    }
`

export const Input = styled.input`
    display: block;
    width: 100%;
    margin-bottom: 1rem;

    font: normal 16px 'Moderat','Helvetica','Arial', sans-serif !important;
    ${breakup.medium`font: normal 18px 'Moderat','Helvetica','Arial', sans-serif !important;`}
    ${breakup.medium`font: normal 20px 'Moderat','Helvetica','Arial', sans-serif !important;`}
    color: ${props => props.theme.bc1};

    text-align: ${props => props.talign ? props.talign : 'left'};

    border: none;
    padding-bottom: 1rem;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        background: none !important;
        -webkit-text-fill-color: ${props => props.theme.bc3};
        transition: background-color 10000s ease-in-out 0s;
    }

    &:focus {
        outline: none;
        background: none !important;
    }

    ${props => props.light && css`
        color: ${props => props.theme.bc3};

        &::placeholder, &[disabled] {
            color: ${props => props.theme.bc3};
            opacity: 0.45;
        }
    `}

    ${props => props.dark && css`
        color: ${props => props.theme.bc1};

        &::placeholder, &[disabled] {
            color: ${props => props.theme.bc1};
            opacity: 0.45;
        }
    `}

    ${props => props.transparent && css`
        background: none !important;
    `}

    &[type='number'] {
        &::-webkit-inner-spin-button {
            display: none;
        }
    }

    &[type='password'] {
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            background: none !important;
            -webkit-text-fill-color: ${props => props.theme.bc3};
            transition: background-color 10000s ease-in-out 0s !important;
        }
    }
`;

export const TextArea = styled.textarea`
    display: block;
    width: 100%;
    margin-bottom: 1rem;

    font: normal 16px 'Moderat','Helvetica','Arial', sans-serif !important;
    ${breakup.medium`font: normal 18px 'Moderat','Helvetica','Arial', sans-serif !important;`}
    ${breakup.medium`font: normal 20px 'Moderat','Helvetica','Arial', sans-serif !important;`}
    color: ${props => props.theme.bc1};
    
    background: ${props => props.withBackground ? props.theme.bc4 : 'transparent'};

    text-align: ${props => props.talign ? props.talign : 'left'};

    border: none;
    padding-bottom: 1rem;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        background: none !important;
        -webkit-text-fill-color: ${props => props.theme.bc3};
        transition: background-color 10000s ease-in-out 0s;
    }

    &:focus {
        outline: none;
        background: none !important;
    }

    ${props => props.light && css`
        color: ${props => props.theme.bc3};

        &::placeholder, &[disabled] {
            color: ${props => props.theme.bc3};
            opacity: 0.45;
        }
    `}

    ${props => props.dark && css`
        color: ${props => props.theme.bc1};

        &::placeholder, &[disabled] {
            color: ${props => props.theme.bc1};
            opacity: 0.45;
        }
    `}

    ${props => props.transparent && css`
        background: none !important;
    `}

`;

export const InputSpan = styled(Span)`
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    background: transparent;
    border: 1px solid #848D95;

    &:after {
        ${props => props.theme.animate('0.3s', 'ease-in-out')}
        content: '';
        position: absolute;
        opacity: 0;

        left: 5px;
        top: 1px;
        width: 3px;
        height: 8px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;

export const PwToggle = styled(Div)`
    display: inline-block;
    cursor: pointer;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: ${props => props.negativeMargin ? '-3rem' : '2rem'};

    svg {
        ${props => props.theme.animate('0.3s', 'ease-in-out')}
        opacity: 1;
    }

    ${props => props.isHidden && css`
        svg {
            opacity: 0.45;
        }
    `}
`;

export const InputJacket = styled(Div)`
    position: relative;
`;

export const CheckboxJacket = styled(Div)`
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute !important;
        z-index: 5;
        top: 0; left: 0;
        opacity: 0 !important;
        cursor: pointer !important;
        height: 18px !important;
        width: 18px !important;
        margin: 0 !important;
    }

    &:hover {
        input ~ .checkmark {
            border-color: #646D74;
        }
    }

    input:checked ~ .checkmark {
        background: ${Gradient};
        border-color: transparent;

        &:after {
            opacity: 1;
        }
    }
`;