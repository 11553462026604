// Imports
// ------
import React from 'react';
import NavLink from 'components/global/navlink/navlink';
import Icon from 'components/global/icons/icons';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import {Jacket, MenuItem, Iconwrap, Label, Bullet} from './mobileFooterStyles';
import {compose} from "recompose";
import {AuthUserContext, withBookings} from "../../../../lib/Session";
import withAuthorization from "../../../../lib/Session/withAuthorization";

// Component
// ------
class MobileFooter extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            bookings: 0
        };
    }


    render() {
        let upcoming = this.props.bookings.filter(booking => booking.status === 'booked' || booking.status === 'started');
        
        return (
            <AuthUserContext.Consumer>

                {authUser =>
                    authUser && <Jacket>
                        <Row isExpanded>
                            <Column relative>
                                {window.location.pathname !== "/profile" && !authUser.profileComplete && <Bullet></Bullet>}
                                <MenuItem>
                                    <NavLink to="/profile">
                                        <Iconwrap><Icon type="user"/></Iconwrap>
                                        <Label>Profile {authUser.profileComplete}</Label>
                                    </NavLink>
                                </MenuItem>
                            </Column>
                            <Column>
                                <MenuItem>
                                    <NavLink to="/lokations">
                                        <Iconwrap><Icon type="location"/></Iconwrap>
                                        <Label>Lokations</Label>
                                    </NavLink>
                                </MenuItem>
                            </Column>
                            <Column relative>
                                {window.location.pathname !== "/bookings" && upcoming.length > 0 && <Bullet></Bullet>}
                                <MenuItem>
                                    <NavLink to="/bookings">
                                        <Iconwrap><Icon type="calendar"/></Iconwrap>
                                        <Label>Bookings</Label>
                                    </NavLink>
                                </MenuItem>
                            </Column>
                        </Row>
                    </Jacket>
                }
            </AuthUserContext.Consumer>
        )
    }
}



const condition = authUser => !!authUser;

export default compose(
    withBookings,
    withAuthorization(condition),
)(MobileFooter);
