// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import Menu from './menu/menu';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

// Styles
// ------
import { Jacket, Header, Close, Title, HelpTitle, ContactDetails } from './helpStyles';

// States
// ------
import { userState } from 'states/user';

// Utils
// ------
import Body from 'utils/bodylock';
import {AuthUserContext, withAuthorization} from "../../../lib/Session";

// Component
// ------
class UserHelp extends React.Component {
    toggleEdit = () => {
        userState.helpIsOpen = !userState.helpIsOpen;
    }

    stateCheck = () => {
        if (userState.helpIsOpen) {
            Body.lock();
        }

        if (!userState.helpIsOpen) {
            Body.unlock();
        }
    }

    render() {

        return (
            <AuthUserContext.Consumer>

                {authUser => (<Jacket isActive={this.props.isActive}>
                {/* Help header */}
                <Header>
                    <Row isExpanded>
                        <Column small={12} medium={10} pushOnMedium={1}>
                            <Row isExpanded>
                                <Column small={12}>
                                    <Div relative text="center">
                                        <Close onClick={this.toggleEdit}>
                                            <Icon type="small-arrow-left" />
                                        </Close>
                                        <Title>Help</Title>
                                    </Div>
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                </Header>

                <Row isExpanded>
                    <Column small={12} medium={10} pushOnMedium={1}>
                        <Row isExpanded>
                            <Column small={12}>
                                <HelpTitle>Hello, {authUser.firstName}!</HelpTitle>
                            </Column>
                        </Row>
                    </Column>
                </Row>

                <ContactDetails>
                    <Row isExpanded>
                        <Column small={12} medium={10} pushOnMedium={1}>
                            <Row isExpanded>
                                <Column small={12} medium={6}>
                                    <Menu
                                        label="Get in touch"
                                        list={[
                                            // {
                                            //     link: "tel:800-600-1234",
                                            //     text: "800-600-1234",
                                            //     icon: "phone",
                                            //     iconGrad: true,
                                            // },
                                            {
                                                link: "mailto:info@booklokus.com",
                                                text: "help@booklokus.com",
                                                icon: "mail",
                                                iconGrad: true,
                                            },
                                            {
                                                link: "slack:lokus",
                                                text: "lokus.slack.com",
                                                icon: "slack",
                                            },
                                        ]}
                                    />
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                </ContactDetails>
            </Jacket>)}
            </AuthUserContext.Consumer>
        )
    }

    componentDidUpdate() {
        this.stateCheck();
    }

    componentWillUnmount() {
        this.stateCheck();
    }
}


const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition),
    observer)(UserHelp);