// Imports
// ------
import { css } from "styled-components";
import { breakup } from '../breakpoints';
import { isDefined } from './utils';

// Exports
// ------
const pull = css`
    ${props => isDefined(props.pullOnSmall) && css`
        position: relative;
        left: calc(-100% / 12 * ${props.pullOnSmall});
    `}

    ${props => isDefined(props.pullOnSmedium) && css`
        ${breakup.smedium`
            position: relative;
            left: calc(-100% / 12 * ${props.pullOnSmedium});
        `}
    `}

    ${props => isDefined(props.pullOnMedium) && css`
        ${breakup.medium`
            position: relative;
            left: calc(-100% / 12 * ${props.pullOnMedium});
        `}
    `}
    
    ${props => isDefined(props.pullOnLarge) && css`
        ${breakup.large`
            position: relative;
            left: calc(-100% / 12 * ${props.pullOnLarge});
        `}
    `}


    ${props => isDefined(props.pullOnXlarge) && css`
        ${breakup.xlarge`
            position: relative;
            left: calc(-100% / 12 * ${props.pullOnXlarge});
        `}
    `}

    ${props => isDefined(props.pullOnXxlarge) && css`
        ${breakup.xxlarge`
            position: relative;
            left: calc(-100% / 12 * ${props.pullOnXxlarge});
        `}
    `}

    ${props => isDefined(props.pullOnHuge) && css`
        ${breakup.huge`
            position: relative;
            left: calc(-100% / 12 * ${props.pullOnHuge});
        `}
    `}

    ${props => isDefined(props.pullOnUber) && css`
        ${breakup.uber`
            position: relative;
            left: calc(-100% / 12 * ${props.pullOnUber});
        `}
    `}
`;

export default pull;