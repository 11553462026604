// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';

// Styles
// ------
import { Jacket, Label, ItemList, Item, Text } from './menuStyles';

// Component
// ------
export default class ContactMenu extends React.Component {
    render() {
        return (
            <Jacket>
                <Label>{this.props.label}</Label>
                <ItemList>
                    {this.props.list.map((block, index) =>
                        <Item key={index}>
                            <a href={block.link}>
                                <Icon type={block.icon} hasGradient={block.iconGrad} />
                                <Text>{block.text}</Text>
                            </a>
                        </Item>
                    )}
                </ItemList>
            </Jacket>
        )
    }
}