// Imports
// ------
import styled from "styled-components";
import { breakup } from 'components/global/theme/breakpoints';
import { Div, GradientIcon, H3, Span, P } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Div)`
    position: relative;
    z-index: 1;
    display: block;
    height: 100%;
    margin-bottom: 4rem;

    background: ${props => props.theme.bc3};
`;

export const FavJacket = styled(Div)`
    position: absolute;
    z-index: 15;
    display: block;

    width: 2.4rem;
    height: 2.4rem;

    top: 3rem;
    right: 2rem;
`;

export const Content = styled(Div)`
    position: relative;
    display: block;
    background: ${props => props.theme.bc3};

    padding: 2rem 0;
`;

export const Title = styled(H3)`
    display: block;
    margin-bottom: 1rem;

    color: ${props => props.theme.bc1};
`;

export const SubTitle = styled(Span)`
    display: block;
    margin-bottom: 0.5rem;
    
    color: ${props => props.theme.bc1};
    line-height: 1.2 !important;
`;

export const Address = styled(P)`
    margin-bottom: 2rem;

    color: ${props => props.theme.bc1};
    font-size: 1.4rem !important;
    line-height: 1.8rem !important;
`;

export const RatingJacket = styled(Div)`
    position: relative;
    display: block;

    svg {
        width: 3rem;
        height: 3rem;

        fill: ${props => props.theme.bc5};
    }

    > span {
        span {
            margin-right: 1rem;

            &:last-child { margin-right: 0; }
        }
    }
`;

export const Details = styled(Div)`
    position: relative;
    display: block;
    padding: 2rem 0;

    background: ${props => props.theme.bc4};
`;

export const Date = styled.time`
    position: relative;
    display: block;

    font-size: 1.4rem;
    color: ${props => props.theme.bc1};
`;

export const Timings = styled(Div)`
    position: relative;
    display: block;

    margin-bottom: 2rem;
`;

export const Start = styled(H3)`
    display: inline-block;
    vertical-align: middle;

    color: ${props => props.theme.bc1};
    
    font-size: 1.4rem !important;
    // ${breakup.medium`
    //     font-size: 3vw !important;
    // `}
    //
    // ${breakup.large`
    //     font-size: 2vw !important;
    // `}
    //
    // ${breakup.xlarge`
    //     font-size: 1.8vw !important;
    // `}
    //
    // ${breakup.xxlarge`
    //     font-size: 1.4vw !important;
    // `}
`;

export const End = styled(H3)`
    display: inline-block;
    vertical-align: middle;

    color: ${props => props.theme.bc1};

    font-size: 1.4rem !important;
    // ${breakup.medium`
    //     font-size: 2.8vw !important;
    // `}
    //
    // ${breakup.large`
    //     font-size: 2vw !important;
    // `}
    //
    // ${breakup.xlarge`
    //     font-size: 1.8vw !important;
    // `}
    //
    // ${breakup.xxlarge`
    //     font-size: 1.4vw !important;
    // `}
`;

export const Iconwrap = styled(Div)`
    display: inline-block;
    vertical-align: middle;

    svg {
        margin-top: 1rem;

        ${GradientIcon}
        width: 2.4rem;
        vertical-align: middle;
    }
`;

export const Full = styled(H3)`
    position: relative;
    display: block;

    color: ${props => props.theme.bc1};
`;