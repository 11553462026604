// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import Button from 'components/global/buttons/primary';
import { observer } from 'mobx-react';

// Styles
// ------
import { Jacket, EmailList, EmailListItem, Text, Remove, InputWrapper, InputLabel, EmailInput, AddEmail } from './shareWithFriendsStyles';
import Loader from "../../loader";

// Component
// ------
class ShareWithFriends extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            emails: [],
            sending: false,
            sent: false
        }
    }

    addEmail() {
        var emails = [...this.state.emails];
        emails.push(this.newText.value);

        this.setState({emails});

        // Reset input
        this.newText.value = "";
    }

    removeEmail = (index) => {
        const emails = this.state.emails;
        emails.splice(index, 1);

        this.setState({ emails });
    }

    share = async () => {
        this.setState({sending: true})

        let response = await fetch("https://us-central1-lokus-mvp.cloudfunctions.net/share", {
            method: "POST",
            headers: {"Content-Type": "text/plain"},
            body: JSON.stringify({
                booking: this.props.data.booking,
                location: this.props.data.location,
                emails: this.state.emails,
                user: this.props.authUser.uid
            })
        });

        await response.json();


        this.setState({sending: false, sent: true})
    }

    render() {
        
        if (this.state.sending) {
            return <Loader/>
        }
        
        
        return (
            <Jacket>
                {/* List the emails */}
                <EmailList>
                    {this.state.emails.map((email, index) => (
                        <EmailListItem key={index}>
                            <Text ref={(ip) => {this.thisText = ip}}>{email}</Text>
                            {!this.state.sent && <Remove onClick={() => this.removeEmail(index)}>
                                <Icon type="close" />
                            </Remove>}
                        </EmailListItem>
                    ))}
                </EmailList>
                
                [{this.props.data.seats}]

                {/* Add Email addresses */}
                {!this.state.sent && (this.state.emails.length < this.props.data.seats) && <InputWrapper>
                    <InputLabel>Add Email Addresses:</InputLabel>
                    <EmailInput type="text" ref={(ip) => {this.newText = ip}} placeholder="Email Address" />
                    <AddEmail onClick={this.addEmail.bind(this)}>Add</AddEmail>
                </InputWrapper>}

                {/* Share with these emails */}
                {this.state.sent ? <Button button fullWidth type="ghost" textColor="dark" text="Shared" disabled /> : 
                    
                    this.state.emails.length > 0 ?
                        <Button button fullWidth type="ghost" textColor="dark" text="Share Booking" onClick={this.share} />
                    :
                        <Button button fullWidth type="ghost" textColor="dark" text="Share Booking" disabled />
                }
            </Jacket>
        )
    }
}

export default observer(ShareWithFriends);