// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';
import { observer } from 'mobx-react';

// Styles
// ------
import {Widget, WidgetJacket, WidgetTitle, Title, ArrowJacket} from '../filterModalStyles';
import { SeatsJacket, Label, SeatButtonJacket, Seat, SeatSelector, SeatList, SeatItem, SeatListInner } from './seatsStyles';

// States
// ------
import { scheduleFilters } from 'states/lokationSidebar';

// Component
// ------
class filterModalSeats extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            seatSelectorOpen: false,

            selectedSeat: scheduleFilters.seats,
            possibleSeats: [1, 2,4,6],
            open: false,
            
        }
    }

    handleSeatSelector = (e) => {
        this.setState({seatSelectorOpen: !this.state.seatSelectorOpen})
    }

    handleClear = () => {
        // Clear the global state of the date filter
        scheduleFilters.seats = "";
        this.setState({selectedSeat: ""});
        this.setState({seatSelectorOpen: false})
    }

    handleNewSeat = (e) => {
        // Set the date and save to global state
        scheduleFilters.seats = 1 * e.target.getAttribute('value');
        scheduleFilters.fulldayPass = scheduleFilters.seats === 1;

        if (scheduleFilters.fulldayPass) {
            // auto-set the start and end time
            scheduleFilters.timeStart = 8 * 60;
            scheduleFilters.timeEnd = 18 * 60;
        }

        this.setState({selectedSeat: scheduleFilters.seats, seatSelectorOpen: false});
        this.props.onChange();
        
    }

    seatName = (seats) => {
        if (seats === 0) {
            return "";
        }
        if (seats === 1) {
            return "1 Seat (Day Pass) @ Shared Table";
        }
        return `${seats} Seats @ Reserved Table`;
    }

    render() {
        const SelectorOpenChecker = this.state.seatSelectorOpen;

        return (
            <Widget>
                <WidgetJacket>
                    <WidgetTitle  onClick={e => this.props.onToggleOpen(this.props.open ? -1 : 2)}>
                        <Row isExpanded equal>
                            <Column small={10}>
                                <Title  light={!this.props.open}>{this.props.open || this.state.selectedSeat === 0 ? 'Seat(s)' : this.seatName(this.state.selectedSeat) }</Title>
                            </Column>
                            <Column small={2}>
                                <ArrowJacket big isOpen={this.props.open}>
                                    <Icon type="small-arrow-down" />
                                </ArrowJacket>
                            </Column>
                        </Row>
                    </WidgetTitle>
                    { this.props.open && <SeatsJacket>
                        <Row isExpanded>
                            <Column small={3}>
                                <Label>Seat(s)</Label>
                            </Column>
                            <Column small={9}>
                                <Div relative text="right">
                                    <SeatButtonJacket onClick={this.handleSeatSelector} isOpen={SelectorOpenChecker}>
                                        <Seat>{this.seatName(scheduleFilters.seats)}</Seat>
                                        <Icon type="small-arrow-down" />
                                    </SeatButtonJacket>
                                </Div>
                            </Column>
                        </Row>
                        <SeatSelector isOpen={SelectorOpenChecker}>
                            <Row isExpanded>
                                <Column small={12}>
                                    <SeatList>
                                        {
                                            this.state.possibleSeats.map(seat => (
                                                <SeatItem key={seat} onClick={(e) => this.handleNewSeat(e)} value={seat} selected={this.state.selectedSeat === seat}>{this.seatName(seat)}</SeatItem>
                                            ))
                                        }
                                    </SeatList>
                                </Column>
                            </Row>
                            <SeatListInner />
                        </SeatSelector>
                    </SeatsJacket> }
                </WidgetJacket>
            </Widget>
        )
    }
}

export default observer(filterModalSeats)