// Imports
// ------
import React from 'react';

// Styles
// ------
import { ItemJacket, ItemText, Switch } from '../listStyles';
import {withFirebase} from "../../../../../lib/Firebase";

// Component
// ------
class EmailItem extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isActive: false,
            hasEmails: props.authUser.hasEmails,
        }
    }
    
    handleSwitch = () => {
        // Component state

        this.setState({hasEmails: !this.state.hasEmails}, () => {
            this.props.firebase.db.doc(`users/${this.props.authUser.uid}`).set({hasEmails: this.state.hasEmails}, {merge: true});
        })
    }
    
    render() {
        return (
            <ItemJacket>
                <ItemText>Email</ItemText>
                <Switch onClick={this.handleSwitch} isActive={this.state.hasEmails} />
            </ItemJacket>
        )
    }
}

export default withFirebase(EmailItem);