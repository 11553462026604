import {contains} from "../../Util/Array";
import {isToday} from "../../Util/TimeUtil";


class LokusSearch {

    constructor(firebase) {
        this.firebase = firebase;

    }


    /**
     * Find passes for a specific date
     *
     * @param date - The date to look for passes
     * @param passes - number of passes to look for. This is here for future support, but we'll always be searching for a single pass for now.
     * @param callback - returns a list of location ids that match this search.
     */
    findPasses = (date, passes, callback) => {

        if (this.search_subscription) {
            this.search_subscription();
        }

        if (date === null) {
            return;
        }
        
        if (isToday(date) && (new Date()).getHours() >= 18) {
            callback([])
            return;
        }
        
        let query = this.firebase.tables()
            .where("active", "==", true)
            .where("shared", "==", true);


        this.search_subscription = query.onSnapshot(snapshot => {
            let tables = {};
            let ids = [];
            let locations = {};
            if (snapshot.size) {

                snapshot.forEach(doc => {
                    let table = {...doc.data(), uid: doc.id};

                    ids.push(doc.id);
                    tables[doc.id] = table;
                    locations[table.location] = true;

                });

            }

            console.log("Founds shared tables", ids);
            
            this.filterBookingPasses(ids, date, locations, tables, callback);

        })
        

    }


    /**
     * Finds tables for specific times
     *
     * First we get all the tables that are active and match the number of seats, and are available at the start_time
     * We then filter that list of tables, removing any that don't match the end time, or that have any exclusion times for the selected date
     * Last we run the list of table ids against any bookings for that date.
     *
     * @param date - the date to search for
     * @param start_time - start time in minutes
     * @param end_time - end time in minutes
     * @param seats - number of seats for that table
     * @param callback - ids of the locations that match.
     */
    findTables = (date, start_time, end_time, seats, callback) => {
        if (this.search_subscription) {
            this.search_subscription();
        }
        
        if (date === null) {
            return;
        }
        let original_start_time = start_time;
        if (start_time === -1) {
            start_time = 24 * 60; // so we partial match on the start date.
        }
        
        let query = this.firebase.tables()
            .where("active", "==", true)
            .where("shared", "==", false)
            .where("start_time", "<=", start_time);
        
        if (seats > 0) {
            query = query.where("seats", "==", seats)
        }
        
        this.search_subscription = query.onSnapshot(snapshot => {
                let tables = {};
                let ids = [];
                let locations = {};
                if (snapshot.size) {

                    snapshot.forEach(doc => {
                        let table = {...doc.data(), uid: doc.id};

                        // is the timing correct?
                        if (end_time === -1 || table.end_time >= end_time) {
                            // do we have any exclusions?
                            let valid = true;
                            if (table.exclusions) {
                                let excl = table.exclusions.any ? table.exclusions.any :
                                    (table.exclusions[date] ? table.exclusions[date] : null);
                                if (excl) {
                                    let sh = excl.start_hour;
                                    let eh = excl.end_hour;

                                    if (end_time === -1 || original_start_time === -1) { // if this was a partial match and we had an exclusion on that date, just skip the whole table.
                                        valid = false;
                                    } else {
                                        if (sh < end_time && eh > start_time) {
                                            valid = false;
                                        }
                                    }

                                }
                            }

                            if (valid) {

                                ids.push(doc.id);
                                tables[doc.id] = table;
                                locations[table.location] = true;
                            }

                        }


                    });

                }

                console.log("Founds tables", ids);
                
                this.filterBookings(ids, date, start_time, end_time, locations, callback);

            })


    }


    /**
     * Filter a list of results, removing any that already have matching bookings.
     *
     * We have a bunch of potential table ids, and in order to confirm the bookings with as little queries as possible,
     * we're going to select all the bookings for that day. Until there's a very large number of bookings per day, this
     * query should return very little results, which makes it safe to run without worrying about potentially reading
     * too much data.
     *
     * @param ids - list of table ids.
     * @param date - date
     * @param start_time - start time in minutes
     * @param end_time = end time in minutes
     * @param locations - list of location ids that match the selected tables.
     * @param callback - called with the list of filtered location ids
     */
    filterBookings = (ids, date, start_time, end_time, locations, callback) => {

        if (this.booking_subscription) {
            this.booking_subscription();
        }
        
        this.booking_subscription = this.firebase.bookings()
            .where("date", "==", date)
            .where("is_table", "==", true)
            .onSnapshot(snapshot => {

                console.log("Got", snapshot.size);
                if (snapshot.size) {
                    
                    snapshot.forEach(doc => {
                        let booking = doc.data();
                        if (contains(ids, booking.table)) {
                            let sh = booking.start_time;
                            let eh = booking.end_time;

                            if (sh < end_time && eh > start_time) {
                                locations[booking.location] = false;
                            }
                        }
                    });

                } else {

                }

                let locs = Object.keys(locations).filter(key => locations[key]);


                console.log("Locations after booking filter", locs);
                
                callback(locs);
            })

    }


    /**
     * TBD
     *
     * @param ids - list of table ids.
     * @param date - date
     * @param locations - list of location ids that match the selected tables.
     * @param tables - list of tables to check the availability count.
     * @param callback - called with the list of filtered location ids
     */
    filterBookingPasses = (ids, date, locations, tables, callback) => {

        if (this.booking_subscription) {
            this.booking_subscription();
        }

        this.booking_subscription = this.firebase.bookings()
            .where("date", "==", date)
            .where("is_table", "==", false)
            .onSnapshot(snapshot => {

                console.log("Got", snapshot.size);
                let total_bookings = {};
                if (snapshot.size) {


                    snapshot.forEach(doc => {
                        let booking = doc.data();
                        if (!total_bookings[booking.table]) {
                            total_bookings[booking.table] = 0;
                        }
                        if (contains(ids, booking.table)) {
                            total_bookings[booking.table]++;
                        }
                    });

                }

                console.log(tables, total_bookings);
                
                let locs = Object.values(tables).filter(table => !total_bookings[table.uid] || table.seats > total_bookings[table.uid]).map(table => table.location);

                console.log("Locations before unique", locs);
                locs = locs.filter((value, index) => locs.indexOf(value) === index);
                console.log("Locations after booking filter", locs);

                callback(locs);
            })

    }
    

}


export default LokusSearch;
