// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup } from 'components/global/theme/breakpoints';

// Exports
// ------
export const Jacket = styled.div`
    ${props => props.color ? css`
        background-color: ${props.color};
    ` : css`
        background-color: ${props => props.theme.bc2};
    `};

    padding-bottom: 14rem;
    ${breakup.large`padding-bottom: 0;`}
`;