import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../../lib/Firebase';



import Input from '../../components/global/formfields/input';
import { Div } from '../../components/global/theme/reusables/reusables';
import { Row, Column } from '../../components/global/theme/grid/grid';
import { Link } from 'react-router-dom';


import { Jacket, Title, LoginForm, Inputwrap, Submission, Signin, Join } from '../../components/login/loginStyles';
import Header from "../../components/global/header/header";
import {notificationState} from "../../states/notifications";
import {observer} from "mobx-react";

const initial_state = {
    email: '',
    error: null,
};


class ForgotPasswordBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...initial_state };
    }

    handleReset = () => {
        const { email } = this.state;

        this.props.firebase.auth
            .sendPasswordResetEmail(email)
            .then(() => {
                this.setState({ error: "Password reset link sent. Please check your email to continue.", email: ""});
            })
            .catch(error => {
                this.err("Error", 'Invalid e-mail address');
                // this.setState({ error });
            });

    };
    err = (title, text) => {
        notificationState.generalTitle = title;
        notificationState.generalText = text;
        notificationState.generalButtons = [];
        notificationState.generalOpen = true
    }
    

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, error } = this.state;

        const isInvalid = email === '';

        return (
            <>

                <Header lightLogo goHome />
            <Jacket>
                <Div pad mpad>
                    <Row isExpanded>
                        <Column small={12} medium={10} pushOnMedium={1}>
                            <Title>Forgot your password?</Title>
                        </Column>
                    </Row>
                    <Row isExpanded isCollapsed>
                        <Column small={12} medium={5} pushOnMedium={1} large={4}>
                            <LoginForm>
                                <Row isExpanded>
                                    <Column small={12}>
                                        
                                        { error && <div>{error}</div>}
                                        
                                        <Inputwrap>
                                            <Input
                                                type="text"

                                                value={email}
                                                onChange={this.onChange}
                                                light
                                                transparent
                                                required
                                                placeholder="Email Address"
                                                name="email"
                                            />
                                        </Inputwrap>
                                    </Column>

                                    <Column small={12}>
                                        <Submission>
                                            <Signin
                                                disabled={isInvalid}
                                                onClick={this.handleReset}>Send Reset Link</Signin>
                                            <Join>Remembered your password? <Link to="/login">Sign In</Link></Join>
                                        </Submission>
                                    </Column>
                                </Row>
                            </LoginForm>
                        </Column>
                    </Row>
                </Div>
            </Jacket>
                </>
        );
    }
}


const ForgotPassword = compose(
    withRouter,
    withFirebase,
    observer,
)(ForgotPasswordBase);


export default ForgotPassword;
export {ForgotPassword};
