import React from 'react';
import { compose } from 'recompose';
import Books from '../components/bookings';
import { AuthUserContext, withAuthorization, withBookings } from '../lib/Session';
import {themeStyles} from "../components/global/theme/theme";
import Background from "../components/global/background/background";


const Bookings = (props) => (
    <Background color={themeStyles.bc1}>
    <AuthUserContext.Consumer>
        {authUser => (
            <Books {...props} authUser={authUser} />
        )}
    </AuthUserContext.Consumer>
    </Background>
);

const condition = authUser => !!authUser;

export default compose(
    withBookings,
    withAuthorization(condition),
)(Bookings);
