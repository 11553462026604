// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';

// Styles
// ------
import { Jacket, Text } from './faqsButtonStyles';

// States
// ------
import { userState } from 'states/user';

// Component
// -------
export default class FaqsButton extends React.Component {
    handleButton = () => {
        userState.faqsIsOpen = !userState.faqsIsOpen;
    }

    render() {
        return (
            <Jacket onClick={this.handleButton}>
                <Text>FAQs</Text>
                <Icon type="small-arrow-right" />
            </Jacket>
        )
    }
}