// Imports
// ------
import styled from "styled-components";
import { Section, Div, H4, Span, GradientIcon } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    display: block;
`;

export const Label = styled(Span)`
    display: block;
    margin-bottom: 1rem;
    
    font-size: 1.4rem !important;
`;

export const Transit = styled(Div)`
    position: relative;
    margin-bottom: 2rem;
`;

export const Itemwrap = styled(Div)`
    display: flex;
    flex-flow: row;
`;

export const Iconwrap = styled(Div)`
    margin-right: 2rem;
    width: 3rem;

    svg {
        ${GradientIcon};
        width: 3rem;
        height: 3rem;
    }
`;

export const Contentwrap = styled(Div)`
    position: relative;
    flex: 1;
`;

export const Title = styled(H4)`
    display: inline;
    margin-right: 2rem;
    color: ${props => props.theme.bc3};
`;

export const Text = styled(Span)`
    display: inline;

    font-size: 1.4rem !important;
`;

export const Image = styled.img`
    display: inline;
    max-width: 100%;
    height: auto;
`;
