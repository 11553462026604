// Imports
// ------
import { observable } from "mobx";

// Exports
// ------
export const userState = observable({
    isLoggedIn: false,
    editProfileOpen: false,
    editPasswordOpen: false,
    helpIsOpen: false,
    legalIsOpen: false,
    faqsIsOpen: false,
    notificationsIsOpen: false,
    aboutIsOpen: false,
});