// Imports
// ------
import styled from "styled-components";
import { Section, Div, H4, P, Span, GradientIcon } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    display: block;
`;

export const Option = styled(Div)`
    position: relative;
    display: block;
    margin-bottom: 1rem;
`;

export const Text = styled(H4)``;

export const Cost = styled(Span)``;

export const ContentJacket = styled(Div)`
    position: relative;
    display: block;
    margin-bottom: 4rem;
`;

export const Iconwrap = styled(Div)`
    display: inline-block;
    margin-bottom: 1rem;

    svg {
        ${GradientIcon};
        width: 3rem;
        height: 3rem;
    }
`;

export const Title = styled(H4)`
    display: block;
    margin-bottom: 1rem;
`;

export const Content = styled(P)``;