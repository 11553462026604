import React from 'react';
import { withFirebase } from '../Firebase';

const withBookings = Component => {
    class WithBookings extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                bookings: [],
            };
        }

        componentDidMount() {
            let user = JSON.parse(localStorage.getItem('authUser'))

            if (user) {
                this.listener = this.props.firebase.bookings()
                    .where("user", "==", user.uid)
                    .orderBy("date", "desc")
                    .onSnapshot(snapshot => {
                        if (snapshot.size) {
                            let bookings = [];
                            snapshot.forEach(doc =>
                                bookings.push({...doc.data(), uid: doc.id}),
                            );

                            this.setState({
                                bookings: bookings,
                            });
                        } else {
                            this.setState({bookings: []});
                        }
                    });
            }
        }

        componentWillUnmount() {
            if (this.listener) {
                this.listener();
            }
        }

        render() {
            return (
                <Component {...this.props} bookings={this.state.bookings} />
            );
        }
    }

    return withFirebase(WithBookings);
};

export default withBookings;
