import React, { Component } from 'react';

import { AuthUserContext } from '../../lib/Session';
import { withFirebase } from '../../lib/Firebase';
import {minutesToTime, timeToMinutes} from '../../lib/Util/TimeUtil';
import {Link} from "react-router-dom";
import {AdminJacket, Jacket} from "./contentStyles";
import {Div} from "../../components/global/theme/reusables/reusables";
import Button from "../../components/global/buttons/primary";
import {Column, Row} from "../../components/global/theme/grid/grid";
import {Title} from "../../components/lokations/list/listStyles";

import Input from "../../components/global/formfields/input";

import {Panel, Heading, SmallButton} from './contentStyles';

class AdminLocation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            location: null,
            passes: [],
            tables: [],
            bookings: [],
            dates: [],
            new_date_passes: {},
            date_passes: {},
            users: {},
            section: 0,

            new_name: "",
            new_seats: "",
            new_start_time: "",
            new_end_time: "",
            new_workspace: "",
            
            editing_table: null,
            editing_workspace: "",
            editing_name: "",
            editing_seats: "",
            editing_start_time: "",
            editing_end_time: "",

        };
    }

    componentDidMount = () => {


        let max = 14; // we'll be able to set for 14 days in the future.
        let passes = {};
        let new_date_passes = {};
        let dates = [];
        for (let i = 0; i < max; i++) {

            let date = new Date();
            date.setDate(date.getDate() + i);

            let month = (date.getMonth() + 1);
            if (month < 10) {
                month = `0${month}`;
            }

            let day = date.getDate();
            if (day < 10) {
                day = `0${day}`;
            }
            let format = `${date.getFullYear()}-${month}-${day}`;

            dates.push({
                date: date,
                label: format
            })
            passes[format] = null;
            new_date_passes[format] = 5;

        }

        this.setState({dates: dates, date_passes: passes, new_date_passes: new_date_passes});


        this.onListenForLocation();

    }

    onListenForLocation = () => {
        this.setState({ loading: true });

        this.unsubscribe = this.props.firebase
            .location(this.props.match.params.location)
            .onSnapshot(snapshot => {
                if (snapshot) {
                    let location = { ...snapshot.data(), uid: this.props.match.params.location };
                    this.setState({
                        location: location,
                        loading: false,
                    });
                } else {
                    console.log("No location", snapshot, this.props.match.params);
                    this.setState({
                        location: null,
                        loading: false,
                    });
                }
            });

        this.getPasses()

        this.unsubscribe3 = this.props.firebase
            .tables()
            .onSnapshot(snapshot => {
                if (snapshot.size) {
                    let tables = [];

                    snapshot.forEach(doc => {
                            let table = {...doc.data(), uid: doc.id};
                            if (table.location === this.props.match.params.location) {
                                tables.push(table);
                            }
                        }
                    );

                    this.setState({
                        tables: tables,
                    });
                } else {

                }
            });


        this.unsubscribe4 = this.props.firebase
            .bookings()
            .onSnapshot(snapshot => {
                if (snapshot.size) {
                    let bookings = [];

                    snapshot.forEach(doc => {
                            let booking = {...doc.data(), uid: doc.id};
                            if (booking.location === this.props.match.params.location) {
                                bookings.push(booking);
                            }
                        }
                    );

                    this.setState({
                        bookings: bookings,
                    });
                } else {

                }
            });


        this.unsubscribe5 = this.props.firebase
            .users()
            .onSnapshot(snapshot => {
                if (snapshot.size) {
                    let users = {};

                    snapshot.forEach(doc => {
                            let user = {...doc.data(), uid: doc.id};
                            users[doc.id] = user;

                        }
                    );

                    this.setState({
                        users: users,
                    });
                } else {

                }
            });

    };

    getPasses() {
        if (this.unsubscribe2) {
            this.unsubscribe2();
        }
        this.unsubscribe2 = this.props.firebase
            .passes()
            .onSnapshot(snapshot => {
                if (snapshot.size) {
                    let passes = [];
                    let date_passes = this.state.date_passes;

                    snapshot.forEach(doc => {
                            let pass = {...doc.data(), uid: doc.id};
                            if (pass.location === this.props.match.params.location) {
                                passes.push(pass);
                                date_passes[pass.date] = pass;
                            }
                        }
                    );

                    this.setState({
                        passes: passes,
                        date_passes: date_passes
                    });
                } else {

                }
            });

    }

    componentWillUnmount() {
        this.unsubscribe();
        this.unsubscribe3();
        this.unsubscribe4();
        this.unsubscribe5();

    }

    onNewDatePass = (date, event) => {
        let value = 1 * event.target.value;
        if (!isNaN(value) && value >= 0) {
            let new_date_passes = this.state.new_date_passes;
            new_date_passes[date] = value;
            this.setState({new_date_passes: new_date_passes});
        }
    }

    onNewPasses = (date) => {
        let value = 1 * this.state.new_date_passes[date];
        if (value > 0) {
            this.props.firebase.passes().doc().set({
                date: date,
                location: this.props.match.params.location,
                available: value,
                total: value
            });
        }
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onRemovePasses = (date) => {
        let pass = this.state.date_passes[date];
        if (pass) {
            this.props.firebase.passes().doc(pass.uid).delete().then(e => {
                let date_passes = this.state.date_passes;
                date_passes[date] = null;
                this.setState({date_passes: date_passes});
                this.getPasses();
            })
        }

    }

    toggleActive = (table) => {

        this.props.firebase.tables().doc(table.uid).set({
            active: !table.active
        }, {merge: true});


    }

    toggleShared = (table) => {
        this.props.firebase.tables().doc(table.uid).set({
            shared: !table.shared
        }, {merge: true});


    }

    togglePassActive = (pass) => {

        this.props.firebase.passes().doc(pass.uid).set({
            active: !pass.active
        }, {merge: true});


    }

    addTable = () => {

        let {new_workspace, new_name, new_seats, new_start_time, new_end_time} = this.state;
        new_seats = 1 * new_seats;

        new_start_time = timeToMinutes(new_start_time);
        new_end_time = timeToMinutes(new_end_time);

        if (new_workspace === "" || new_name === "" || isNaN(new_seats) || new_seats === 0 || !new_start_time || !new_end_time) {
            return;
        }

        this.props.firebase.tables().doc().set({
            active: true,
            name: new_name,
            workspace: new_workspace,
            seats: new_seats,
            start_time: new_start_time,
            end_time: new_end_time,
            shared: false,
            location: this.props.match.params.location,
        }).then(e => {
            this.setState({
                new_name: "",
                new_seats: "",
                new_start_time: "",
                new_end_time: "",
                new_workspace: "",
            })
        })


    }

    addPass = () => {

        let {new_name, new_seats, new_start_time, new_end_time} = this.state;
        new_seats = 1 * new_seats;

        new_start_time = timeToMinutes(new_start_time);
        new_end_time = timeToMinutes(new_end_time);

        if (new_name === "" || isNaN(new_seats) || new_seats === 0 || !new_start_time || !new_end_time) {
            return;
        }

        this.props.firebase.passes().doc().set({
            active: true,
            name: new_name,
            seats: new_seats,
            start_time: new_start_time,
            end_time: new_end_time,
            location: this.props.match.params.location,
        }).then(e => {
            this.setState({
                new_name: "",
                new_seats: "",
                new_start_time: "",
                new_end_time: ""
            })
        })


    }
    
    // Booking actions.
    activate = (uid) => {
        this.props.firebase.booking(uid).set({status: "booked"}, {merge: true})
    }
    
    cancelBooking = (uid) => {
        this.props.firebase.booking(uid).set({status: "canceled"}, {merge: true})
        
    }
    
    checkOutBooking = (uid) => {
        this.props.firebase.booking(uid).set({status: "completed"}, {merge: true})
        
    }
    
    editTable = (uid) => {
        let table = this.state.tables.filter(t => t.uid === uid)[0];
        if (!table) {
            return;
        }
        
        this.setState({
            editing_workspace: table.workspace,
            editing_name: table.name,
            editing_seats: table.seats,
            editing_table: table.uid,
            editing_start_time: table.start_time,
            editing_end_time: table.end_time,
        })
        
        
    }

    saveEditing = () => {
        this.props.firebase.table(this.state.editing_table).set({
            name: this.state.editing_name,
            workspace: this.state.editing_workspace,
            seats: this.state.editing_seats,
            start_time: this.state.editing_start_time,
            end_time: this.state.editing_end_time,
        }, {merge: true})

        this.setState({
            editing_workspace: "",
            editing_name: "",
            editing_seats: "",
            editing_table: "",
            editing_start_time: "",
            editing_end_time: "",
        })
    }

    render() {
        const { location, section } = this.state;

        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    <Jacket>

                        {location && <Div relative mpad pad>
                            <AdminJacket>
                            <Row isExpanded>
                                <Column small={12} medium={10} pushOnMedium={1}>
                                    <Title>{ location.name }</Title>
                                </Column>
                            </Row>
                            <Row isExpanded isCollapsed>
                                <Column small={12} medium={10} pushOnMedium={1}>
                                    <div style={{float: "right"}}>
                                        <Link to={`/admin/edit-location/${location.uid}`}>Edit</Link>
                                    </div>
                                </Column>
                            </Row>

                            <Row isExpanded isCollapsed>

                                <Column small={12} medium={10} pushOnMedium={1}>
                                    <div style={{display: "flex"}}>
                                    <Button button type={section===0?"full":"ghost"} onClick={e => this.setState({section: 0})} text="Tables" />
                                    <Button button type={section===1?"full":"ghost"} onClick={e => this.setState({section: 1})} text="Bookings" />
                                    </div>
                                </Column>

                                <Column small={12} medium={10} pushOnMedium={1}>
                                    
                                    
                                { section === 0 ?

                                    <Panel>
                                        <Heading>Edit Tables</Heading>
                                        
                                        <table width="100%">
                                            <thead>
                                            <tr>
                                                <th>Drop-In Workspace</th>
                                                <th>Table Name</th>
                                                <th># of Seats</th>
                                                <th>Start Time</th>
                                                <th>End Time</th>
                                                <th>Shared Table</th>
                                                <th>Active</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            
                                            <tbody>
                                            {
                                                this.state.tables.map(table => (

                                                    <tr key={table.uid} >

                                                        <td >
                                                            { this.state.editing_table === table.uid ?
                                                                <Input textColor="dark" type="text" placeholder="Drop-in Workspace" name="editing_workspace" value={this.state.editing_workspace} onChange={this.onChange} />
                                                                :
                                                                table.workspace
                                                            }
                                                        </td>

                                                        <td >
                                                            { this.state.editing_table === table.uid ?
                                                                <Input textColor="dark" type="text" placeholder="Table Name" name="editing_name" value={this.state.editing_name} onChange={this.onChange} />
                                                                :
                                                                table.name
                                                            }
                                                        </td>

                                                        <td >
                                                            { this.state.editing_table === table.uid ?
                                                                <Input textColor="dark" type="text" placeholder="Seats" name="editing_seats" value={this.state.editing_seats} onChange={this.onChange} />
                                                                :
                                                                table.seats
                                                            }
                                                        </td>


                                                        <td >
                                                            { this.state.editing_table === table.uid ?
                                                                <Input textColor="dark" type="text" placeholder="Start Time" name="editing_start_time" value={this.state.editing_start_time} onChange={this.onChange} />
                                                                :
                                                                minutesToTime(table.start_time)
                                                            }
                                                        </td>


                                                        <td >
                                                            { this.state.editing_table === table.uid ?
                                                                <Input textColor="dark" type="text" placeholder="End Time" name="editing_end_time" value={this.state.editing_end_time} onChange={this.onChange} />
                                                                :
                                                                minutesToTime(table.end_time)
                                                            }
                                                        </td>

                                                        <td>
                                                            <input type="checkbox" checked={table.shared} onChange={e => this.toggleShared(table)} /> Shared
                                                        </td>
                                                        <td>
                                                            <input type="checkbox" checked={table.active} onChange={e => this.toggleActive(table)} /> Active
                                                        </td>


                                                        <td>
                                                            {this.state.editing_table === table.uid ?
                                                                <Button button type="ghost" text="Save"
                                                                        onClick={
                                                                            this.saveEditing
                                                                        }/>
                                                                :
                                                                <Button button type="ghost" text="Edit Table"
                                                                        onClick={e => {
                                                                            this.editTable(table.uid)
                                                                        }}/>
                                                            }
                                                        </td>
                                                        

                                                    </tr>))
                                            }

                                            <tr>
                                                <td><Input textColor="dark" type="text" placeholder="Drop-in Workspace" name="new_workspace" value={this.state.new_workspace} onChange={this.onChange} /></td>
                                                <td><Input textColor="dark" type="text" placeholder="Table Name" name="new_name" value={this.state.new_name} onChange={this.onChange} /></td>
                                                <td><Input textColor="dark" type="text" placeholder="Seats" name="new_seats" value={this.state.new_seats} onChange={this.onChange} /></td>
                                                <td><Input textColor="dark" type="text" placeholder="Start Time" name="new_start_time" value={this.state.new_start_time} onChange={this.onChange} /></td>
                                                <td><Input textColor="dark" type="text" placeholder="End Time" name="new_end_time" value={this.state.new_end_time} onChange={this.onChange} /></td>
                                                <td>
                                                    <Button button type="ghost" text="Add Table" onClick={this.addTable} /></td>
                                            </tr>
                                            
                                            </tbody>
                                            
                                        </table>


 </Panel>: "" }

                                { section === 1 ? <Panel>

                                    <Heading>Bookings</Heading>
                                    
                                    <table width="100%">
                                        <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Type</th>
                                            <th>User</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        
                                        <tbody>
                                        {this.state.bookings.map(booking => (
                                            <tr key={booking.uid}>

                                                <td><span>{booking.date}</span></td>
                                                <td>
                                                    {booking.is_table ?
                                                        <span>
                                                    <strong className="font-bold">{booking.seats}</strong> seats from <strong className="font-bold">{minutesToTime(booking.start_time)}</strong> to <strong className="font-bold">{minutesToTime(booking.end_time)}</strong>
                                                </span>: <span>
                                                    <strong className="font-bold">{booking.passes}</strong> day pass
                                                </span>}
                                                    
                                                </td>
                                                <td>{this.state.users[booking.user] && <span>{this.state.users[booking.user].fullName}</span>}</td>
                                                <td><span>{ booking.status }</span></td>
                                                <td>
                                                    {
                                                        booking.status === 'booked' ?
                                                            <>
                                                                <SmallButton type="button" onClick={e => this.cancelBooking(booking.uid)}>Cancel</SmallButton>
                                                            </>
                                                            :
                                                            ""

                                                    }

                                                    {
                                                        booking.status === 'started' ?
                                                            <>
                                                                <SmallButton type="button" onClick={e => this.checkOutBooking(booking.uid)}>Check-Out</SmallButton>
                                                            </>
                                                            :
                                                            ""

                                                    }

                                                    {
                                                        booking.status === 'pending' ?
                                                            <>
                                                                <SmallButton type="button" onClick={e => this.activate(booking.uid)}>Activate</SmallButton>
                                                            </>
                                                            :
                                                            ""

                                                    }
                                                    
                                                    
                                                </td>
                                                
                                            </tr>
                                        ))}
                                        </tbody>
                                        
                                    </table>
                                    

                                </Panel> : "" }

                                </Column>
                            </Row>
                            </AdminJacket>
                        </Div> 
                            
                            
                        }
                    
                    </Jacket>
                )}
            </AuthUserContext.Consumer>
        );
    }
}

export default withFirebase(AdminLocation);
