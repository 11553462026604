// Imports
// ------
import React from 'react';
import Location from '../location/location';
import Amenities from '../amenities/amenities';
import Pricing from '../pricing/pricing';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// Styles
// ------
import { Jacket } from './contentStyles';

// Component
// ------
export default class LokationInformationContent extends React.Component {
    render() {
        return (
            <Jacket>
                <Tabs>
                    <TabList>
                        <Tab>Pricing</Tab>
                        <Tab>Amenities</Tab>
                        <Tab>Transit</Tab>
                    </TabList>



                    <TabPanel>
                        <Pricing
                            pricingOptions={this.props.pricingOptions}
                            pricingContent={this.props.pricingContent}
                        />
                    </TabPanel>
                    <TabPanel>
                        <Amenities amenities={this.props.amenities} />
                    </TabPanel>
                    <TabPanel>
                        <Location
                            address={this.props.address}
                            mapData={this.props.mapData}
                            mapContent={this.props.mapContent}
                        />
                    </TabPanel>
                </Tabs>
            </Jacket>
        )
    }
}