// Imports
// ------
import styled, { css } from "styled-components";
import { breakup } from 'components/global/theme/breakpoints';
import { Section, Div, GradientIcon, Span } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    display: block;
    padding: 2rem 0;

    background: ${props => props.theme.bc3};

    color: ${props => props.theme.bc1};
`;

export const Iconwrap = styled(Div)`
    position: relative;

    svg {
        ${GradientIcon}
        width: 3rem
        height: 3rem;
    }
`;

export const Option = styled(Div)`
    position: relative;
    display: block;
    margin-bottom: 1rem;

    cursor: pointer;

    ${props => props.disabled && css`
        pointer-events: none;
    `}
`;

export const Text = styled(Span)`
    font-size: 1.4rem !important;
`;

export const Radio = styled(Div)`
    position: relative;
    display: inline-block;
`;

export const Radioselected = styled(Div)`
    position: relative;
    display: inline-block;

    svg {
        ${GradientIcon}
        width: 2.4rem;
        height: 2.4rem;
    }
`;

export const Radioblank = styled(Div)`
    position: relative;
    display: inline-block;

    width: 2.4rem;
    height: 2.4rem;

    border: 1px solid ${props => props.theme.bc5};
    border-radius: 100%;
`;

export const Textarea = styled.textarea`
    position: relative;
    display: block;
    margin-top: 1rem;
    min-height: 10rem;
    max-height: 10rem;

    background: ${props => props.theme.bc4};
    padding: 1.5rem;

    color: ${props => props.theme.bc1};
    font-size: 1.6rem;

    ${breakup.large`font-size: 1.8rem;`}

    &::placeholder {
        color: ${props => props.theme.bc1};
        opacity: 0.45;
    }
`;