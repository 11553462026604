// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';

// Styles
// ------
import { Jacket, Text } from './legalButtonStyles';

// States
// ------
import { userState } from 'states/user';

// Component
// -------
export default class HelpButton extends React.Component {
    handleButton = () => {
        userState.legalIsOpen = !userState.legalIsOpen;
    }

    render() {
        return (
            <Jacket onClick={this.handleButton}>
                <Text>Legal</Text>
                <Icon type="small-arrow-right" />
            </Jacket>
        )
    }
}