// Imports
// ------
import styled from "styled-components";
import { breakup } from 'components/global/theme/breakpoints';
import { Section, Div, Span } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    display: block;
    padding: 3rem 0;

    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0; left: -2rem; right: -2rem; bottom: 0;
        background: -webkit-linear-gradient(20deg, rgba(252,0,132,1) 0%, rgba(254,73,2,1) 100%);

        ${breakup.large`
            left: -1.5rem; right: -1.5rem;
        `}
    }

    svg {
        fill: ${props => props.theme.bc3};
    }
`;

export const Iconwrap = styled(Div)`
    display: block;
    margin-bottom: 0.5rem;
    text-align: center;

    svg {
        width: 3rem;
        height: 3rem;
    }
`;

export const Text = styled(Span)`
    display: block;
    margin: 0;

    font-size: 1.4rem !important;
    line-height: 2rem !important;
    text-align: center !important;
`;