// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';
import { observer } from 'mobx-react';

// Styles
// ------
import { Jacket, Header, Close, Title, HelpTitle } from './faqsStyles';

// States
// ------
import { userState } from 'states/user';

// Utils
// ------
import Body from 'utils/bodylock';

// Component
// ------
class About extends React.Component {
    toggleClose = () => {
        userState.aboutIsOpen = !userState.aboutIsOpen;
    }

    stateCheck = () => {
        if (userState.aboutIsOpen) {
            Body.lock();
        }

        if (!userState.aboutIsOpen) {
            Body.unlock();
        }
    }

    render() {
        return (
            <Jacket isActive={this.props.isActive}>
                {/* Help header */}
                <Header>
                    <Row isExpanded>
                        <Column small={12} medium={10} pushOnMedium={1}>
                            <Row isExpanded>
                                <Column small={12}>
                                    <Div relative text="center">
                                        <Close onClick={this.toggleClose}>
                                            <Icon type="small-arrow-left" />
                                        </Close>
                                        <Title>About Us</Title>
                                    </Div>
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                </Header>

                <Row isExpanded>
                    <Column small={12} medium={10} pushOnMedium={1}>
                        <Row isExpanded>
                            <Column small={12}>
                                <HelpTitle>About Text</HelpTitle>
                                
                                <p>Something...</p>
                            </Column>
                        </Row>
                    </Column>
                </Row>

            </Jacket>
        )
    }

    componentDidUpdate() {
        this.stateCheck();
    }

    componentWillUnmount() {
        this.stateCheck();
    }
}

export default observer(About);