// Imports
// ------
import styled, { css } from "styled-components";
import { breakup } from 'components/global/theme/breakpoints';
import { Header, Div, GradientIcon } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Header)`
    position: absolute;
    z-index: 900;
    display: block;
    width: 100%;
    margin-top: 4rem;

    ${breakup.large`margin-top: 7rem;`}

    ${props => props.showOnLarge && css`
        display: none;
        ${breakup.large`display: block;`}
    `}
`;

export const LogoWrapper = styled(Div)`
    text-align: center;

    ${breakup.large`text-align: left;`}
`;

export const Logo = styled.img`
    position: relative;
    display: inline-block;

    width: 100%;
    max-width: 10rem;

    margin: 0 auto;

    ${breakup.large`margin: 0;`}
`;

export const Menu = styled.ul`
    list-style: none;
`;

export const MenuItem = styled.li`
    position: relative;
    display: inline-block;
    margin: 0;
    margin-right: 3rem;

    &:last-child { margin-right: 0; }

    a {
        svg {
            ${props => props.theme.animate('0.5s', 'ease-in-out')}
            display: inline-block;
            margin-right: 1rem;
    
            width: 1.8rem;
            vertical-align: middle;
        }

        &:hover {
            svg {
                ${GradientIcon};
            }
        }
    }
`;

export const Span = styled.span`
    display: inline-block;
    vertical-align: middle;
    
    color: ${props => props.theme.bc3};
    font-size: 1.6rem;

    ${breakup.large`font-size: 1.8rem;`}
`;

export const Return = styled.li`
    position: relative;
    display: inline-block;
    margin: 0;

    a {
        svg {
            display: inline-block;
            margin-right: 1rem;
            vertical-align: middle;

            width: 1.8rem;
            height: 1.8rem;
        }
    }
`;