// Imports
// ------
import styled from "styled-components";
import { breakup } from 'components/global/theme/breakpoints';
import { Section, Div } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    display: block;

    padding-top: 2rem;
    text-align: center;

    ${breakup.large`
        padding-top: 7rem;
        text-align: left;
    `}
`;

export const Return = styled(Div)`
    position: relative;
    display: block;
    margin-top: 2rem;

    a {
        font-size: 1.4rem;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
`;