// Imports
// ------
import styled, { css } from "styled-components";
import { breakup } from 'components/global/theme/breakpoints';
import { Button, Div, Span, Gradient, GradientReverse } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Text = styled(Span)`
    ${props => props.theme.animate('0.5s', 'ease-in-out')}
    color: ${props => props.theme.bc3};

    ${props => props.color === "dark" && css`
        color: ${props => props.theme.bc1};
    `}
    
    ${props => props.color === "pink" && css`
        color: ${props => props.theme.bc5};
    `}

    ${props => props.align === "center" && css`
        display: block;
        text-align: center;
    `}
`;

export const ButtonJacket = styled(Button)`
    ${props => props.theme.animate('0.5s', 'ease-in-out')}
    position: relative;
    margin-top: 1rem;
    cursor: pointer;

    ${props => props.disabled && css`
        pointer-events: none;
        opacity: 0.4;
    `}

    ${props => props.type === 'full' && css`
        ${props => props.theme.animate('0.5s', 'ease-in-out')}
        position: relative;
        display: block;
        width: 100%;
        padding: ${props => props.small ? "0.5rem" : "1.5rem 2rem"};
        background: ${Gradient};
        z-index: 1;

        ${breakup.large`
            padding: ${props => props.small ? "0.5rem" : "1.5rem 3rem"};
            width: auto;
        `}

        &:before {
            content: '';
            background: ${GradientReverse};
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
            top: 0; left: 0; right: 0; bottom: 0;
            opacity: 0;
            transition: opacity 0.4s;
        }

        &:hover {
            &:before {
                opacity: 1;
            }
        }
    `}

    ${props => props.type === 'ghost' && css`
        ${props => props.theme.animate('0.5s', 'ease-in-out')}
        position: relative;
        display: block;
        width: 100%;
        box-sizing: border-box;
        
        padding: ${props => props.small ? "0.5rem" : "1.5rem 2rem"};
        z-index: 1;

        border-image: linear-gradient(to right, ${props => props.theme.bc5} 0%, ${props => props.theme.bc6} 100%);
        border-image-slice: 1;
        border-width: 1px;
        border-style: solid;
        background: transparent;

        ${breakup.large`
            padding: ${props => props.small ? "0.5rem" : "1.5rem 3rem"};
            width: auto;
        `}

        &:before {
            ${props => props.theme.animate('0.5s', 'ease-in-out')}
            content: '';
            position: absolute;
            z-index: -1;
            top: 0; right: 0; bottom: 0; left: 0;

            background: linear-gradient(to right, ${props => props.theme.bc5} 0%, ${props => props.theme.bc6} 100%);
            opacity: 0;
        }

        &:hover {
            &:before {
                opacity: 1;
            }

            ${Text} {
                color: ${props => props.theme.bc3};
            }
        }
    `}

    ${props => props.type === 'ghost-light' && css`
        ${props => props.theme.animate('0.5s', 'ease-in-out')}
        position: relative;
        display: block;
        width: 100%;
        box-sizing: border-box;
        
        padding: 1.5rem 2rem;
        z-index: 1;
        
        border: 1px solid ${props => props.theme.bc3};
        background: transparent;

        &:hover {
            background: ${props => props.theme.bc3};

            ${Text} {
                color: ${props => props.theme.bc5};
            }
        }
    `}

    ${props => props.fullWidth && css`width: 100% !important;`}
`;

export const Link = styled(Div)`
    ${props => props.type === 'ghost' && css`
        a {
            ${props => props.theme.animate('0.5s', 'ease-in-out')}
            position: relative;
            display: inline-block;
            box-sizing: border-box;

            padding: 1.5rem 2rem;
            z-index: 1;

            border-image: linear-gradient(to right, ${props => props.theme.bc5} 0%, ${props => props.theme.bc6} 100%);
            border-image-slice: 1;
            border-width: 1px;
            border-style: solid;
            background: transparent;

            ${breakup.large`
                width: auto;
            `}

            &:before {
                ${props => props.theme.animate('0.5s', 'ease-in-out')}
                content: '';
                position: absolute;
                z-index: -1;
                top: 0; right: 0; bottom: 0; left: 0;

                background: linear-gradient(to right, ${props => props.theme.bc5} 0%, ${props => props.theme.bc6} 100%);
                opacity: 0;
            }

            &:hover {
                &:before {
                    opacity: 1;
                }

                ${Text} {
                    color: ${props => props.theme.bc3};
                }
            }
        }
    `}

    ${props => props.type === "text" && css`
        a {
            ${props => props.theme.animate('0.5s', 'ease-in-out')}
            position: relative;

            ${Text} {
                font-size: 1.4rem;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    `}

    ${props => props.fullWidth && css`
        a {
            width: 100% !important;
        }
    `}
`;