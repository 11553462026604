// Imports
// ------
import React from 'react';
// import Map from 'components/global/map/map';
import Icon from 'components/global/icons/icons';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket, Label, Transit, Itemwrap, Iconwrap, Contentwrap, Title, Text, Image } from './locationStyles';

// Component
// ------
export default class LokationLocation extends React.Component {
    render() {
        return (
            <Jacket>
                <Row isExpanded isCollapsed>
                    <Column small={12}>
                        {/*<Map location={this.props.address.location}  />*/}
                        <div><Image src={`/images/${this.props.address.mapImage}`} alt="Lokation Map" /></div>
                    </Column>
                </Row>
                <Row isExpanded isCollapsed>
                    <Column small={12}>
                        <Label>Nearby Transit</Label>
                    </Column>
                    {this.props.address.info && this.props.address.info.map((block, index) =>
                        <Column key={index} small={12} medium={6}>
                            <Transit>
                                <Row isExpanded isCollapsed>
                                    <Column small={12}>
                                        <Itemwrap>
                                            <Iconwrap>
                                                <Icon type={block.icon} />
                                            </Iconwrap>
                                            <Contentwrap>
                                                <Title>{block.text}</Title>
                                                <Text>{block.title}</Text>
                                            </Contentwrap>
                                        </Itemwrap>
                                    </Column>
                                </Row>
                            </Transit>
                        </Column>
                    )}
                </Row>
            </Jacket>
        )
    }
}