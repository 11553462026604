// Imports
// ------
import React from 'react';
import Rate from 'react-rating';
import Icon from 'components/global/icons/icons';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket, Title, RatingJacket } from './ratingStyles';

// Component
// ------
export default class SidebarRating extends React.Component {
    render() {
        return (
            <Jacket>
                <Row isExpanded equal>
                    {this.props.reviewReceived === false && <Column small={12}>
                        <Div text="center">
                            <Title>How was your drop-in work experience?</Title>
                        </Div>
                    </Column>}
                    <Column small={12}>
                        <RatingJacket>
                            {this.props.reviewReceived ?
                                <Rate
                                    initialRating={this.props.activeBooking.review ? this.props.activeBooking.review.rating : 0}
                                    emptySymbol={<Icon type="star-empty" />}
                                    fullSymbol={<Icon type="star" />}
                                    readonly
                                />
                                :
                                <Rate
                                    initialRating={this.props.rating}
                                    emptySymbol={<Icon type="star-empty" />}
                                    fullSymbol={<Icon type="star" />}
                                    onChange={this.props.handleRating}
                                />
                            }
                            
                        </RatingJacket>
                    </Column>
                </Row>
            </Jacket>
        )
    }
}