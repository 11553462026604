// Imports
// ------
import styled, { css } from 'styled-components';
// import { breakup } from 'components/global/theme/breakpoints';
import { Div, H4 } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Div)`
    position: relative;
    display: block;
    margin-top: 4rem;
    padding: 2rem 0;
`;

export const FaqItemJacket = styled(Div)`
    ${props => props.theme.animate(`.3s`, `ease-in-out`)}
    position: relative;
    display: block;
    cursor: pointer;
    
    z-index: 3;
    padding: 2rem;
    margin-bottom: 1px;
    background: ${props => props.theme.bc1};

    ${props => props.isActive && (css`
        margin-bottom: 2rem;
    `)}

    .MuiCollapse-container, .MuiCollapse-wrapper {
        width: 100%;
    }
`;

export const Title = styled(H4)`
    ${props => props.theme.animate(`.3s`, `ease-in-out`)}
    position: relative;
    display: block;

    vertical-align: middle;
    color: ${props => props.theme.bc3};
    
    ${props => props.isActive && (css`
        color: ${props => props.theme.bc3};
    `)}

    svg {
        ${props => props.theme.animate(`.3s`, `ease-in-out`)}
        float: right;
        vertical-align: middle;
        transform: rotate(-90deg);
        
        ${props => props.isActive && (css`
            transform: rotate(0deg);
            fill: ${props => props.theme.bc4};
        `)}
    }
`;

export const Content = styled(Div)`
    position: relative;
    padding-top: 2rem;

    font-size: 1.6rem;
`;