// Imports
// ------
import React from 'react';
import Body from 'utils/bodylock';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';
import { observer } from 'mobx-react';

// Styles
// ------
import { Button, Widget, WidgetJacket, WidgetTitle, Title, Clear, WidgetSave, SaveAndClose, SaveText } from '../filtersStyles';
import { CalendarJacket, Month, Day, DaysList } from './dateStyles';

// States
// ------
import { scheduleFilters } from 'states/lokationSidebar';
import moment from "moment";
import {humanDate} from "../../../../../lib/Util/TimeUtil";

// Component
// ------
class DateButton extends React.Component {
    constructor(props) {
        super(props)
        
        let max = 7; 
        let dates = [];
        let days = [];
        let selected = null;
        let left = '';
        let right = '';
        
        for (let i = 0; i < max; i++) {

            let date = new Date();
            date.setDate(date.getDate() + i);

            let month = (date.getMonth() + 1);
            if (month < 10) {
                month = `0${month}`;
            }

            let day = date.getDate();
            if (day < 10) {
                day = `0${day}`;
            }
            let formatted = `${date.getFullYear()}-${month}-${day}`;
            
            let label = `${month}/${day}/${date.getFullYear()}`;
            let short = `${day}`;
            let mnt = moment(date).format('MMMM');
            if (left === '') {
                left = mnt;
            }
            right = mnt;
            let obj = {
                index: i,
                date: formatted,
                label: label,
                short: short
            };
            if (scheduleFilters.date === formatted || selected === null) {
                selected = obj;
            }
            dates.push(obj);
            days.push(['S','M','T','W','T','F','S'][date.getDay()]);
        }
        
        if (left === right) {
            right = '';
        }

        this.state = {
            isActive: false,
            selectedDate: selected,
            dates: dates,
            days: days,
            leftMonth: left,
            rightMonth: right,
        }
    }

    stateCheck = () => {
        if (this.state.isActive) {
            Body.lock();
        }

        if (!this.state.isActive) {
            Body.unlock();
        }
    }

    handleDate = () => {
        // Open the widget when button is clicked
        this.setState({isActive: !this.state.isActive})
    }

    handleClear = () => {
        // Clear the global state of the date filter
        scheduleFilters.date = null;
        this.setState({selectedDate:null});

        this.setState({isActive: !this.state.isActive})

        this.props.onChange();
    }

    handleSetDate = (e) => {
        // Set the date and save to global state
        this.setState({selectedDate: this.state.dates[e.target.getAttribute('value')]}, () => { this.handleSaveAndClose()});
        
    }

    handleSaveAndClose = () => {
        // Do Save
        // ...
        scheduleFilters.date = this.state.selectedDate.date;
        // Do Close
        this.setState({isActive: !this.state.isActive})
        
        this.props.onChange();
    }

    render() {
        const checkActive = this.state.isActive;

        return (
            <>
                {/* The button / activator */}
                <Button onClick={this.handleDate} isActive={checkActive || this.state.selectedDate}>
                    {this.state.selectedDate ? humanDate(this.state.selectedDate.date) : "Date"}
                </Button>

                {/* The widget that pops up */}
                <Widget isActive={checkActive}>
                    <WidgetJacket>
                        <WidgetTitle>
                            <Row isExpanded equal>
                                <Column small={10}>
                                    <Title>Select Date</Title>
                                </Column>
                                <Column small={2}>
                                    <Div text="right">
                                        <Clear onClick={this.handleClear}>Clear</Clear>
                                    </Div>
                                </Column>
                            </Row>
                        </WidgetTitle>
                        <CalendarJacket>
                            <Row isExpanded>
                                <Column small={6}>
                                    <Month>{this.state.leftMonth}</Month>
                                </Column>
                                <Column small={6}>
                                    <Div text="right">
                                        <Month>{this.state.rightMonth}</Month>
                                    </Div>
                                </Column>
                            </Row>
                            <Row isExpanded>
                                <Column small={12}>
                                    <DaysList>
                                        {
                                            this.state.days.map((day, index) => (
                                                <Day key={index}>{day}</Day>
                                            ))
                                        }
                                    </DaysList>
                                </Column>
                            </Row>

                            <Row isExpanded>
                                <Column small={12}>
                                    <DaysList>
                                        {
                                            this.state.dates.map(date => (
                                                <Day key={date.index} onClick={(e) => this.handleSetDate(e)} value={date.index} selected={this.state.selectedDate && this.state.selectedDate.index === date.index}>{date.short}</Day>
                                            ))
                                        }
                                    </DaysList>
                                </Column>
                            </Row>
                        </CalendarJacket>
                        <WidgetSave>
                            {this.state.selectedDate === null ?
                                <SaveAndClose disabled>
                                    <SaveText>Search</SaveText>
                                </SaveAndClose>
                            :
                                <SaveAndClose onClick={this.handleSaveAndClose}>
                                    <SaveText>Search</SaveText>
                                </SaveAndClose>
                            }
                        </WidgetSave>
                    </WidgetJacket>
                </Widget>
            </>
        )
    }

    componentDidUpdate() {
        this.stateCheck();
    }

    componentWillUnmount() {
        this.stateCheck();
    }
}

export default observer(DateButton)