// Imports
// ------
import styled from 'styled-components';
import { breakup } from 'components/global/theme/breakpoints';
import { Section, Div } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const LokusUser = styled(Section)`
    position: relative;
    display: block;
    background: ${props => props.theme.bc1};

    padding: 4rem 0;
    ${breakup.medium`padding: 7.5rem 0;`}
    ${breakup.large`
        padding: 15rem 0 10rem;
    `}
`;

export const LokusProfileContent = styled(Section)`
    position: relative;
    display: block;
    background: ${props => props.theme.bc2};
`;

export const ButtonJacket = styled(Div)`
    button {
        display: inline-block;

        &:first-child {
            margin-right: 2rem;
        }
    }
`;