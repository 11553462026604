// Imports
// ------
import React from 'react';
import NavLink from '../navlink/navlink';

// Styles
// ------
import { ButtonJacket, Text, Link } from './primaryStyles';

// Component
// ------
export default class ButtonPrimary extends React.Component {
    render() {
        if(this.props.button) return (
            <ButtonJacket onClick={this.props.onClick} small={this.props.small} type={this.props.type} disabled={this.props.disabled} fullWidth={this.props.fullWidth}>
                <Text color={this.props.textColor}>{this.props.text}</Text>
            </ButtonJacket>
        )

        if(this.props.navlink) return (
            <Link type={this.props.type} fullWidth={this.props.fullWidth}>
                <NavLink to={this.props.href} onClick={this.props.onClick}>
                    <Text align={this.props.align} color={this.props.textColor}>{this.props.text}</Text>
                </NavLink>
            </Link>
        )
    }
}