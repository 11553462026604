// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import Field from '../field/field';
import Button from 'components/global/buttons/primary';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';
import { observer } from 'mobx-react';

// Styles
// ------
import { Jacket, Header, Close, Title, Personal, ButtonJacket } from './editPasswordStyles';

// States
// ------
import { userState } from 'states/user';

// Utils
// ------
import Body from 'utils/bodylock';
import {compose} from "recompose";
import {AuthUserContext, withAuthorization} from "../../../../lib/Session";
import {withFirebase} from "../../../../lib/Firebase";
import {notificationState} from "../../../../states/notifications";

// Component
// ------
class EditPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            oldpass: '',
            newpass: '',
            confirmpass: '',
            error: null,
        }
    }
    
    
    toggleEdit = () => {
        userState.editPasswordOpen = !userState.editPasswordOpen;
    }

    stateCheck = () => {
        if (userState.editPasswordOpen) {
            Body.lock();
        }

        if (!userState.editPasswordOpen) {
            Body.unlock();
        }
    }
    
    err = (title, text) => {
        notificationState.generalTitle = title;
        notificationState.generalText = text;
        notificationState.generalButtons = [];
        notificationState.generalOpen = true
    }

    handleSave = (authUser) => {
        let {oldpass, newpass, confirmpass} = this.state;
        let error = null;
        if (oldpass === '') {
            error = "Please enter your old password";
        } else if (newpass === '') {
            error = "Please enter a new password";
        } else if (newpass !== confirmpass) {
            error = "Password confirmation does not match";
        } 
        
        if (error !== null) {
            
            this.err("Error", error);
            
            // this.setState({error});
            return;
        }
        
        // try to login.
        this.props.firebase.auth
            .signInWithEmailAndPassword(authUser.email, oldpass)
            .then((user) => {
                // save...
                return this.props.firebase.auth.currentUser.updatePassword(newpass)
            }).then(() => {

                this.err("Success", "Password Changed Successfully");
                userState.editPasswordOpen = !userState.editPasswordOpen;
                return this.setState({
                    oldpass: "",
                    newpass: "",
                    confirmpass: "",
                })

            })
            .catch(error => {

                this.err("Error", error.message);
            });
        
    }
    
    onChange = (event) => {
        this.setState({[event.target.name]: event.target.value})
    }

    render() {
        let {error} = this.state;
        return (

            <AuthUserContext.Consumer>
                {authUser =>
                    authUser && <Jacket isActive={this.props.isActive}>
                        {/* Edit profile header */}
                        <Header>
                            <Row isExpanded>
                                <Column small={12} medium={10} pushOnMedium={1}>
                                    <Row isExpanded>
                                        <Column small={12}>
                                            <Div relative text="center">
                                                <Close onClick={this.toggleEdit}>
                                                    <Icon type="small-arrow-left"/>
                                                </Close>
                                                <Title>Change Password</Title>
                                            </Div>
                                        </Column>
                                    </Row>
                                </Column>
                            </Row>
                        </Header>

                        {/* Edit your password */}
                        <Personal>
                            <Field
                                label="Current Password"
                                type="password"
                                placeholder="Enter the current password"
                                name="oldpass"
                                hasToggle
                                negativeMargin
                                value={this.state.oldpass}
                                onChange={this.onChange}
                            />

                            <Field
                                label="New Password"
                                type="password"
                                placeholder="Enter the new password"
                                name="newpass"
                                hasToggle
                                negativeMargin
                                value={this.state.newpass}
                                onChange={this.onChange}
                            />
                            <Field
                                label="Verify Password"
                                type="password"
                                placeholder="Enter the new password again"
                                name="confirmpass"
                                hasToggle
                                negativeMargin
                                value={this.state.confirmpass}
                                onChange={this.onChange}
                            />
                        </Personal>

                        <ButtonJacket>
                            <Row isExpanded>
                                <Column small={12} medium={10} pushOnMedium={1}>
                                    <Row isExpanded>
                                        <Column small={12} medium={4} large={3}>

                                            {error && <p>{error}</p>}
                                            <Button button type="full" text="Change Password" onClick={e => this.handleSave(authUser)}/>
                                        </Column>
                                    </Row>
                                </Column>
                            </Row>
                        </ButtonJacket>
                    </Jacket>
                }
            </AuthUserContext.Consumer>
        )
    }

    componentDidUpdate() {
        this.stateCheck();
    }

    componentWillUnmount() {
        this.stateCheck();
    }
}

const condition = authUser => !!authUser;

export default compose(
    withFirebase,
    withAuthorization(condition),
    observer
)(EditPassword);
