// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';

// Styles
// ------
import { Jacket, Text } from './favoriteStyles';

// Component
// ------
export default class Favorite extends React.Component {
    
    render() {
        return (
            <>
                <Jacket onClick={this.props.onClick}>
                    {this.props.isFavorite ?
                        <Icon hasGradient type="heart" />
                    :
                        <Icon hasGradient={this.props.hasGradient===true} type="heart-empty" />
                    }
                    {this.props.withText &&
                        <Text>Favorite</Text>
                    }
                </Jacket>
            </>
        )
    }

}