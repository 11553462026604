// Imports
// ------
import React from 'react';
import Body from 'utils/bodylock';
import Icon from 'components/global/icons/icons';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';
import { observer } from 'mobx-react';

// Styles
// ------
import { Button, Widget, WidgetJacket, WidgetTitle, Title, Clear, WidgetSave, SaveAndClose, SaveText } from '../filtersStyles';
import { SeatsJacket, Label, SeatButtonJacket, Seat, SeatSelector, SeatList, SeatItem, SeatListInner } from './seatsStyles';

// States
// ------
import { scheduleFilters } from 'states/lokationSidebar';

// Component
// ------
class SeatsButton extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isActive: false,
            seatSelectorOpen: false,
            selectedSeat: scheduleFilters.seats,
            possibleSeats: [1, 2,4,6]
        }
    }

    stateCheck = () => {
        if (this.state.isActive) {
            Body.lock();
        }

        if (!this.state.isActive) {
            Body.unlock();
        }
    }

    handleSeats = () => {
        // Open the widget when button is clicked
        this.setState({isActive: !this.state.isActive})
    }

    handleSeatSelector = (e) => {
        this.setState({seatSelectorOpen: !this.state.seatSelectorOpen})
    }

    handleClear = () => {
        // Clear the global state of the date filter
        scheduleFilters.seats = 0;
        scheduleFilters.fulldayPass = false;
        scheduleFilters.timeStart = -1;
        scheduleFilters.timeEnd = -1;
        this.setState({selectedSeat: 0});
        this.setState({seatSelectorOpen: false})
        this.handleSaveAndClose();
    }

    handleNewSeat = (e) => {
        // Set the date and save to global state
        scheduleFilters.seats = 1 * e.target.getAttribute('value');
        scheduleFilters.fulldayPass = scheduleFilters.seats === 1;
        if (scheduleFilters.fulldayPass) {
            // auto-set the start and end time
            scheduleFilters.timeStart = 8 * 60;
            scheduleFilters.timeEnd = 18 * 60;
        } else {
            if (scheduleFilters.timeStart === 8 * 60) {
                scheduleFilters.timeStart = -1;
            }

            if (scheduleFilters.timeEnd === 18 * 60) {
                scheduleFilters.timeEnd = -1;
            }
        }
        this.setState({selectedSeat: scheduleFilters.seats});
        this.handleSaveAndClose();
    }

    handleSaveAndClose = () => {
        
        // Do Close
        this.setState({isActive: !this.state.isActive, seatSelectorOpen: false})
        this.props.onChange();
    }
    
    seatName = (seats) => {
        if (seats === 0) {
            return "";
        }
        if (seats === 1) {
            return "1 Seat (Day Pass) @ Shared Table";
        }
        return `${seats} Seats @ Reserved Table`;
    }

    render() {
        const checkActive = this.state.isActive;
        const SelectorOpenChecker = this.state.seatSelectorOpen;

        return (
            <>
                {/* The button / activator */}
                <Button onClick={this.handleSeats} isActive={checkActive || scheduleFilters.seats !== 0}>
                    {scheduleFilters.seats > 0 ? this.seatName(scheduleFilters.seats) : "Seat(s)"}
                </Button>

                {/* The widget that pops up */}
                <Widget isActive={checkActive}>
                    <WidgetJacket>
                        <WidgetTitle>
                            <Row isExpanded equal>
                                <Column small={10}>
                                    <Title>Select Seat(s)</Title>
                                </Column>
                                <Column small={2}>
                                    <Div text="right">
                                        <Clear onClick={this.handleClear}>Clear</Clear>
                                    </Div>
                                </Column>
                            </Row>
                        </WidgetTitle>
                        <SeatsJacket>
                            <Row isExpanded>
                                <Column small={3}>
                                    <Label>Seat(s)</Label>
                                </Column>
                                <Column small={9}>
                                    <Div relative text="right">
                                        <SeatButtonJacket onClick={this.handleSeatSelector} isOpen={SelectorOpenChecker}>
                                            <Seat>{this.seatName(scheduleFilters.seats)}</Seat>
                                            <Icon type="small-arrow-down" />
                                        </SeatButtonJacket>
                                    </Div>
                                </Column>
                            </Row>
                            <SeatSelector isOpen={SelectorOpenChecker}>
                                <Row isExpanded>
                                    <Column small={12}>
                                        <SeatList>
                                            {
                                                this.state.possibleSeats.map(seat => (
                                                    <SeatItem key={seat} onClick={(e) => this.handleNewSeat(e)} value={seat} selected={this.state.selectedSeat === seat}>{this.seatName(seat)}</SeatItem>
                                                ))
                                            }
                                           
                                            </SeatList>
                                    </Column>
                                </Row>
                                <SeatListInner />
                            </SeatSelector>
                        </SeatsJacket>
                        <WidgetSave>
                            {this.state.selectedSeat === "" ?
                                <SaveAndClose disabled>
                                    <SaveText>Search</SaveText>
                                </SaveAndClose>
                            :
                                <SaveAndClose onClick={this.handleSaveAndClose}>
                                    <SaveText>Search</SaveText>
                                </SaveAndClose>
                            }
                        </WidgetSave>
                    </WidgetJacket>
                </Widget>
            </>
        )
    }

    componentDidUpdate() {
        this.stateCheck();
    }

    componentWillUnmount() {
        this.stateCheck();
    }
}

export default observer(SeatsButton)