// Imports
// ------
import React from 'react';
import Notice from './notice/notice';
import Details from './details/details';
import Perks from './perks/perks';
import BookNow from './booknow/booknow';
import BookingPayment from './bookingpayment/bookingpayment';
import About from './about/about';
import Checkin from './checkin/checkin';
import Amenities from './amenities/amenities';
import Extender from './extender/extender';
import ReservationExtender from './reservationextender/reservationextender';
import Rating from './rating/rating';
import CheckoutForm from './checkoutform/checkoutform';

// Styles
// ------
import { Jacket } from './sidebarStyles';
import {Elements} from "react-stripe-elements";

// Component
// ------
export default class LokationSidebar extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            startBooking: false
        }
        
    }
    

    handleStartBooking = () => {
        this.setState({startBooking: true})
    }
    
    
    
    render() {
        return (
            <Jacket>
                {this.props.hasNotice &&
                    <Notice
                        noticeTitle={this.props.noticeTitle}
                        noticeContent={this.props.noticeContent}
                        noticeHasLink={this.props.noticeHasLink}
                        noticeLinkText={this.props.noticeLinkText}
                        noticeLinkUrl={this.props.noticeLinkUrl}
                    />
                }

                <Details
                    locationSet={this.props.locationSet}
                    locationTitle={this.props.locationTitle}
                    hasAddress={this.props.hasAddress}
                    addressLine1={this.props.addressLine1}
                    addressLine2={this.props.addressLine2}
                    scheduleDate={this.props.scheduleDate}
                    schedulePass={this.props.schedulePass}
                    scheduleStart={this.props.scheduleStart}
                    scheduleEnd={this.props.scheduleEnd}
                    scheduleSeats={this.props.scheduleSeats}

                    activeBooking={this.props.activeBooking}
                    hasCheckin={this.props.hasCheckin}


                    isFavorite={this.props.isFavorite}
                    onFavToggle={this.props.onFavToggle}

                    hasAmenities={this.props.hasAmenities}
                />
                
                {this.props.hasPerks &&
                    <Perks

                        locationTitle={this.props.locationTitle}
                        booking={this.props.activeBooking}
                        hasAmenities={this.props.hasAmenities}
                        canCheckIn={this.props.canCheckIn}
                        perks={this.props.perks}
                    />
                }

                {!this.state.startBooking && this.props.hasBooking &&
                    <BookNow
                        hasNoResults={this.props.hasNoResults} handleStartBooking={this.handleStartBooking} totalCost={this.props.totalCost} />
                }

                
                {this.state.startBooking && this.props.totalCost > 0 &&
                <Elements>
                    <BookingPayment
                        handleCharge={this.props.handleCharge}
                        location={this.props.location}
                        authUser={this.props.authUser}
                        totalCost={this.props.totalCost}
                        scheduleDate={this.props.scheduleDate}
                    />
                </Elements>
                }

                {!this.props.scheduleDate && this.props.hasAbout &&
                    <About
                        aboutTitle={this.props.aboutTitle}
                        aboutContent={this.props.aboutContent}
                        aboutLinks={this.props.aboutLinks}
                        // aboutHasLink={this.props.aboutHasLink}
                        // aboutLinkTitle={this.props.aboutLinkTitle}
                        // aboutLinkUrl={this.props.aboutLinkUrl}
                    />
                }

                {this.props.hasCheckin &&
                    <Checkin booking={this.props.activeBooking}
                             canCheckIn={this.props.canCheckIn}
                             canCancelCheckIn={this.props.canCancelCheckIn}

                             handleCancelBooking={this.props.handleCancelBooking}
                             handleCheckIn={this.props.handleCheckIn} />
                }



                {this.props.hasAmenities &&
                    <Amenities
                        amenities={this.props.amenities}
                        booking={this.props.activeBooking}
                        reviewReceived={this.props.reviewReceived}
                    />
                }

                {this.props.hasExtender &&
                    <Extender booking={this.props.activeBooking} handleEndBooking={this.props.handleEndBooking} />
                }

                {this.props.hasReservationExtender &&
                    <ReservationExtender />
                }

                {(this.props.hasRating || this.props.reviewReceived) &&
                    <Rating reviewReceived={this.props.reviewReceived} rating={this.props.rating} activeBooking={this.props.activeBooking} handleRating={this.props.handleRating} />
                }

                {(this.props.hasCheckoutForm || this.props.reviewReceived) &&
                    <CheckoutForm
                        handleCheckout={this.props.handleCheckout}
                        options={this.props.checkoutFormOptions}
                        activeBooking={this.props.activeBooking}
                        reviewReceived={this.props.reviewReceived}
                        cancelCheckout={this.props.cancelCheckout}
                        handleCheckoutOptions={this.props.handleCheckoutOptions} />
                }
                
                
            </Jacket>
        )
    }
}