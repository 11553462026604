// Imports
// ------
import styled, { css } from "styled-components";
// import { breakup } from 'components/global/theme/breakpoints';
import { Div, H4, Span } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const SeatsJacket = styled(Div)`
    position: relative;
    z-index: 10;
    display: block;

    background: ${props => props.theme.bc4};
    padding: 3rem 0;
`;

export const Label = styled(Span)`
    color: ${props => props.theme.bc1};
`;

export const Seat = styled(H4)`
    color: ${props => props.theme.bc1};
    line-height: 3rem !important;
`;

export const SeatButtonJacket = styled.button`
    position: relative;
    display: block;
    width: 100%;
    float: right;
    cursor: pointer;
    background: none;
    text-align: right;
    margin-bottom: 1rem;
    padding-right: 2.5rem;

    ${Seat} {
        display: inline-block;
        vertical-align: middle;
    }

    svg {
        ${props => props.theme.animate('0.3s', 'ease-in-out')}
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(0);

        display: inline-block;
        vertical-align: middle;

        fill: ${props => props.theme.bc1};
    }

    ${props => props.isOpen && css`
        svg {
            transform: translateY(-40%) rotate(180deg);
        }
    `}
`;

export const SeatSelector = styled(Div)`
    position: relative;
    display: none;
    padding-top: 2rem;

    ${props => props.isOpen && css`
        display: block;
    `}
`;

export const SeatListInner = styled(Div)`
    position: absolute;
    display: block;
    z-index: 10;
    top: 0; left: 0; right: 0;
    height: 15rem;
    width: 100%;

    pointer-events: none;

    background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
`;

export const SeatList = styled.ul`
    position: relative;
    list-style: none;
    margin: 0;
    padding: 1rem 0;

    height: 100%;
    max-height: 15rem;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: trouch;
`;

export const SeatItem = styled.li`
    position: relative;
    display: block;
    width: 100%;
    padding: 1rem 2rem;

    text-align: center;
    color: ${props => props.theme.bc1};
    font-size: 1.4rem;

    cursor: pointer;

    ${props => props.selected && css`
        border-image: linear-gradient(to right, ${props => props.theme.bc5} 0%, ${props => props.theme.bc6} 100%);
        border-image-slice: 1;
        border-width: 1px;
        border-style: solid;
        background: transparent;
    `}
`;