// Imports
// ------
import styled, { css } from "styled-components";
import { Section, Div, H4, P } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    display: none;

    ${props => props.isOpen === true && css`
        display: block;
        position: fixed;
        top: 0; left: 0; right: 0; bottom: 0;
        width: 100%;
        z-index: 10000;

        &:before {
            content: '';
            position: absolute;
            top: 0; left: 0; right: 0; bottom: 0;
            background: ${props => props.theme.bc1};
            opacity: 0.55;
        }
    `}
`;

export const Inner = styled(Div)`
    display: block;
    width: 100%;
    max-width: 40rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${props => props.theme.bc3};
`;

export const Header = styled(Div)`
    position: relative;
    display: block;
    padding: 1.5rem 0;

    background: ${props => props.theme.bc4};
`;

export const Title = styled(H4)`
    color: ${props => props.theme.bc1};
`;

export const Close = styled(Div)`
    position: relative;
    cursor: pointer;

    svg {
        ${props => props.theme.animate('0.3s', 'ease-in-out')}
        fill: ${props => props.theme.bc1};
        opacity: 0.45;
    }

    &:hover {
        svg {
            opacity: 1;
        }
    }
`;

export const Content = styled(Div)`
    position: relative;
    display: block;
    padding: 2.5rem 0;
`;

export const Text = styled(P)`
    display: block;
    margin-bottom: 2rem;

    color: ${props => props.theme.bc1};
    
    font-style: ${props => props.tiny ? "italic" : "normal" };
`;

export const Link = styled(Div)`
    display: block;
    margin-top: 1rem;

    a {
        color: ${props => props.theme.bc1};
        font-size: 1.4rem !important;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
`;