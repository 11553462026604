// Imports
// ------
import styled from "styled-components";
import { Div } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Div)`
    position: absolute;
    z-index: 900;
    display: block;
    top: 0px;
    right: 0px;
    padding: 1rem;
    cursor: pointer;
`;
