// Imports
// ------
import React from 'react';
import Filters from './filters/filters';
import Card from './card/card';
// import Icon from 'components/global/icons/icons';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket, Title, Label } from './listStyles';
import LokusSearch from "../../../lib/Lokus/Search";
import {compose} from "recompose";
import {withFirebase} from "../../../lib/Firebase";
import {Base64} from "js-base64";
import {contains} from "../../../lib/Util/Array";
import {scheduleFilters} from "../../../states/lokationSidebar";
import Loader from "../../global/loader";
import {notificationState} from "../../../states/notifications";

// Component
// ------
class LokationsList extends React.Component {
    constructor(props) {
        super(props);
        
        let filters = {
            date: null,
            passes: 1,
            allday: false,
            start_time: -1,
            end_time: -1,
            seats: 0
        };
        
        this.state = {
            loading: false,
            list: [],

            filters: filters,
            encoded_params: "",

            tables: [],
            ids: [],
            limit: 5,
            searching: false,
            
            user: null,
            
        }

        this.searchEngine = new LokusSearch(this.props.firebase);

        
        
    }

    componentDidMount = () => {

        this.onListenForLocations();

        this.handleFilters();
        
        console.log(this.props.authUser)
        if (!this.props.authUser || !this.props.authUser.emailVerified) {
            notificationState.generalTitle = "Verification Email Sent!";
            notificationState.generalText = "Please check your inbox and follow the instructions to verify your email address.";
            notificationState.generalTinyText = <>If you do not receive a verification email, reach out to <a href="mailto:help@booklokus.com">help@booklokus.com</a> for support.</>;
            notificationState.generalButtons = [{
                url: '#',
                title: 'Okay'
            }];
            notificationState.generalOpen = true
        } else {
            notificationState.generalOpen = false
        }

    }

    resetSearch = () => {
        let filters = this.state.filters;
        filters.date = null;
        filters.passes = 1;
        filters.allday = false;
        filters.start_time = -1;
        filters.end_time = -1;
        filters.seats = 0;

        let encoded_params = Base64.encode(JSON.stringify(filters));

        this.setState({ids: [], filters: filters, encoded_params: encoded_params, searching: false});

    }

    search = ({date, passes, allday, start_time, end_time, seats}) => {

        if (this.search_subscription) {
            console.log("Cancelling previous subscription");
            this.search_subscription();
        }

        passes *= 1;
        start_time *= 1;
        end_time *= 1;
        seats *= 1;

        let filters = this.state.filters;
        filters.date = date;
        filters.passes = passes;
        filters.allday = allday;
        filters.start_time = start_time;
        filters.end_time = end_time;
        filters.seats = seats;

        let encoded_params = Base64.encode(JSON.stringify(filters));

        this.setState({ids: [], filters: filters, encoded_params: encoded_params});

        if (allday) {


            if (date === null) {
                this.resetSearch();
                return
            }
            
            console.log("searching for ", date, " and ", passes, "passes");
            

            this.searchEngine.findPasses(date, passes, ids => {
                if (ids.length === 0) {
                    this.props.firebase.analytics.logEvent("no_search_results", {date, daypass: true, start_time, end_time, seats});
                } else {
                    this.props.firebase.analytics.logEvent("search_results_found", {results: ids.length});
                }
                this.setState({ids: ids, searching: true});
            })

        } else {

            if (date === null) {
                this.resetSearch();
                return;
            }
            
            console.log("searching for an active table with ", seats, "seats and starting at", start_time);

            this.searchEngine.findTables(date, start_time, end_time, seats, ids => {
                if (ids.length === 0) {
                    this.props.firebase.analytics.logEvent("no_search_results", {date, daypass: false, start_time, end_time, seats});
                } else {
                    this.props.firebase.analytics.logEvent("search_results_found", {results: ids.length});
                }
                this.setState({ids: ids, searching: true});
            });

        }



    }
    
    onListenForLocations = () => {

        this.setState({ loading: true });

        this.unsubscribe = this.props.firebase
            .locations()
            .onSnapshot(snapshot => {
                if (snapshot.size) {
                    let locations = [];
                    snapshot.forEach(doc =>
                        locations.push({ ...doc.data(), uid: doc.id }),
                    );

                    this.setState({
                        list: locations.filter(doc => doc.active),
                        loading: false,
                    });

                } else {
                    this.setState({ list: [], loading: false });
                }
            });

        this.unsubscribe2 = this.props.firebase
            .user(this.props.authUser.uid)
            .onSnapshot(snapshot => {
                
                let user = { ...snapshot.data(), uid: this.props.authUser.uid };
                this.setState({user: user})
            
            });

    };

    componentWillUnmount() {
        this.unsubscribe();
        this.unsubscribe2();
    }

    handleFilters = () => {
        let {date, fulldayPass, timeStart, timeEnd, seats} = scheduleFilters;


        this.props.firebase.analytics.logEvent("perform_search", {date, daypass: fulldayPass, timeStart, timeEnd, seats});
        
        this.search({date, passes: 1, allday: fulldayPass, start_time: timeStart, end_time: timeEnd, seats});
        
    }

    
    favToggle = (location) => {
        let user  = this.state.user;
        if (!user) {
            return
        }
        
        this.props.firebase.favToggle(location.uid, user).then( e => {
            console.log("Fav toggled");
        });
        
    }
    
    hasAllFilters = () => {

        const filters = this.state.filters;
        
        if (filters.date && filters.start_time && filters.end_time && filters.seats) {
            return true;
        }
        
        return false;
    }
    
    resultPrice = () => {
        let filters = this.state.filters;
        let amount = 0;

        if (filters.date) {
            if (filters.allday) {
                amount = 20 * filters.passes;
            } else {
                let mult = {
                    "2": 10,
                    "4": 20,
                    "6": 30
                };
                let val = mult[filters.seats];
                amount = val * Math.ceil((filters.end_time - filters.start_time) / 60);
            }
        }
        
        return amount;
    }
    
    render() {
        
        let buttonTitle = "More Lokation Details";
        if (this.hasAllFilters()) {
            buttonTitle = "Book Now: $" + this.resultPrice();
        }

        return (
            <Jacket>
                
                <Div relative mpad pad>
                    <Row isExpanded>
                        <Column small={12} medium={10} pushOnMedium={1}>
                            <Title>{this.props.title}</Title>
                        </Column>
                    </Row>
                    <Row isExpanded>
                        <Column small={12} medium={10} pushOnMedium={1}>
                            <Filters onChange={this.handleFilters} />
                        </Column>
                    </Row>
                    <Row isExpanded isCollapsed>
                        <Column small={12} medium={10} pushOnMedium={1}>
                            <Row isExpanded equal>
                                {this.state.loading ? <Loader/> : 
                                this.state.list.filter(loc => !this.state.searching || contains(this.state.ids, loc.uid)).map((location, index) =>
                                    <Column key={location.uid} small={12} medium={6} large={4} xlarge={3}>
                                        <Card
                                            location={location}
                                            onFavToggle={e => {this.favToggle(location)}}
                                            isFavorite={this.props.firebase.isFavorite(location.uid, this.state.user)}
                                            slug={`/lokation/${location.uid}`}
                                            title={location.name}
                                            subTitle={location.address.neighborhood}
                                            address={location.address.name}
                                            images={location.images}

                                            // Has Details
                                            hasDetails={location.hasDetails}
                                            
                                            buttonTitle={buttonTitle}

                                            // Has Past Details
                                            hasPastDetails={location.hasPastDetails}
                                        />
                                    </Column>
                                )}
                                
                                {
                                    this.state.searching && this.state.list.filter(loc => !this.state.searching || contains(this.state.ids, loc.uid)).length === 0 && <Row isExpanded>
                                        <Column small={12}>
                                            <Div text="center">
                                                <Label>No Results</Label>
                                            </Div>
                                        </Column>
                                    </Row>
                                }
                                
                            </Row>
                        </Column>
                    </Row>
                    <Row isExpanded>
                        <Column small={12}>
                            <Div text="center">
                                
                            </Div>
                        </Column>
                    </Row>
                </Div>
            </Jacket>
        )
    }
}


export default compose(
    withFirebase,
)(LokationsList);