import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { withFirebase } from '../../lib/Firebase';
import Header from "../../components/global/header/header";


import Button from '../../components/global/buttons/primary';
import Input from '../../components/global/formfields/input';
import { Div } from '../../components/global/theme/reusables/reusables';
import { Row, Column } from '../../components/global/theme/grid/grid';

import { Jacket, Title, SignupStep, Inputwrap, Submission, Text } from '../../components/signup/signupStyles';
import moment from "moment";

const initial_state = {
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    dob: '',
    phoneNumber: '',
    passwordOne: '',
    passwordTwo: '',
    error: null,

    ShowFirstStep: true,
    ShowSecondStep: false,
    RemoveFirstStep: false,
};


class SignUpFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...initial_state };
    }

    handleJoin = () => {
        const { firstName, lastName, email, passwordOne, companyName, dob, phoneNumber } = this.state;

        const fullName = `${firstName} ${lastName}`;
        
        let date = moment(dob, "MM / DD / YYYY");
        
        let years = moment().diff(date, "year", false);
        
        if (years < 21) {
            this.setState({error: {
                message: "You must be 21 years or older to join."
                }});
            return;
        }
        
        this.props.firebase.auth
            .createUserWithEmailAndPassword(email, passwordOne)
            .then(authUser => {


                this.props.firebase.analytics.logEvent("account_created", {});

                return this.props.firebase.user(authUser.user.uid).set(
                    {
                        fullName,
                        firstName,
                        lastName,
                        email,
                        companyName,
                        dob,
                        phoneNumber
                    },
                    { merge: true },
                );
            })
            .then(() => {
                return this.props.firebase.doSendEmailVerification();
            })
            .then(() => {
                this.setState({ ...initial_state });
                this.props.history.push("/lokations");
            })
            .catch(error => {
                this.setState({ error });
            });

    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleSave = () => {

        let {email} = this.state;
        let password = "invalid-password";
        this.props.firebase.auth.signInWithEmailAndPassword(email, password)
            .then(() => {
                // This should never happen.
            })
            .catch(error => {
               
                // 
                let code = error.code;
                if (code === 'auth/invalid-email' || code === 'auth/wrong-password' || code === 'auth/user-disabled') {
                    // This is an existing user, so we can't use that email.
                    
                    this.setState({error: {message: "Invalid email or email already in use."}})
                    
                } else {

                    this.setState({
                        ShowFirstStep: false,
                        error: null
                    })

                    setTimeout(() => {
                        this.setState({
                            RemoveFirstStep: true,
                        })
                    }, 500)

                    setTimeout(() => {
                        this.setState({
                            ShowSecondStep: true,
                        })
                    }, 800)
                    
                }
                
                
            });
        
        
    }

    render() {
        const {
            firstName,
            lastName,
            email,
            companyName,
            dob,
            phoneNumber,
            passwordOne,
            passwordTwo,
            error,
        } = this.state;

        const isInvalid =
            passwordOne !== passwordTwo ||
            passwordOne === '' ||
            email === '' ||
            phoneNumber === '' ||
            dob === '' ||
            firstName === '' ||
            lastName === '';


        const FirstStepCheck = this.state.ShowFirstStep;
        const SecondStepCheck = this.state.ShowSecondStep;
        const FirstStepRemoved = this.state.RemoveFirstStep;


        return (
            <>

                <Header goBack />

                <Jacket>
                    <Div pad mpad>
                        <Row isExpanded>
                            <Column small={12} medium={10} pushOnMedium={1}>
                                <Title>Sign up</Title>
                            </Column>
                        </Row>
                        <Row isExpanded isCollapsed>
                            <Column small={12} medium={10} pushOnMedium={1} large={7}>
                                <SignupStep isActive={FirstStepCheck} isRemoved={FirstStepRemoved}>
                                    <Row isExpanded>
                                        <Column small={12} medium={6}>
                                            <Inputwrap>
                                                <Input
                                                    label="First Name*"
                                                    type="text"
                                                    light
                                                    transparent
                                                    required
                                                    placeholder="Your first name"
                                                    name="firstName"
                                                    onChange={this.onChange}
                                                    value={firstName}
                                                />
                                            </Inputwrap>
                                        </Column>

                                        <Column small={12} medium={6}>
                                            <Inputwrap>
                                                <Input
                                                    label="Last Name*"
                                                    type="text"
                                                    light
                                                    transparent
                                                    required
                                                    placeholder="Your last name"
                                                    name="lastName"
                                                    onChange={this.onChange}
                                                    value={lastName}
                                                />
                                            </Inputwrap>
                                        </Column>
                                        <Column small={12} medium={6}>
                                            <Inputwrap>
                                                <Input
                                                    label="Email*"
                                                    type="text"
                                                    light
                                                    transparent
                                                    required
                                                    placeholder="Your email address"
                                                    name="email"
                                                    onChange={this.onChange}
                                                    value={email}
                                                />
                                            </Inputwrap>
                                        </Column>
                                        <Column small={12} medium={6}>
                                            <Inputwrap>
                                                <Input
                                                    label="Company Name (Optional)"
                                                    type="text"
                                                    light
                                                    transparent
                                                    placeholder="Your company name"
                                                    name="companyName"
                                                    onChange={this.onChange}
                                                    value={companyName}
                                                />
                                            </Inputwrap>
                                        </Column>
                                        <Column small={12} medium={6}>
                                            <Inputwrap>
                                                <Input
                                                    label="Phone*"
                                                    type="phone"
                                                    light
                                                    transparent
                                                    required
                                                    placeholder="Phone Number"
                                                    name="phoneNumber"
                                                    onChange={this.onChange}
                                                    value={phoneNumber}
                                                />
                                            </Inputwrap>
                                        </Column>
                                        <Column small={12}>
                                            <Submission>
                                                {error && error.message}
                                                
                                                {(firstName && lastName && email && phoneNumber) ?
                                                    <Button button type="ghost" text="Save &amp; Continue" onClick={this.handleSave} />
                                                    :
                                                    <Button button type="ghost" text="Save &amp; Continue" disabled />
                                                }
                                            </Submission>
                                        </Column>
                                    </Row>
                                </SignupStep>
                                <SignupStep isActive={SecondStepCheck}>
                                    <Row isExpanded>
                                        <Column small={12} medium={6}>
                                            <Inputwrap>
                                                <Input
                                                    label="Password*"
                                                    type="password"
                                                    light
                                                    transparent
                                                    required
                                                    placeholder="Password"
                                                    name="passwordOne"
                                                    hasToggle
                                                    onChange={this.onChange}
                                                    value={passwordOne}
                                                />
                                                <small>Minimum of 6 characters in length.</small>
                                            </Inputwrap>
                                        </Column>
                                        
                                        <Column small={12} medium={6}>
                                            <Inputwrap>
                                                <Input
                                                    label="Verify*"
                                                    type="password"
                                                    light
                                                    transparent
                                                    placeholder="Verify Password"
                                                    name="passwordTwo"
                                                    hasToggle
                                                    onChange={this.onChange}
                                                    value={passwordTwo}
                                                />
                                            </Inputwrap>
                                        </Column>
                                        <Column small={12} medium={6}>
                                            <Inputwrap>
                                                <Input
                                                    label="Date of Birth"
                                                    type="text"
                                                    id="date"
                                                    light
                                                    transparent
                                                    required
                                                    placeholder="MM / DD / YYYY"
                                                    name="dob"
                                                    value={dob}
                                                    onChange={this.onChange}
                                                />
                                            </Inputwrap>
                                        </Column>
                                        {/*<Column small={12} medium={6}>*/}
                                        {/*    <Inputwrap>*/}
                                        {/*        <Row isExpanded isCollapsed>*/}
                                        {/*            <Column small={2} large={1}>*/}
                                        {/*                <Input*/}
                                        {/*                    type="checkbox"*/}
                                        {/*                    light*/}
                                        {/*                    transparent*/}
                                        {/*                />*/}
                                        {/*            </Column>*/}
                                        {/*            <Column small={10} large={11}>*/}
                                        {/*                <SmallText>I don’t want to receive marketing promotions and special offers from Lokus. I can also opt out of receiving these messages at any time.</SmallText>*/}
                                        {/*            </Column>*/}
                                        {/*        </Row>*/}
                                        {/*    </Inputwrap>*/}
                                        {/*</Column>*/}
                                        <Column small={12}>
                                            <Submission>
                                                {isInvalid === false ?
                                                    <>
                                                        {error && error.message}
                                                        <Button button type="ghost" text="Agree &amp; Join Now" onClick={this.handleJoin} />
                                                        <Row isExpanded isCollapsed>
                                                            <Column small={12} medium={12} large={12}>
                                                                <Text hideOnLarge style={{textAlign:'center'}}>By continuing, you agree to our <Link to="/privacy-policy">Privacy Policy and Terms</Link></Text>
                                                                <Text showOnLarge>By continuing, you agree to our&nbsp;<Link to="/privacy-policy">Privacy Policy and Terms</Link></Text>
                                                            </Column>
                                                        </Row>
                                                    </>
                                                    :
                                                    <Button button type="ghost" text="Agree &amp; Join Now" disabled />
                                                }
                                            </Submission>
                                        </Column>

                                    </Row>
                                </SignupStep>
                            </Column>
                        </Row>
                    </Div>
                </Jacket>
                
                </>
        );
    }
}

const SignUpForm = withRouter(withFirebase(SignUpFormBase));
export default SignUpForm;
export { SignUpForm };
