// Imports
// ------
import styled, { css } from "styled-components";
import { breakup } from 'components/global/theme/breakpoints';
import { Section, Div, Gradient, H3, Span } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    z-index: 10;
    display: block;
    margin-bottom: 3rem;
`;

export const Button = styled.button`
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;

    border: 1px solid ${props => props.theme.bc5};
    border-radius: 2.5rem;
    background: none;

    padding: .5rem 2rem;
    font-size: 1.8rem;
    color: ${props => props.theme.bc3};

    ${breakup.large`
        font-size: 2rem;
        margin-right: 2rem;
    `}

    ${props => props.isActive && css`
        background: ${Gradient};
    `}
`;

export const Widget = styled(Div)`
    display: none;
    ${props => props.isActive && css`display: block;`}

    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: 50;
    padding: 2rem;

    &:before {
        content: '';
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        background: ${props => props.theme.bc1};
        opacity: .55;
    }
`;

export const WidgetJacket = styled(Div)`
    position: absolute;
    z-index: 5;
    top: 10rem;
    margin: 0 auto;

    display: block;
    width: 92vw;

    ${breakup.medium`
        top: 26rem;
        left: calc(100% / 12 * 1);
        width: calc(100% / 12 * 10);

        padding: 2rem;
    `}

    ${breakup.large`
        top: 34rem;
        width: calc(100% / 12 * 5);
    `}
`;

export const WidgetTitle = styled(Div)`
    position: relative;
    display: block;
    padding: 1rem 0;

    background: ${props => props.theme.bc3};
`;

export const Title = styled(H3)`
    color: ${props => props.theme.bc1};
`;

export const Clear = styled(Span)`
    display: block;
    text-align: right;

    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);
    cursor: pointer;

    color: ${props => props.theme.bc1};
`;

export const WidgetSave = styled(Div)`
    position: relative;
    display: block;
`;

export const SaveText = styled(Span)`
    ${props => props.theme.animate('0.3s', 'ease-in-out')}
    position: relative;
    z-index: 5;
`;

export const SaveAndClose = styled.button`
    ${props => props.theme.animate('0.3s', 'ease-in-out')}
    position: relative;
    display: block;
    width: 100%;
    padding: 1rem 2rem;
    text-align: center;

    cursor: pointer;

    font-size: 1.6rem;
    color: ${props => props.theme.bc1};

    background: ${props => props.theme.bc3};

    &:before {
        ${props => props.theme.animate('0.3s', 'ease-in-out')}
        content: '';
        position: absolute;
        z-index: 0;
        top: 0; right: 0; bottom: 0; left: 0;
        background: ${Gradient};
        opacity: 0;
    }

    &:hover {
        &:before {
            opacity: 1;
        }

        ${SaveText} {
            color: ${props => props.theme.bc3};
        }
    }

    ${props => props.disabled && css`
        pointer-events: none;
    `}
`;