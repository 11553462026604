// Imports
// ------
import styled, { css } from "styled-components";
import { breakup } from 'components/global/theme/breakpoints';
import { Div, Gradient, Span } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const CalendarJacket = styled(Div)`
    position: relative;
    z-index: 10;
    display: block;

    background: ${props => props.theme.bc4};
    padding: 3rem 0;
`;

export const Month = styled(Span)`
    display: block;
    margin-bottom: 1rem;
    color: ${props => props.theme.bc1};
`;

export const DaysList = styled(Div)`
    display: flex;
    flex-flow: row;
`;

export const Day = styled(Span)`
    ${props => props.theme.animate('0.45s', 'ease-in-out')}
    position: relative;
    display: inline-block;
    width: 14%;
    
    cursor: pointer;
    margin-top: 2rem;
    padding: 1rem;
    color: ${props => props.theme.bc1};
    line-height: 1 !important;
    max-height: 5rem;

    ${breakup.large`padding: 1.5rem;`}

    span {
        display: block;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        text-align: center;
    }   

    &:before {
        ${props => props.theme.animate('0.4s', 'ease-in-out')}
        content: '';
        position: absolute;
        z-index: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background: ${Gradient};
        border-radius: 100%;
        width: 5rem;
        height: 5rem;
        opacity: 0;
    }

    ${props => props.selected && css`
        span {
            color: ${props => props.theme.bc3};
        }
        &:before {
            opacity: 1;
        }
    `}
`;