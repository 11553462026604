// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import Input from 'components/global/formfields/input';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket, Avatar, Name, UploadWrapper, Upload, Label } from './userStyles';

// Component
// ------
export default class User extends React.Component {
    render() {
        return (
            <Jacket>
                <Row isExpanded isCollapsed isCenter>
                    <Column small={4} large={2}>
                        <Avatar>
                            {this.props.avatar ?
                                <img src={this.props.avatar} alt={this.props.name} />
                            :
                                <Icon type="photo" />
                            }
                        </Avatar>
                    </Column>
                    <Column small={8}>
                        <Name>Hello, {this.props.name}!</Name>
                        {!this.props.avatar &&
                            <UploadWrapper>
                                <Upload>
                                    <Label>Upload Photo</Label>
                                    <Icon type="upload" />
                                </Upload>
                                <Input type="file" name="avatarfile" />
                            </UploadWrapper>
                        }
                    </Column>
                </Row>
            </Jacket>
        )
    }
}