// Imports
// ------
import styled, { css } from "styled-components";
import { breakup } from 'components/global/theme/breakpoints';
import { Section, Div } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    z-index: 2;
    display: block;

    background: ${props => props.theme.bc2};
`;

export const Slider = styled(Div)`
    position: relative;
`;

export const Slide = styled(Div)`
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    min-height: 20rem;
    max-height: 20rem;

    img {
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
    }
`;

export const SliderControls = styled(Div)`
    position: absolute;
    z-index: 10;
    top: 50%; left: 0;
    transform: translateY(-50%);

    display: block;
    width: 100%;
`;

export const Button = styled.button`
    position: absolute;
    z-index: 5;
    top: 40%;
    transform: translateY(-40%);
    cursor: pointer;
    background: transparent;
    padding: 1rem;

    ${breakup.large`
        top: 50%;
        transform: translateY(-50%);
    `}

    ${props => props.prev && css`
        left: 1rem;
    `}

    ${props => props.next && css`
        left: auto; right: 1rem;
    `}
`;