// Imports
// ------
import styled, { css } from "styled-components";
import { breakup } from 'components/global/theme/breakpoints';
import { Section, Div, H3, Span, Gradient } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    display: none;

    ${props => props.isOpen === true && css`
        display: block;
        overflow-y: scroll;
        position: fixed;
        top: 0; left: 0; right: 0; bottom: 0;
        width: 100%;
        z-index: 1000;

        &:before {
            content: '';
            position: fixed;
            top: 0; left: 0; right: 0; bottom: 0;
            background: ${props => props.theme.bc1};
            opacity: 0.55;
        }
    `}
`;

export const Inner = styled(Div)`
    display: block;
    width: 90%;
    max-width: 60rem;
    margin: 0 auto;
    background: ${props => props.theme.bc3};

    position: relative;
    margin-top: 4rem;
    margin-bottom: 6rem;

    ${breakup.large`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    `}
`;

export const ArrowJacket = styled(Div)`
    position: absolute;
    z-index: 5;
    top: 2rem;
    right: 2rem;
    cursor: pointer;

    svg {
        ${props => props.theme.animate('0.3s', 'ease-in-out')}
        fill: ${props => props.theme.bc1};
        opacity: 0.45;
    }

    &:hover {
        svg {
            opacity: 1;
        }
    }
`;

export const Close = styled(Div)`
    position: absolute;
    z-index: 5;
    top: 2rem;
    right: 2rem;
    cursor: pointer;

    svg {
        ${props => props.theme.animate('0.3s', 'ease-in-out')}
        fill: ${props => props.theme.bc1};
        opacity: 0.45;
    }

    &:hover {
        svg {
            opacity: 1;
        }
    }
`;

export const Widget = styled(Div)`
    display: block;
    position: relative;
`;

export const WidgetJacket = styled(Div)`
    position: relative;
`;

export const WidgetTitle = styled(Div)`
    position: relative;
    display: block;
    padding: 1rem 0;

    background: ${props => props.theme.bc3};
    border-bottom: 1px solid ${props => props.theme.bc8};
`;

export const Title = styled(H3)`
    color: ${props => props.light ? props.theme.bc7 : props.theme.bc1};
`;

export const SaveText = styled(Span)`
    ${props => props.theme.animate('0.3s', 'ease-in-out')}
    position: relative;
    z-index: 5;
`;

export const Save = styled.button`
    ${props => props.theme.animate('0.3s', 'ease-in-out')}
    position: relative;
    display: block;
    width: 100%;
    padding: 1rem 2rem;
    text-align: center;

    cursor: pointer;

    font-size: 1.6rem;
    color: ${props => props.theme.bc1};

    background: ${props => props.theme.bc3};

    &:before {
        ${props => props.theme.animate('0.3s', 'ease-in-out')}
        content: '';
        position: absolute;
        z-index: 0;
        top: 0; right: 0; bottom: 0; left: 0;
        background: ${Gradient};
        opacity: 0;
    }

    &:hover {
        &:before {
            opacity: 1;
        }

        ${SaveText} {
            color: ${props => props.theme.bc3};
        }
    }

    ${props => props.disabled && css`
        pointer-events: none;
    `}
`;