// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';

// Styles
// ------
import { Jacket, Label } from './editButtonStyles';

// States
// ------
import { userState } from 'states/user';

// Component
// ------
export default class EditButton extends React.Component {
    handleEdit = () => {
        userState.editProfileOpen = !userState.editProfileOpen;
    }

    render() {
        return (
            <Jacket onClick={this.handleEdit}>
                <Label>Edit Profile</Label>
                <Icon type="settings" />
            </Jacket>
        )
    }
}