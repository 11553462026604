// Imports
// ------
import React from 'react';
import Body from 'utils/bodylock';
import Icon from 'components/global/icons/icons';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';
import { observer } from 'mobx-react';

// Styles
// ------
import { Button, Widget, WidgetJacket, WidgetTitle, Title, Clear, WidgetSave, SaveAndClose, SaveText } from '../filtersStyles';
import { TimeJacket, Switch, Label, TimeButtonJacket, Time, TimeSelector, TimeList, TimeItem, TimeListInner } from './timeStyles';

// States
// ------
import { scheduleFilters } from 'states/lokationSidebar';
import {isToday, minutesToTime} from "../../../../../lib/Util/TimeUtil";

// Component
// ------
class TimeButton extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isActive: false,
            selectedTimeStart: scheduleFilters.timeStart,
            selectedTimeEnd: scheduleFilters.timeEnd,
            fullDayPass: scheduleFilters.fulldayPass,

            timeStartOpen: false,
            timeEndOpen: false,

            times: []
        }
    }

    stateCheck = () => {
        if (this.state.isActive) {
            Body.lock();
        }

        if (!this.state.isActive) {
            Body.unlock();
        }
    }

    handleTime = () => {
        this.setState({isActive: !this.state.isActive})
    }

    handleSwitch = () => {
        // Component state
        let newPass = !this.state.fullDayPass;
        let start = newPass ? 8 * 60 : -1;
        let end = newPass ? 18 * 60 : -1;
        scheduleFilters.fulldayPass = newPass;
        scheduleFilters.timeStart = start;
        scheduleFilters.timeEnd = end;
        this.setState({selectedTimeStart: start, selectedTimeEnd: end, fullDayPass: newPass}, () => {
            if (this.state.fullDayPass) {
                this.handleSaveAndClose()
            }
        })
        
    }

    handleTimeStart = () => {
        this.setState({timeStartOpen: !this.state.timeStartOpen})
        this.setState({timeEndOpen: false})
        
        // create the start times...
        let times = [];
        let now = new Date();
        let hour = now.getHours() + 1;
        hour *= 60;
        
        // if the date isn't today, then we allow previous dates.
        if (scheduleFilters.date === null || !isToday(scheduleFilters.date)) {
            hour = 8 * 60; // we start at 8am?
        }
        
        
        let max = this.state.selectedTimeEnd === -1 ? 24 * 60 : this.state.selectedTimeEnd;
        do {
            times.push(hour);
            hour += 60;
        } while (hour < max)
        
        this.setState({times: times});
        
    }

    handleNewTimeStart = (e) => {
        this.setState({selectedTimeStart: e.target.getAttribute('value'), timeStartOpen: false})
    }

    handleTimeEnd = () => {
        this.setState({timeEndOpen: !this.state.timeEndOpen})
        this.setState({timeStartOpen: false})

        // create the end times... (not armageddon)
        let times = [];
        
        if (this.state.selectedTimeStart === -1) {
            this.setState({times: times});
            return;
        }
        
        let hour = (1 * this.state.selectedTimeStart) + 60;
        do {
            times.push(hour);
            hour += 60;
        } while (hour < 24 * 60);

        this.setState({times: times});
        
    }

    handleNewTimeEnd = (e) => {
        this.setState({selectedTimeEnd: e.target.getAttribute('value'), timeEndOpen: false})
    }

    handleClear = () => {
        // Clear component states
        this.setState({selectedTimeStart:-1, selectedTimeEnd:-1, fullDayPass: false}, () => {
            this.handleSaveAndClose();
        });

        
    }

    handleSaveAndClose = () => {
        // Do Save
        // ...
        scheduleFilters.timeStart = this.state.selectedTimeStart;
        scheduleFilters.timeEnd = this.state.selectedTimeEnd;
        scheduleFilters.fulldayPass = this.state.fullDayPass;
        if (scheduleFilters.fulldayPass) {
            scheduleFilters.seats = 1;
        } else {
            if (scheduleFilters.seats === 1) {
                scheduleFilters.seats = 0;
            }
        }
        // Do Close
        this.setState({isActive: !this.state.isActive, timeStartOpen: false, timeEndOpen: false})

        this.props.onChange();
    }

    render() {
        const checkActive = this.state.isActive;
        const timeStartOpenChecker = this.state.timeStartOpen;
        const timeEndOpenChecker = this.state.timeEndOpen;
        return (
            <>
                {/* The button / activator */}
                <Button onClick={this.handleTime} isActive={checkActive || scheduleFilters.timeStart !== -1 || scheduleFilters.fulldayPass}>
                    {!scheduleFilters.fulldayPass  && (scheduleFilters.timeStart > -1 || scheduleFilters.timeEnd > -1) &&
                        minutesToTime(scheduleFilters.timeStart) + " - " + minutesToTime(scheduleFilters.timeEnd)
                    }
                    {(scheduleFilters.fulldayPass) &&
                        "Day Pass"
                    }
                    {(!scheduleFilters.fullDayPass && scheduleFilters.timeStart === -1 && scheduleFilters.timeEnd === -1) &&
                        "Time"
                    }
                </Button>

                {/* The widget that pops up */}
                <Widget isActive={checkActive}>
                    <WidgetJacket>
                        <WidgetTitle>
                            <Row isExpanded equal>
                                <Column small={10}>
                                    <Title>Select Time</Title>
                                </Column>
                                <Column small={2}>
                                    <Div text="right">
                                        <Clear onClick={this.handleClear}>Clear</Clear>
                                    </Div>
                                </Column>
                            </Row>
                        </WidgetTitle>
                        <TimeJacket>
                            <Row isExpanded>
                                <Column small={6}>
                                    <Label>Day Pass</Label>
                                </Column>
                                <Column small={6}>
                                    <Div relative text="right">
                                        <Switch onClick={this.handleSwitch} isActive={scheduleFilters.fulldayPass } />
                                    </Div>
                                </Column>
                            </Row>
                            
                                <>
                                    <Row isExpanded equal>
                                        <Column small={6}>
                                            <Label>Start Time (EST)</Label>
                                        </Column>
                                        <Column small={6}>
                                            <TimeButtonJacket onClick={this.handleTimeStart} isOpen={timeStartOpenChecker}>
                                                <Time>{minutesToTime(this.state.selectedTimeStart)}</Time>
                                                <Icon type="small-arrow-down" />
                                            </TimeButtonJacket>
                                        </Column>
                                    </Row>
                                    <TimeSelector isOpen={timeStartOpenChecker}>
                                        <Row isExpanded>
                                            <Column small={12}>
                                                <TimeList>
                                                    {
                                                        this.state.times.map(minutes => (
                                                            <TimeItem key={minutes} onClick={(e) => this.handleNewTimeStart(e)} value={minutes} selected={this.state.selectedTimeStart === minutes}>{minutesToTime(minutes)}</TimeItem>
                                                        ))
                                                    }
                                                    
                                                </TimeList>
                                            </Column>
                                        </Row>
                                        <TimeListInner />
                                    </TimeSelector>
                                    <Row isExpanded equal>
                                        <Column small={6}>
                                            <Label>End Time (EST)</Label>
                                        </Column>
                                        <Column small={6}>
                                            <TimeButtonJacket onClick={this.handleTimeEnd} isOpen={timeEndOpenChecker}>
                                                <Time>{minutesToTime(this.state.selectedTimeEnd)}</Time>
                                                <Icon type="small-arrow-down" />
                                            </TimeButtonJacket>
                                        </Column>
                                    </Row>
                                    <TimeSelector isOpen={timeEndOpenChecker}>
                                        <Row isExpanded>
                                            <Column small={12}>
                                                <TimeList>
                                                    {
                                                        this.state.times.map(minutes => (
                                                            <TimeItem key={minutes} onClick={(e) => this.handleNewTimeEnd(e)} value={minutes} selected={this.state.selectedTimeEnd === minutes}>{minutesToTime(minutes)}</TimeItem>
                                                        ))
                                                    }
                                                </TimeList>
                                            </Column>
                                        </Row>
                                        <TimeListInner />
                                    </TimeSelector>
                                </>
                            
                        </TimeJacket>
                        <WidgetSave>
                            {(this.state.fullDayPass) || (this.state.selectedTimeStart && this.state.selectedTimeEnd) ?
                                <SaveAndClose onClick={this.handleSaveAndClose}>
                                    <SaveText>Search</SaveText>
                                </SaveAndClose>
                            :
                                <SaveAndClose disabled>
                                    <SaveText>Search</SaveText>
                                </SaveAndClose>
                            }
                        </WidgetSave>
                    </WidgetJacket>
                </Widget>
            </>
        )
    }

    componentDidUpdate() {
        this.stateCheck();
    }

    componentWillUnmount() {
        this.stateCheck();
    }
}

export default observer(TimeButton)