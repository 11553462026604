import React, { Component } from 'react';

import { AuthUserContext } from '../../lib/Session';
import { withFirebase } from '../../lib/Firebase';

import {minutesToTime} from '../../lib/Util/TimeUtil';
import MyBookings from '../../components/bookings/content/content';
import Loader from "../global/loader";
import moment from "moment";

class Bookings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            locations: {}
        }

    }

    componentDidMount = () => {

        this.onListenForLocations();

    }


    onListenForLocations = () => {

        this.setState({ loading: true });

        this.unsubscribe = this.props.firebase
            .locations()
            .onSnapshot(snapshot => {
                
                if (snapshot.size) {
                    let locations = {};
                    snapshot.forEach(doc => {
                        locations[doc.id] = {...doc.data(), uid: doc.id};
                    });
                    this.setState({
                        locations: locations,
                        loading: false,
                    });
                } else {
                    this.setState({ locations: {}, loading: false });
                }
            });

    };

    componentWillUnmount() {
        this.unsubscribe();
    }

    bookingTime = (booking) => {
        let seconds = moment(booking.date).unix();
        if (booking.is_table) {
            seconds += booking.start_time;
        } 
        return seconds;
    }

    render = () => {
        const { locations, loading } = this.state;
        const { bookings } = this.props;


        if (loading || !locations) {
            return <Loader/>
        }
        
        let upcoming = bookings ? bookings.filter(booking => booking.status === 'booked' || booking.status === 'started') : [];
        let past = bookings ? bookings.filter(booking => booking.status === 'completed') : [];

        upcoming = upcoming.sort((a, b) => {
            return this.bookingTime(a) < this.bookingTime(b) ? 1 : -1;
        })
        past = past.sort((a, b) => {
            return this.bookingTime(a) < this.bookingTime(b) ? 1 : -1;
        })

        let allocations = [];
        for (let i in locations) {
            allocations.push(locations[i]);
        }

        const section = this.props.match.params.section;
        let defaultIndex = 0;
        switch (section) {
            case 'past':
                defaultIndex = 1;
                break;
            case 'favorites':
                defaultIndex = 2;
                break;
            default:
                defaultIndex = 0;
        }
        
        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    <MyBookings
                        defaultIndex={defaultIndex}
                        authUser={authUser}
                        upcoming={
                            upcoming.map(booking => {
                                if (booking.is_table === false) {
                                    booking.start_time = 8 * 60;
                                    booking.end_time = 18 * 60;
                                    booking.seats = 1;
                                }
                                return {
                                    isFavorite: this.props.firebase.isFavorite(booking.location, authUser),
                                    slug: `/booking/${booking.uid}`,
                                    location: booking.location,
                                    title: locations[booking.location].name,
                                    subTitle: locations[booking.location].address.neighborhood,
                                    address: locations[booking.location].address.name,
                                    images: locations[booking.location].images,
                                    hasDetails: true,
                                    booking: booking,
                                    scheduleDate: booking.date,
                                    scheduleStart: minutesToTime(booking.start_time),
                                    scheduleEnd: minutesToTime(booking.end_time),
                                    schedulePass: booking.is_table === false,
                                    scheduleSeats: booking.seats,
                                }
                            })}

                        past={
                            past.map(booking => {
                                if (booking.is_table === false) {
                                    booking.start_time = 8 * 60;
                                    booking.end_time = 18 * 60;
                                    booking.seats = 1;
                                }
                                return {
                                    isFavorite: this.props.firebase.isFavorite(booking.location, authUser),
                                    slug: `/lokation/${booking.location}`,
                                    booking: booking,
                                    title: locations[booking.location].name,
                                    subTitle: locations[booking.location].address.neighborhood,
                                    address: locations[booking.location].address.name,
                                    images: locations[booking.location].images,
                                    hasPastDetails: true,
                                    scheduleDate: booking.date,
                                    scheduleStart: minutesToTime(booking.start_time),
                                    scheduleEnd: minutesToTime(booking.end_time),
                                    schedulePass: booking.is_table === false,
                                    scheduleSeats: booking.seats,
                                    location: booking.location,
                                    
                                    hasRatings: true,
                                    ratingValue: booking.review ? booking.review.rating : 0
                                }
                            })}

                        favorites={allocations.filter(location => this.props.firebase.isFavorite(location.uid, authUser)).map(location => {
                            return {
                                isFavorite: true,
                                onlyFavorite: true,
                                slug: `/lokation/${location.uid}`,
                                title: location.name,
                                subTitle: location.address.neighborhood,
                                address: location.address.name,
                                images: location.images,
                                location: location.uid,
                            }
                        })}
                         
                    />
                    
                )}
            </AuthUserContext.Consumer>
        );
    }
}

export default withFirebase(Bookings);
