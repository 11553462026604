// Imports
// ------
import { createGlobalStyle } from 'styled-components';
import { themeStyles } from './theme';
import { breakup } from './breakpoints';

// Styles
// ------
const Global = createGlobalStyle`

    html {
        font-size: 62.5%;
    }

    html > body {
        background-color: ${themeStyles.bc1};
        font-family: ${themeStyles.body};
        color: white;
        margin: 0;
    }

    body {
        width: 100%;
    }

    a, button {
        color: inherit;
        text-decoration: none;

        &:visited {
            color: inherit;
        }
    }

    h1, h2, h3, h4, h5, h6, p, span, em, ul, li, ol, button, a {
        margin: 0;
        padding: 0;
        outline: none;
        font-variant-ligatures: none;
    }

    p, span, em, ul, li, ol, button, a {
        font-family: ${themeStyles.body};
        font-size: 1.6rem;
        line-height: 2.6rem;

        ${breakup.large`
            font-size: 1.8rem;
            line-height: 3.2rem;
        `}
    }

    .tl-wrapper { overflow: hidden; }

    input[type='text'],
    input[type='email'],
    input[type='tel'],
    input[type='submit'],
    input[type='button'],
    select,
    option,
    button,
    textarea {
        -moz-appearance: none;
        text-indent: 0.01px;
        text-overflow: '';
        border-radius: 0;
        border: none;

        &:focus { outline: none; }
    }
`;

export default Global;
