// Imports
// ------
import styled from 'styled-components';
import { Div, Span } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Div)`
    position: relative;
    display: block;

    cursor: pointer;

    svg {
        width: 2.4rem;
        height: 2.4rem;
    }
`;

export const Text = styled(Span)`
    margin-left: 1rem;
    line-height: 1rem !important;
    margin-top: .8rem;
    display: inline-block;
    vertical-align: top;

    color: ${props => props.theme.bc3};
`;
