// Imports
// ------
import styled, { css } from "styled-components";
import { breakup } from 'components/global/theme/breakpoints';
import { Section, Div, Span } from 'components/global/theme/reusables/reusables';

import visibility from '../../../components/global/theme/grid/visibility';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    display: block;
`;

export const GobackLabel = styled(Span)`
    ${props => props.theme.animate('0.3s', 'ease-in-out')}
    display: none;
    vertical-align: middle;
    margin-left: 0.5rem;

    ${breakup.medium`display: inline-block;`}
`;

export const Goback = styled(Div)`
    position: absolute;
    top: 2rem;
    left: 2rem;
    z-index: 10;

    ${breakup.large`
        top: 14rem;
        left: calc(100% / 12 * 1.1)
    `}

    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.6rem;
    }

    &:hover {
        ${GobackLabel} {
            opacity: 0.4;
        }
    }
`;

export const HeroJacket = styled(Div)`
    position: relative;
`;

export const FavoriteWrapper = styled(Div)`
    position: absolute;
    z-index: 50;
    top: 3rem;
    left: 2rem;

    ${props => props.posRight && css`
        left: auto;
        right: 3rem;
    `}
    
    
    /* Visibility */
    ${visibility}
`;
