// Imports
// ------
import styled from "styled-components";
import {Section, H2, Span, Div} from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    z-index: 10;
    display: block;
    padding-top: 6rem;
`;

export const Title = styled(H2)`
    display: block;
    margin-bottom: 2rem;
    
    color: ${props => props.theme.bc3};
`;

export const Loadmore = styled.button`
    position: relative;
    display: inline-block;

    background: none;
    cursor: pointer;
`;

export const Label = styled(Span)`
    display: inline-block;
    vertical-align: middle;

    color: ${props => props.theme.bc3};

    + svg {
        display: inline-block;
        vertical-align: middle;
    }
`;


export const AdminJacket = styled(Section)`
    position: relative;
    z-index: 10;
    display: block;
    padding-top: 6rem;
    padding-bottom: 6rem;
    background: rgba(255, 255, 255, 0.1);
    
`;

export const Flex = styled(Div)`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

`;

export const Col = styled(Div)`
    max-width: 50%;
    font-size: 2rem;
`;

export const Panel = styled(Section)`
    display: block;
    padding: 6rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
`;

export const Heading = styled.h2`
    font-size: 24px;
    padding-bottom: 2rem;
`;

export const SmallButton = styled.button`
      background: red;
      color: white;
      padding-left: 1rem;
      padding-right: 1rem;
`;

export const DateLabel = styled.span`
    font-size: 18px;
    font-weight: bold;    
`;