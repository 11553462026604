// Imports
// ------
import React from 'react';
import Background from 'components/global/background/background';
import Profile from 'components/user/profile/profile';
import {AuthUserContext, withAuthorization} from "../lib/Session";
import {compose} from "recompose";

// Page
// ------
class UserProfile extends React.Component {
    render() {
        return (
            <Background>


                <AuthUserContext.Consumer>

                    {authUser => 
                        authUser && <Profile
                            avatar={authUser.profileImage}
                            name={authUser.firstName}
                            authUser={authUser}

                        />
                    }
                </AuthUserContext.Consumer>
            </Background>
        )
    }
}


const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition)
)(UserProfile);