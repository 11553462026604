// Imports
// ------
import styled from "styled-components";
import { breakup } from 'components/global/theme/breakpoints';
import { Div, H3, H4, P, GradientIcon, Span } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Details = styled(Div)`
    position: relative;
    background: ${props => props.theme.bc3};
    padding: 2rem;
`;

export const Schedule = styled(Div)`
    position: relative;
    background: ${props => props.theme.bc4};
    padding: 2rem 0;
`;

export const Title = styled(H3)`
    position: relative;
    vertical-align: middle;

    color: ${props => props.theme.bc1};

    svg {
        ${GradientIcon}
        width: 1.8rem;

        display: inline-block;
        margin-right: 1rem;
        vertical-align: middle;
    }
`;

export const Address = styled(Div)`
    position: relative;
    display: block;
    margin-top: 1.5rem;
`;

export const ALine1 = styled(H4)`
    position: relative;
    display: block;

    color: ${props => props.theme.bc1};
    line-height: 1 !important;
`;

export const ALine2 = styled(Span)`
    position: relative;
    display: block;

    font-size: 1.4rem !important;
    line-height: 1.8rem !important;
    color: ${props => props.theme.bc1};
`;

export const Label = styled(Span)`
    color: ${props => props.theme.bc1};
`;

export const Date = styled(Span)`
    position: relative;
    display: block;

    color: ${props => props.theme.bc1};
`;

export const ScheduleDetailWrapper = styled(Div)`
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    margin-bottom: 1rem;
`;

export const Start = styled(Span)`
    vertical-align: middle;

    color: ${props => props.theme.bc1};
`;

export const End = styled(Span)`
    vertical-align: middle;

    color: ${props => props.theme.bc1};
`;

export const Iconwrap = styled(Span)`
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    vertical-align: middle;

    svg {
        ${GradientIcon}
        width: 2.4rem;
        vertical-align: middle;
    }
`;

export const Seats = styled(P)`
    position: relative;
    margin: 0;

    color: ${props => props.theme.bc1};

    ${breakup.large`font-size: 2rem;`}
`;

export const Full = styled(H3)`
    position: relative;
    display: block;

    color: ${props => props.theme.bc1};
`;

export const ScheduleEdit = styled(Div)`
    position: relative;
    display: block;
`;

export const Add = styled.button`
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;

    background: transparent;
    color: ${props => props.theme.bc2};

    font-size: 1.6rem;
    ${breakup.large`font-size: 1.8rem;`}
`;

export const Edit = styled.button`
    position: relative;
    display: block;
    width: 100%;
    padding: 1.5rem 2rem;
    cursor: ${props => props.noclick ? 'defalult' : 'pointer'};

    background: ${props => props.theme.bc3};
    color: ${props => props.black ? props.theme.bc2 : props.theme.bc5};

    font-size: 1.6rem;
    ${breakup.large`font-size: 1.8rem;`}

    &:hover {
        color: ${props => props.theme.bc2};
    }
`;

export const AddDetailsButton = styled.button`
    ${props => props.theme.animate('0.3s', 'ease-in-out')}
    position: relative;
    display: inline-block;
    background: none;
    cursor: pointer;

    color: ${props => props.theme.bc5};
    opacity: 0.4;

    &:hover {
        opacity: 0.8;
    }
`;