// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import FaqList from './list/list';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';
import { observer } from 'mobx-react';

// Styles
// ------
import { Jacket, Header, Close, Title, HelpTitle } from './faqsStyles';

// States
// ------
import { userState } from 'states/user';

// Utils
// ------
import Body from 'utils/bodylock';

// Component
// ------
class Faqs extends React.Component {
    toggleClose = () => {
        userState.faqsIsOpen = !userState.faqsIsOpen;
    }

    stateCheck = () => {
        if (userState.faqsIsOpen) {
            Body.lock();
        }

        if (!userState.faqsIsOpen) {
            Body.unlock();
        }
    }

    render() {
        return (
            <Jacket isActive={this.props.isActive}>
                {/* Help header */}
                <Header>
                    <Row isExpanded>
                        <Column small={12} medium={10} pushOnMedium={1}>
                            <Row isExpanded>
                                <Column small={12}>
                                    <Div relative text="center">
                                        <Close onClick={this.toggleClose}>
                                            <Icon type="small-arrow-left" />
                                        </Close>
                                        <Title>FAQs</Title>
                                    </Div>
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                </Header>

                <Row isExpanded>
                    <Column small={12} medium={10} pushOnMedium={1}>
                        <Row isExpanded>
                            <Column small={12}>
                                <HelpTitle>Answers to Common Questions</HelpTitle>
                            </Column>
                        </Row>
                    </Column>
                </Row>

                <FaqList list={[
                    {
                        question: "What is Lokus?",
                        answer: <>Lokus connects professionals to hotels with unique drop-in workspaces, reliable services, and smile-worthy perks - so you can tailor your workday and life needs to…well, you. This is our take on bleisure (business + leisure).</>,
                    }
                    ,{
                        question: "What is a “bleisiure” (business + lesuire) work experience?",
                        answer: <>Your booking comes with more than a dedicated seat, secure WiFi, accessible power outlet (and a clean restroom!). From discounts on food and beverage to wellness offerings, as Lokals, will find everything you need to have the most productive (delicious and relaxing) workday.<br/><br/>
                            Between daily perks and other onsite amenities offered at each of our Lokations, Lokals will have an abundant number of choices to take any workday from a-okay to YAY!</>,
                    }
                    ,{
                        question: "Where are your drop-in workspaces located?",
                        answer: <>Each drop-in workspace is intentionally situated within noteworthy hotels (i.e. Lokations). This enables Lokus to offer you personalized, work-life experiences in collaboration with our Lokations. </>,
                    }
                    ,{
                        question: "What are your drop-in workspace booking options?",
                        answer: <>Professionals (i.e. Lokals), you can drop-in when you need to… for as long as you need to.
                            Whether you want to work heads-down for a few short hours or have a day filled with in-person and virtual meetings, you have the flexibility of choosing booking options that make sense for your schedule each day.<br/><br/>

                            DAILY<br/>
                            1 Seat (Day Pass) @ Shared Table   $20/Day<br/>
                            Ideal for individuals wanting uninterrupted, personal work-time (and time away from a home office, cramped coffee shop, or open office plan!)<br/>
                            <br/>
                            HOURLY<br/>
                            2 Seats @ Reserved Table       $10/Hour<br/>
                            Great for individuals seeking a reliable and convenient place to meet with clients, host interviews, network, or join virtual/phone meetings<br/>
                            <br/>
                            4 Seats @ Reserved Table       $20/Hour<br/>
                            Perfect for small teams and groups needing a dedicated place to briefly convene and connect outside the office or while on the road<br/>
                            <br/>
                            6 Seats @ Reserved Table       $30/Hour<br/>
                            Fantastic for impromptu off-site meetings that can accommodate everyone without the hassle of formal event planning and catering orders<br/>
                            <br/>
                            Please note that all tables listed above are located in public, and not private, areas within the drop-in workspaces.</>,
                    }
                    ,{
                        question: "What are the working hours of the Lokations?",
                        answer: <>Days and times vary by Lokation, though we try our best to provide availability Monday-Friday; 8am-6pm EST. Please review the Lokation profile when booking for exact days and times.</>,
                    }
                    ,{
                        question: "Do I need an account to book a drop-in work experience?",
                        answer: <>Yes, in order to book a drop-in work experience, you are required to create a Lokus account. This account is free of charge; you only pay per booking. We will keep your profile and booking details all in one place, helping you speed up your booking, check-in, and check-out experiences.</>,
                    }
                    ,{
                        question: "How far in advance can I book a drop-in workspace?",
                        answer: <>You can book up to 7 days in advance or on-demand when you’re out and about.</>,
                    }
                    ,{
                        question: "What is your cancellation policy?",
                        answer: <>You can cancel up to 24-hours before your booking date/time for a full refund. Bookings made within 24-hours of booking date/time are non-refundable. </>,
                    }
                    ,{
                        question: "How do I access my drop-in workspace?",
                        answer: <>Check-in is made available 15 minutes prior to the booking date/time. To check-in, sign into your account to view your bookings. Upon check-in, you gain instant access to your assigned drop-in workspace table, dedicated WiFi network, and daily lokation perks.</>,
                    }
                    ,{
                        question: "Am I able to make more than one booking on the same day?",
                        answer: <>You can make one or more bookings on the same day at a specific Lokation or across a variety of Lokations.</>,
                    }
                    ,{
                        question: "Why do I need to check-out at the end of my booking?",
                        answer: <>We want to hear about your on-site experience, so we can continue to provide you with awesome drop-in work experiences.</>,
                    }
                    ,{
                        question: "What payment methods do you offer?",
                        answer: <>Lokus accepts all major credit and debit cards.</>,
                    }
                    ,{
                        question: "What if I am a hotel guest at one of your Lokations?",
                        answer: <>Hotel guests book for free at the Lokation they are residing at for the stay of their duration. See the front desk for details.</>,
                    }
                    ,{
                        question: "How will Lokus communicate with me?",
                        answer: <>You will receive email notifications from us regarding your booking activity and drop-in work experience. You can opt-out of marketing emails at any time, though we encourage you to stay in tune with us to hear about events, promotions, and opportunities. </>,
                    }
                    ,{
                        question: "Questions or issues? ",
                        answer: <>Contact the Lokal Experience team at <a href="mailto:help@booklokus.com">help@booklokus.com</a></>
                    }

                ]} />
            </Jacket>
        )
    }

    componentDidUpdate() {
        this.stateCheck();
    }

    componentWillUnmount() {
        this.stateCheck();
    }
}

export default observer(Faqs);