// Import
// ------
import React from 'react';
import NavLink from 'components/global/navlink/navlink';
import Icon from 'components/global/icons/icons';
import Favorite from 'components/global/favorite/favorite';
import ImageSlider from 'components/global/imageslider/imageslider';
import LokationSidebar from 'components/lokations/sidebar/sidebar';
import { Row, Column } from 'components/global/theme/grid/grid';
import { Div } from 'components/global/theme/reusables/reusables';

// Styles
// ------
import { Jacket, Goback, GobackLabel, HeroJacket, FavoriteWrapper } from './heroStyles';

// Component
// ------
export default class LokationHero extends React.Component {
    
    
    favToggle = () => {
        this.props.favToggle(this.props.location.uid, this.props.authUser)    
    }
    
    render() {
        
        let sidebar = <LokationSidebar
            // Notice
            location={this.props.location}
            handleCharge={this.props.handleCharge}
            activeBooking={this.props.activeBooking}

            isFavorite={this.props.isFavorite} 
            onFavToggle={this.favToggle}

            hasNotice={this.props.hasNotice}
            noticeTitle={this.props.noticeTitle}
            noticeContent={this.props.noticeContent}
            noticeHasLink={this.props.noticeHasLink}
            noticeLinkText={this.props.noticeLinkText}
            noticeLinkUrl={this.props.noticeLinkUrl}

            // Details
            locationSet={this.props.locationSet}
            locationTitle={this.props.locationTitle}
            hasAddress={this.props.hasAddress}
            addressLine1={this.props.addressLine1}
            addressLine2={this.props.addressLine2}
            scheduleDate={this.props.scheduleDate}
            schedulePass={this.props.schedulePass}
            scheduleStart={this.props.scheduleStart}
            scheduleEnd={this.props.scheduleEnd}
            scheduleSeats={this.props.scheduleSeats}

            // Perks
            hasPerks={this.props.hasPerks}
            perks={this.props.perks}

            // Book Now
            hasBooking={this.props.hasBooking}
            hasNoResults={this.props.hasNoResults}
            totalCost={this.props.totalCost}

            // About
            hasAbout={this.props.hasAbout}
            aboutTitle={this.props.aboutTitle}
            aboutContent={this.props.aboutContent}
            aboutLinks={this.props.aboutLinks}
            // aboutHasLink={this.props.aboutHasLink}
            // aboutLinkTitle={this.props.aboutLinkTitle}
            // aboutLinkUrl={this.props.aboutLinkUrl}

            // Checkin
            hasCheckin={this.props.hasCheckin}
            handleCheckIn={this.props.handleCheckIn}
            canCheckIn={this.props.canCheckIn}
            canCancelCheckIn={this.props.canCancelCheckIn}
            handleCancelBooking={this.props.handleCancelBooking}

            // Amenities
            hasAmenities={this.props.hasAmenities}
            amenities={this.props.amenities}

            // Extender
            hasExtender={this.props.hasExtender}
            handleEndBooking={this.props.handleEndBooking}

            // Reservation Extender
            hasReservationExtender={this.props.hasReservationExtender}

            // Rating
            hasRating={this.props.hasRating}
            rating={this.props.rating}
            handleRating={this.props.handleRating}

            // Checkout Form
            hasCheckoutForm={this.props.hasCheckoutForm}
            checkoutFormOptions={this.props.checkoutFormOptions}
            handleCheckoutOptions={this.props.handleCheckoutOptions}
            handleCheckout={this.props.handleCheckout}
            authUser={this.props.authUser}
            cancelCheckout={this.props.cancelCheckout}

            // Review Received
            reviewReceived={this.props.reviewReceived}
        />
        
        return (
            <Jacket>
                {this.props.hasBackButton &&
                    <Goback>
                        <NavLink to="/lokations">
                            <Icon type="small-arrow-left" />
                            <GobackLabel>Go Back</GobackLabel>
                        </NavLink>
                    </Goback>
                }

                {/* Mobile version */}
                <Row isExpanded isCollapsed hideOnXlarge>
                    <Column small={12}>
                        <HeroJacket>
                            {/*{this.props.hasFavorite &&*/}
                            {/*<FavoriteWrapper posRight>*/}
                            {/*    <Favorite isFavorite={this.props.isFavorite} onClick={this.favToggle} />*/}
                            {/*</FavoriteWrapper>*/}
                            {/*}*/}
                            { this.props.images && <ImageSlider
                                heroTitle={this.props.heroTitle}
                                subheroTitle={this.props.subheroTitle}
                                images={this.props.images}
                            /> }
                        </HeroJacket>
                        
                    </Column>
                    <Column small={12}>
                        {sidebar}
                    </Column>
                </Row>

                {/* Desktop version */}
                <Div padTop>
                    <Row isExpanded showOnXlarge>
                        <Column small={12} large={10} pushOnLarge={1}>
                            <Div relative pad={this.props.pad} padTop={this.props.padTop}>
                                <Row isExpanded isCollapsed>
                                    <Column large={8}>
                                        <HeroJacket>
                                            {this.props.hasFavorite &&
                                            <FavoriteWrapper>
                                                <Favorite isFavorite={this.props.isFavorite} onClick={this.favToggle} withText />
                                            </FavoriteWrapper>
                                            }
                                            { this.props.images && <ImageSlider
                                                heroTitle={this.props.heroTitle}
                                                subheroTitle={this.props.subheroTitle}
                                                images={this.props.images}
                                            /> }
                                        </HeroJacket>
                                        
                                    </Column>
                                    <Column large={4}>
                                        {sidebar}
                                    </Column>
                                </Row>
                            </Div>
                        </Column>
                    </Row>
                </Div>
            </Jacket>
        )
    }
}