import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                authUser: JSON.parse(localStorage.getItem('authUser')),
            };
        }

        componentDidMount() {
            this.listener = this.props.firebase.onAuthUserListener(
                authUser => {
                    localStorage.setItem('authUser', JSON.stringify(authUser));
                    this.setState({ authUser });

                    this.userListener = this.props.firebase.user(authUser.uid).onSnapshot(snapshot => {
                   
                        let user = { ...snapshot.data(), uid: authUser.uid, emailVerified: authUser.emailVerified };
                        localStorage.setItem('authUser', JSON.stringify(authUser));
                        this.setState({authUser: user})
                        
                    });
                },
                () => {
                    localStorage.removeItem('authUser');
                    this.setState({ authUser: null });
                    this.userListener = null;
                },
            );
        }

        componentWillUnmount() {
            this.listener();
            if (this.userListener) {
                this.userListener();
            }
        }

        render() {
            return (
                <AuthUserContext.Provider value={this.state.authUser}>
                    <Component {...this.props} />
                </AuthUserContext.Provider>
            );
        }
    }

    return withFirebase(WithAuthentication);
};

export default withAuthentication;
