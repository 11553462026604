// Imports
// ------
import React from 'react';
import Button from 'components/global/buttons/primary';
import Input from 'components/global/formfields/input';
import { Label as InputLabel } from 'components/global/formfields/inputStyles';
import NavLink from 'components/global/navlink/navlink';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';
import { observer } from 'mobx-react';


// Styles
// ------
import {Promo, Jacket, Label, Total, Cost, PaymentJacket, Terms, Striked} from './bookingpaymentStyles';

// Stripe

import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe,
} from 'react-stripe-elements';

import { themeStyles } from '../../../global/theme/theme';
import Loader from "../../../global/loader";
import {notificationState} from "../../../../states/notifications";
import {compose} from "recompose";
import {withFirebase} from "../../../../lib/Firebase";
import moment from "moment";
import {stringToDate} from "../../../../lib/Util/TimeUtil";

// Component
// ------
class SidebarBookingPayment extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            message: "",
            loading: false,
            postalCode: '',
            promoCode: '',
            totalCost: props.totalCost,
            codeApplied: false,
            
            purpose: null,
            
            purposes: [
                "Individual Work-Time",
                "Team Meeting",
                "Client Meeting",
                "Interview",
                "Networking",
                "Other"
            ]
        }
    }

    handleChange = event => {
        let value = event.target.value;
        if (event.target.name === 'promoCode') {
            value = value.toUpperCase();
        } else if (event.target.name === 'postalCode') {
            value = value.substr(0, 5);
        }
        this.setState({ [event.target.name]: value });
    };

    handlePromo = () => {
        
        if (this.state.promoCode === 'TEST') {
            this.setState({totalCost: 0, codeApplied: true})
        } else {
            
            // get the promo codes for that hotel
            this.props.firebase.codes()
                .where("location", "==", this.props.location.uid)
                .where("code", "==", this.state.promoCode)
                .get()
                .then(snapshot => {
                    if (snapshot.size) {
                        let codes = [];
                        snapshot.forEach(doc =>
                            codes.push({ ...doc.data(), uid: doc.id }),
                        );

                        if (codes.length === 0) {
                            throw new Error("Invalid Promo Code Existance");
                        }
                        
                        let code = codes[0];
                        
                        if (!this.isValid(code)) {
                            console.log(code);
                            throw new Error("Invalid Promo Code Dates");
                        }

                        if (code.user && code.user !== this.props.authUser.uid) {
                            console.log(code.user, this.props.authUser.uid)
                            throw new Error("Invalid Promo Code User");
                        }

                        this.props.firebase.codes().doc(code.uid).set({user: this.props.authUser.uid}, {merge: true}).then(() => {
                            this.setState({totalCost: 0, codeApplied: true})

                        })
                        
                    } else {
                        throw new Error("Invalid Promo Code");
                    }
                    
                }).catch(error => {
                    console.log(error);
                    
                    notificationState.generalTitle = "Invalid Promo Code";
                    notificationState.generalText = "Pleasde provide a valid promo code";
                    notificationState.generalButtons = [
                        {
                            title: "Okay",
                            url: "#"
                        }
                    ];
                    notificationState.generalOpen = true;
            })
            
            
            
        }
    }

    isValid = (code) => {
        if (code.usages === 0) {
            return false;
        }
        let start_date = moment(code.start_date);
        let end_date = moment(code.end_date);
        let check = moment(stringToDate(this.props.scheduleDate))
        if (end_date.isBefore(check) || start_date.isAfter(check)) {
            return false;
        }
        return true;
    }
    

    removePromo = () => {
        this.setState({
            totalCost: this.props.totalCost,
            codeApplied: false,
            promoCode: ''
        });
    }
    
    stripeElementsStyle = () => {
        return {
            style: {
                base: {
                    fontSize: '1.5rem',
                    color: themeStyles.bc1,
                    fontFamily: 'Helvetica',
                },
                invalid: {
                    color: '#9e2146',
                },
            }
        }
    };

    handlePayment = async (ev) => {
        
        if (this.state.totalCost === 0) {
            
            // do the booking without a payment.

            this.setState({loading: true});
            this.props.handleCharge(null, this.state.promoCode, this.state.purpose, (error) => {
                notificationState.thanksOpen = true;
                this.setState({message: error, loading: false})
            })
            
            return;
        }
        
        let {token} = await this.props.stripe.createToken({
            type: 'card',
            name: this.props.authUser.fullName,
            address_zip: this.state.postalCode,
            
        });
        
        if (token) {

            this.setState({loading: true});

            this.props.handleCharge(token, null,  this.state.purpose, (error) => {
                notificationState.thanksOpen = true;
                this.setState({message: error, loading: false})
            })
            
        } else {

            this.setState({loading: false});
        }
        
    }

    render() {

        return (
            <>

                <Promo>
                    <Label
                        
                        dark
                    >
                        Booking Purpose
                    </Label>
                    
                    {this.state.purpose && <Button onClick={e => { this.setState({purpose: null})}} button fullWidth type="full" text={this.state.purpose} textColor="light" />}
                    
                    {!this.state.purpose && this.state.purposes.map(purp => <Button key={purp} button fullWidth type="ghost" text={purp} textColor="dark" onClick={e => { this.setState({purpose: purp})}} />)}
                    
                </Promo>


                <Promo>
                    <Input name="promoCode" value={this.state.promoCode} onChange={this.handleChange} autocomplete="off" disabled={this.state.codeApplied} label="Promo Code" dark type="text"  placeholder="Enter promo code" />
                    { this.state.codeApplied === false && <Button button fullWidth type="ghost" text="Apply Code" textColor="dark" onClick={this.handlePromo} /> }
                    { this.state.codeApplied === true && <Button button fullWidth type="ghost" text="Remove Code" textColor="dark" onClick={this.removePromo} /> }
                </Promo>
                
                
                <Jacket>
                    <Total>
                        <Row isExpanded isCollapsed>
                            <Column small={6}>
                                <Label>To Pay</Label>
                            </Column>
                            <Column small={6}>
                                <Div text="right">
                                    <Cost>
                                        {this.state.totalCost < this.props.totalCost && <Striked>${this.props.totalCost}</Striked>}
                                        ${this.state.totalCost}
                                    
                                    </Cost>
                                </Div>
                            </Column>
                        </Row>
                    </Total>
                    
                    { this.state.totalCost === 0 &&
                    <PaymentJacket>
                        <Row isExpanded isCollapsed>
                            <Column small={12}>
                                <Button button onClick={this.handlePayment} fullWidth type="full"
                                        text="Book Now"/>
                            </Column>
                        </Row>
                        <Row isExpanded isCollapsd>
                            <Column small={12}>
                                <Div text="center">
                                    <Terms>
                                        By booking, you agree to our <NavLink to="/">Privacy Policy and Terms</NavLink>.
                                        Bookings made within 24-hours of booking date/time are non-refundable.
                                    </Terms>
                                </Div>
                            </Column>
                        </Row>
                        
                        
                    </PaymentJacket>
                    }
                    { this.state.totalCost > 0 && <PaymentJacket>
                        {this.state.loading ? 
                            <Loader />
                            :
                            <>
                                <Row isExpanded isCollapsed>
                                    <Column small={12}>
                                        <InputLabel>Card Number*</InputLabel>
                                        <CardNumberElement {...this.stripeElementsStyle()} />
                                    </Column>
                                </Row>
                                <Row isExpanded isCollapsed>
                                    <Column small={4}>
                                        <InputLabel>Expiry*</InputLabel>
                                        <CardExpiryElement {...this.stripeElementsStyle()}  />

                                    </Column>
                                    <Column small={4}>
                                        <InputLabel>CCV*</InputLabel>
                                        <CardCVCElement {...this.stripeElementsStyle()} />

                                    </Column>
                                    <Column small={4}>
                                        <Input dark type="text" name="postalCode" value={this.state.postalCode}
                                               onChange={this.handleChange} label="Postal Code*"
                                               placeholder="00000"/>
                                    </Column>
                                </Row>
                                <Row isExpanded isCollapsed>
                                    <Column small={12}>
                                        <Button button onClick={this.handlePayment} fullWidth type="full"
                                                text="Book Now"/>
                                    </Column>
                                </Row>
                                <Row isExpanded isCollapsd>
                                    <Column small={12}>
                                        <Div text="center">
                                            <Terms>
                                                By booking, you agree to our <NavLink to="/">Privacy Policy and Terms</NavLink>. 
                                                Bookings made within 24-hours of booking date/time are non-refundable.
                                            </Terms>
                                        </Div>
                                    </Column>
                                </Row>
                            </>
                        }
                        
                    </PaymentJacket> }
                </Jacket>
            </>
        )
    }
}

export default compose(
    observer,
    withFirebase
)(injectStripe(SidebarBookingPayment));