import React, {Component} from 'react';

import {AuthUserContext} from '../lib/Session';
import {withFirebase} from '../lib/Firebase';
import {compose} from 'recompose';
import {StripeProvider} from 'react-stripe-elements';

import Lokations from "../lib/Lokus/Lokations";
import LokationsHero from "../components/lokations/hero/hero";
import {scheduleFilters} from "../states/lokationSidebar";
import LokationAbout from "../components/lokations/about/about";
import LokationInformation from "../components/lokations/information/information";
import Notification from "../components/global/notification/notification";
import withBookings from "../lib/Session/withBookings";
import Loader from "../components/global/loader";
import Filters from 'components/lokations/filterModal/filterModal';
import { themeStyles } from 'components/global/theme/theme';
import Background from 'components/global/background/background';
import {isToday} from "../lib/Util/TimeUtil";


class Location extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            location: null,
            notificationURL: '/',
            notificationData: {
                booking: null,
                location: null,
                allday: false,
                seats: 2,
                url: '/'
            },
            amount: 0,
            paymentSuccess: false,
            hasBooking: false,
            hasAnySearch: false,
            filters: {
                date: scheduleFilters.date,
                passes: 1,
                allday: scheduleFilters.fulldayPass,
                start_time: scheduleFilters.timeStart,
                end_time: scheduleFilters.timeEnd,
                seats: scheduleFilters.seats
            },
        };

        this.lokations = new Lokations(this.props.firebase);
    }

    componentDidMount = () => {
        
        this.props.firebase.analytics.logEvent("visit_lokation", {lokation: this.props.match.params.location});

        let filters = this.state.filters;
        let amount = 0;

        if (filters.date) {
            if (filters.allday) {
                amount = 20 * filters.passes;
            } else {
                let mult = {
                    "2": 10,
                    "4": 20,
                    "6": 30
                };
                let val = mult[filters.seats];
                amount = val * Math.ceil((filters.end_time - filters.start_time) / 60);
            }
        }
        
        this.setState({amount: amount, paymentSuccess: false});


        this.onListenForLocation();

    }

    clearFilters = () => {
        
        scheduleFilters.date = null;
        scheduleFilters.fulldayPass = false;
        scheduleFilters.timeStart = -1;
        scheduleFilters.timeEnd = -1;
        scheduleFilters.seats = 0;
        
        this.onFilterChange();
    }

    onListenForLocation = async () => {
        this.setState({ loading: true });

        this.unsubscribe = this.props.firebase
            .location(this.props.match.params.location)
            .onSnapshot(snapshot => {
                if (snapshot) {
                    let location = { ...snapshot.data(), uid: this.props.match.params.location };
                    this.setState({
                        location: location,
                        loading: false,
                    }, () => {
                        this.onFilterChange();
                    });
                } else {
                    console.log("No location", snapshot, this.props.match.params);
                    this.setState({
                        location: null,
                        loading: false,
                    });
                }
            });
        


        let hasBooking = await this.hasResults();
        this.setState({hasBooking : hasBooking, hasAnySearch: this.hasAnySearch()});
        
    };

    componentWillUnmount() {
        this.unsubscribe();
    }


    onFilterChange = async () => {
        
        let start_time = scheduleFilters.timeStart;
        let end_time = scheduleFilters.timeEnd;
        if (scheduleFilters.fulldayPass) {
            start_time = this.state.location.start_time;
            end_time = this.state.location.end_time;
        }
        
        await this.setState({filters: {
                date: scheduleFilters.date,
                passes: 1,
                allday: scheduleFilters.fulldayPass,
                start_time: start_time,
                end_time: end_time,
                seats: scheduleFilters.seats
            }});


        
        let filters = this.state.filters;
        let amount = 0;

        if (filters.date) {
            if (filters.allday) {
                amount = 20 * filters.passes;
            } else {
                let mult = {
                    "2": 10,
                    "4": 20,
                    "6": 30
                };
                let val = mult[filters.seats];
                amount = val * Math.ceil((filters.end_time - filters.start_time) / 60);
            }
        }


        let hasBooking = await this.hasResults();
        let hasAnySearch = this.hasAnySearch();

         this.props.firebase.analytics.logEvent("lokation_filter_change", {lokation: this.state.location.name, has_search: hasAnySearch, has_booking: hasBooking});
        
        this.setState({amount: amount, hasBooking: hasBooking, hasAnySearch: hasAnySearch});
        
    }

    addCharge = async (token, promo, booking, authUser, callback) => {

        let charge = null;
        if (token && !promo) {
            let result = await this.performCharge(token);

            if (result.statusCode === 200) {
                charge = result.body;

                this.props.firebase.analytics.logEvent("lokation_booking_charge", {lokation: this.state.location.name, amount: this.state.amount});

            } else {
                let message = "";
                if (result.statusCode === 500) {
                    if (result.body.error) {
                        message = result.body.error;
                        // TODO: check error codes.

                        this.props.firebase.analytics.logEvent("lokation_booking_charge_failed", {lokation: this.state.location.name});
                    }
                }

                callback(message);
                return;

            }
        } else {


            this.props.firebase.analytics.logEvent("lokation_booking_with_promo", {lokation: this.state.location.name, promo: promo});
        }
        
        await this.props.firebase.booking(booking).set({status: 'booked'}, {merge: true})
        
        // record the charge.
        this.props.firebase.charges().doc().set({
            user: authUser.uid,
            location: this.state.location.uid,
            booking: booking,
            charge: charge,
            promo: promo,
        }).then(e => {
        //    this.props.history.push("/bookings");
            
            let data = {
                booking: booking,
                location: this.state.location.uid,
                allday: this.state.filters.allday,
                seats: this.state.filters.seats,
                url:  `/booking/${booking}`
            };
            
            this.setState({
                notificationURL: `/booking/${booking}`,
                notificationData: data,
                paymentSuccess: true,
                amount: 0, // this will hide the form for now.
                
            }, () => {
                
                // clear out the booking criteria
                this.clearFilters()
                callback(null);
            })

        })


    }

    
    performCharge = async (token) => {

        let response = await fetch("https://us-central1-lokus-mvp.cloudfunctions.net/charge", {
            method: "POST",
            headers: {"Content-Type": "text/plain"},
            body: JSON.stringify({
                token: token,
                charge: {
                    amount: this.state.amount * 100,
                    currency: "USD",

                },

                account: this.state.location.connect_id
            })
        });

        return await response.json();
        
    }
    
    hasAnySearch = () => {
        let {date, allday, start_time, end_time, seats} = this.state.filters;
        return date || allday || start_time > -1 || end_time > -1 || seats > 0
    }
    
    hasResults = async () => {
        console.log("Updating Search")
        if (!this.props.match.params.location) {
            return false; 
        }
        
        let {date, allday, start_time, end_time, seats} = this.state.filters;
        if (allday) {

            if (isToday(date) && (new Date()).getHours() >= 18) {
                return false;
            }
            
            
            let pass = await this.lokations.getPass(date, this.props.match.params.location);
            console.log("got pass", pass);
            if (pass) {
                return true;
            }

        } else {

            start_time *= 1;
            end_time *= 1;
            seats *= 1;
            let table = await this.lokations.getTable(date, start_time, end_time, seats, this.props.match.params.location);
            console.log("got table", table)
            if (table) {
                return true;
            }

        }
        
        return false;
    }
    
    book = async (authUser, token, promo, purpose, callback) => {
        // do the booking.
        
        console.log("Making sure reservation data exists.");
        
        const fee = (token && !promo) ? this.state.amount : 0;
        const tzOffset = authUser.tzOffset ? authUser.tzOffset : 0;

        let {date, passes, allday, start_time, end_time, seats} = this.state.filters;
        passes = 1 * passes;
        if (allday) {

            let pass = await this.lokations.getPass(date, this.state.location.uid);
            if (pass) {
                this.props.firebase.bookings()
                    .add({
                        location: this.state.location.uid,
                        date: date,
                        seats: passes,
                        table: pass.uid,
                        user: authUser.uid,
                        fee: fee,
                        tz_offset: tzOffset,
                        purpose: purpose,
                        promo: promo,
                        is_table: false,
                        status: 'pending',
                    }).then(docRef => {
                        
                        this.props.firebase.analytics.logEvent("lokation_booking", {lokation: this.state.location.name, date: date, day_pass: true, purpose: purpose, promo: promo});
                        
                        let id = docRef.id;
                        this.addCharge(token, promo, id, authUser, callback)

                })
            }

        } else {

            console.log("Table", date, start_time, end_time, seats, this.state.location.uid);
            start_time *= 1;
            end_time *= 1;
            seats *= 1;
            let table = await this.lokations.getTable(date, start_time, end_time, seats, this.state.location.uid);
            if (table) {
                this.props.firebase.bookings()
                    .add({
                        location: this.state.location.uid,
                        date: date,
                        start_time: start_time,
                        end_time: end_time,
                        seats: seats,
                        table: table.uid,
                        user: authUser.uid,
                        fee: fee,
                        promo: promo,
                        tz_offset: tzOffset,
                        purpose: purpose,
                        is_table: true,
                        status: 'pending',
                    }).then(docRef => {

                    this.props.firebase.analytics.logEvent("lokation_booking", {lokation: this.state.location.name, date: date, day_pass: false, purpose: purpose, promo: promo});

                    this.addCharge(token, promo, docRef.id, authUser, callback)


                })
            } else {
                console.log("Table not found");
            }

        }
    }

    activeBooking = () => {
        // const { bookings } = this.props;
        //         // if (bookings) {
        //         //     let active = bookings.filter( booking => {
        //         //         return booking.location === this.props.match.params.location && booking.status === 'booked';
        //         //     }).filter(booking => {
        //         //         return isToday(booking.date)
        //         //     })
        //         //     if (active && active.length > 0) {
        //         //         return active[0];
        //         //     }
        //         //
        //         // }
        return null;
    }

    activeBookingIsToday = () => {
        // let booking = this.activeBooking()
        // if (booking) {
        //     if (booking.date === dateToString(new Date())) {
        //         if (booking.is_table) {
        //             let time = (new Date().getMinutes()) + (new Date().getHours() * 60);
        //             return booking.start_time >= time - 30; // I can start checking in 30 minutes earlier.
        //         } else {
        //             return true;
        //         }
        //     }
        // }
        return false;
    }

    
    siteName = (url) => {
        if (url === null) {
            return null;
        }
        let a = document.createElement("a");
        a.href = url;
        return a.hostname;
    }

    favToggle = (location, user) => {
        this.props.firebase.favToggle(location, user).then(e => {
            //
        })
    }

    render() {
        const { loading, location, filters, paymentSuccess, notificationURL, notificationData } = this.state;

        return (
            <AuthUserContext.Consumer>

                {authUser => (
                    <StripeProvider apiKey="pk_test_NsC2FKVA7ewKjsWlA7ocV84A00BYrCv5TZ">

                        
                        
                        {
                            (loading || location === null)  ? <div><Loader /></div> :

                                <>
                                    <Filters location={location} onChange={this.onFilterChange} />
                                <Background color={themeStyles.bc1}>

                                    
                                    
                                    {paymentSuccess &&
                                    <Notification
                                        url={notificationURL}
                                        notificationData={notificationData}
                                        authUser={authUser}
                                        thanks
                                    />
                                    }
                                    <LokationsHero
                                        // Hero padding
                                        padTop

                                        // Back button
                                        hasBackButton

                                        location={location}

                                        hasFavorite
                                        isFavorite={this.props.firebase.isFavorite(location.uid, authUser)}
                                        favToggle={this.favToggle}
                                        
                                        // Images
                                        images={location.images}

                                        // Details
                                        locationSet={false}
                                        locationTitle={location.name}
                                        hasAddress
                                        addressLine1={location.address && location.address.neighborhood}
                                        addressLine2={location.address && location.address.name}
                                        
                                        
                                        scheduleDate={filters.date}
                                        schedulePass={filters.allday}
                                        scheduleStart={filters.start_time}
                                        scheduleEnd={filters.end_time}
                                        scheduleSeats={filters.seats}


                                        activeBooking={this.activeBooking()}
                                        
                                        hasBooking={this.state.hasAnySearch}
                                        hasNoResults={!this.state.hasBooking}
                                        hasCheckin={this.activeBookingIsToday()}
                                        
                                        // Perks
                                        hasPerks
                                        perks={location.perks}

                                        // Book Now
                                        authUser={authUser}
                                        totalCost={this.state.amount}
                                        handleCharge={(token, promo, purpose, callback) => this.book(authUser, token, promo, purpose, callback)}

                                        // About - should only get props if no schedule info exists
                                        hasAbout={this.activeBooking() === null && !this.state.isBooking}
                                        aboutTitle="About this Lokation"
                                        aboutContent={location.about}
                                        aboutLinks={location.links}
                                        // aboutHasLink
                                        // aboutLinkTitle={this.siteName(location.website)}
                                        // aboutLinkUrl={location.website}
                                    />

                                    {/* If the schedule filters have been assigned, then the about content is outside of the hero */}
                                    {filters.date && filters.start_time &&
                                    <LokationAbout
                                        aboutTitle="About this Lokation"
                                        aboutContent={location.about}
                                        aboutLinks={location.links}
                                        // aboutHasLink
                                        // aboutLinkTitle={this.siteName(location.website)}
                                        // aboutLinkUrl={location.website}
                                    />
                                    }

                                    <LokationInformation
                                        // Map
                                        address={location.address}
                                        mapData={[
                                            {
                                                lat: "48.00",
                                                lng: "-122.00"
                                            }
                                        ]}
                                        mapContent={
                                            location.address.info
                                            }

                                        // Amenities
                                        amenities={location.amenities}

                                        // Pricing
                                        pricingOptions={[
                                            {
                                                text: "1 Seat (Day Pass) @ Shared Table",
                                                price: "$20 per Day"
                                            },
                                            {
                                                text: "2 Seats @ Reserved Table",
                                                price: "$10 per Hour"
                                            },
                                            {
                                                text: "4 Seats @ Reserved Table",
                                                price: "$20 per Hour"
                                            },
                                            {
                                                text: "6 Seats @ Reserved Table",
                                                price: "$30 per Hour"
                                            }
                                        ]}
                                        pricingContent={[
                                            {
                                                icon: "table",
                                                title: "Shared Table (Day Pass)",
                                                content: "This is an individual seat at a shared productivity table for those that book a day pass. This seat is best for people  wanting to work quietly and independently during your booking. You will be assigned a table number at check-in and can choose a seat at that table alongside others. It is not intended for those looking to work in groups and/or join meetings (virtually or by phone)."
                                            },
                                            {
                                                icon: "chair",
                                                title: "Reserved Table",
                                                content: "This is a private productivity table. This table is best for people wanting to work in groups and/or join meetings (virtually or by phone) during your booking. You will be assigned a table number at check-in and only those invited as part of your booking will be seated at your table. Please note this table will be located in a shared space (and not a private room)."
                                            },
                                        ]}

                                        // Booking
                                        hasBookingButtons
                                    />

                                    
                                </Background>
                                </>  
                        }
                        
                    </StripeProvider>
                )}
            </AuthUserContext.Consumer>
        );
    }
}

export default compose(
    withFirebase,
    withBookings
)(Location);
