// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket, Amenity, Iconwrap, Text } from './amenitiesStyles';
import {withFirebase} from "../../../../lib/Firebase";

// Component
// ------
class SidebarAmenities extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            table: null
        }
    }
    
    componentDidMount() {
        this.props.firebase.table(this.props.booking.table).get().then(e => {
            if (e.exists) {
                this.setState({table: e.data()})
            }
        })
    }


    render() {
        
        const {table} = this.state;
        
        return (
            <Jacket>
                <Row isExpanded isCollapsed>
                    <Column small={12}>
                        
                        { table && <>
                            { table.workspace && <Amenity>
                                <Row isExpanded>
                                    <Column small={2} large={1}>
                                        <Iconwrap><Icon type="location" /></Iconwrap>
                                    </Column>
                                    <Column small={10} large={5}>
                                        <Text>Drop-in Workspace</Text>
                                    </Column>
                                    <Column small={12} large={5} pushOnSmall={2} pushOnLarge={1}>
                                        <Div text="rightMedium">
                                            <Text>{ table.workspace }</Text>
                                        </Div>
                                    </Column>
                                </Row>
                            </Amenity>}

                            <Amenity>
                                <Row isExpanded>
                                    <Column small={2} large={1}>
                                        <Iconwrap><Icon type="table" /></Iconwrap>
                                    </Column>
                                    <Column small={10} large={5}>
                                        <Text>Table Assignment</Text>
                                    </Column>
                                    <Column small={12} large={5} pushOnSmall={2} pushOnLarge={1}>
                                        <Div text="rightMedium">
                                            <Text>{ table.name }</Text>
                                        </Div>
                                    </Column>
                                </Row>
                            </Amenity>
                        </>}
                        
                        {this.props.amenities.map((block, index) =>
                            <Amenity key={index}>
                                <Row isExpanded>
                                    <Column small={2} large={1}>
                                        <Iconwrap><Icon type={block.icon} /></Iconwrap>
                                    </Column>
                                    <Column small={10} large={5}>
                                        <Text>{block.title}</Text>
                                    </Column>
                                    <Column small={12} large={5} pushOnSmall={2} pushOnLarge={1}>
                                        <Div text="rightMedium">
                                            <Text>{block.text}</Text>
                                        </Div>
                                    </Column>
                                </Row>
                            </Amenity>
                        )}
                    </Column>
                </Row>
            </Jacket>
        )
    }
}

export default withFirebase(SidebarAmenities)