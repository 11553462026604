// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import AvatarUpload from '../avatar/avatar';
import Field from '../field/field';
import Button from 'components/global/buttons/primary';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';
import { observer } from 'mobx-react';

// Styles
// ------
import {Jacket, Header, Close, Title, UploadAvatar, Personal, ButtonJacket} from './editProfileStyles';

// States
// ------
import { userState } from 'states/user';

// Utils
// ------
import Body from 'utils/bodylock';
import {withFirebase} from "../../../../lib/Firebase";

// Component
// ------
class EditProfile extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            authUser: {
                uid: props.authUser.uid,
                firstName: props.authUser.firstName ? props.authUser.firstName : "",
                lastName: props.authUser.lastName ? props.authUser.lastName : "",
                companyName: props.authUser.companyName ? props.authUser.companyName : "",
                email: props.authUser.email,
                phoneNumber: props.authUser.phoneNumber ? props.authUser.phoneNumber : "",
                jobTitle: props.authUser.jobTitle ? props.authUser.jobTitle : "",
                dob: props.authUser.dob ? props.authUser.dob : "",
                remote: props.authUser.remote ? props.authUser.remote : false,
                selfEmployed: props.authUser.selfEmployed ? props.authUser.selfEmployed : false,
            }
        }
        
        
    }
    
    toggleEdit = () => {
        userState.editProfileOpen = !userState.editProfileOpen;
    }

    stateCheck = () => {
        if (userState.editProfileOpen) {
            Body.lock();
        }

        if (!userState.editProfileOpen) {
            Body.unlock();
        }
    }

    handleSave = () => {
        
        const data = this.state.authUser;
        data.fullName = `${data.firstName} ${data.lastName}`;
        
        if (["firstName", "lastName", "jobTitle", "companyName", "selfEmployed", "remote"].reduce((acc, field) => acc && data[field], true)) {
            data.profileComplete = true;
        }
        
        
        this.props.firebase.user(this.props.authUser.uid).set(data, {merge: true}).then(e => {
            userState.editProfileOpen = false;
        })

        this.props.firebase.analytics.setUserProperties({
            "self_employed" : data.selfEmployed,
            "remote_worker" : data.remote,
            "job_title" : data.jobTitle
        })

        
    }

    onChange = (e) => {
        let authUser = this.state.authUser;
        authUser[e.target.name] = e.target.value;
        this.setState(authUser)
    }
    
    render() {
        const {authUser} = this.state;
        return (
            <Jacket isActive={this.props.isActive}>
                {/* Edit profile header */}
                <Header>
                    <Row isExpanded>
                        <Column small={12} medium={10} pushOnMedium={1}>
                            <Row isExpanded>
                                <Column small={12}>
                                    <Div relative text="center">
                                        <Close onClick={this.toggleEdit}>
                                            <Icon type="small-arrow-left" />
                                        </Close>
                                        <Title>Edit Profile</Title>
                                    </Div>
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                </Header>

                {/* Upload a new avatar */}
                <UploadAvatar>
                    <Row isExpanded>
                        <Column small={12} medium={10} pushOnMedium={1}>
                            <Row isExpanded>
                                <Column small={12}>
                                    <AvatarUpload authUser={this.props.authUser} />
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                    

                    
                    
                </UploadAvatar>

               
                
                {/* Edit your personal details */}
                <Personal>
                    <Field
                        label="First Name"
                        type="text"
                        placeholder="..."
                        value={authUser.firstName}
                        name="firstName"
                        onChange={this.onChange}
                    />
                    <Field
                        label="Last Name"
                        type="text"
                        placeholder="..."
                        value={authUser.lastName}
                        name="lastName"
                        onChange={this.onChange}
                    />
                    <Field
                        label="Phone Number"
                        type="phone"
                        placeholder="+44..."
                        value={authUser.phoneNumber}
                        name="phoneNumber"
                        onChange={this.onChange}
                    />
                    <Field
                        label="Email"
                        type="email"
                        disabled
                        value={authUser.email}
                    />
                    <Field
                        label="Date of Birth"
                        type="text"
                        id="date"
                        placeholder="MM / DD / YYYY"
                        value={authUser.dob}
                        name="dob"
                        onChange={this.onChange}
                    />
                    <Field
                        label="Job Title"
                        type="text"
                        placeholder="Job Title"
                        value={authUser.jobTitle}
                        name="jobTitle"
                        onChange={this.onChange}
                    />
                    <Field
                        label="Company Name"
                        type="text"
                        placeholder="Company"
                        value={authUser.companyName}
                        name="companyName"
                        onChange={this.onChange}
                    />

                    <Field
                        label="Are you self-employed?"
                        type="checkbox"
                        placeholder=""
                        value={authUser.selfEmployed}
                        name="selfEmployed"
                        onChange={this.onChange}
                    />

                    <Field
                        label="Do you primarily work remotely?"
                        type="checkbox"
                        placeholder=""
                        value={authUser.remote}
                        name="remote"
                        onChange={this.onChange}
                    />
                    
                    {/*<Field*/}
                    {/*    label="Industry"*/}
                    {/*    type="text"*/}
                    {/*    placeholder="Finance..."*/}
                    {/*/>*/}

                </Personal>

                <ButtonJacket>
                    <Row isExpanded>
                        <Column small={12} medium={10} pushOnMedium={1}>
                            <Row isExpanded>
                                <Column small={12} medium={4} large={3}>
                                    <Button button type="full" text="Save Details" onClick={this.handleSave} />
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                </ButtonJacket>
            </Jacket>
        )
    }

    componentDidUpdate() {
        this.stateCheck();
    }

    componentWillUnmount() {
        this.stateCheck();
    }
}

export default withFirebase(observer(EditProfile));