// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import { Row, Column } from 'components/global/theme/grid/grid';
import { Div } from 'components/global/theme/reusables/reusables';
import { Link } from 'react-router-dom';
import * as ROLES from '../../../constants/roles';
// Files
// ------
import DefaultBrand from '../../../images/Logo.svg';
import LightBrand from '../../../images/Logo-Light.svg';

// Styles
// ------
import {Jacket, LogoWrapper, Logo, Menu, MenuItem, Return, Span} from './headerStyles';
import {AuthUserContext} from "../../../lib/Session";
import SignOut from "../../AuthButtons/SignOut";

// Component
// ------
export default class Header extends React.Component {
    render() {
        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    <Jacket showOnLarge={this.props.showOnLarge}>
                        <Row isExpanded isCollapsed>
                            <Column small={12} large={10} pushOnLarge={1}>
                                <Row isExpanded>
                                    <Column small={12} large={3}>
                                        <LogoWrapper>
                                            <Link to="/lokations">
                                                {this.props.lightLogo ?
                                                    <Logo src={LightBrand} />
                                                    :
                                                    <Logo src={DefaultBrand} />
                                                }
                                            </Link>
                                        </LogoWrapper>
                                    </Column>
                                    <Column showOnLarge large={9}>
                                        {authUser ?
                                            <Div text="right">
                                                <Menu>
                                                    <MenuItem>
                                                        <Link to="/profile">
                                                            <Icon type="user"/>
                                                            <Span>Profile</Span>
                                                        </Link>
                                                    </MenuItem>
                                                    <MenuItem>
                                                        <Link to="/lokations">
                                                            <Icon type="location"/>
                                                            <Span>Lokations</Span>
                                                        </Link>
                                                    </MenuItem>
                                                    <MenuItem>
                                                        <Link to="/bookings">
                                                            <Icon type="calendar"/>
                                                            <Span>Bookings</Span>
                                                        </Link>
                                                    </MenuItem>

                                                    {
                                                        authUser && authUser.roles && authUser.roles[ROLES.ADMIN] && <MenuItem>
                                                            <Link to="/admin/locations">
                                                                <Icon type="calendar"/>
                                                                <Span>Admin</Span>
                                                            </Link>
                                                        </MenuItem>
                                                    }

                                                    {
                                                        authUser && authUser.roles && authUser.roles[ROLES.MANAGER] && <MenuItem>
                                                            <Link to="/admin/promocodes">
                                                                <Icon type="calendar"/>
                                                                <Span>PromoCodes</Span>
                                                            </Link>
                                                        </MenuItem>
                                                    }
                                                    
                                                    <MenuItem>
                                                        <SignOut />
                                                    </MenuItem>
                                                </Menu>
                                            </Div>
                                            :
                                            <Div text="right">
                                                {this.props.goHome &&
                                                <Menu>
                                                    <Return>
                                                        <a href="//www.booklokus.com/">
                                                            <Icon type="small-arrow-left" />
                                                            <Span>Return Home</Span>
                                                        </a>
                                                    </Return>
                                                </Menu>
                                                }
                                                {this.props.goBack &&
                                                <Menu>
                                                    <Return>
                                                        <Link to="/">
                                                            <Icon type="small-arrow-left" />
                                                            <Span>Go back</Span>
                                                        </Link>
                                                    </Return>
                                                </Menu>
                                                }
                                                
                                            </Div>
                                        }
                                    </Column>
                                </Row>
                            </Column>
                        </Row>
                    </Jacket>
                }
            </AuthUserContext.Consumer>
        )
    }
}