// Imports
// ------
import styled, { css } from "styled-components";
import { breakup } from 'components/global/theme/breakpoints';
import { Section, Div, H2, H4, Span } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    z-index: 2;
    display: block;

    background: ${props => props.theme.bc2};

    ${breakup.large`margin-top: 1rem;`}
`;

export const Title = styled(H2)`
    position: absolute;
    z-index: 10;
    left: 2rem;
    bottom: 16rem;
    max-width: 90%;

    color: ${props => props.theme.bc3};

    ${breakup.large`
        bottom: auto;
        top: 2rem;
    `}
`;

export const SubTitle = styled(H4)`
    position: absolute;
    z-index: 10;
    left: 2rem;
    bottom: 14rem;
    max-width: 90%;

    color: ${props => props.theme.bc3};

    ${breakup.large`
        bottom: auto;
        top: 7rem;
    `}
`;

export const Slider = styled(Div)`
    position: relative;
`;

export const Slide = styled(Div)`
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    min-height: 30rem;

    ${breakup.medium`min-height: 40rem;`}
    ${breakup.large`min-height: 56rem;`}

    img {
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
    }
`;

export const SliderControls = styled(Div)`
    position: absolute;
    z-index: 10;
    bottom: 0; left: 0;

    display: block;
    width: 100%;
    padding: 2rem 2rem 8rem;

    ${breakup.large`padding: 2rem;`}

    &:before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0; left: 0; right: 0; bottom: 0;

        background: ${props => props.theme.bc1};
        opacity: 0.45;
    }
`;

export const Paging = styled(Div)`
    position: absolute;
    z-index: 5;
    top: 40%; left: 50%;
    transform: translate(-50%, -40%);

    display: block;
    width: 40%;

    text-align: center;

    ${breakup.large`
        position: relative;
        transform: none;
        top: auto;
        left: auto;

        margin: 0 auto;
    `}
`;

export const CurrentSlide = styled(Span)`
    display: inline-block;

    &:after {
        content: '/';
        margin: 0 5px;
    }
`;

export const SlideCount = styled(Span)`
    display: inline-block;
`;

export const Button = styled.button`
    position: absolute;
    z-index: 5;
    top: 40%;
    transform: translateY(-40%);
    cursor: pointer;
    background: transparent;
    padding: 1rem;

    ${breakup.large`
        top: 50%;
        transform: translateY(-50%);
    `}

    ${props => props.prev && css`
        left: 2rem;
    `}

    ${props => props.next && css`
        left: auto; right: 2rem;
    `}
`;