

export const minutesToTime = (minutes) => {
    if (minutes === -1 || !minutes) {
        return "";
    }
    let hours = Math.floor(minutes / 60);
    let modifier = "am";
    if (hours === 12) {
        modifier = "pm";
    }
    if (hours > 12) {
        hours -= 12;
        modifier = "pm";
    }
    minutes = minutes % 60;

    hours = `${hours}`;
    minutes = `${minutes}`;

    return `${hours}:${minutes.padStart(2, '0')}${modifier}`;
};

export const timeToMinutes = (time) => {
    let comps = time.split(":");
    if (comps.length !== 2) {
        return null;
    }

    let hours = 1 * comps[0];
    let minutes = 1 * comps[1];

    if (isNaN(minutes)) {
        minutes = 1 * comps[1].substr(0, comps[1].length - 2);
        let mod = comps[1].substr(comps[1].length - 2);
        if (mod === 'pm') {
            hours += 12;
        }
    }

    if (isNaN(hours) || isNaN(minutes)) {
        return null;
    }

    return (hours * 60) + minutes;


}

export const dateToString = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
        month = `0${month}`;
    }
    if (day < 10) {
        day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
}

export const stringToDate = (str) => {
    let components = str.split("-");
    let year = 1 * components[0];
    let month = 1 * components[1];
    let day = 1 * components[2];
    let date = new Date();
    date.setFullYear(year);
    date.setDate(day);
    date.setMonth(month - 1);
    return date;
    
}

// eslint-disable-next-line no-unused-vars
export const dateAdjust = (date) => {
    let comps = date.split("-");
    let year = comps[0];
    let month = comps[1];
    let day = comps[2];
    return `${month.padStart(2, '0')}/${day.padStart(2, '0')}/${year}`;
}

export const humanDate = (date) => {
    const thedate = stringToDate(date);
    const today = new Date();
    if (thedate.getFullYear() === today.getFullYear() && thedate.getMonth() === today.getMonth()) {
        if (thedate.getDate() === today.getDate()) {
            return "Today";
        } else if (thedate.getDate() === today.getDate() + 1) {
            return "Tomorrow"
        }
    }
    
    let output = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'][thedate.getDay()];
    output += ' ';
    output += ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][thedate.getMonth()];
    output += ' ' + thedate.getDate();
    return output;
    
}


/**
 * Checks if a string date is today
 * @param date string
 */
export const isToday = (date) => {
    if (!date) {
        return false;
    }
    let components = date.split("-");
    let year = 1 * components[0];
    let month = 1 * components[1];
    let day = 1 * components[2];
    let today = new Date();

    return today.getFullYear() === year && (today.getMonth() + 1) === month && today.getDate() === day;

}

/**
 * Checks if a string date is tomorrow
 * @param date string
 */
export const isTomorrow = (date) => {
    let components = date.split("-");
    let year = 1 * components[0];
    let month = 1 * components[1];
    let day = 1 * components[2];
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    return tomorrow.getFullYear() === year && (tomorrow.getMonth() + 1) === month && tomorrow.getDate() === day;

}
