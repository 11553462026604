// Imports
// ------
import { css } from 'styled-components';
import { themeStyles } from './theme';

const sizes = {
    small: themeStyles.small,
    smedium: themeStyles.smedium,
    medium: themeStyles.medium,
    large: themeStyles.large,
    xlarge: themeStyles.xlarge,
    xxlarge: themeStyles.xxlarge,
    huge: themeStyles.huge,
    uber: themeStyles.uber
}

// Exports
// ------
export const breakup = Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media (min-width: ${sizes[label]}) {
            ${css(...args)}
        }
    `

    return acc
}, {})

export const breakdown = Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media (max-width: ${sizes[label]}) {
            ${css(...args)}
        }
    `

    return acc
}, {})


