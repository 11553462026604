// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import Button from 'components/global/buttons/primary';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';
import { observer } from 'mobx-react';

// Styles
// ------
import { Jacket, Form, Label, TimeButton, Time, TimeSelector, TimeListInner, TimeList, TimeItem } from './reservationextenderStyles';

// Component
// ------
class SidebarReservationExtender extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            timeIsOpen: false,
            timeSelected: "12:00pm",
        }
    }
    
    handleExtension = () => {
        alert("Extending booking")
    }

    handleEndBooking = () => {
        alert("Ending your booking now")
    }

    handleTime = () => {
        this.setState({timeIsOpen: !this.state.timeIsOpen})
    }

    handleNewtime = (e) => {
        this.setState({timeSelected: e.target.getAttribute('value')})
    }

    render() {
        const timeOpenChecker = this.state.timeIsOpen;

        return (
            <Jacket>
                <Row isExpanded isCollapsed>
                    <Column small={12}>
                        <Form>
                            <Row isExpanded>
                                <Column small={6}>
                                    <Label>Extend End Time</Label>
                                </Column>
                                <Column small={6}>
                                    {/* Slot after the current end time */}
                                    <TimeButton onClick={this.handleTime} isOpen={timeOpenChecker}>
                                        <Time>{this.state.timeSelected}</Time>
                                        <Icon type="small-arrow-down" />
                                    </TimeButton>
                                </Column>
                            </Row>
                            <TimeSelector isOpen={timeOpenChecker}>
                                <Row isExpanded>
                                    <Column small={12}>
                                        <TimeList>
                                            <TimeItem onClick={(e) => this.handleNewtime(e)} value="12:00pm" selected={this.state.timeSelected === "12:00pm"}>12:00pm</TimeItem>
                                            <TimeItem onClick={(e) => this.handleNewtime(e)} value="13:00pm" selected={this.state.timeSelected === "13:00pm"}>13:00pm</TimeItem>
                                            <TimeItem onClick={(e) => this.handleNewtime(e)} value="14:00pm" selected={this.state.timeSelected === "14:00pm"}>14:00pm</TimeItem>
                                            <TimeItem onClick={(e) => this.handleNewtime(e)} value="15:00pm" selected={this.state.timeSelected === "15:00pm"}>15:00pm</TimeItem>
                                            <TimeItem onClick={(e) => this.handleNewtime(e)} value="16:00pm" selected={this.state.timeSelected === "16:00pm"}>16:00pm</TimeItem>
                                            <TimeItem onClick={(e) => this.handleNewtime(e)} value="17:00pm" selected={this.state.timeSelected === "17:00pm"}>17:00pm</TimeItem>
                                        </TimeList>
                                    </Column>
                                </Row>
                                <TimeListInner />
                            </TimeSelector>
                        </Form>
                    </Column>
                </Row>
                <Row isExpanded>
                    <Column small={12}>
                        <Button button type="full" text="Extend Booking" onClick={this.handleExtension} />
                        <Div text="center">
                            <Button navlink type="text" text="Thanks for the Reminder" href="/" />
                        </Div>
                    </Column>
                </Row>
            </Jacket>
        )
    }
}

export default observer(SidebarReservationExtender)