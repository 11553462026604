// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import Button from 'components/global/buttons/primary';
import { observer } from 'mobx-react';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';

// States
// ------
import { notificationState } from 'states/notifications';

// Styles
// ------
import { Jacket, Inner, Header, Title, Close, Content, Text } from './notificationStyles';

// Component
// ------
class GenericNotification extends React.Component {
    handleClose = () => {
        notificationState.generalButtons = [];
        notificationState.generalText = "";
        notificationState.generalTitle = "";
        notificationState.generalTinyText = "";
        notificationState.generalOpen = false;
        if (notificationState.generalOnClose) {
            notificationState.generalOnClose();
            notificationState.generalOnClose = null;
        }
    }

    handleClick = (button) => {
        if (button.onClick) {
            button.onClick();
        } else {
            this.handleClose();
        }

        notificationState.generalOnClose = null;
    }


    render() {
        const generalOpen = notificationState.generalOpen;
        
        return (
            <Jacket isOpen={generalOpen}>
                <Inner>
                    <Header>
                        <Row isExpanded>
                            <Column small={9}>
                                <Title>{notificationState.generalTitle}</Title>
                            </Column>
                            <Column small={3}>
                                <Div text="right">
                                    <Close onClick={this.handleClose}>
                                        <Icon type="close" />
                                    </Close>
                                </Div>
                            </Column>
                        </Row>
                    </Header>
                    <Content>
                        <Row isExpanded>
                            <Column small={12}>
                                <Text>{notificationState.generalText}</Text>
                            </Column>

                            {notificationState.generalTinyText !== "" && <Column small={12}>
                                <Text tiny>{notificationState.generalTinyText}</Text>
                            </Column>}
                            
                            <Column small={12}>
                                <Div text="center">
                                    {
                                        notificationState.generalButtons.map((button, index) => <Button key={index} onClick={e => this.handleClick(button)} button fullWidth type="ghost" href={button.url} textColor="dark" text={button.title} />)
                                    }
                                </Div>
                            </Column>
                        </Row>
                    </Content>
                </Inner>
            </Jacket>
        
        )
    }
}

export default observer(GenericNotification);