// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import Input from 'components/global/formfields/input';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket, Avatar, UploadWrapper, Upload } from './avatarStyles';
import {withFirebase} from "../../../../lib/Firebase";

// Component
// ------
class AvatarUpload extends React.Component {

    upload = (e) => {
        let selectedFile = e.target.files[0];
        const storageRef = this.props.firebase.storage.ref();
        let filename = selectedFile.name;
        filename = this.props.authUser.uid + '-' + Math.random() + '-' + filename;
        const uploadTask = storageRef.child(`profiles/${filename}`).put(selectedFile);
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            console.log("change", snapshot);
        }, (error) => {
            // Handle unsuccessful uploads
            console.log(">>", error);
        }, () => {
            // Do something once upload is complete
            console.log("Complete");
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                console.log("url", downloadURL, this.props.authUser.uid);
                this.props.firebase.user(this.props.authUser.uid).set({profileImage: downloadURL}, {merge: true});
            });

        });

    }

    render() {
        return (
          <Jacket>
              <Row isExpanded isCollapsed isCenter>
                  <Column>
                      <UploadWrapper>
                          <Upload>
                              <Avatar>
                                  {this.props.authUser.profileImage ?
                                    <>
                                        <img  src={this.props.authUser.profileImage} alt={this.props.authUser.fullName} />
                                        <Icon type="photo" />
                                    </>
                                    :
                                    <Icon type="photo" />
                                  }
                              </Avatar>
                          </Upload>
                          <Input type="file" name="avatarfile" onChange={this.upload} />
                      </UploadWrapper>

                  </Column>
              </Row>
          </Jacket>
        )
    }
}

export default withFirebase(AvatarUpload);