// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Iconwrap, Option, Text, Radio, Radioselected, Radioblank } from './checkoutformStyles';

// Component
// ------
export default class CheckoutOption extends React.Component {
    
    render() {
        return (
            <Option onClick={this.props.onClick} disabled={this.props.disabled}>
                <Row isExpanded>
                    <Column small={2} medium={1} large={1}>
                        <Iconwrap>
                            <Icon type={this.props.icon} />
                        </Iconwrap>
                    </Column>
                    <Column small={8} medium={9} large={8}>
                        <Text>{this.props.text}</Text>
                    </Column>
                    <Column small={2} medium={2} large={1} pushOnXlarge={1}>
                        <Div text="right">
                            <Radio>
                                {this.props.isSelected ?
                                    <Radioselected>
                                        <Icon type="checked" />
                                    </Radioselected>
                                :
                                    <Radioblank />
                                }
                            </Radio>
                        </Div>
                    </Column>
                </Row>
            </Option>
        )
    }
}