// Imports
// ------
import styled from "styled-components";
import { Section, Div, H4, Span, GradientIcon } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    display: block;
`;

export const Amenity = styled(Div)`
    position: relative;
    margin-bottom: 2rem;
`;

export const Iconwrap = styled(Div)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;

    svg {
        ${GradientIcon};
        width: 3rem;
        height: 3rem;
    }
`;

export const Title = styled(H4)`
    color: ${props => props.theme.bc3};
`;

export const Text = styled(Span)`
    display: block;

    font-size: 1.4rem !important;
`;