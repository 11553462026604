// Imports
// ------
import styled from 'styled-components';
import { breakup } from 'components/global/theme/breakpoints';
import { Div, Gradient, H1, P } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Div)`
    position: relative;
    display: block;
    height: 100%;
    
    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0; right: 0; bottom: 0; left: 0;
        height: 100%;
        min-height: 100vh;

        background: ${Gradient};
    }
`;

export const Title = styled(H1)`
    position: relative;
    display: block;
    margin-top: 10rem;

    color: ${props => props.theme.bc3};
`;

export const Inputwrap = styled(Div)`
    margin-bottom: 2rem;
`;

export const LoginForm = styled(Div)`
    position: relative;
    display: block;

    margin-top: 10rem;

    input {
        display: block;
        width: 100%;
        padding: 1.5rem 0;
        margin: 0;
    }
`;

export const Submission = styled(Div)`
    position: relative;
    display: block;
    margin-top: 4rem;
`;

export const Signin = styled.button`
    display: block;
    width: 100%;
    margin-bottom: 2rem;
    cursor: pointer;

    background: transparent;
    border: 1px solid ${props => props.theme.bc3};
    padding: 1.2rem 3rem;
    text-align: center;

    font-size: 1.6rem;
    color: ${props => props.theme.bc3};

    ${breakup.medium`
        font-size: 1.8rem;

        display: inline-block;
        width: auto;
    `}

    ${breakup.large`
        padding: 1.2rem 4rem;
        font-size: 2rem;
    `}
`;

export const Join = styled(P)`
    font-size: 1.4rem !important;

    a {
        font-size: 1.4rem !important;
        text-decoration: underline;
    }
`;