// Imports
// ------
import React from 'react';
import MobileFooter from './mobileFooter/mobileFooter';
import DesktopFooter from './desktopFooter/desktopFooter';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket } from './footerStyles';
import {compose} from "recompose";
import {withRouter} from 'react-router-dom';
import {observer} from "mobx-react";

// Component
// ------
class Footer extends React.Component {
    
    showsFooter = () => {
        
        const {pathname} = this.props.location;
        return pathname !== '/login' &&  pathname !== '/' && pathname !== '/register' && pathname !== '/forgot'; 
        
    }
    
    render() {
        return this.showsFooter() ?
                    <Jacket>
                        <Row isExpanded isCollapsed>
                            <Column hideOnLarge small={12}>
                                <MobileFooter/>
                            </Column>
                            <Column showOnLarge small={12}>
                                <DesktopFooter/>
                            </Column>
                        </Row>
                    </Jacket>
            : '';
          
    }
}


export default compose(
    withRouter,
    observer
)(Footer);
