// Imports
// ------
import { css } from "styled-components";
import { breakup } from '../breakpoints';

// Exports
// --------------
// 0. Shared Prop Styles
// --------------
const sharedPropStyles = css`
    ${props => [
        props.hasGradient && css`
            background: -webkit-linear-gradient(20deg, rgba(252,0,132,1) 0%, rgba(254,73,2,1) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        `
    ]}
`;

// 1. Shared Heading Styles
// --------------
const sharedHeadingStyles = css`
    ${sharedPropStyles}
    font-weight: ${props => props.theme.reg};
    font-family: ${props => props.theme.heading};
`;

// --------------
// 2. Heading Styles
// --------------
export const h1Styles = css`
    ${sharedHeadingStyles}
    font-size: 3.2rem;
    line-height: 4.8rem;

    ${breakup.large`
        font-size: 6rem;
        line-height: 7rem;
    `}
`;

export const h2Styles = css`
    ${sharedHeadingStyles}
    font-size: 2.6rem;
    line-height: 4rem;

    ${breakup.large`
        font-size: 4.2rem;
        line-height: 5.2rem;
    `}
`;

export const h3Styles = css`
    ${sharedHeadingStyles}
    font-size: 2.6rem;
    line-height: 3.8rem;

    ${breakup.large`
        font-size: 3rem;
        line-height: 4rem;
    `}
`;

export const h4Styles = css`
    ${sharedHeadingStyles}
    font-size: 1.8rem;
    line-height: 2.2rem;

    ${breakup.large`
        font-size: 2rem;
        line-height: 2.4rem;
    `}
`;

export const h5Styles = css`
    ${sharedHeadingStyles}
    font-size: 2rem;
    line-height: 3.2rem;

    ${breakup.large`
        font-size: 2.4rem;
        line-height: 3.6rem;
    `}
`;

// --------------
// 3. Shared Body Styles
// --------------
const sharedBodyStyles = css`
    ${sharedPropStyles}
    font-family: ${props => props.theme.body};
    font-weight: ${props => props.theme.reg};
`;

// --------------
// 3. Body Styles
// --------------
export const pStyles = css`
    ${sharedBodyStyles}
    font-size: 1.6rem;
    line-height: 2.4rem;

    ${breakup.large`
        font-size: 1.8rem;
        line-height: 3rem;
    `}
`;

export const spanStyles = css`
    ${sharedBodyStyles}
    font-size: 1.6rem;
    line-height: 2.6rem;

    ${breakup.large`
        font-size: 1.8rem;
        line-height: 3.2rem;
    `}
`;

export const emStyles = css`
    ${sharedBodyStyles}
    display: block;
    font-style: normal;
    font-size: 1.2rem;
    line-height: 1.6rem;

    ${breakup.large`
        font-weight: ${props => props.theme.med};
        font-size: 1.4rem;
        line-height: 1.8rem;
    `}
`;

export const anchorStyles = css`
    ${sharedBodyStyles}
`;