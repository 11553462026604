// Imports
// ------
import React from 'react';

// Styles
// ------
import { Jacket } from './backgroundStyles';

// Component
// ------
class Background extends React.Component {
    render() {
        return (
            <Jacket color={this.props.color}>
                {this.props.children}
            </Jacket>
        )
    }
}

export default Background;