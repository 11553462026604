// Imports
// ------
import styled, { css } from 'styled-components';
import { GradientIcon } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled.svg`
    
    ${css`
        ${GradientIcon}
    `}
    
`


export const G = styled.g`
    
    ${props => props.cls1 && css`
        fill:none;
    `}
    
    ${props => props.cls2 && css`
        clip-path:url(#clip-path);
    `}
    
    ${props => props.cls3 && css`
        ${GradientIcon}
    `}
    
    ${props => props.cls4 && css`
        clip-path:url(#clip-path-2);
    `}
    
    ${props => props.cls5 && css`
        opacity:0.15;
    `}
    
    ${props => props.cls6 && css`
        clip-path:url(#clip-path-3);
    `}
    
    ${props => props.cls7 && css`
        clip-path:url(#clip-path-4);
    `}
        
`;

export const Rect = styled.rect`
    
    ${props => props.cls1 && css`
        fill:none;
    `}
    
    ${props => props.cls2 && css`
        clip-path:url(#clip-path);
    `}
    
    ${props => props.cls3 && css`
        ${GradientIcon}
    `}
    
    ${props => props.cls4 && css`
        clip-path:url(#clip-path-2);
    `}
    
    ${props => props.cls5 && css`
        opacity:0.15;
    `}
    
    ${props => props.cls6 && css`
        clip-path:url(#clip-path-3);
    `}
        
    ${props => props.cls7 && css`
        clip-path:url(#clip-path-4);
    `}
`;