// Styles
// ------
import 'styles/app.scss';
import Global from 'components/global/theme/global';
import { ThemeProvider } from 'styled-components';

// Imports
// ------
import React from 'react';
import PropTypes from 'prop-types';
import { themeStyles } from 'components/global/theme/theme';
import Helmet from 'react-helmet';
import { BreakpointProvider, setDefaultBreakpoints } from 'react-socks';

// Breakpoints
// ------
setDefaultBreakpoints([
	{ small: 0 },
	{ smedium: 300 },
	{ medium: 768 },
	{ large: 1024 },
	{ xlarge: 1200 },
	{ xxlarge: 1440 },
  { huge: 1600 },
  { uber: 2000 }
]);

// Global
// ------
class Layout extends React.Component {
  render() {
    return (
      <>
        {/* Global Styles */}
        <Global />

        {/* SEO */}
        <Helmet
          title={`Lokus`}
          meta={[
            {
              name: 'description',
              content: 'Enter_your_description',
            },
            {
              name: 'keywords',
              content: 'Enter_Your_Keywords',
            },
          ]}
        >
        <html lang='en' />
        <meta charSet="utf-8" />
        </Helmet>

        <BreakpointProvider>
          <ThemeProvider theme={themeStyles}>
            {this.props.children}
          </ThemeProvider>
        </BreakpointProvider>
      </>
    )
  }
}

export default Layout;


Layout.defaultProps = {
	children: null,
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

