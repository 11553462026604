// Imports
// ------
import styled from "styled-components";
import { breakup } from 'components/global/theme/breakpoints';
import { Footer, Gradient } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Footer)`
    position: relative;
    z-index: 100;
    width: 100%;
    display: block;
    padding: 6rem 0;
    background: ${props => props.theme.bc2};
`;

export const Menu = styled.ul`
    position: relative;
    display: block;

    list-style: none;
    margin: 0;
    margin-bottom: 4rem;
`;

export const MenuItem = styled.li`
    position: relative;
    display: inline-block;

    width: 100%;
    max-width: 25rem;

    a {
        position: relative;
        display: inline-block;

        &:before {
            ${props => props.theme.animate('0.3s', 'ease-in-out')}
            content: '';
            position: absolute;
            bottom: 0;

            height: 2px;
            width: 0%;
            background: ${Gradient};
        }

        &:hover {
            &:before {
                width: 100%;
            }
        }
    }
`;

export const Logo = styled.img`
    position: relative;
    display: inline-block;

    width: 100%;
    max-width: 12rem;

    margin: 0 auto;

    ${breakup.large`margin: 0;`}
`;

export const Copy = styled.ul`
    position: relative;
    display: block;

    list-style: none;
    margin: 0;
    margin-top: 4rem;
`;

export const CopyItem = styled.li`
    position: relative;
    display: inline-block;

    margin-right: 3rem;

    font-size: 1.4rem;

    a {
        font-size: 1.4rem;

        &:hover {
            text-decoration: underline;
        }
    }
`;

