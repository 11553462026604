// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket, ContentJacket, Title, Content, Button, ButtonText } from './aboutStyles';

// Component
// ------
export default class LokationAbout extends React.Component {
    render() {
        return (
            <Jacket>
                <Div mpad>
                    <Row isExpanded>
                        <Column small={12} large={10} pushOnLarge={1}>
                            <ContentJacket>
                                <Title>{this.props.aboutTitle}</Title>
                                <Content dangerouslySetInnerHTML={{ __html: `<p>${this.props.aboutContent}</p>` }} />

                                {this.props.aboutLinks.map(link => <Button href={link.text} target="_blank" rel="noopener noreferrer"><Icon type={link.icon} /><ButtonText hasGradient>{link.title}</ButtonText></Button>)}
                                
                            </ContentJacket>
                        </Column>
                    </Row>
                </Div>
            </Jacket>
        )
    }
}