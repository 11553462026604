import React, { Component } from 'react';

import {AuthUserContext, withAuthorization} from '../../lib/Session';
import { withFirebase } from '../../lib/Firebase';
import * as ROLES from "../../constants/roles";
import {compose} from "recompose";
import Input from "../../components/global/formfields/input";
import Select from "../../components/global/formfields/select";

import { Label } from '../../components/global/formfields/inputStyles';
import Button from "../../components/global/buttons/primary";
import {Column, Row} from "../../components/global/theme/grid/grid";
import {Div} from "../../components/global/theme/reusables/reusables";
import {
    Details,
    Schedule, 
    Title
} from "../../components/lokations/sidebar/details/detailsStyles";
import Icon from "../../components/global/icons/icons";
import Loader from "../../components/global/loader";
import {minutesToTime, timeToMinutes} from "../../lib/Util/TimeUtil";

class EditLocation extends Component {
    constructor(props) {
        super(props);

        
        
        this.state = {
            loading: false,
            location: null,
            uploading: false,
            newlocation: null,

        };
    }

    componentDidMount = () => {
        
        this.onListenForLocation();

    }

    onListenForLocation = () => {
        this.setState({ loading: true });

        this.unsubscribe = this.props.firebase
            .location(this.props.match.params.location)
            .onSnapshot(snapshot => {
                if (snapshot) {
                    let location = { ...snapshot.data(), uid: this.props.match.params.location };
                    if (!location.links) {
                        location.links = [];
                    }
                    let newlocation = {...location};
                    newlocation.start_time = minutesToTime(location.start_time);
                    newlocation.end_time = minutesToTime(location.end_time);
                    this.setState({
                        location: location,
                        newlocation: newlocation,
                        loading: false,
                    });
                } else {
                    console.log("No location", snapshot, this.props.match.params);
                    this.setState({
                        location: null,
                        loading: false,
                    });
                }
            });


    };


    componentWillUnmount() {
        this.unsubscribe();
        
    }


    onChange = event => {
        let name = event.target.name.split(".");
        let mod = (source, keys, value) => {
            if (keys.length === 1) {
                source[keys[0]] = value;
                return source;
            }
            let first = keys[0];
            let newkeys = keys;
            newkeys.shift();
            source[first] = mod(source[first], newkeys, value);
            return source;
        }
        let newlocation = mod(this.state.newlocation, name, event.target.value);
        
        this.setState({ newlocation: newlocation });
    };

    removeItem = (field, index) => {
        let loc = this.state.newlocation;
        let comps = field.split(".");
        let ref = loc;
        while (comps.length > 1) {
            let f = comps.shift();
            ref = ref[f];
        }
        field = comps[0];
        ref[field].splice(index, 1);
        
        this.setState({newlocation: loc})
    }

    addItem = (field) => {
        let loc = this.state.newlocation;
        let comps = field.split(".");
        let ref = loc;
        while (comps.length > 1) {
            let f = comps.shift();
            ref = ref[f];
        }
        field = comps[0];
        ref[field].push({
            title: "",
            text: "",
            icon: ""
        });

        this.setState({newlocation: loc})
    }

    toggleActive = () => {

        this.props.firebase.locations().doc(this.state.location.uid).set({
            active: !this.state.location.active
        }, {merge: true});


    }
    
    save = () => {
        let location = this.state.newlocation;
        location.start_time = timeToMinutes(location.start_time);
        location.end_time = timeToMinutes(location.end_time);
        this.props.firebase.locations().doc(this.state.location.uid).set(location, {merge: true}).then(e => {
            this.props.history.push(`/admin/locations`);
        })
    }

    cancel = () => {
        this.props.history.push(`/admin/locations`);
    }

    upload = (e) => {
        let selectedFile = e.target.files[0];
        const storageRef = this.props.firebase.storage.ref();
        let filename = selectedFile.name;
        filename = this.state.location.uid + '-' + Math.random() + '-' + filename;
        this.setState({uploading: true})
        const uploadTask = storageRef.child(`location/${filename}`).put(selectedFile);
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            console.log("change", snapshot);
        }, (error) => {
            // Handle unsuccessful uploads
            console.log(">>", error);
        }, () => {
            // Do something once upload is complete
            console.log("Complete");
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                let loc = this.state.newlocation;
                loc.images.push({image: downloadURL})
                this.setState({newlocation: loc, uploading: false})
            });

        });

    }

    render() {
        const { loading, location, newlocation } = this.state;

        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    <div className="">

                        {location && <Row isExpanded showOnLarge>
                            <Column small={12} large={10} pushOnLarge={1}>
                                <Div relative pad={10} padTop>
                                    <Row isExpanded isCollapsed>
                                        <Column large={12}>
                                            <Details>
                                                <div style={{float: "right", display: "flex"}}>
                                                    <Button button onClick={this.cancel} fullWidth textColor="dark" type="ghost" text="Cancel" />
                                                    <Button button onClick={this.save} fullWidth type="full" text="Save" />
                                                </div>
                                                <Title>{location.name}</Title>
                                                
                                            </Details>
                                            <Schedule>

                                                <Row>
                                                    <Label dark>Active</Label>
                                                    <input type="checkbox" name="active" checked={location.active} onChange={this.toggleActive} />
                                                </Row>
                                                {
                                                    location.active && <div>

                                                        <Row>
                                                            <Column><Input dark type="text" name="name" value={newlocation.name} onChange={this.onChange} label="Name" placeholder="" /></Column>
                                                        </Row>

                                                        <Row>
                                                            <Column><Input dark type="text" name="address.neighborhood" value={newlocation.address.neighborhood} onChange={this.onChange} label="Neighborhood" placeholder="" /></Column>
                                                        </Row>


                                                        <Row>

                                                            <Column> <Input dark type="text" name="address.name" value={newlocation.address.name} onChange={this.onChange} label="Address" placeholder="" /></Column>
                                                        </Row>


                                                        <Row>

                                                            <Column> <Input dark type="text" name="address.mapImage" value={newlocation.address.mapImage} onChange={this.onChange} label="Map Image" placeholder="" /></Column>
                                                        </Row>


                                                        <Row>

                                                            <Column> <Input dark type="area" name="about" value={newlocation.about} onChange={this.onChange} label="About" placeholder="" /></Column>

                                                        </Row>
                                                        

                                                        <Row>

                                                            <Column> <Input dark type="text" name="connect_id" value={newlocation.connect_id} onChange={this.onChange} label="Stripe Connect ID" placeholder="" /></Column>

                                                        </Row>


                                                        <Row>

                                                            <Column> <Input dark type="text" name="start_time" value={newlocation.start_time} onChange={this.onChange} label="Day Pass Start Time" placeholder="" /></Column>

                                                        </Row>

                                                        <Row>

                                                            <Column> <Input dark type="text" name="end_time" value={newlocation.end_time} onChange={this.onChange} label="Day Pass End Time" placeholder="" /></Column>

                                                        </Row>


                                                        <hr/>

                                                        <Title>Links</Title>

                                                        {
                                                            newlocation.links.map((link, index) => <Row>

                                                                <Column>

                                                                    <Select isIcon values={Icon.all} dark type="text" name={`links.${index}.icon`} value={link.icon} onChange={this.onChange} label="Icon" placeholder="" />
                                                                </Column>
                                                                <Column>
                                                                    <Input dark type="text" name={`links.${index}.title`} value={link.title} onChange={this.onChange} label="Title" placeholder="" />
                                                                </Column>

                                                                <Column>
                                                                    <Input dark type="text" name={`links.${index}.text`} value={link.text} onChange={this.onChange} label="URL" placeholder="" />
                                                                </Column>



                                                                <Column>
                                                                    <Button button onClick={e => this.removeItem('links', index)} fullWidth textColor="dark" type="ghost" text="X" />
                                                                </Column>

                                                            </Row>)
                                                        }

                                                        <Row>
                                                            <Column>
                                                                <Button button onClick={e => this.addItem('links')} fullWidth textColor="dark" type="ghost" text="Add" />
                                                            </Column>
                                                        </Row>




                                                        <hr/>

                                                        <Title>Amenities</Title>

                                                        {
                                                            newlocation.amenities.map((amenity, index) => <Row>

                                                                <Column>

                                                                    <Select isIcon values={Icon.all} dark type="text" name={`amenities.${index}.icon`} value={amenity.icon} onChange={this.onChange} label="Icon" placeholder="" />
                                                                </Column>
                                                                <Column>
                                                                    <Input dark type="text" name={`amenities.${index}.title`} value={amenity.title} onChange={this.onChange} label="Title" placeholder="" />
                                                                </Column>

                                                                <Column>
                                                                    <Input dark type="text" name={`amenities.${index}.text`} value={amenity.text} onChange={this.onChange} label="Text" placeholder="" />
                                                                </Column>



                                                                <Column>
                                                                    <Button button onClick={e => this.removeItem('amenities', index)} fullWidth textColor="dark" type="ghost" text="X" />
                                                                </Column>

                                                            </Row>)
                                                        }

                                                        <Row>
                                                            <Column>
                                                                <Button button onClick={e => this.addItem('amenities')} fullWidth textColor="dark" type="ghost" text="Add" />
                                                            </Column>
                                                        </Row>


                                                        <hr/>

                                                        <Title>Secret Info</Title>

                                                        {
                                                            newlocation.info.map((info, index) => <Row>

                                                                <Column>

                                                                    <Select isIcon values={Icon.all} dark type="text" name={`info.${index}.icon`} value={info.icon} onChange={this.onChange} label="Icon" placeholder="" />
                                                                </Column>
                                                                <Column>
                                                                    <Input dark type="text" name={`info.${index}.title`} value={info.title} onChange={this.onChange} label="Title" placeholder="" />
                                                                </Column>

                                                                <Column>
                                                                    <Input dark type="text" name={`info.${index}.text`} value={info.text} onChange={this.onChange} label="Text" placeholder="" />
                                                                </Column>



                                                                <Column>
                                                                    <Button button onClick={e => this.removeItem('info', index)} fullWidth textColor="dark" type="ghost" text="X" />
                                                                </Column>

                                                            </Row>)
                                                        }

                                                        <Row>
                                                            <Column>
                                                                <Button button onClick={e => this.addItem('info')} fullWidth textColor="dark" type="ghost" text="Add" />
                                                            </Column>
                                                        </Row>


                                                        <hr/>

                                                        <Title>Perks</Title>

                                                        {
                                                            newlocation.perks.map((perk, index) => <Row>

                                                                <Column>
                                                                    <Select isIcon values={Icon.all} dark type="text" name={`perks.${index}.icon`} value={perk.icon} onChange={this.onChange} label="Icon" placeholder="" />
                                                                </Column>
                                                                <Column>
                                                                    <Input dark type="text" name={`perks.${index}.title`} value={perk.title} onChange={this.onChange} label="Text" placeholder="" />
                                                                </Column>

                                                                <Column>
                                                                    <Input dark type="area" name={`perks.${index}.text`} value={perk.text} onChange={this.onChange} label="Information" placeholder="" />
                                                                </Column>


                                                                <Column>
                                                                    <Button button onClick={e => this.removeItem('perks', index)} fullWidth textColor="dark" type="ghost" text="X" />
                                                                </Column>

                                                            </Row>)
                                                        }

                                                        <Row>
                                                            <Column>
                                                                <Button button onClick={e => this.addItem('perks')} fullWidth textColor="dark" type="ghost" text="Add" />
                                                            </Column>
                                                        </Row>


                                                        
                                                        <hr/>
                                                        <Title>Address Info</Title>

                                                        {
                                                            newlocation.address.info && newlocation.address.info.map((address, index) => <Row>

                                                                <Column>
                                                                    <Select isIcon values={Icon.all} dark type="text" name={`address.info.${index}.icon`} value={address.icon} onChange={this.onChange} label="Icon" placeholder="" />
                                                                </Column>

                                                                <Column>
                                                                    <Input dark type="text" name={`address.info.${index}.text`} value={address.text} onChange={this.onChange} label="Title" placeholder="" />
                                                                </Column>
                                                                <Column>
                                                                    <Input dark type="text" name={`address.info.${index}.title`} value={address.title} onChange={this.onChange} label="Distance" placeholder="" />
                                                                </Column>




                                                                <Column>
                                                                    <Button button onClick={e => this.removeItem('address.info', index)} fullWidth textColor="dark" type="ghost" text="X" />
                                                                </Column>

                                                            </Row>)
                                                        }

                                                        <Row>
                                                            <Column>
                                                                <Button button onClick={e => this.addItem('address.info')} fullWidth textColor="dark" type="ghost" text="Add" />
                                                            </Column>
                                                        </Row>



                                                        <hr/>
                                                        <Title>Images</Title>
                                                        
                                                        {
                                                            newlocation.images.map((image, index) => <Row>
                                                                <Column style={{width:"75%"}}>
                                                                    <img src={image.image} alt={image.image} style={{maxWidth: "75%"}} />
                                                                </Column>

                                                                <Column>
                                                                    <Button button onClick={e => this.removeItem('images', index)} fullWidth textColor="dark" type="ghost" text="X" />
                                                                </Column>
                                                            </Row>)
                                                        }

                                                        <Row>
                                                            <Column>
                                                                {
                                                                    this.state.uploading ? <Loader/> : <Input type="file" name="avatarfile" onChange={this.upload} />
                                                                }
                                                                
                                                            </Column>
                                                        </Row>
                                                        
                                                        

                                                    </div>
                                                }
                                                
                                            </Schedule>
                                        </Column>
                                    </Row>
                                </Div>
                            </Column>
                        </Row> }
                        

                        

                        {loading && <div>Loading ...</div>}

                    </div>
                )}
            </AuthUserContext.Consumer>
        );
    }
}

const condition = authUser => authUser && authUser.roles && !!authUser.roles[ROLES.ADMIN];

export default compose(
    withAuthorization(condition),
    withFirebase
)(EditLocation);
