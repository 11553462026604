

class Lokations {

    constructor(firebase) {
        this.firebase = firebase;
    }


    /**
     * Get a location pass for a specific date, if it exists
     *
     * @param date
     * @param location
     * @returns {Promise<null|*>}
     */
    getPass = async (date, location) => {

        console.log("Finding SHARED table for", date, location);
        let snapshot = await this.firebase.tables()
            .where("location", "==", location)
            .where("shared", "==", true)
            .where("active", "==", true)
            .get();

        console.log(snapshot);

        if (snapshot.size) {
            let tables = [];

            console.log(">>", snapshot.size);
            snapshot.forEach(doc => {
                let table = {...doc.data(), uid: doc.id};
                tables.push(table);

            });

            if (tables.length > 0) {

                let snapshot = await this.firebase.bookings()
                    .where("date", "==", date)
                    .where("is_table", "==", false)
                    .get();

                let bookings = {};
                if (snapshot.size) {
                    snapshot.forEach(doc => {
                        let booking = doc.data();
                        if (bookings[booking.table]) {
                            bookings[booking.table]++;
                        } else {
                            bookings[booking.table] = 1;
                        }
                    })
                }

                console.log("Bookings", bookings);

                let found = [];
                tables.forEach(table => {
                   if (!bookings[table.uid] || (bookings[table.uid] < table.seats)) {
                       found.push(table);
                   }
                });
                
                console.log("Finally got " , found);
                if (found.length > 0) {
                    console.log("Picking at random");
    
                    return found[Math.floor(Math.random()*found.length)];;
                }
            } else {
                console.log("ended up with nothing");
            }

        } else {
            console.log("Nothing");
        }

        return null;
    }



    /**
     * Get a table for a specific location, date, time, and seats
     *
     * @param date
     * @param start_time
     * @param end_time
     * @param seats
     * @param location
     * @returns {Promise<null|*>}
     */
    getTable = async (date, start_time, end_time, seats, location) => {
        
        console.log("Finding table for", date, start_time, end_time, seats, location);
        
        let snapshot = await this.firebase.tables()
            .where("location", "==", location)
            .where("shared", "==", false)
            .where("active", "==", true)
            .where("start_time", "<=", start_time)
            .where("seats", "==", seats)
            .get();
        
        console.log(snapshot);

        if (snapshot.size) {
            let tables = [];

            console.log(">>", snapshot.size);
            snapshot.forEach(doc => {
                let table = {...doc.data(), uid: doc.id};

                console.log(table);
                // check for exclusions.
                if (table.end_time >= end_time) {
                    // do we have any exclusions?
                    let valid = true;
                    if (table.exclusions) {
                        let excl = table.exclusions.any ? table.exclusions.any :
                            (table.exclusions[date] ? table.exclusions[date] : null);
                        console.log("exclusions", excl);
                        if (excl) {
                            let sh = excl.start_hour;
                            let eh = excl.end_hour;

                            if (sh < end_time && eh > start_time) {
                                valid = false;
                            }

                        }
                    }
                    
                    console.log("valid", valid);

                    if (valid) {
                        tables.push(table);
                    }

                } else {
                    console.log("skipped");
                }
            });

            if (tables.length > 0) {

                let snapshot = await this.firebase.bookings()
                    .where("date", "==", date)
                    .where("is_table", "==", true)
                    .get();

                let bookings = {};
                if (snapshot.size) {
                    snapshot.forEach(doc => {
                        let booking = doc.data();
                        bookings[booking.table] = true;
                    })
                }
                
                console.log("Bookings", bookings);

                let found = null;
                tables.forEach(table => {
                    if (!bookings[table.uid] && found === null) {
                        found = table;
                    }
                });

                return found;
            } else {
                console.log("ended up with nothing");
            }

        } else {
            console.log("Nothing");
        }

        return null;
    }


}

export default Lokations;
