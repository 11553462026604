// Imports
// ------
import styled from 'styled-components';
import { breakup } from 'components/global/theme/breakpoints';
import { Span } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled.button`
    position: relative;
    display: inline-block;
    cursor: pointer;
    background: none;

    svg {
        display: inline-block;
        vertical-align: middle;
    }
`;

export const Label = styled(Span)`
    display: none;
    vertical-align: middle;
    margin-right: 1rem;

    ${breakup.large`display: inline-block;`}
`;