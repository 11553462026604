// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import Button from 'components/global/buttons/primary';
import NavLink from 'components/global/navlink/navlink';
import { observer } from 'mobx-react';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';

// States
// ------
import { notificationState } from 'states/notifications';

// Styles
// ------
import { Jacket, Inner, Header, Title, Close, Content, Text, Link } from './notificationStyles';
import ShareWithFriends from "./share/shareWithFriends";

// Component
// ------
class Notification extends React.Component {
    handleClose = () => {
        notificationState.thanksOpen = !notificationState.thanksOpen;
        notificationState.checkoutOpen = !notificationState.checkoutOpen;
        notificationState.bookingsOpen = !notificationState.bookingsOpen;
        notificationState.emailverifyOpen = !notificationState.emailverifyOpen;
    }

    render() {
        const thanksState = notificationState.thanksOpen;
        const checkoutState = notificationState.checkoutOpen;
        const bookingsState = notificationState.bookingsOpen;
        const emailverifyState = notificationState.emailverifyOpen;

        if(this.props.thanks) return (
            <Jacket isOpen={thanksState}>
                <Inner>
                    <Header>
                        <Row isExpanded>
                            <Column small={9}>
                                <Title>Booking Confirmed</Title>
                            </Column>
                            <Column small={3}>
                                <Div text="right">
                                    <Close onClick={this.handleClose}>
                                        <Icon type="close" />
                                    </Close>
                                </Div>
                            </Column>
                        </Row>
                    </Header>
                    <Content>
                        <Row isExpanded>
                            <Column small={12}>
                                <Text>
                                    { this.props.notificationData.allday ?
                                        "Check-in is available 15 minutes prior to your booking start time. Happy working!" 
                                        :
                                        "Check-in is available 15 minutes prior to your booking start time. Share booking details via email with guest(s) invited to your table."
                                    }
                                    </Text>
                            </Column>
                            <Column small={12}>
                                <Div text="center">
                                    {!this.props.notificationData.allday && <ShareWithFriends authUser={this.props.authUser} data={this.props.notificationData} />}
                                    
                                    <br/>
                                    <Button navlink fullWidth  type="ghost" href={this.props.notificationData.url} textColor="dark" text="View Booking" />
                                    {/*<Link>*/}
                                    {/*    <NavLink to={this.props.url}>View My Bookings</NavLink>*/}
                                    {/*</Link>*/}
                                </Div>
                            </Column>
                        </Row>
                    </Content>
                </Inner>
            </Jacket>
        )

        if(this.props.checkout) return (
            <Jacket isOpen={checkoutState}>
                <Inner>
                    <Header>
                        <Row isExpanded>
                            <Column small={9}>
                                <Title>Time to Check-out!</Title>
                            </Column>
                            <Column small={3}>
                                <Div text="right">
                                    <Close onClick={this.handleClose}>
                                        <Icon type="close" />
                                    </Close>
                                </Div>
                            </Column>
                        </Row>
                    </Header>
                    <Content>
                        <Row isExpanded>
                            <Column small={12}>
                                <Text>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod, lorem ipsum dolor sit amet.</Text>
                            </Column>
                            <Column small={12}>
                                <Div text="center">
                                    <Button navlink type="ghost" href="/lokations-review" textColor="dark" text="Check Out" />
                                    <Link>
                                        <NavLink to="/">Privacy Policy</NavLink>
                                    </Link>
                                </Div>
                            </Column>
                        </Row>
                    </Content>
                </Inner>
            </Jacket>
        )

        if(this.props.bookings) return (
            <Jacket isOpen={bookingsState}>
                <Inner>
                    <Header>
                        <Row isExpanded>
                            <Column small={9}>
                                <Title>Thank you! Where will you book next?</Title>
                            </Column>
                            <Column small={3}>
                                <Div text="right">
                                    <Close onClick={this.handleClose}>
                                        <Icon type="close" />
                                    </Close>
                                </Div>
                            </Column>
                        </Row>
                    </Header>
                    <Content>
                        <Row isExpanded>
                            <Column small={12}>
                                <Text>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod.</Text>
                            </Column>
                            <Column small={12}>
                                <Div text="center">
                                    <Button navlink type="ghost" href="/" textColor="dark" text="Book Now" />
                                    <Link>
                                        <NavLink to="/">View My Bookings</NavLink>
                                    </Link>
                                </Div>
                            </Column>
                        </Row>
                    </Content>
                </Inner>
            </Jacket>
        )

        if(this.props.emailverify) return (
            <Jacket isOpen={emailverifyState}>
                <Inner>
                    <Header>
                        <Row isExpanded>
                            <Column small={9}>
                                <Title>Email Verification</Title>
                            </Column>
                            <Column small={3}>
                                <Div text="right">
                                    <Close onClick={this.handleClose}>
                                        <Icon type="close" />
                                    </Close>
                                </Div>
                            </Column>
                        </Row>
                    </Header>
                    <Content>
                        <Row isExpanded>
                            <Column small={12}>
                                <Text>Please verify your email address by clicking the link in the sign up email that has been sent to you.</Text>
                            </Column>
                            <Column small={12}>
                                <Div text="center">
                                    <Button navlink type="ghost" href="/" textColor="dark" text="Continue" />
                                    <Link>
                                        <NavLink to="/">Re-Send Email</NavLink>
                                    </Link>
                                </Div>
                            </Column>
                        </Row>
                    </Content>
                </Inner>
            </Jacket>
        )
    }
}

export default observer(Notification);