// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket, Iconwrap, Text } from './perksStyles';
import {notificationState} from "../../../../states/notifications";
import {withFirebase} from "../../../../lib/Firebase";
import {compose} from "recompose";
import {observer} from "mobx-react";

// Component
// ------
class SidebarPerks extends React.Component {

    popup = (block, index) => {
        if (this.props.hasAmenities || this.props.canCheckIn) {
            const claimed = this.props.booking.claimedperks && this.props.booking.claimedperks[index];
            // let date = "";
            // if (claimed) {
            //    
            //     date = new Date(this.props.booking.claimedperks[index].seconds);
            //     date = date.getHours() + ":" + date.getMinutes();
            //     console.log(date);
            // }
            notificationState.generalTitle = block.title;
            notificationState.generalText = <>
                {block.text}
                {claimed && <Iconwrap><br /><Icon hasGradient type="checked" /></Iconwrap>}
                </>;
            if (!claimed && this.props.hasAmenities) {
                notificationState.generalButtons = [{
                    url: '#',
                    title: 'Redeem',
                    onClick: () => {
                        
                        let perks = this.props.booking.claimedperks || {};
                        perks[index] = new Date();
                        this.props.firebase.booking(this.props.booking.uid).set({claimedperks: perks}, {merge: true}).then(e => {
                            this.popup(block, index)

                            this.props.firebase.analytics.logEvent("perk_claimed", {lokation: this.props.locationTitle, perk: block.title, date: new Date()});
                        })
                        
                    }
                }];
            } else {
                notificationState.generalButtons = [];
            }
            notificationState.generalOpen = true
        }
    } 
    
    render() {
        const perks = this.props.perks;
        
        return (
            <Jacket>
                <Row isExpanded equal>
                    {perks.map((block, index) => (
                        <Column small={4} key={index} clickable={this.props.hasAmenities || this.props.canCheckIn} onClick={e => { this.popup(block, index) }}>
                            <Iconwrap><Icon type={block.icon} /></Iconwrap>
                            {/* TODO: fix this.*/}
                            <Text dangerouslySetInnerHTML={{ __html: block.title }} />
                        </Column>
                    ))}
                </Row>
            </Jacket>
        )
    }
}


export default compose(
withFirebase,
    observer
)(SidebarPerks);