import React from 'react';
import { compose } from 'recompose';

import { AuthUserContext, withAuthorization } from '../../lib/Session';
import Locs from '../../components/Locations/admin';

import * as ROLES from '../../constants/roles';
import {Jacket} from "./contentStyles";

const AdminLocations = (props) => (
    <AuthUserContext.Consumer>
        {authUser => (
        <Jacket>
            <Locs {...props} />
        </Jacket>
        )}
    </AuthUserContext.Consumer>
);

const condition = authUser => authUser && authUser.roles && !!authUser.roles[ROLES.ADMIN];

export default compose(
    withAuthorization(condition),
)(AdminLocations);
