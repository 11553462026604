// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket, Amenity, Iconwrap, Title, Text } from './amenitiesStyles';

// Component
// ------
export default class LokationAmenities extends React.Component {
    render() {
        return (
            <Jacket>
                <Row isExpanded isCollapsed>
                    {this.props.amenities.map((block, index) =>
                        <Column key={index} small={12} medium={6}>
                            <Amenity>
                                <Row isExpanded equal>
                                    <Column small={2} medium={1}>
                                        <Iconwrap>
                                            <Icon type={block.icon} />
                                        </Iconwrap>
                                    </Column>
                                    <Column small={10}>
                                        <Title>{block.title}</Title>
                                        <Text>{block.text}</Text>
                                    </Column>
                                </Row>
                            </Amenity>
                        </Column>
                    )}
                </Row>
            </Jacket>
        )
    }
}