// Imports
// ------
import styled, { css } from "styled-components";
import { breakup } from 'components/global/theme/breakpoints';
import { Section, Gradient, Div, P } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    display: none;

    ${props => props.isOpen === true && css`
        display: block;
        position: fixed;
        bottom: 0; left: 0; right: 0;
        width: 100%;
        z-index: 999999;

        background: ${Gradient};
    `}
`;

export const PopupJacket = styled(Div)`
    position: relative;
    display: block;
    padding: 5rem 2rem;

    ${breakup.large`
        padding: 3rem 2rem;
    `}
`;

export const Close = styled.button`
    position: absolute;
    top: 2rem; right: 2rem;
    background: none;

    cursor: pointer;

    svg {
        fill: ${props => props.theme.bc3};
    }
`;

export const TextWrapper = styled(Div)`
    text-align: center;

    ${breakup.large`text-align: left;`}
`;

export const Text = styled(P)`
    color: ${props => props.theme.bc3};
`;