// Imports
// ------
import React from 'react';
import FaqItem from './faqItem';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket } from './listStyles';

// Component
// ------
export default class FaqsList extends React.Component {
    render() {
        return (
            <Jacket>
                <Row isExpanded>
                    <Column small={12} medium={10} pushOnMedium={1}>
                        <Row isExpanded>
                            <Column small={12}>
                                {this.props.list.map((block, index) =>
                                    <FaqItem
                                        key={index}
                                        question={block.question}
                                        answer={block.answer}
                                    />
                                )}
                            </Column>
                        </Row>
                    </Column>
                </Row>
            </Jacket>
        )
    }
}