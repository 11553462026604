// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';

// Styles
// ------
import { Jacket, Text } from './helpButtonStyles';

// States
// ------
import { userState } from 'states/user';

// Component
// -------
export default class HelpButton extends React.Component {
    handleButton = () => {
        userState.helpIsOpen = !userState.helpIsOpen;
    }

    render() {
        return (
            <Jacket onClick={this.handleButton}>
                <Text>Help</Text>
                <Icon type="small-arrow-right" />
            </Jacket>
        )
    }
}