// Imports
// ------
import React from 'react';
import NavLink from 'components/global/navlink/navlink';
// import Social from 'components/global/social/social';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';

// Files
// ------
import Brand from '../../../../images/Logo.svg';

// Styles
// ------
import { Jacket, Menu, MenuItem, Logo, Copy, CopyItem } from './desktopFooterStyles';
import {userState} from "../../../../states/user";
import {observer} from "mobx-react";

// Component
// ------
class DesktopFooter extends React.Component {
    render() {
        return (
            <Jacket>
                <Row isExpanded>
                    <Column large={5} pushOnLarge={1}>
                        <Menu>
                            <MenuItem>
                                <NavLink to="/lokations">Book Now</NavLink>
                            </MenuItem>
                            <MenuItem>
                                <NavLink to={"#"} onClick={e => userState.faqsIsOpen = !userState.faqsIsOpen}>FAQs</NavLink>
                            </MenuItem>
                            <MenuItem>
                                <NavLink to={"#"} onClick={e => userState.aboutIsOpen = !userState.aboutIsOpen}>About Us</NavLink>
                            </MenuItem>
                            <MenuItem>
                                <NavLink  to={"#"} onClick={e => userState.legalIsOpen = !userState.legalIsOpen}>Legal</NavLink>
                            </MenuItem>
                            {/*<MenuItem>*/}
                            {/*    <NavLink to="/">Contact</NavLink>*/}
                            {/*</MenuItem>*/}
                            <MenuItem>
                                <NavLink  to={"#"} onClick={e => userState.helpIsOpen = !userState.helpIsOpen}>Help</NavLink>
                            </MenuItem>
                        </Menu>
                    </Column>
                    <Column large={5} pushOnLarge={1}>
                        <Div text="right">
                            <Logo src={Brand} />
                        </Div>
                    </Column>
                </Row>
                <Row isExpanded>
                    <Column large={6} pushOnLarge={1}>
                        <Copy>
                            <CopyItem>&copy; 2019 Lokus, Inc. All rights reserved</CopyItem>
                            <CopyItem>
                                <NavLink to={"#"} onClick={e => userState.legalIsOpen = !userState.legalIsOpen}>Privacy Policy</NavLink>
                            </CopyItem>
                            <CopyItem>
                                <NavLink to={"#"} onClick={e => userState.legalIsOpen = !userState.legalIsOpen}>Terms of Service</NavLink>
                            </CopyItem>
                        </Copy>
                    </Column>
                    <Column large={4} pushOnLarge={1}>
                        <Div text="right">
                            {/*<Social*/}
                            {/*    instagram="http://www.instagram.com/"*/}
                            {/*    facebook="http://www.facebook.com/"*/}
                            {/*    twitter="http://www.twitter.com/"*/}
                            {/*    pinterest="http://www.pinterest.com/"*/}
                            {/*/>*/}
                        </Div>
                    </Column>
                </Row>
            </Jacket>
        )
    }
}

export default observer(DesktopFooter);