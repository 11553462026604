// Imports
// ------
import styled from "styled-components";
import { breakup } from 'components/global/theme/breakpoints';
import { Section, Div, H3, Gradient, GradientIcon, Anchorlink, Span } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    display: block;

    background: ${props => props.theme.bc3};
    padding: 2rem 3rem;
`;

export const Title = styled(H3)`
    display: block;
    margin-bottom: 1rem;

    color: ${props => props.theme.bc1};
`;

export const Content = styled(Div)`
    display: block;
    margin-bottom: 2rem;

    color: ${props => props.theme.bc1};
`;

export const ContentJacket = styled(Div)`
    position: relative;
`;

export const ButtonText = styled(Span)`
    position: relative;
    margin-left: 0.5rem;

    &:before {
        ${props => props.theme.animate('0.5s', 'ease-in-out')}
        content: '';
        position: absolute;
        bottom: -2px;
        height: 1px;
        width: 0%;

        background: ${Gradient};
    }

    &:hover {
        &:before {
            width: 100%;
        }
    }

    ${breakup.large`
        font-size: 2rem;
        line-height: 2rem;
    `}
`;

export const Button = styled(Anchorlink)`
    position: relative;
    z-index: 2;
    display: block;

    svg, span {
        display: inline-block;
        vertical-align: middle;
    }

    svg {
        ${GradientIcon}
        width: 1.8rem;
        height: 1.8rem;
    }
`;