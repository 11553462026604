// Imports
// ------
import { css } from "styled-components";
import { breakup } from '../breakpoints';
import { Column } from './grid';

// Exports
// ------
const collapsed = css`
    /* Is Collapsed */
    ${props => props.isCollapsed && css`
        > ${Column} { padding: 0; }
    `}
    ${props => props.isCollapsedOnSmedium && css`
        > ${Column} {
            ${breakup.smedium` padding: 0; `}
        }
    `}
    ${props => props.isCollapsedOnMedium && css`
        > ${Column} {
            ${breakup.medium` padding: 0; `}
        }
    `}
    ${props => props.isCollapsedOnLarge && css`
        > ${Column} {
            ${breakup.large` padding: 0; `}
        }
    `}
    ${props => props.isCollapsedOnXlarge && css`
        > ${Column} {
            ${breakup.xlarge` padding: 0; `}
        }
    `}
    ${props => props.isCollapsedOnXxlarge && css`
        > ${Column} {
            ${breakup.xxlarge` padding: 0; `}
        }
    `}
    ${props => props.isCollapsedOnHuge && css`
        > ${Column} {
            ${breakup.huge` padding: 0; `}
        }
    `}
    ${props => props.isCollapsedOnUber && css`
        > ${Column} {
            ${breakup.uber` padding: 0; `}
        }
    `}
    /* Not Collapsed */
    ${props => props.isNotCollapsedOnSmedium && css`
        > ${Column} {
            ${breakup.smedium`
                padding-left: calc(${props => props.theme.gutterLarge} / 2);
                padding-right: calc(${props => props.theme.gutterLarge} / 2);
            `}
        }
    `}
    ${props => props.isNotCollapsedOnMedium && css`
        > ${Column} {
            ${breakup.medium`
                padding-left: calc(${props => props.theme.gutterLarge} / 2);
                padding-right: calc(${props => props.theme.gutterLarge} / 2);
            `}
        }
    `}
    ${props => props.isNotCollapsedOnLarge && css`
        > ${Column} {
            ${breakup.large`
                padding-left: calc(${props => props.theme.gutterLarge} / 2);
                padding-right: calc(${props => props.theme.gutterLarge} / 2);
            `}
        }
    `}
    ${props => props.isNotCollapsedOnXlarge && css`
        > ${Column} {
            ${breakup.xlarge`
                padding-left: calc(${props => props.theme.gutterLarge} / 2);
                padding-right: calc(${props => props.theme.gutterLarge} / 2);
            `}
        }
    `}
    ${props => props.isNotCollapsedOnXxlarge && css`
        > ${Column} {
            ${breakup.xxlarge`
                padding-left: calc(${props => props.theme.gutterLarge} / 2);
                padding-right: calc(${props => props.theme.gutterLarge} / 2);
            `}
        }
    `}
    ${props => props.isNotCollapsedOnHuge && css`
        > ${Column} {
            ${breakup.huge`
                padding-left: calc(${props => props.theme.gutterLarge} / 2);
                padding-right: calc(${props => props.theme.gutterLarge} / 2);
            `}
        }
    `}
    ${props => props.isNotCollapsedOnUber && css`
        > ${Column} {
            ${breakup.uber`
                padding-left: calc(${props => props.theme.gutterLarge} / 2);
                padding-right: calc(${props => props.theme.gutterLarge} / 2);
            `}
        }
    `}
`;
export default collapsed;