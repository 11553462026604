// Imports
// ------
import styled from "styled-components";
import { Div, Span} from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Div)`
    position: relative;
    display: block;
`;

export const EmailList = styled.ul`
    list-style: none;
    margin: 0;
`;

export const EmailListItem = styled.li`
    position: relative;
    display: block;
    margin-bottom: 1rem;

    text-align: left;
`;

export const Text = styled(Span)`
    color: ${props => props.theme.bc5};
`;

export const Remove = styled.button`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    width: 2.4rem;
    height: 2.4rem;

    svg {
        fill: ${props => props.theme.bc5};
    }
`;

export const InputWrapper = styled(Div)`
    position: relative;
    display: block;

    margin-bottom: 1rem;

    &:after {
        content: '';
        display: table;
        clear: both;
    }
`;

export const InputLabel = styled(Span)`
    display: block;
    text-align: left;

    font-size: 1.4rem !important;
    color: ${props => props.theme.bc2};
`;

export const EmailInput = styled.input`
    display: inline-block;
    max-width: 80%;
    width: 100%;
    float: left;

    font-size: 1.6rem;
    text-align: left;
    padding: 1rem 0;
`;

export const AddEmail = styled.button`
    position: relative;
    display: inline-block;
    float: right;

    font-size: 1.4rem;
    color: ${props => props.theme.bc2};
    background: none;
`;