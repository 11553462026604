// Imports
// ------
import styled from 'styled-components';
import { breakup } from 'components/global/theme/breakpoints';
import { Div, Span } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Div)`
    position: relative;
    display: block;
    margin-bottom: 1px;

    padding: 1rem 0;
    background: ${props => props.theme.bc1};

    input {
        margin: 0;
        padding: 1rem 0;
    }
`;

export const Label = styled(Span)``;

export const Phone = styled(Div)`
    position: relative;
    display: block;

    input {
        font: normal 16px 'Moderat','Helvetica','Arial', sans-serif !important;
        ${breakup.medium`font: normal 18px 'Moderat','Helvetica','Arial', sans-serif !important;`}
        ${breakup.medium`font: normal 20px 'Moderat','Helvetica','Arial', sans-serif !important;`}

        color: ${props => props.theme.bc3};
        background: none !important;
        text-align: ${props => props.textAlign || "right"};

        &::placeholder, &[disabled] {
            color: ${props => props.theme.bc3};
            opacity: 0.45;
        }
    }

    .react-phone-number-input__icon {
        width: 1.5rem;
        height: 1.5rem;
        border: none;
    }

    .react-phone-number-input__country-select-arrow {
        border-width: 8px 6px 0 6px;
    }
`;