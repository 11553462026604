import React  from 'react';
// import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";

import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import ForgotPassword from "./pages/auth/Forgot";
import AdminLocations from "./pages/admin/Locations";
import Locations from "./pages/Locations";
import AdminLocation from "./pages/admin/Location";
import Location from "./pages/Location";
import Booking from "./pages/Booking";
import UserProfile from "./pages/UserProfile";

import { withAuthentication } from './lib/Session';
import Bookings from "./pages/Bookings.js";
import EditLocation from "./pages/admin/EditLocation";
import Layout from "./Layout";
import Header from "./components/global/header/header";
import Footer from "./components/global/footer/footer";
import Cookies from './components/global/cookies/popup';
import Help from "./components/user/help/help";
import Legal from "./components/user/legal/legal";
import Faqs from "./components/user/faqs/faqs";
import {userState} from "./states/user";
import {observer} from "mobx-react";
import About from "./components/user/faqs/about";
import GenericNotification from "./components/global/notification/generic-notification";
import IconTest from "./pages/admin/IconTest";
import PromoCodes from "./pages/admin/PromoCodes";
import EditPromos from "./pages/admin/EditPromos";
import FeedbackButton from "./components/feedback/FeedbackButton";


const App = () => (
    <Layout>
            <Router>

                <Header />
                <div className="App">

                    <GenericNotification />
                    
                    <Help isActive={userState.helpIsOpen} />
                    <Legal isActive={userState.legalIsOpen} />
                    <Faqs isActive={userState.faqsIsOpen} />
                    <About isActive={userState.aboutIsOpen} />


                    <FeedbackButton />

                    <Route exact path="/" component={Login}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/forgot" component={ForgotPassword}/>
                    <Route path="/admin/promocodes" exact component={PromoCodes} />
                    <Route path="/admin/edit-promos/:location" component={EditPromos} />

                    <Route path="/admin/locations" exact component={AdminLocations} />
                    <Route path="/admin/location/:location" component={AdminLocation} />
                    <Route path="/admin/edit-location/:location" component={EditLocation} />
                    <Route path="/lokations" exact component={Locations} />
                    <Route path="/register" component={Register} />
                    <Route path="/lokation/:location/:action" component={Location} />
                    <Route path="/lokation/:location" component={Location} />
                    <Route path="/bookings" exact component={Bookings} />
                    <Route path="/bookings/:section" component={Bookings} />
                    <Route path="/booking/:booking" exact component={Booking}/>

                    <Route exact path="/profile" component={UserProfile} />
                    
                    <Route exact path="/icon-test" component={IconTest} />
                    
                </div>
                <Footer />
                <Cookies />
            </Router>

    </Layout>
        );

export default withAuthentication(observer(App));
