// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket, Option, Text, Cost, ContentJacket, Iconwrap, Title, Content } from './pricingStyles';

// Components
// ------
export default class LokationPricing extends React.Component {
    render() {
        return (
            <Jacket>
                <Div relative padBottom>
                    {this.props.pricingOptions.map((block, index) =>
                        <Option key={index}>
                            <Row isExpanded isCollapsed>
                                <Column small={12} large={7}>
                                    <Text>{block.text}</Text>
                                </Column>
                                <Column small={12} large={5}>
                                    <Cost>{block.price}</Cost>
                                </Column>
                            </Row>
                        </Option>
                    )}
                </Div>
                <Div relative>
                    {this.props.pricingContent.map((block, index) =>
                        <ContentJacket key={index}>
                            <Row isExpanded isCollapsed>
                                <Column small={12}>
                                    <Iconwrap><Icon type={block.icon} /></Iconwrap>
                                </Column>
                                <Column small={12}>
                                    <Title>{block.title}</Title>
                                </Column>
                                <Column small={12}>
                                    <Content>{block.content}</Content>
                                </Column>
                            </Row>
                        </ContentJacket>
                    )}
                </Div>
            </Jacket>
        )
    }
}