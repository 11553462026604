// Imports
// ------
import styled, { css } from 'styled-components';
// import { breakup } from 'components/global/theme/breakpoints';
import { Section, Div, H2, H4 } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    ${props => props.theme.animate('0.4s', 'ease-in-out')}
    position: fixed;
    z-index: 9999;
    top: 0; left: -100%;;
    display: block;
    width: 100%;
    height: 100vh;
    
    background: ${props => props.theme.bc2};

    ${props => props.isActive && css`
        left: 0;
    `}
`;

export const Header = styled(Div)`
    position: relative;
    background: ${props => props.theme.bc2};
`;

export const Close = styled.button`
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    display: inline-block;
    cursor: pointer;
    background: none;

    svg {
        ${props => props.theme.animate('0.3s', 'ease-in-out')}
    }

    &:hover {
        svg {
            opacity: 0.6;
        }
    }
`;

export const Title = styled(H4)`
    display: block;
    padding: 2rem 0;
`;

export const HelpTitle = styled(H2)`
    position: relative;
    display: block;

    margin-top: 4rem;
`;

export const ContactDetails = styled(Section)`
    position: relative;
    display: block;
    margin-top: 4rem;
    padding: 2rem 0;
    background: ${props => props.theme.bc2};
`;