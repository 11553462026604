// Imports
// ------
import React from 'react';

// Styles
// ------
import {
    Jacket
} from './iconsStyles';

// Component
// ------
class Icon extends React.PureComponent {
    
    static all = [
        'ada-friendly', 'bar-lounge', 'bodega', 'communication', 'hotel-room', 'spa', 'upload',
        'check-in', 'check-out', 'restaurant','revenue',
        'upload','photo','user','table','smile','chair','restroom','transit','link','cafe','wifi','fitness','food','chat','info','mail','locked','location','calendar','search','phone','power','big-arrow-left','big-arrow-right','big-arrow-down','small-arrow-right','small-arrow-left','small-arrow-down','instagram','twitter','facebook','pinterest','close','star','star-empty','checked','heart','heart-empty','eye','settings','slack'];
    
    render() {
        const type = this.props.type;
        const className = this.props.className;


        if (type === 'upload') return (
            <Jacket height="24px" width="24px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>ada-friendly</title>
                <polygon className="st28"
                         points="22.9,18.7 22.9,22.2 9.1,22.2 9.1,18.7 6.9,18.7 6.9,24.4 25.1,24.4 25.1,18.7 	"/>
                <polygon className="st28"
                         points="14.9,11.9 14.9,20 17.1,20 17.1,11.8 19.3,14 20.9,12.5 16,7.6 11.2,12.5 12.7,14 	"/>

            </Jacket>
        );
        
        if (type === 'spa') return (
            <Jacket height="24px" width="24px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>ada-friendly</title>
                <path className="st28" d="M25.9,14.5C25.9,14.5,25.9,14.5,25.9,14.5L25.9,14.5c-0.6,0-1.1,0-1.7,0c0.3-1.5,0.2-3-0.2-4.1
		C23.8,10,23.4,9.8,23,10c-1.1,0.3-2.3,1-3.3,1.7c-1.2-2.8-3.2-4.5-3.3-4.6c-0.3-0.2-0.7-0.2-1,0c-0.1,0.1-2.1,1.8-3.3,4.6
		c-0.9-0.7-2.2-1.4-3.3-1.7C8.6,9.8,8.2,10,8,10.4c-0.4,1.1-0.5,2.6-0.2,4.1c-0.9-0.1-1-0.1-1.7-0.1c-0.4,0-0.7,0.4-0.7,0.8
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.2,5.5,4.9,9.8,10.6,9.8c5.7,0,10.4-4.3,10.6-9.8C26.7,14.8,26.3,14.5,25.9,14.5z
		 M22.7,11.7c0.2,0.9,0.1,2-0.2,3.1c-0.7,0.2-1.3,0.4-2,0.6c0-0.8-0.1-1.5-0.3-2.2C20.8,12.6,21.8,12.1,22.7,11.7z M9.3,11.7
		c0.9,0.4,1.9,1,2.5,1.6c-0.2,0.7-0.3,1.4-0.3,2.2c-0.7-0.3-1.3-0.5-2-0.6C9.2,13.7,9.1,12.6,9.3,11.7z M7,16c1.6,0,3.2,0.5,4.6,1.1
		c0.4,2.7,1.7,5,2.7,6.3C10.4,22.7,7.5,19.8,7,16z M16,23.2c-1-1.2-3-4.2-3-7.6c0-3.4,2.1-5.9,3-6.9c1,1,3,3.5,3,6.9
		C19,19,17,21.9,16,23.2z M17.7,23.4c1-1.4,2.3-3.7,2.7-6.3c1.7-0.7,3.2-1.1,4.6-1.1C24.5,19.8,21.6,22.7,17.7,23.4z"/>
                <path className="st28" d="M16,25.3c-5.8,0-10.6-4.4-10.8-10l0,0c0-0.5,0.4-1,0.9-1c0.2,0,0.3,0,0.5,0c0.3,0,0.6,0,1,0
		c-0.3-1.5-0.2-2.9,0.3-4C8,9.9,8.6,9.6,9,9.8c1.1,0.4,2.3,0.9,3.1,1.6c1.2-2.7,3.1-4.4,3.2-4.4c0.4-0.3,0.9-0.3,1.2,0
		c0.1,0.1,2,1.7,3.2,4.4c0.9-0.6,2-1.2,3.1-1.6c0.5-0.2,1,0.1,1.2,0.6c0.4,1.1,0.5,2.5,0.3,4c0.5,0,1.1-0.1,1.5,0l0,0
		c0.5,0,0.9,0.5,0.9,1C26.6,20.9,21.8,25.3,16,25.3z M5.6,15.2L5.6,15.2c0.2,5.4,4.8,9.7,10.4,9.7s10.2-4.2,10.4-9.7
		c0-0.3-0.2-0.6-0.5-0.6l0,0c-0.5,0-1.1,0-1.7,0.1l-0.3,0l0-0.3c0.3-1.5,0.2-2.9-0.2-4c-0.1-0.3-0.4-0.4-0.7-0.3
		c-1.2,0.4-2.4,1-3.2,1.7L19.7,12l-0.1-0.2c-1.1-2.8-3.1-4.5-3.2-4.5c-0.2-0.2-0.5-0.2-0.7,0c-0.1,0.1-2.1,1.7-3.2,4.5L12.3,12
		l-0.2-0.2c-0.8-0.7-2-1.3-3.2-1.7c-0.3-0.1-0.6,0.1-0.7,0.3c-0.4,1.1-0.5,2.5-0.2,4l0,0.3l-0.3,0c-0.6-0.1-0.9-0.1-1.3-0.1
		c-0.1,0-0.3,0-0.5,0C5.8,14.7,5.6,15,5.6,15.2z M17.3,23.7l0.3-0.4c1-1.4,2.3-3.6,2.7-6.3l0-0.1l0.1,0c1.6-0.7,3.2-1.1,4.7-1.1
		l0.2,0l0,0.2c-0.5,3.8-3.5,6.9-7.5,7.6L17.3,23.7z M20.6,17.2c-0.4,2.4-1.5,4.5-2.5,5.9c3.5-0.8,6.1-3.6,6.7-7
		C23.5,16.3,22,16.6,20.6,17.2z M14.7,23.7l-0.5-0.1c-4-0.7-7-3.8-7.5-7.6l0-0.2l0.2,0c1.5,0,3.1,0.4,4.7,1.1l0.1,0l0,0.1
		c0.4,2.6,1.7,4.9,2.7,6.3L14.7,23.7z M7.2,16.2c0.6,3.4,3.2,6.1,6.7,7c-1-1.4-2.1-3.5-2.5-5.9C10,16.6,8.5,16.3,7.2,16.2z M16,23.5
		l-0.1-0.2c-0.9-1.2-3.1-4.2-3.1-7.7c0-3.5,2.2-6.1,3.1-7.1L16,8.4l0.1,0.1c0.9,1,3.1,3.6,3.1,7.1c0,3.5-2.2,6.5-3.1,7.7L16,23.5z
		 M16,9c-1,1-2.9,3.5-2.9,6.7c0,3.2,1.9,6,2.8,7.3c1-1.2,2.9-4.1,2.9-7.3C18.8,12.4,17,10,16,9z M11.6,15.7l-0.3-0.1
		c-0.7-0.2-1.3-0.5-2-0.6l-0.1,0l0-0.1c-0.3-1.2-0.4-2.3-0.2-3.2l0-0.2l0.2,0.1c1.1,0.4,2,1,2.5,1.6l0.1,0.1l0,0.1
		c-0.2,0.7-0.3,1.4-0.3,2.1L11.6,15.7z M9.6,14.6c0.5,0.1,1.1,0.3,1.6,0.5c0-0.6,0.1-1.2,0.3-1.9c-0.5-0.5-1.2-0.9-2.1-1.3
		C9.3,12.7,9.4,13.7,9.6,14.6z M20.4,15.7l0-0.3c0-0.7-0.1-1.4-0.3-2.1l0-0.1l0.1-0.1c0.5-0.5,1.5-1.1,2.5-1.6l0.2-0.1l0,0.2
		c0.2,0.9,0.1,2-0.2,3.2l0,0.1l-0.1,0c-0.7,0.1-1.3,0.4-2,0.6L20.4,15.7z M20.5,13.3c0.1,0.6,0.2,1.2,0.3,1.9
		c0.5-0.2,1.1-0.4,1.6-0.5c0.2-1,0.3-1.9,0.2-2.7C21.7,12.3,20.9,12.8,20.5,13.3z"/>

            </Jacket>
        );
        
        if (type === 'hotel-room') return (
            <Jacket height="24px" width="24px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>ada-friendly</title>
                <path className="st28" d="M27.5,25.4L23.4,7l0-0.2L12.2,9.3C12,9.2,11.8,9.1,11.7,9L11.5,9l-1,1.7l0,0.1c0.2,0.5,0.5,0.9,0.9,1.2
	l0.2,0.1l0.5-0.9c0.9,0.4,1.8,0.9,2.7,1.4c0.9,0.5,1.7,1,2.5,1.6l-2.5,4.3l-2-1.2L7.8,26l-1-0.1l-0.2-0.3l5.2-9l-2-1.2l0.9-1.6
	c0.7,0.4,1.5,0.7,2.3,0.8c0.2,0.4,0.6,0.6,1.1,0.6c0,0,0,0,0,0c0.5,0,1-0.3,1.3-0.7c0.2-0.3,0.2-0.7,0.1-1.1
	c-0.1-0.4-0.3-0.7-0.7-0.9c-0.5-0.3-1.4-0.2-1.8,0.3c-1.7-0.3-3-1.7-3-3.5c0-1.9,1.6-3.5,3.5-3.5c1.4,0,2.6,0.8,3.1,2L16.8,8
	l1.8-0.4l-0.1-0.2c-0.8-2-2.7-3.3-4.9-3.3c-2.9,0-5.3,2.4-5.3,5.3c0,1.2,0.4,2.3,1.1,3.3l-2,3.4l2,1.2l-5,8.6l1.4,1.7l2.8,0.4
	l4.8-8.3l1,0.6l1.8,7.7L27.5,25.4z M14.6,10.5L22,8.8l3.4,15.3l-7.8,1.8l-1.4-6.2l0.7-1.2l1.4,6.3l6-1.3l-3-13.4l-5.4,1.2
	c-0.1,0-0.1-0.1-0.2-0.1C15.4,10.9,15,10.7,14.6,10.5z M19.5,13.6c-0.5-0.4-1-0.8-1.6-1.1L20,12l2.3,10.1l-2.6,0.6l-1.5-6.5l1.5-2.5
	L19.5,13.6z"/>

            </Jacket>
        );

        if (type === 'communication') return (
            <Jacket height="24px" width="24px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>ada-friendly</title>
                <path className="st28" d="M16,26.5c1.9,0,3.6-0.5,5.1-1.3c0,0,4.7,0.7,4.7,0.7S25.2,21,25.2,21c0.8-1.5,1.3-3.2,1.3-5
		c0-5.8-4.7-10.5-10.5-10.5S5.5,10.2,5.5,16S10.2,26.5,16,26.5z M16,24.5c1.7,0,3.2-0.5,4.5-1.3c0,0,3,0.3,3,0.3s-0.4-3-0.3-3.1
		c0.8-1.3,1.3-2.8,1.3-4.5c0-4.7-3.8-8.5-8.5-8.5S7.5,11.3,7.5,16S11.3,24.5,16,24.5z M13.3,18c0.9,0,1.6-0.7,1.6-1.6
		s-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6S12.5,18,13.3,18z M18.7,18c0.9,0,1.6-0.7,1.6-1.6s-0.7-1.6-1.6-1.6
		c-0.9,0-1.6,0.7-1.6,1.6S17.8,18,18.7,18z"/>

            </Jacket>
        );
        
        if (type === 'bodega') return (
            <Jacket height="24px" width="24px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>ada-friendly</title>
                <path className="st28" d="M27,8.3C27,8.3,27,8.3,27,8.3l-1.7-2.9c-0.1-0.3-0.4-0.5-0.8-0.5H7.6c-0.3,0-0.6,0.2-0.8,0.5L5,8.3v0.2
		c0,0.1-0.1,0.1-0.1,0.2v2.8c0,1.4,0.6,2.6,1.7,3.4V26v0.1v1h1h16.9h1V26h0V14.9c1.1-0.8,1.7-2.1,1.7-3.4V8.7
		C27.1,8.6,27.1,8.5,27,8.3z M8.1,6.8h15.8l0.5,0.9H7.6L8.1,6.8z M18.4,9.7v1.8c0,1.4-1.1,2.4-2.4,2.4s-2.4-1.1-2.4-2.4V9.7H18.4z
		 M6.8,11.5V9.7h4.8v1.8c0,1.4-1.1,2.4-2.4,2.4S6.8,12.9,6.8,11.5z M18.3,25.2v-6.7h2.4v6.7h-2H18.3z M23.5,25.2h-0.3h-1v-8.2h-5.4
		v8.2h0h-2.7H8.5v-9.4c0.2,0,0.5,0.1,0.7,0.1c1.3,0,2.6-0.6,3.4-1.6c0.8,1,2.1,1.6,3.4,1.6s2.6-0.6,3.4-1.6c0.8,1,2.1,1.6,3.4,1.6
		c0.2,0,0.5,0,0.7-0.1V25.2z M25.2,11.5c0,1.4-1.1,2.4-2.4,2.4s-2.4-1.1-2.4-2.4V9.7h4.8V11.5z"/>
                <path className="st28" d="M9.9,23.5h5.5v-6.6H9.9V23.5z M11.5,18.4h2.5V22h-2.5V18.4z"/>

            </Jacket>
        );

        

        if (type === 'bar-lounge') return (
            <Jacket height="24px" width="24px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>ada-friendly</title>
                <path className="st28" d="M23,3.7c-2.5,0-4.5,1.8-5,4.1H3.9l9.6,11v7.6h-2.8v1.9h7.7v-1.9h-2.8v-7.6l4.9-5.6c0.8,0.4,1.6,0.7,2.5,0.7
	c2.8,0,5.1-2.3,5.1-5.1C28.1,5.9,25.8,3.7,23,3.7z M14.6,16.9L8.3,9.8h12.4L14.6,16.9z M23,11.8c-0.4,0-0.8-0.1-1.2-0.2l3.4-3.9
	h-5.1c0.4-1.2,1.6-2.1,2.9-2.1c1.7,0,3.1,1.4,3.1,3.1C26.1,10.5,24.7,11.8,23,11.8z"/>
            </Jacket>
        );

        if (type === 'ada-friendly') return (
            <Jacket height="24px" width="24px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>ada-friendly</title>
                
                    <path className="st28" d="M14.3,10.4c1.9,0,3.5-1.6,3.5-3.5c0-1.9-1.6-3.4-3.5-3.4c-1.9,0-3.5,1.6-3.5,3.5
		C10.9,8.8,12.4,10.4,14.3,10.4z M14.3,5.6c0.7,0,1.3,0.6,1.3,1.3s-0.6,1.3-1.3,1.3S13,7.6,13,6.9S13.6,5.6,14.3,5.6z"/>

                    <rect x="21.6" y="17.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -8.8562 22.4893)"
                          className="st28" width="2.2" height="9.3"/>
                    <polygon className="st28" points="21.3,15 15.4,15 15.4,11.3 13.2,11.3 13.2,17.2 21.3,17.2 	"/>
                    <rect x="11.8" y="20" className="st28" width="2" height="2"/>
                    <path className="st28" d="M16.6,24.8c-1,1-2.4,1.6-3.8,1.6c-1.4,0-2.8-0.6-3.8-1.6c-1-1-1.6-2.4-1.6-3.8c0-1.4,0.6-2.8,1.6-3.8
		c0.7-0.7,1.6-1.2,2.7-1.4v-2.2c-1.6,0.2-3,0.9-4.2,2.1C6,17.1,5.2,19,5.2,21s0.8,3.9,2.2,5.3s3.3,2.2,5.3,2.2s3.9-0.8,5.3-2.2
		c0.9-0.9,1.5-2,1.9-3.2l-1.9-1.9C18.1,22.6,17.5,23.8,16.6,24.8z"/>
                
            </Jacket>
        );

        if (type === 'check-in') return (
            <Jacket height="24px" width="24px" viewBox="5 5 35 35" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>check-in</title>
                <polygon className="cls-1"
                         points="15.11 27.78 17.24 29.87 20.16 26.95 20.16 35.69 23.16 35.69 23.16 26.9 26.14 29.87 28.26 27.78 21.69 21.2 15.11 27.78"/>
                <path className="cls-1"
                      d="M23.26,4.47V2h-3V4.47H11.72V2h-3V4.47H3.48V27.91H7.87v-3H6.47V14.18H25.53V24.92h-1.4v3h4.39V4.47Zm2.27,6.72H6.47V7.46H8.74V9.63h3V7.46h8.56V9.63h3V7.46h2.27Z"
                      transform="translate(5.69 5.69)"/>
            </Jacket>
        );

        if (type === 'check-out') return (
            <Jacket height="24px" width="24px" viewBox="5 5 35 35" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>check-out</title>
                <polygon className="cls-1"
                         points="23.21 29.94 23.21 21.2 20.21 21.2 20.21 29.99 17.24 27.02 15.11 29.11 21.69 35.69 28.26 29.11 26.14 27.02 23.21 29.94"/>
                <path className="cls-1"
                      d="M23.26,4.47V2h-3V4.47H11.72V2h-3V4.47H3.48V27.91H7.87v-3H6.47V14.18H25.53V24.92h-1.4v3h4.39V4.47Zm2.27,6.72H6.47V7.46H8.74V9.63h3V7.46h8.56V9.63h3V7.46h2.27Z"
                      transform="translate(5.69 5.69)"/>

            </Jacket>
        );




        if (type === 'restaurant') return (
            <Jacket height="24px" width="24px" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>restaurant</title>
                <path className="cls-1"
                      d="M29.71,12.56V3H14.38v9.56a7.63,7.63,0,0,0,6.43,7.56v6.4H16.7V29H28V26.52h-4.7v-6.4A7.63,7.63,0,0,0,29.71,12.56ZM27.23,5.48v7.08a5.19,5.19,0,1,1-10.37,0V5.48Z"
                      transform="translate(-2.29 -3)"/>
                <path className="cls-1"
                      d="M9.61,11.72a1.57,1.57,0,0,1-1.18,1.51V3H6V13.23a1.57,1.57,0,0,1-1.18-1.51V3H2.29v8.72a4.11,4.11,0,0,0,3.66,4V29H8.43V15.77a4.1,4.1,0,0,0,3.65-4V3H9.61Z"
                      transform="translate(-2.29 -3)"/>
            </Jacket>
        );


        if (type === 'revenue') return (
            <Jacket height="24px" width="24px" viewBox="0 0 15 28" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>revenue</title>
                <path className="cls-1"
                      d="M23.87,20.18v.37A5.92,5.92,0,0,1,18,26.47h-.39V30H14.44V26.47H10V23.36h8a2.81,2.81,0,0,0,2.81-2.81v-.37A2.81,2.81,0,0,0,18,17.37h-3.9a5.92,5.92,0,0,1,0-11.84h.39V2h3.12V5.53H22V8.64h-8a2.81,2.81,0,1,0,0,5.62H18a5.92,5.92,0,0,1,5.92,5.92"
                      transform="translate(-8.13 -2)"/>
            </Jacket>
        );
        


        // Custom Icons from Joe
        if (type === 'upload') return (
            <Jacket height="24px" width="24px" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>upload</title>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <polygon className="cls-1" points="41.96 29.2 41.96 38.34 5.78 38.34 5.78 29.2 0 29.2 0 44.12 47.74 44.12 47.74 29.2 41.96 29.2"/>
                        <polygon className="cls-1" points="20.95 11.16 20.95 32.46 26.79 32.46 26.79 11.06 32.56 16.84 36.68 12.76 23.92 0 11.16 12.76 15.28 16.84 20.95 11.16"/>
                    </g>
                </g>
            </Jacket>
        );

        if (type === 'photo') return (
            <Jacket height="24px" width="24px" viewBox="0 0 62 54" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>photo</title>
                <path className="cls-1" d="M40.17,5.8l-3-5.8H21.43l-3,5.8H0V48.3H58.6V5.8ZM53,42.7H5.6V11.4H21.84l1.56-3L24.84,5.6h8.92L35.2,8.37l1.56,3H53Z"/><path className="cls-1" d="M29.3,12.35A13.49,13.49,0,1,0,42.79,25.84,13.49,13.49,0,0,0,29.3,12.35Zm0,21.37a7.89,7.89,0,1,1,7.89-7.88A7.89,7.89,0,0,1,29.3,33.72Z"/>
            </Jacket>
        );

        if (type === 'user') return (
            <Jacket height="24px" width="24px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>user</title>
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M35.5,31.25a16.82,16.82,0,1,0-17.22,0A26.88,26.88,0,0,0,0,56.72H5.8a21.09,21.09,0,1,1,42.17,0h5.8A26.89,26.89,0,0,0,35.5,31.25ZM26.89,5.8a11,11,0,1,1-11,11A11,11,0,0,1,26.89,5.8Z"/></g></g>
            </Jacket>
        );

        if (type === 'table') return (
            <Jacket height="24px" width="24px" viewBox="0 0 60 54" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>table</title>
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M0,0V5.59H5.31V43.1h5.58V15.56H50.77V43.1h5.59V5.59h5.57V0ZM50.77,5.59V10H10.89V5.59Z"/></g></g>
            </Jacket>
        );

        if (type === 'smile') return (
            <Jacket height="24px" width="24px" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>smile</title>
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M18.45,25.64A3.64,3.64,0,1,0,14.81,22,3.64,3.64,0,0,0,18.45,25.64Z"/><path className="cls-1" d="M36.7,25.64A3.64,3.64,0,1,0,33.06,22,3.64,3.64,0,0,0,36.7,25.64Z"/><path className="cls-1" d="M27.57,0A27.58,27.58,0,1,0,55.15,27.57,27.6,27.6,0,0,0,27.57,0Zm0,49.75A22.18,22.18,0,1,1,49.75,27.57,22.2,22.2,0,0,1,27.57,49.75Z"/><path className="cls-1" d="M39.16,31.29a12.17,12.17,0,0,1-23.22-.12l-4.77,1.47a17.16,17.16,0,0,0,32.75.17Z"/></g></g>
            </Jacket>
        );

        if (type === 'chair') return (
            <Jacket height="24px" width="24px" viewBox="0 0 68 60" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>chair</title>
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M27.1,15.66a2.49,2.49,0,1,0,2.49,2.49A2.49,2.49,0,0,0,27.1,15.66Z"/><path className="cls-1" d="M41,15.66a2.49,2.49,0,1,0,2.49,2.49A2.49,2.49,0,0,0,41,15.66Z"/><path className="cls-1" d="M64.4,20.3A10.45,10.45,0,0,0,56.92,18v-6.5A11.47,11.47,0,0,0,45.47,0H22.64A11.46,11.46,0,0,0,11.19,11.45V18A10.3,10.3,0,0,0,6.71,37.86v8.27A5.85,5.85,0,0,0,12.55,52h.88v6.19h5V52H49.71v6.19h5V52h.87a5.85,5.85,0,0,0,5.84-5.84V37.86a10.29,10.29,0,0,0,3-17.56ZM9.56,33.48a5.32,5.32,0,0,1-2.68-9.35,5.27,5.27,0,0,1,3.43-1.25,6.61,6.61,0,0,1,1,.08,5.65,5.65,0,0,1,4.36,5.7v7.4a4.73,4.73,0,0,0,4.72,4.72H47.71A4.74,4.74,0,0,0,52.44,36V28.6A5.61,5.61,0,0,1,56.77,23a5.33,5.33,0,1,1,1.78,10.51h0a2.49,2.49,0,0,0-2.13,2.46V46.13a.87.87,0,0,1-.87.86h-43a.87.87,0,0,1-.86-.86V35.94A2.5,2.5,0,0,0,9.56,33.48ZM51.94,19.74a10.82,10.82,0,0,0-4.47,8.86v7.2H20.64V28.66a10.94,10.94,0,0,0-4.48-8.88V11.45A6.48,6.48,0,0,1,22.64,5H45.47a6.47,6.47,0,0,1,6.47,6.47Z"/></g></g>
            </Jacket>
        );

        if (type === 'restroom') return (
            <Jacket height="24px" width="24px" viewBox="0 0 62 54" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>restroom</title>
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M50.31,25.31a9.72,9.72,0,1,0-9.72-9.72A9.73,9.73,0,0,0,50.31,25.31Zm-5.37-9.72A5.37,5.37,0,1,1,50.31,21,5.38,5.38,0,0,1,44.94,15.59Z"/><path className="cls-1" d="M11.74,25.31A9.72,9.72,0,1,0,2,15.59,9.73,9.73,0,0,0,11.74,25.31ZM6.37,15.59A5.37,5.37,0,1,1,11.74,21,5.38,5.38,0,0,1,6.37,15.59Z"/><path className="cls-1" d="M61.67,48.8l-5-20.1-.27-1.87H44.19l-.26,1.82-5,20.2-.37,2.48H62.05ZM56.94,47H43.68l4.24-15.8H52.7Z"/><rect className="cls-1" x="28.85" width="4.35" height="54.69"/><path className="cls-1" d="M.38,29.36l5,20.1.27,1.87H17.86l.26-1.82,5-20.19.37-2.49H0Zm4.73,1.82H18.38L14.14,47H9.35Z"/></g></g>
            </Jacket>
        );

        if (type === 'transit') return (
            <Jacket height="24px" width="24px" viewBox="0 0 54 66" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>transit</title>
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M15.19,51.37A6.39,6.39,0,1,0,8.8,45,6.4,6.4,0,0,0,15.19,51.37Zm0-8.59A2.21,2.21,0,1,1,13,45,2.22,2.22,0,0,1,15.19,42.78Z"/><path className="cls-1" d="M37.15,51.37A6.39,6.39,0,1,0,30.77,45,6.4,6.4,0,0,0,37.15,51.37Zm0-8.59A2.21,2.21,0,1,1,34.94,45,2.22,2.22,0,0,1,37.15,42.78Z"/><path className="cls-1" d="M40.66,6.73H37.59V2.59A2.59,2.59,0,0,0,35,0H17.33a2.59,2.59,0,0,0-2.58,2.59V6.73H11.68A11.69,11.69,0,0,0,0,18.41v30.4A11.71,11.71,0,0,0,8.7,60.09L4.13,68.7l-.06.12v.62H9.72l4.73-9H37.88l4.74,9h6l-5-9.34a11.7,11.7,0,0,0,8.69-11.29V18.41A11.69,11.69,0,0,0,40.66,6.73Zm-20.74,0V5.17h12.5V6.73ZM28.75,30V11.91H40.66a6.51,6.51,0,0,1,6.5,6.5V30ZM5.17,30V18.41a6.52,6.52,0,0,1,6.51-6.51h11.9V30Zm42,5.17V48.81a6.51,6.51,0,0,1-6.5,6.5h-29a6.51,6.51,0,0,1-6.51-6.5V35.2Z"/></g></g>
            </Jacket>
        );

        if (type === 'link') return (
            <Jacket height="24px" width="24px" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>link</title>
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M41.85,3.55a12.1,12.1,0,0,0-17.12,0L18,10.3a14.39,14.39,0,0,1,1.62-.09,15.68,15.68,0,0,1,4.94.8l3.83-3.83A7,7,0,0,1,38.21,17l-7.48,7.49a7,7,0,0,1-9.85,0,6.84,6.84,0,0,1-1.48-2.19,3.4,3.4,0,0,0-2.26,1l-2,2a12.54,12.54,0,0,0,2.1,2.82,12.12,12.12,0,0,0,17.12,0l7.49-7.5A12.09,12.09,0,0,0,41.85,3.55Z"/><path className="cls-1" d="M25.88,35.18a15.69,15.69,0,0,1-5-.82L17,38.21a7,7,0,1,1-9.84-9.84l7.49-7.49A7,7,0,0,1,26,23.07a3.47,3.47,0,0,0,2.27-1l2-2a12.24,12.24,0,0,0-2.1-2.82,12.1,12.1,0,0,0-17.12,0L3.55,24.73A12.11,12.11,0,0,0,20.67,41.85l6.74-6.74c-.5,0-1,.07-1.52.07Z"/></g></g>
            </Jacket>
        );

        if (type === 'cafe') return (
            <Jacket height="24px" width="24px" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>cafe</title>
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M28.92,0H21.4L10.21,11.18h7.64ZM47.74,14.73H0V34.62A21.74,21.74,0,0,0,43.33,37.1h4.41a11.19,11.19,0,1,0,0-22.37ZM38.92,34.62A17.18,17.18,0,0,1,9.57,46.77,17,17,0,0,1,4.52,34.62V19.25h34.3V34.62Zm8.82-2H43.55V19.25h4.19a6.61,6.61,0,0,1,6.67,6.66A6.7,6.7,0,0,1,47.74,32.58ZM42,0,30.86,11.18H23.23L34.41,0Z"/></g></g>
            </Jacket>
        );

        if (type === 'wifi') return (
            <Jacket height="24px" width="24px" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>wifi</title>
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M60.89,12.41a43.1,43.1,0,0,0-60.53,0L0,12.77l4.46,4.47.35-.35a36.77,36.77,0,0,1,51.62,0l.36.35,4.45-4.47Z"/><path className="cls-1" d="M7.83,19.86l-.35.35,4.46,4.48.35-.35a26.09,26.09,0,0,1,36.66,0l.36.35,4.46-4.48-.36-.35A32.46,32.46,0,0,0,7.83,19.86Z"/><path className="cls-1" d="M15.31,27.3l-.36.36,4.46,4.47.36-.35a15.47,15.47,0,0,1,21.71,0l.35.35,4.46-4.47-.35-.36A21.82,21.82,0,0,0,15.31,27.3Z"/><path className="cls-1" d="M22.79,34.77l-.35.36,8.18,8.15,8.19-8.15-.36-.36A11.15,11.15,0,0,0,22.79,34.77Z"/></g></g>
            </Jacket>
        );

        if (type === 'fitness') return (
            <Jacket height="24px" width="24px" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>fitness</title>
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><rect className="cls-1" y="5.76" width="5.76" height="31.72"/><polygon className="cls-1" points="43.17 18.75 14.39 18.75 14.39 0 8.64 0 8.64 43.24 14.39 43.24 14.39 24.49 43.17 24.49 43.17 43.24 48.93 43.24 48.93 0 43.17 0 43.17 18.75"/><rect className="cls-1" x="51.82" y="5.76" width="5.76" height="31.72"/></g></g>
            </Jacket>
        );

        if (type === 'food') return (
            <Jacket height="24px" width="24px" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>food</title>
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M50.14,19.57h0a18.17,18.17,0,0,0-10.87-6.63A13.83,13.83,0,0,0,42.84,2.23l-.17-2-2-.17a13.64,13.64,0,0,0-11.18,4,13.77,13.77,0,0,0-2.32,3.12A14.73,14.73,0,0,0,14.65.2h-.54V5.09h.54a9.78,9.78,0,0,1,9.66,8.36,17.18,17.18,0,0,0-6.83-.69A18.05,18.05,0,0,0,5,19.64,24,24,0,0,0,.23,37.74C1.79,49.81,11.17,59,21.55,59c.54,0,1.09,0,1.63-.08a17.91,17.91,0,0,0,4.37-1,17.84,17.84,0,0,0,4.33,1,13,13,0,0,0,1.61.07C44,59,53.31,49.65,54.79,37.75A24,24,0,0,0,50.14,19.57Zm-17.27-12h0a9.18,9.18,0,0,1,5-2.57,8.69,8.69,0,0,1-2.56,5,9.26,9.26,0,0,1-5,2.56A9.18,9.18,0,0,1,32.87,7.54ZM50,37.07C48.74,47.16,40.81,54.74,32.4,54a12.35,12.35,0,0,1-3.89-1l-1-.34-.92.42a12.22,12.22,0,0,1-3.84,1c-8.51.78-16.41-6.8-17.64-16.9A18.91,18.91,0,0,1,8.8,22.72,13.31,13.31,0,0,1,18,17.64c.38,0,.76-.05,1.14-.05a14.69,14.69,0,0,1,7.27,2l1.23.79,1.21-.78a13.69,13.69,0,0,1,8.42-2,13.33,13.33,0,0,1,9.18,5.08A19.21,19.21,0,0,1,50,37.07Z"/><path className="cls-1" d="M11.43,25a12.41,12.41,0,0,0-2.64,8h4.86a8,8,0,0,1,1.55-5,5.2,5.2,0,0,1,3.57-2V21.2l-.42,0A9.92,9.92,0,0,0,11.43,25Z"/></g></g>
            </Jacket>
        );

        if (type === 'chat') return (
            <Jacket height="24px" width="24px" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>chat</title>
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M27.6,55.2a27.45,27.45,0,0,0,13.46-3.51c.1,0,12.35,1.88,12.35,1.88S51.8,40.87,51.9,40.69A27.6,27.6,0,1,0,27.6,55.2Zm0-5.29A22.22,22.22,0,0,0,39.46,46.5c.12-.08,7.95.89,7.95.89s-.93-7.89-.83-8.06a22.32,22.32,0,1,0-19,10.58Zm-7-17a4.11,4.11,0,1,0-4.11-4.11A4.11,4.11,0,0,0,20.55,32.88Zm14.09,0a4.11,4.11,0,1,0-4.11-4.11A4.11,4.11,0,0,0,34.64,32.88Z"/></g></g>
            </Jacket>
        );

        if (type === 'info') return (
            <Jacket height="24px" width="24px" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>info</title>
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M24.3,0A24.3,24.3,0,1,0,48.6,24.3,24.3,24.3,0,0,0,24.3,0Zm0,42.9A18.6,18.6,0,1,1,42.9,24.3,18.62,18.62,0,0,1,24.3,42.9Z"/><path className="cls-1" d="M23.26,31.28a3.33,3.33,0,0,0-2.41,1,3.27,3.27,0,0,0-1,2.43,3.24,3.24,0,0,0,1,2.4,3.39,3.39,0,0,0,4.81,0,3.24,3.24,0,0,0,1-2.4,3.27,3.27,0,0,0-1-2.43A3.32,3.32,0,0,0,23.26,31.28Z"/><path className="cls-1" d="M30.49,12.52a8.85,8.85,0,0,0-6.17-2q-4.34,0-6.38,2.38a8.41,8.41,0,0,0-2,5.66h5.19q.42-3.19,3.4-3.19a3.13,3.13,0,0,1,2.26.74,2.5,2.5,0,0,1,.4,3.09,4.34,4.34,0,0,1-.83,1c-.31.27-.76.64-1.34,1.12s-1.07.93-1.47,1.32a8.9,8.9,0,0,0-2.36,3.58,13,13,0,0,0-.51,3.33h5.19v-.16a6.73,6.73,0,0,1,.47-1.79,6.54,6.54,0,0,1,1.34-2.19c.26-.28.76-.77,1.51-1.47s1.34-1.27,1.77-1.72a7.37,7.37,0,0,0,1.21-2,6.6,6.6,0,0,0,.57-2.72A6.45,6.45,0,0,0,30.49,12.52Z"/></g></g>
            </Jacket>
        );

        if (type === 'mail') return (
            <Jacket height="24px" width="24px" viewBox="0 0 54 62" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>mail</title>
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M0,0V45.5H58.6V0ZM5.8,15.1,17.25,24,5.8,32.88ZM29.3,33.35l7.32-5.69,15.51,12H6.47L22,27.66ZM41.35,24,52.8,15.1V32.88ZM52.8,5.8v2L29.3,26,5.8,7.76v-2Z"/></g></g>
            </Jacket>
        );

        if (type === 'locked') return (
            <Jacket height="24px" width="24px" viewBox="0 0 48 62" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>locked</title>
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M42.71,16.76A18.5,18.5,0,0,0,24.3,0h0A18.5,18.5,0,0,0,5.89,16.76H0v45.5H48.6V16.76ZM24.3,5.7A12.81,12.81,0,0,1,37,16.76H11.63A12.81,12.81,0,0,1,24.3,5.7ZM42.8,56.46H5.8V22.56h37Z"/><path className="cls-1" d="M21.4,39.64v9.13h5.8V39.64a5.14,5.14,0,1,0-5.8,0Z"/></g></g>
            </Jacket>
        );

        if (type === 'location') return (
            <Jacket height="24px" width="24px" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>location</title>
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M23.25,13.8a9.5,9.5,0,1,0,9.5,9.5A9.56,9.56,0,0,0,23.25,13.8Zm0,13.9a4.4,4.4,0,1,1,4.4-4.4A4.44,4.44,0,0,1,23.25,27.7Z"/><path className="cls-1" d="M39.65,6.8a23.3,23.3,0,0,0-32.9,0,23.42,23.42,0,0,0,0,32.9l16.4,16.4,16.4-16.4A23.15,23.15,0,0,0,39.65,6.8Zm-4.1,28.8L23.25,48,10.85,35.6a17.5,17.5,0,1,1,24.7,0Z"/></g></g>
            </Jacket>
        );

        if (type === 'calendar') return (
            <Jacket height="24px" width="24px" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>calendar</title>
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M38.4,4.8V0H32.6V4.8H16V0H10.2V4.8H0V50.3H48.6V4.8ZM10.2,10.6v4.8H16V10.6H32.6v4.8h5.8V10.6h4.4V19H5.8V10.6ZM5.8,44.5V24.8h37V44.5Z"/></g></g>
            </Jacket>
        );

        if (type === 'search') return (
            <Jacket height="24px" width="24px" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>search</title>
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M33.2,0A20.78,20.78,0,0,0,16.6,33.3L0,50l4.1,4.1L20.7,37.5A20.83,20.83,0,1,0,33.2,0Zm0,35.9a15,15,0,1,1,15-15A15,15,0,0,1,33.2,35.9Z"/></g></g>
            </Jacket>
        );

        if (type === 'phone') return (
            <Jacket height="24px" width="24px" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>phone</title>
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M49.47,42.82l-.17-.5L39.84,31.26,39.3,31a4,4,0,0,0-4.12.12l-.21.16c-.86.7-1.51,1.21-2.14,1.69s-1.52,1.17-2.55,2C27,34,18.51,24,18.06,20.61c.91-.79,1.58-1.34,2.28-1.91s1.38-1.12,2.32-1.92l.13-.12a3.89,3.89,0,0,0,.88-4.09L23.51,12l-.39-.46c-1.45-1.69-2.89-3.41-4.33-5.12S15.89,3,14.43,1.24L14.11.86,13.68.63A4.18,4.18,0,0,0,9.51.49L9.24.64,9,.84c-1.45,1.29-3,2.58-4.44,3.83L2.71,6.27l-.19.25C-1.61,12.16-1.86,24.11,11,39.25c9.41,11.06,18.27,14.89,24.77,14.89a13.31,13.31,0,0,0,6-1.33l.25-.13.22-.18c1.55-1.25,3.09-2.54,4.58-3.79l1.65-1.39.31-.31A4.08,4.08,0,0,0,49.47,42.82Zm-6.1,1.75C42,45.77,40.56,47,39.12,48.11c-6.35,2.93-16.84-3.94-24-12.36S2.8,15.79,6.71,10L8.08,8.78C9.16,7.86,10.27,6.92,11.37,6l3.29,3.91,3.24,3.85c-.34.29-.66.54-1,.79-.79.64-1.53,1.24-2.57,2.15a4.44,4.44,0,0,0-1.68,4.41c.73,5.75,10.56,17.38,16.18,19.14a4.46,4.46,0,0,0,4.71-.91c1-.88,1.77-1.45,2.55-2,.33-.25.67-.51,1-.81l6.64,7.77Z"/></g></g>
            </Jacket>
        );

        if (type === 'power') return (
            <Jacket height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>power_1</title>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path className="cls-1" d="M10.5,21A10.5,10.5,0,1,1,21,10.5,10.51,10.51,0,0,1,10.5,21Zm0-19A8.5,8.5,0,1,0,19,10.5,8.51,8.51,0,0,0,10.5,2Z"/>
                        <rect className="cls-1" x="6.5" y="7.5" width="2" height="6"/>
                        <rect className="cls-1" x="12.5" y="7.5" width="2" height="6"/>
                    </g>
                </g>
            </Jacket>
        );

        // Arrow icons from Nucleo
        if (type === 'big-arrow-left') return (
            <Jacket height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                {/* <title>Big Arrow Left</title> */}
                <path d="M1.293,11.293L9,3.586L10.414,5l-6,6H22c0.553,0,1,0.448,1,1s-0.447,1-1,1H4.414l6,6L9,20.414l-7.707-7.707 C0.902,12.316,0.902,11.684,1.293,11.293z" />
            </Jacket>
        );

        if (type === 'big-arrow-right') return (
            <Jacket height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                {/* <title>Big Arrow Right</title> */}
                <path d="M22.707,11.293L15,3.586L13.586,5l6,6H2c-0.553,0-1,0.448-1,1s0.447,1,1,1h17.586l-6,6L15,20.414 l7.707-7.707C23.098,12.316,23.098,11.684,22.707,11.293z" />
            </Jacket>
        );

        if (type === 'big-arrow-down') return (
            <Jacket height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                {/* <title>Big Arrow Down</title> */}
                <path d="M12.707,22.707L20.414,15L19,13.586l-6,6V2c0-0.553-0.448-1-1-1s-1,0.447-1,1v17.586l-6-6L3.586,15 l7.707,7.707C11.684,23.098,12.316,23.098,12.707,22.707z" />
            </Jacket>
        );

        if (type === 'small-arrow-right') return (
            <Jacket height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <path d="M7,23.414L5.586,22l10-10l-10-10L7,0.586l10.707,10.707c0.391,0.391,0.391,1.023,0,1.414L7,23.414z" />
            </Jacket>
        );

        if (type === 'small-arrow-left') return (
            <Jacket height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <path d="M17,23.414L6.293,12.707c-0.391-0.391-0.391-1.023,0-1.414L17,0.586L18.414,2l-10,10l10,10L17,23.414z" />
            </Jacket>
        );

        if (type === 'small-arrow-down') return (
            <Jacket height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <path d="M12,15c-0.256,0-0.512-0.098-0.707-0.293L6.586,10L8,8.586l4,4l4-4L17.414,10l-4.707,4.707 C12.512,14.902,12.256,15,12,15z" />
            </Jacket>
        );

        // Social icons from Nucleo
        if (type === 'instagram') return (
            <Jacket height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <g>
                    <path d="M12,2.162c3.204,0,3.584,0.012,4.849,0.07c1.366,0.062,2.633,0.336,3.608,1.311 c0.975,0.975,1.249,2.242,1.311,3.608c0.058,1.265,0.07,1.645,0.07,4.849s-0.012,3.584-0.07,4.849 c-0.062,1.366-0.336,2.633-1.311,3.608c-0.975,0.975-2.242,1.249-3.608,1.311c-1.265,0.058-1.645,0.07-4.849,0.07 s-3.584-0.012-4.849-0.07c-1.366-0.062-2.633-0.336-3.608-1.311c-0.975-0.975-1.249-2.242-1.311-3.608 c-0.058-1.265-0.07-1.645-0.07-4.849s0.012-3.584,0.07-4.849c0.062-1.366,0.336-2.633,1.311-3.608 c0.975-0.975,2.242-1.249,3.608-1.311C8.416,2.174,8.796,2.162,12,2.162 M12,0C8.741,0,8.332,0.014,7.052,0.072 c-1.95,0.089-3.663,0.567-5.038,1.942C0.639,3.389,0.161,5.102,0.072,7.052C0.014,8.332,0,8.741,0,12 c0,3.259,0.014,3.668,0.072,4.948c0.089,1.95,0.567,3.663,1.942,5.038c1.375,1.375,3.088,1.853,5.038,1.942 C8.332,23.986,8.741,24,12,24s3.668-0.014,4.948-0.072c1.95-0.089,3.663-0.567,5.038-1.942c1.375-1.375,1.853-3.088,1.942-5.038 C23.986,15.668,24,15.259,24,12s-0.014-3.668-0.072-4.948c-0.089-1.95-0.567-3.663-1.942-5.038 c-1.375-1.375-3.088-1.853-5.038-1.942C15.668,0.014,15.259,0,12,0L12,0z" />
                    <path d="M12,5.838c-3.403,0-6.162,2.759-6.162,6.162S8.597,18.162,12,18.162s6.162-2.759,6.162-6.162 S15.403,5.838,12,5.838z M12,16c-2.209,0-4-1.791-4-4s1.791-4,4-4s4,1.791,4,4S14.209,16,12,16z"/>
                    <circle cx="18.406" cy="5.594" r="1.44"/>
                </g>
            </Jacket>
        );

        if (type === 'twitter') return (
            <Jacket height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <g>
                    <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
                </g>
            </Jacket>
        );

        if (type === 'facebook') return (
            <Jacket height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <g>
                    <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                </g>
            </Jacket>
        );

        if (type === 'pinterest') return (
            <Jacket height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <g>
                    <path d="M12,0C5.4,0,0,5.4,0,12c0,5.1,3.2,9.4,7.6,11.2c-0.1-0.9-0.2-2.4,0-3.4c0.2-0.9,1.4-6,1.4-6S8.7,13,8.7,12 c0-1.7,1-2.9,2.2-2.9c1,0,1.5,0.8,1.5,1.7c0,1-0.7,2.6-1,4c-0.3,1.2,0.6,2.2,1.8,2.2c2.1,0,3.8-2.2,3.8-5.5c0-2.9-2.1-4.9-5-4.9 c-3.4,0-5.4,2.6-5.4,5.2c0,1,0.4,2.1,0.9,2.7c0.1,0.1,0.1,0.2,0.1,0.3c-0.1,0.4-0.3,1.2-0.3,1.4c-0.1,0.2-0.2,0.3-0.4,0.2 c-1.5-0.7-2.4-2.9-2.4-4.6c0-3.8,2.8-7.3,7.9-7.3c4.2,0,7.4,3,7.4,6.9c0,4.1-2.6,7.5-6.2,7.5c-1.2,0-2.4-0.6-2.8-1.4 c0,0-0.6,2.3-0.7,2.9c-0.3,1-1,2.3-1.5,3.1C9.6,23.8,10.8,24,12,24c6.6,0,12-5.4,12-12C24,5.4,18.6,0,12,0z" />
                </g>
            </Jacket>
        );

        // Util icons from Nucleo
        if (type === 'close') return (
            <Jacket height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <g>
                    <path d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z" />
                </g>
            </Jacket>
        );

        if (type === 'star') return (
            <Jacket height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <path className="cls-1" d="M18,21.21l-6.89-3.63L4.26,21.21l1.32-7.68L0,8.1,7.7,7l3.45-7,3.44,7L22.3,8.1l-5.58,5.43Z"/>
            </Jacket>
        );

        if (type === 'star-empty') return (
            <Jacket height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <path className="cls-1" d="M18,21.21l-6.89-3.63L4.26,21.21l1.32-7.68L0,8.1,7.7,7l3.45-7,3.44,7L22.3,8.1l-5.58,5.43Zm-6.89-5.89,4.23,2.23-.8-4.71L18,9.5l-4.73-.69L11.15,4.52,9,8.81,4.3,9.5l3.42,3.34-.8,4.71Z"/>
            </Jacket>
        );

        if (type === 'checked') return (
            <Jacket height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <g>
                    <path d="M12,0A12,12,0,1,0,24,12,12.035,12.035,0,0,0,12,0ZM10,17.414,4.586,12,6,10.586l4,4,8-8L19.414,8Z" />
                </g>
            </Jacket>
        );

        if (type === 'heart') return (
            <Jacket height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>heart</title>
                <path d="M21.95,3.051C20.627,1.729,18.87,1,17,1s-3.627,0.729-4.949,2.05C12.034,3.067,12.017,3.084,12,3.102 c-0.017-0.018-0.033-0.034-0.05-0.051C10.627,1.729,8.87,1,7,1S3.373,1.729,2.05,3.051S0,6.13,0,8s0.728,3.627,2.05,4.949l9.95,9.95 l9.95-9.95C23.272,11.627,24,9.87,24,8C24,6.131,23.272,4.373,21.95,3.051z" />
            </Jacket>
        );

        if (type === 'heart-empty') return (
            <Jacket height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>heart</title>
                <g stroke={this.props.hasGradient?"rgb(252, 0, 132)":"#ffffff"} strokeLinecap="round" strokeWidth="2">
                    <path d="M21.243,3.757 c-2.343-2.343-6.142-2.343-8.485,0c-0.289,0.289-0.54,0.6-0.757,0.927c-0.217-0.327-0.469-0.639-0.757-0.927 c-2.343-2.343-6.142-2.343-8.485,0c-2.343,2.343-2.343,6.142,0,8.485L12,21.485l9.243-9.243C23.586,9.899,23.586,6.1,21.243,3.757z" fill="none" stroke={this.props.hasGradient?"rgb(252, 0, 132)":"#ffffff"} />
                </g>
            </Jacket>
        );

        if (type === 'eye') return (
            <Jacket height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>eye</title>
                <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>
            </Jacket>
        );

        if (type === 'settings') return (
            <Jacket height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>settings</title>
                <path d="M22,10H20.769a8.943,8.943,0,0,0-1.156-2.785l.872-.872a2,2,0,0,0-2.828-2.828l-.872.872A8.943,8.943,0,0,0,14,3.231V2a2,2,0,0,0-4,0V3.231A8.943,8.943,0,0,0,7.215,4.387l-.872-.872A2,2,0,0,0,3.515,6.343l.872.872A8.943,8.943,0,0,0,3.231,10H2a2,2,0,0,0,0,4H3.231a8.943,8.943,0,0,0,1.156,2.785l-.872.872a2,2,0,0,0,2.828,2.828l.872-.872A8.943,8.943,0,0,0,10,20.769V22a2,2,0,0,0,4,0V20.769a8.943,8.943,0,0,0,2.785-1.156l.872.872a2,2,0,0,0,2.828-2.828l-.872-.872A8.943,8.943,0,0,0,20.769,14H22a2,2,0,0,0,0-4ZM12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16Z" />
            </Jacket>
        );

        // Coloured Logos
        if (type === 'slack') return (
            <Jacket height="24px" width="24px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" className={className} hasGradient={this.props.hasGradient}>
                <title>slack</title>
                <g>
                    <path d="M18.133,2a4.4,4.4,0,1,0,0,8.8h4.4V6.4a4.4,4.4,0,0,0-4.4-4.4" fill="#36c5f0"/>
                    <path d="M18.133,13.733H6.4a4.4,4.4,0,1,0,0,8.8H18.134a4.4,4.4,0,0,0,0-8.8" fill="#36c5f0"/>
                    <path d="M46,18.134a4.4,4.4,0,1,0-8.8,0v4.4h4.4a4.4,4.4,0,0,0,4.4-4.4" fill="#2eb67d"/>
                    <path d="M34.267,18.134V6.4a4.4,4.4,0,1,0-8.8,0V18.133a4.4,4.4,0,1,0,8.8,0" fill="#2eb67d"/>
                    <path d="M29.866,46a4.4,4.4,0,1,0,0-8.8h-4.4v4.4a4.4,4.4,0,0,0,4.4,4.4" fill="#ecb22e"/>
                    <path d="M29.866,34.267H41.6a4.4,4.4,0,1,0,0-8.8H29.866a4.4,4.4,0,0,0,0,8.8h0" fill="#ecb22e"/>
                    <path d="M2,29.866a4.4,4.4,0,0,0,4.4,4.4h0a4.4,4.4,0,0,0,4.4-4.4v-4.4H6.4a4.4,4.4,0,0,0-4.4,4.4" fill="#e01e5a"/>
                    <path d="M13.733,29.866V41.6a4.4,4.4,0,1,0,8.8,0V29.866a4.4,4.4,0,0,0-4.4-4.4h0a4.4,4.4,0,0,0-4.4,4.4" fill="#e01e5a"/>
                </g>
            </Jacket>
        );

        console.error(`You've not added the correct 'type' prop to your <Icon /> component`);
        console.warn(`Remember to add "type='YOUR_ICON_NAME'" to your <Icon /> props or else your icon wont display`);
        return null;
    }
}

export default Icon;