// Imports
// ------
import React from 'react';
import Button from 'components/global/buttons/primary';
import Option from './option';
import Notification from 'components/global/notification/notification';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket, Textarea } from './checkoutformStyles';

// States
// ------
import { notificationState } from 'states/notifications';
import {withRouter} from "react-router-dom";

// Component
// ------
class SidebarCheckoutForm extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            message: (props.activeBooking && props.activeBooking.review) ? props.activeBooking.review.message : ''
        }
    }
    
    onChange = (e) => {
        this.setState({message: e.target.value})
    }
    
    handleCheckout = () => {
        // alert("Checking out")
        
        this.props.handleCheckout(this.state.message);

        // notificationState.bookingsOpen = !notificationState.bookingsOpen;
    }

    backToLocations = () => {
        this.props.history.push("/lokations");
    }

    formOptions = () => {
        if (this.props.reviewReceived && this.props.activeBooking.review) {
            return this.props.activeBooking.review.checks;
        }
        return this.props.options
    }

    render() {
        
        const reviewSuccess = notificationState.bookingsOpen;

        return (
            <>
                {reviewSuccess &&
                    <Notification
                        bookings
                        isOpen={reviewSuccess}
                    />
                }

                <Jacket>
                    <Div mpad>
                        <Row isExpanded isCollapsed>
                            <Column small={12}>
                                {this.formOptions().map((block, index) =>
                                    <Option
                                        key={index}
                                        icon={block.icon}
                                        text={block.text}
                                        isSelected={block.checked}
                                        onClick={e => this.props.handleCheckoutOptions(index)}

                                        disabled={this.props.reviewReceived}
                                    />
                                )}
                            </Column>
                            <Column small={12}>
                                <Row isExpanded>
                                    <Column small={12}>
                                        <Textarea placeholder="Tell us more..." value={this.state.message} onChange={this.onChange} disabled={this.props.reviewReceived} />
                                    </Column>
                                </Row>
                            </Column>
                            <Column small={12}>
                                <Row isExpanded>
                                    <Column small={12}>
                                        {this.props.reviewReceived ?
                                            <Button button fullWidth type="ghost" textColor="dark"
                                                    text="Return to Lokations" onClick={this.backToLocations} />
                                            :
                                            <>
                                                <Button button fullWidth type="full" text="Check-Out"
                                                      onClick={this.handleCheckout}/>
                                                <Button button fullWidth type="ghost" textColor="dark" text="Cancel"
                                                    onClick={this.props.cancelCheckout}/>
                                            </>
                                        }
                                    </Column>
                                </Row>
                            </Column>
                        </Row>
                    </Div>
                </Jacket>
            </>
        )
    }
}

export default withRouter(SidebarCheckoutForm)