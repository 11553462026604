
import React from 'react';

import { Jacket, G, Rect } from "./loaderStyles";

class Loader extends React.PureComponent {
    
    constructor(props) {
        super(props);
        this.state = {
            frame: 0
        }
    }
    
    componentDidMount() {
        
        this.r = setInterval(() => {
            this.setState({frame: (this.state.frame + 1) % 9})
        }, 100)
        
    }
    
    componentWillUnmount() {
        clearInterval(this.r);
    }

    render() {
        const { frame } = this.state;
        return <div style={{width: "100%", textAlign: "center"}}><Jacket height="50px" width="50px" viewBox="0 0 108 108" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <clipPath id="clip-path">
                        { frame === 0 && <path className="cls-1" d="M42.5,60.91l9.76-9.76a2.87,2.87,0,0,0,0-4.06h0L42.5,56.85Z"/> }
                        { frame === 1 && <path className="cls-1" d="M41,50.75H54.79a2.87,2.87,0,0,0,2.87-2.87H43.85Z"/> }
                        { frame === 2 && <path className="cls-1" d="M47.09,42.5l9.76,9.76a2.87,2.87,0,0,0,4.06,0h0L51.15,42.5Z"/> }
                        { frame === 3 && <path className="cls-1" d="M57.25,41V54.79a2.87,2.87,0,0,0,2.87,2.87h0V43.85Z"/> }
                        { frame === 4 && <path className="cls-1" d="M42.5,47.09l9.76,9.76a2.87,2.87,0,0,1,0,4.06h0L42.5,51.15Z"/> }
                        { frame === 5 && <path className="cls-1" d="M50.75,41V54.79a2.87,2.87,0,0,1-2.87,2.87h0V43.85Z"/> }
                        { frame === 6 && <path className="cls-1" d="M60.91,42.5l-9.76,9.76a2.87,2.87,0,0,1-4.06,0h0l9.76-9.76Z"/> }
                        { frame === 7 && <path className="cls-1" d="M50.75,67V53.21a2.87,2.87,0,0,0-2.87-2.87h0V64.15Z"/> }
                        { frame === 8 && <path className="cls-1" d="M42.5,60.91l9.76-9.76a2.87,2.87,0,0,0,0-4.06h0L42.5,56.85Z"/> }
                    </clipPath>
                    <linearGradient id="linear-gradient" y1="108" x2="108" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stopColor="#fc0084"/>
                        <stop offset="1" stopColor="#fe4902"/>
                    </linearGradient>
                    <clipPath id="clip-path-2">
                        { frame === 0 && <path className="cls-1" d="M65.5,60.91l-9.76-9.76a2.87,2.87,0,0,1,0-4.06h0l9.76,9.76Z"/> }
                        { frame === 1 && <path className="cls-1" d="M57.25,67V53.21a2.87,2.87,0,0,1,2.87-2.87h0V64.15Z"/> }
                        { frame === 2 && <path className="cls-1" d="M47.09,65.5l9.76-9.76a2.87,2.87,0,0,1,4.06,0h0L51.15,65.5Z"/> }
                        { frame === 3 && <path className="cls-1" d="M41,57.25H54.79a2.87,2.87,0,0,1,2.87,2.87H43.85Z"/> }
                        { frame === 4 && <path className="cls-1" d="M65.5,47.09l-9.76,9.76a2.87,2.87,0,0,0,0,4.06h0l9.76-9.76Z"/> }
                        { frame === 5 && <path className="cls-1" d="M67,57.25H53.21a2.87,2.87,0,0,0-2.87,2.87H64.15Z"/> }
                        { frame === 6 && <path className="cls-1" d="M60.91,65.5l-9.76-9.76a2.87,2.87,0,0,0-4.06,0h0l9.76,9.76Z"/> }
                        { frame === 7 && <path className="cls-1" d="M67,50.75H53.21a2.87,2.87,0,0,1-2.87-2.87H64.15Z"/> }
                        { frame === 8 && <path className="cls-1" d="M65.5,60.91l-9.76-9.76a2.87,2.87,0,0,1,0-4.06h0l9.76,9.76Z"/> }
                    </clipPath>
                    <clipPath id="clip-path-3">
                        { frame === 0 && <path className="cls-1"  d="M54,30.4A23.6,23.6,0,1,1,30.4,54,23.63,23.63,0,0,1,54,30.4M54,27A27,27,0,1,0,81,54,27,27,0,0,0,54,27Z"/> }
                        { frame > 0 && <path className="cls-1" d="M54,30.4A23.6,23.6,0,1,1,30.4,54,23.63,23.63,0,0,1,54,30.4m4.68,50.2A27,27,0,0,0,54,27h0a27,27,0,0,0,0,54,27.71,27.71,0,0,0,4.68-.4"/> }
                    </clipPath>
                    <clipPath id="clip-path-4">
                        {/**/}
                        {frame === 1 && <path className="cls-1"  d="M53.5,27v3.4H54a23.52,23.52,0,0,1,16.55,6.8L73,34.79A26.87,26.87,0,0,0,54,27Z"/> }
                        {frame === 2 && <path className="cls-1" d="M54.5,27v3.4A23.63,23.63,0,0,1,77.58,53.25H81A27,27,0,0,0,54.5,27Z"/> }
                        {frame === 3 && <path className="cls-1"  d="M77.6,54a23.53,23.53,0,0,1-6.92,16.68l2.41,2.41A27,27,0,0,0,54,27v3.4A23.63,23.63,0,0,1,77.6,54Z"/> }
                        {frame === 4 && <path className="cls-1" d="M54.5,27v3.4a23.6,23.6,0,0,1,0,47.18V81a27,27,0,0,0,0-54Z"/> }
                        {frame === 5 && <path className="cls-1" d="M54.5,27v3.4A23.6,23.6,0,1,1,37.7,71.05l-2.41,2.41A27,27,0,1,0,54.5,27Z"/> }
                        {frame === 6 && <path className="cls-1" d="M54,27h-.5v3.4H54A23.6,23.6,0,1,1,30.4,54c0-.25,0-.5,0-.75H27c0,.25,0,.5,0,.75A27,27,0,0,0,49.32,80.6,27.71,27.71,0,0,0,54,81a27,27,0,0,0,0-54Z"/> }
                        {frame === 7 && <path className="cls-1" d="M54,81a27,27,0,0,0,0-54v3.4a23.63,23.63,0,1,1-16.68,6.92l-2.41-2.41A27,27,0,0,0,54,81Z"/> }
                        
                    </clipPath>

                </defs>
                <title>
                    Asset 10</title>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <G cls2 className="cls-2">
                            <Rect cls3 className="cls-3" width="108" height="108"/>
                        </G>
                        <G cls4 className="cls-4">
                            <Rect cls3 className="cls-3" width="108" height="108"/>
                        </G>
                        <G cls5={frame!==8} className="cls-5">
                            <G cls6 className="cls-6">
                                <Rect cls3 className="cls-3" width="108" height="108"/>
                            </G>
                        </G>
                        <G cls7 className="cls-7">
                            <Rect cls3 className="cls-3" width="108" height="108"/>
                        </G>
                    </g>
                </g>
            
        </Jacket></div>;
    }
    
}


export default Loader;