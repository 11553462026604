// Imports
// ------
import React from 'react';
import Body from 'utils/bodylock';
import Date from './date/date';
import Time from './time/time';
import Seats from './seats/seats';
import { observer } from 'mobx-react';

// Styles
// ------
import { Jacket, Inner, Save, SaveText } from './filterModalStyles';

// States
// ------
import { lokationFilterState } from 'states/lokationFilters';
import { scheduleFilters } from 'states/lokationSidebar';

// Component
// ------
class LokationFilterModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isActive: lokationFilterState.isOpen,
            changed: false,
            current: 0,
        }
    }

    stateCheck = () => {
        if (lokationFilterState.isOpen === true) {
            Body.lock();
        }

        if (lokationFilterState.isOpen === false) {
            Body.unlock();
        }
    }

    handleClose = () => {
        this.setState({changed: false})
        lokationFilterState.isOpen = !lokationFilterState.isOpen;
    }

    handleSave = () => {
        this.setState({changed: false})
        lokationFilterState.isOpen = !lokationFilterState.isOpen;
    }
    
    onDateChange = () => {
        this.setState({current: 1, changed: true})
        this.props.onChange();
    }

    onTimeChange = () => {
        if (scheduleFilters.fulldayPass === true || (scheduleFilters.timeStart > -1 && scheduleFilters.timeEnd > -1)) {
            this.setState({current: 2, changed: true})
        }
        this.props.onChange();
    }

    onSeatChange = () => {
        this.setState({current: -1, changed: true})
        this.props.onChange();
    }

    onToggleOpen = (index) => {
        this.setState({current: index});
    }

    render() {
        const checker = lokationFilterState.isOpen;
        const current = this.state.current;

        return (
            <Jacket isOpen={checker}>
                <Inner>
                    {/*<Close onClick={this.handleClose}>*/}
                    {/*    <Icon type="close" />*/}
                    {/*</Close>*/}

                    <Date open={current === 0} onToggleOpen={this.onToggleOpen} onChange={this.onDateChange} />
                    <Time open={current === 1} onToggleOpen={this.onToggleOpen} location={this.props.location} onChange={this.props.onChange} onNext={this.onTimeChange} />
                    <Seats open={current === 2} onToggleOpen={this.onToggleOpen} onChange={this.onSeatChange} />

                    {/*{(scheduleFilters.date || scheduleFilters.fulldayPass === true || scheduleFilters.timeStart || scheduleFilters.timeEnd || scheduleFilters.seats) ?*/}
                        <Save onClick={this.handleSave}>
                            <SaveText>{this.state.changed ? 'Search' : 'Close'}</SaveText>
                        </Save>
                    {/* :*/}
                    {/*     <Save onClick={this.handleClose}>*/}
                    {/*         <SaveText>Done</SaveText>*/}
                    {/*     </Save>*/}
                    {/* }*/}
                </Inner>
            </Jacket>
        )
    }

    componentDidUpdate() {
        this.stateCheck();
    }

    componentWillUnmount() {
        this.stateCheck();
    }
}

export default observer(LokationFilterModal);