// Imports
// ------
import React from 'react';
import Button from 'components/global/buttons/primary';
import { Row, Column } from 'components/global/theme/grid/grid';
import { Div } from 'components/global/theme/reusables/reusables';

// Styles
// ------
import { Jacket, Label, Total, Warning, Return } from './booknowStyles';

// Component
// ------
export default class SidebarBookNow extends React.Component {
    

    render() {
        return (
            <Jacket>
                {this.props.hasNoResults &&
                <Row isExpanded>
                    <Column>
                        <Warning>There is no availabilities for this selected time. Please select a different time or return to lokations to select a different lokation.</Warning>
                    </Column>
                </Row>
                }
                { this.props.hasNoResults === false && <Row isExpanded>
                    <Column>
                        <Label>Total</Label>
                    </Column>
                    <Column>
                        <Div relative text="right">
                            <Total>${this.props.totalCost}</Total>
                        </Div>
                    </Column>
                </Row> }
                <Row isExpanded>
                    <Column>
                        <Button disabled={this.props.hasNoResults} button onClick={this.props.handleStartBooking} type="full" text="Book Now" />
                        {this.props.hasNoResults &&
                        <Return>
                            <Button navlink fullWidth type="ghost" textColor="dark" text="Return to Lokations" href="/lokations/" align="center" />
                        </Return>
                        }
                    </Column>
                </Row>
            </Jacket>
        )
    }
}