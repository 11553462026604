// Imports
// ------
import React from 'react';
import Input from 'components/global/formfields/input';
import PhoneInput from 'react-phone-number-input';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket, Label, Phone } from './fieldStyles';
import 'react-phone-number-input/style.css'
import {Switch} from "../../notifications/list/listStyles";

// Component
// ------
export default class ProfileField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        }
    }

    handlSwitch = () => {
        this.setState({value: !this.state.value}, () => {
            this.props.onChange({target:{name:this.props.name, value: this.state.value}})
        })
    }
    
    render() {
        return (
            <Jacket>
                <Div mpad>
                    <Row isExpanded>
                        <Column small={12} medium={10} pushOnMedium={1}>
                            <Row isExpanded isCenter>
                                <Column small={4} medium={8}>
                                    <Label>{this.props.label}</Label>
                                </Column>
                                <Column small={8} medium={4} block={this.props.type === 'checkbox'}>
                                    {this.props.type === "phone" ?
                                        <Phone>
                                            <PhoneInput
                                                country="US"
                                                placeholder="Enter phone number"
                                                value={this.state.value}
                                                onChange={ value => this.setState({value}, () => this.props.onChange({target:{name:this.props.name, value: value}}))}
                                            />
                                        </Phone>
                                        :
                                        (this.props.type === 'checkbox' ?
                                            <Switch nomargin name={this.props.name} onClick={this.handlSwitch} isActive={this.state.value} />
                                            
                                            :
                                        <Input
                                            type={this.props.type}
                                            talign="right"
                                            required={this.props.required}
                                            placeholder={this.props.placeholder}
                                            value={this.props.value}
                                            name={this.props.name}
                                            id={this.props.id}
                                            className={this.props.className}
                                            onChange={this.props.onChange}
                                            disabled={this.props.disabled}
                                            transparent
                                            light
                                            hasToggle={this.props.hasToggle}
                                            negativeMargin={this.props.negativeMargin}
                                        />)
                                    }
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                </Div>
            </Jacket>
        )
    }
}