// Imports
// ------
import React from 'react';
import Button from 'components/global/buttons/primary';
import { scheduleFilters } from 'states/lokationSidebar';
import { Link } from 'react-router-dom';

// Styles
// ------
import { Jacket, Return } from './bookctaStyles';

// Component
// ------
export default class BookCta extends React.Component {
    handleSubmit = () => {
        // console.log(this.props.bookNowRef);
        // window.scrollTo(0, this.props.bookNowRef.current.offsetTop)
    }


    render() {
        return (
            <Jacket>
                {scheduleFilters.date ?
                    <>
                        <Button button type="full" onClick={this.handleSubmit} text="Book Now" />
                    </>
                :
                    <>
                        <Button button type="ghost" onClick={this.handleSubmit} text="Add Booking Details" />
                    </>
                }

                <Return>
                    <Link to="/lokations">Return to Lokations</Link>
                </Return>
            </Jacket>
        )
    }
}