// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import Button from 'components/global/buttons/primary';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket, PopupJacket, Close, TextWrapper, Text } from './popupStyles';
import {AuthUserContext, withAuthentication} from "../../../lib/Session";
import {compose} from "recompose";
import {withFirebase} from "../../../lib/Firebase";

// Component
// ------
class Popup extends React.Component {

    accept = (uid) => {
        this.props.firebase.user(uid).set({policyAccepted: true}, {merge: true});
    }
    
    render() {

        return (
            <AuthUserContext.Consumer>

                {authUser => (
            <Jacket isOpen={authUser && authUser.policyAccepted!==true}>
                <PopupJacket>
                    <Close onClick={e => this.accept(authUser.uid)}>
                        <Icon type="close" />
                    </Close>

                    <Row isExpanded isCollapsed>
                        <Column small={12}>
                            <Row isExpanded>
                                <Column small={12} large={7} pushOnLarge={1}>
                                    <TextWrapper>
                                        <Text>We use technical and analytic cookies to ensure that we give you the best experience on our website.</Text>
                                        <Button navlink type="text" href="/" textColor="light" align="left" text="More info" />
                                    </TextWrapper>
                                </Column>
                                <Column small={12} large={3} pushOnLarge={1}>
                                    <Button button type="ghost-light" textColor="light" text="Accept" onClick={e => this.accept(authUser.uid)} />
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                </PopupJacket>
            </Jacket>
                )}
            </AuthUserContext.Consumer>
        )
    }
}


export default compose(
    withFirebase,
    withAuthentication
)(Popup);
