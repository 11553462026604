// Imports
// ------
import styled from "styled-components";
import { Section, H2, Span } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    z-index: 899;
    display: block;
    padding-top: 6rem;
`;

export const Title = styled(H2)`
    display: block;
    margin-bottom: 2rem;
    
    color: ${props => props.theme.bc3};
`;

export const Loadmore = styled.button`
    position: relative;
    display: inline-block;

    background: none;
    cursor: pointer;
`;

export const Label = styled(Span)`
    display: inline-block;
    vertical-align: middle;

    color: ${props => props.theme.bc3};

    + svg {
        display: inline-block;
        vertical-align: middle;
    }
`;