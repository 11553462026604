// Imports
// --------------
import styled from "styled-components";
import { containerStyles } from './containerStyles';
import { imageStyles } from './imageStyles';
import { h1Styles, h2Styles, h3Styles, h4Styles, h5Styles, pStyles, spanStyles, emStyles, anchorStyles } from "./typographyStyles";
import { buttonStyles } from "./buttonStyles";
import { listStyles, listItemStyles } from "./listStyles";

// --------------
// 1. Containers
// --------------
export const Section = styled.section` ${containerStyles} `;
export const Div = styled.div` ${containerStyles} `;
export const Main = styled.main` ${containerStyles} `;
export const Article = styled.article` ${containerStyles} `;
export const Header = styled.header` ${containerStyles} `;
export const Footer = styled.footer` ${containerStyles} `;

// --------------
// 2. Images
// --------------
export const Img = styled.img` ${imageStyles} `;

// --------------
// 3. Typography
// --------------
export const H1 = styled.h1` ${h1Styles} `;
export const H2 = styled.h2` ${h2Styles} `;
export const H3 = styled.h3` ${h3Styles} `;
export const H4 = styled.h4` ${h4Styles} `;
export const H5 = styled.h5` ${h5Styles} `;
export const P = styled.p` ${pStyles} `;
export const Em = styled.em` ${emStyles} `;
export const Span = styled.span` ${spanStyles} `;
export const Anchorlink = styled.a` ${anchorStyles} `;

// --------------
// 4. Buttons
// --------------
export const Button = styled.button` ${buttonStyles} `;

// --------------
// 5. List
// --------------
export const List = styled.ul` ${listStyles} `;
export const ListItem = styled.li` ${listItemStyles} `;

// --------------
// 6. Misc
// --------------
export const Gradient = 'linear-gradient(20deg, rgba(252,0,132,1) 0%, rgba(254,73,2,1) 100%);';
export const GradientReverse = 'linear-gradient(20deg, rgba(254,73,2,1) 0%, rgba(252,0,132,1) 100%);';
export const GradientIcon = 'fill: url("data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\'><linearGradient id=\'grad\'><stop offset=\'0%\' stop-color=\'rgb(252, 0, 132)\'/><stop offset=\'100%\' stop-color=\'rgb(254, 73, 2)\'/></linearGradient></svg>#grad") #fc0084;';