// Imports
// ------
import styled from "styled-components";
import { Section, H3, Span } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    z-index: 10;
    display: block;
`;

export const Title = styled(H3)``;

export const Text = styled(Span)`
    display: block;
    margin-top: 1rem;
    margin-bottom: 2rem;
`;