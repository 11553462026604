// Imports
// ------
import React from 'react';

// Styles
// ------
import { Label, Select } from './inputStyles';
import Icon from "../icons/icons";

// Component
// ------
export default class SelectType extends React.PureComponent {
    


    render() {
        return (
            <>
                {this.props.label &&
                <Label
                    htmlFor={this.props.name}
                    light={this.props.light}
                    dark={this.props.dark}
                >
                    {this.props.label}
                    {this.props.isIcon &&
                    <span><Icon hasGradient type={this.props.value} /></span>
                    }
                </Label>
                }
                <Select
                    required={this.props.required}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    name={this.props.name}
                    className={this.props.className}
                    light={this.props.light}
                    dark={this.props.dark}
                    transparent={this.props.transparent}
                    talign={this.props.talign}
                    onChange={this.props.onChange}
                    disabled={this.props.disabled}
                    autocomplete={this.props.autocomplete}
                >
                    <option value="">--select--</option>
                    {
                        this.props.values.map(value => <option selected={value===this.props.value} value={value}>{value}</option>)
                    }
                    
                </Select>
            </>
        )

    }
}