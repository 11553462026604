import React, {Component} from 'react';

import {AuthUserContext} from '../lib/Session';
import {withFirebase} from '../lib/Firebase';
import LokationsHero from "../components/lokations/hero/hero";
import Background from "../components/global/background/background";
import {themeStyles} from "../components/global/theme/theme";
import {isToday, isTomorrow} from "../lib/Util/TimeUtil";
import {notificationState} from "../states/notifications";


class Booking extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            location: null,
            booking: null,
            shouldReview: false,
            
            canCheckIn: false,
            canCancelCheckIn: false,

            reminderSubtitle: null,
            reminderTitle: null,
            
            review: {
                rating: -1,
                checks: [
                    {
                        icon: "chair",
                        text: "Vacant Seat(s) Upon Arrival",
                        checked: false,
                    },
                    {
                        icon: "wifi",
                        text: "Strong WiFi Connection",
                        checked: false,
                    },
                    {
                        icon: "power",
                        text: "Accessible Power Outlet",
                        checked: false,
                    },
                    {
                        icon: "star-empty",
                        text: "Great Lokation Perks",
                        checked: false,
                    },
                    {
                        icon: "calendar",
                        text: "Would Book Lokation Again",
                        checked: false,
                    },
                ],

                message: ''

            }
        };
    }

    componentDidMount = () => {

        this.onListenForBooking();
        let check = () => {
            if (this.state.booking && isToday(this.state.booking.date) && this.state.booking.status === 'started') {
                let now = (new Date().getHours() * 60) + (new Date().getMinutes());
                if (now >= this.state.booking.end_time - 15) {
                    let diff = this.state.booking.end_time - now;
                    this.setState({
                        reminderTitle: "Reminder",
                        reminderSubtitle: diff > 1 ? `${diff} minutes remaining` : 'Out of time'
                    });
                }
            }
        };
        this.timerId = setInterval(check, 10000);
        check();
    }

    onListenForBooking = async () => {
        this.setState({ loading: true });

        this.unsubscribe = this.props.firebase
            .booking(this.props.match.params.booking)
            .onSnapshot(snapshot => {
                if (snapshot) {
                    let booking = { ...snapshot.data(), uid: this.props.match.params.booking };
                    this.setState({
                        booking: booking,
                        loading: false,
                    }, e => {
                        this.getLocation()
                        this.updateCheckinStatus();
                    })
                } else {
                    this.setState({
                        booking: null,
                        loading: false,
                    });
                }
            });


    };
    
    updateCheckinStatus = () => {
        let {booking} = this.state;
        
        let canCheckIn = false;
        let canCancelCheckIn = true;
        if (booking) {
            if (isToday(booking.date)) {
                if (booking.is_table) {
                    let time = (new Date().getMinutes()) + (new Date().getHours() * 60);
                    canCheckIn = time >= booking.start_time - 15; // I can start checking in earlier.

                } else {
                    canCheckIn = true;
                }

                canCancelCheckIn = false; // cannot cancel on the same day
            } else if (booking.is_table === false) {
                if (isTomorrow(booking.date)) {
                    canCancelCheckIn = false;
                }
            }
        }



        this.setState({canCancelCheckIn: canCancelCheckIn, canCheckIn: canCheckIn});
        
    }

    cancelCheckout = () => {
        this.setState({shouldReview: false});
    }

    getLocation = () => {

        this.props.firebase
            .location(this.state.booking.location)
            .get().then(snapshot => {

                if (snapshot) {
                    this.setState({location: {...snapshot.data(), uid: snapshot.id}}, () => {

                        this.props.firebase.analytics.logEvent("visit_booking", {lokation: this.state.location.name});
                    });
                }
            })


    }

    componentWillUnmount() {
        this.unsubscribe();
        if (this.timerId) {
            clearInterval(this.timerId);
        }
    }
    
    
    handleCheckIn = () => {
        this.props.firebase.checkIn(this.state.booking, new Date())
        this.props.firebase.analytics.logEvent("check_in", {lokation: this.state.location.name});
    }

    handleEndBooking = () => {
        this.setState({shouldReview: true});

    }

    handleRating = (rating) => {
        let {review} = this.state;
        review.rating = rating;
        this.setState(review);
    }

    handleCheckoutOptions = (index) => {
        let {review} = this.state;
        review.checks[index].checked = !(review.checks[index].checked);
        this.setState(review);
    }

    handleCheckout = (message) => {

        let {review} = this.state;
        review.message = message;
        this.setState(review, () => {
            this.props.firebase.checkOut(this.state.booking, new Date(), review).then(e => {
                this.setState({shouldReview: false});

                notificationState.generalTitle = "Thank You";
                notificationState.generalText = "Hope you had a productive session! Have a great day.";
                notificationState.generalButtons = [{
                    url: '/lokations',
                    title: 'Book Again'
                }];
                notificationState.generalOpen = true;


                this.props.firebase.analytics.logEvent("check_out", {lokation: this.state.location.name,
                    rating: review.rating,
                    checks: review.checks.filter(check => check.checked).map(check => check.text)
                });
                
            });
        });
        
    }


    handleCancelBooking = async () => {
        // get the charge.

        let refunded = false;
        if (this.state.booking.charge && this.state.booking.promo === null && this.state.canCancelCheckIn) {

            let charge = await this.props.firebase.charges()
                .where("booking", "==", this.state.booking.uid)
                .get();

            if (!charge.empty) {

                let id = charge.docs[0].id;
                charge = charge.docs[0].data();


                console.log(this.state.booking.uid, charge);


                let response = await fetch("https://us-central1-lokus-mvp.cloudfunctions.net/refund", {
                    method: "POST",
                    headers: {"Content-Type": "text/plain"},
                    body: JSON.stringify({
                        charge: {
                            id: charge.charge.charge.id
                        },
                        transfer: {
                            id: charge.charge.transfer.id,
                            amount: charge.charge.transfer.amount
                        }
                    })
                });

                let refund = await response.json();

                await this.props.firebase.charges().doc(id).set({refund: refund.body.refund}, {merge: true});
                refunded = true;
            }
        }
        
        await this.props.firebase.booking(this.state.booking.uid).set({status: 'canceled'}, {merge: true});


        this.props.firebase.analytics.logEvent("cancel_booking", {lokation: this.state.location.name, refunded: refunded});

        notificationState.generalTitle = "Booking Cancelled"
        notificationState.generalText = "Your booking has been cancelled. An email has been sent with your booking cancellation details."
        notificationState.generalTinyText = ""

        notificationState.generalButtons = [
            {
                title: "Book Again",
                url: "/lokation/" + this.state.location.uid
            }
        ]
        notificationState.generalOpen = true
        notificationState.generalOnClose = () => {
            this.props.history.push("/lokations");
        }
        
    }

    render() {
        const { loading, booking, location, shouldReview, canCheckIn, canCancelCheckIn } = this.state;

        return (

            <Background color={themeStyles.bc1}>
            <AuthUserContext.Consumer>

                {authUser => (
                    
                    <>

                        {loading && <div>Loading ...</div>}

                        { booking && location && <>

                            <LokationsHero
                                // Hero padding
                                padTop

                                // Back button
                                hasBackButton

                                location={location}


                                heroTitle={this.state.reminderTitle}
                                subheroTitle={this.state.reminderSubtitle}

                                // Images
                                images={location.images}

                                // Details
                                locationSet={false}
                                locationTitle={location.name}
                                hasAddress
                                addressLine1={location.address && location.address.neighborhood}
                                addressLine2={location.address && location.address.name}


                                scheduleDate={booking.date}
                                schedulePass={booking.is_table===false}
                                scheduleStart={booking.start_time}
                                scheduleEnd={booking.end_time}
                                scheduleSeats={booking.seats}

                                activeBooking={booking}

                                hasCheckin={booking.status==='booked'}
                                reviewReceived={booking.status==='completed'}
                                canCheckIn={canCheckIn}
                                canCancelCheckIn={canCancelCheckIn}
                                handleCheckIn={this.handleCheckIn}
                                handleEndBooking={this.handleEndBooking}
                                handleCancelBooking={this.handleCancelBooking}

                                // Book Now
                                authUser={authUser}
                                totalCost={this.state.amount}

                                // Perks
                                hasPerks={(booking.status==='started'||canCheckIn)&&shouldReview===false}
                                perks={location.perks}

                                // Amenities
                                hasAmenities={booking.status==='started'&&shouldReview===false}
                                amenities={location.info}

                                // Extend Booking
                                hasExtender={booking.status==='started'&&shouldReview===false}


                                // Rate
                                hasRating={shouldReview}
                                rating={this.state.review.rating}
                                handleRating={this.handleRating}

                                // Checkout form
                                hasCheckoutForm={shouldReview}
                                cancelCheckout={this.cancelCheckout}
                                checkoutFormOptions={this.state.review.checks}
                                handleCheckoutOptions={this.handleCheckoutOptions}
                                handleCheckout={this.handleCheckout}
                                
                            />
                        
                        
                        </>}
                        
                        
                        
                        </>
                        
                )}
            </AuthUserContext.Consumer>
            </Background>
        );
    }
}

export default withFirebase(Booking);
