// Imports
// ------
import React from 'react';
import Button from 'components/global/buttons/primary';
import { Row, Column } from 'components/global/theme/grid/grid';
import { Div } from 'components/global/theme/reusables/reusables';

// Styles
// ------
import { Jacket, Title, Text } from './emptyStyles';

// Component
// ------
export default class EmptyList extends React.Component {
    render() {
        return (
            <Jacket>
                <Row isExpanded>
                    <Column small={12}>
                        <Div text="center">
                            { this.props.isFav && <>
                                <Title>No Favorite Lokations</Title>
                                <Text> </Text>
                                <Button navlink type="ghost" textColor="light" text="Discover Lokations" href="/lokations/"/>
                            </>}
                            {!this.props.isFav && <>
                                <Title>No Upcoming bookings</Title>
                                <Text> </Text>
                                <Button navlink type="ghost" textColor="light" text="Book Now" href="/lokations/"/>
                            </>
                            }
                        </Div>
                    </Column>
                </Row>
            </Jacket>
        )
    }
}