// Imports
// ------
import styled from 'styled-components';
// import { breakup } from 'components/global/theme/breakpoints';
import { Section, Div, Span } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    display: block;
    margin-bottom: 2.5rem;
`;

export const Label = styled(Div)`
    display: block;
    margin-bottom: 1rem;

    font-size: 1.4rem;
    padding: 0 3rem;
`;

export const ItemList = styled.ul`
    position: relative;
    list-style: none;
`;

export const Item = styled.li`
    display: block;
    margin-bottom: 1px;

    a {
        display: block;
        background: ${props => props.theme.bc1};

        padding: 1rem 3rem;

        &:hover {
            text-decoration: underline;
        }
    }

    svg {
        display: inline-block;
        vertical-align: middle;
        margin-right: 1rem;

        width: 2.4rem;
        height: 2.4rem;
    }
`;

export const Text = styled(Span)`
    color: ${props => props.theme.bc3};
`;