import React, { Component } from 'react';

import { AuthUserContext } from '../../lib/Session';
import { withFirebase } from '../../lib/Firebase';
import {Div} from "../global/theme/reusables/reusables";
import {Column, Row} from "../global/theme/grid/grid";
import { Title} from "../lokations/list/listStyles";
import Loader from "../global/loader";
import Card from "../lokations/list/card/card";

class Promos extends Component {
    constructor(props) {
        super(props);



        this.state = {
            loading: false,
            locations: [],


            tables: [],
            ids: [],
            limit: 5,
            searching: false,
        };


    }

    componentDidMount = () => {

        this.onListenForLocations();

    }


    onListenForLocations = () => {

        this.setState({ loading: true });

        this.unsubscribe = this.props.firebase
            .locations()
            .onSnapshot(snapshot => {
                if (snapshot.size) {
                    let locations = [];
                    snapshot.forEach(doc =>
                        locations.push({ ...doc.data(), uid: doc.id }),
                    );

                    this.setState({
                        locations: locations.reverse(),
                        loading: false,
                    });
                } else {
                    this.setState({ locations: null, loading: false });
                }
            });

    };

    componentWillUnmount() {
        this.unsubscribe();
    }

    onNextPage = () => {
        this.setState(
            state => ({ limit: state.limit + 5 }),
            this.onListenForLocations,
        );
    };


    render = () => {
        const { locations, loading } = this.state;

        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    <Div relative mpad pad>
                        <Row isExpanded>
                            <Column small={12} medium={10} pushOnMedium={1}>
                                
                                <Title>
                                    Promo Codes
                                </Title>
                                
                            </Column>
                        </Row>
                        <Row isExpanded isCollapsed>
                            <Column small={12} medium={10} pushOnMedium={1}>
                                <Row isExpanded equal>
                                    {loading ? <Loader/> :
                                        locations.filter(location => authUser.locations && authUser.locations[location.uid] ).map((location, index) =>
                                            <Column key={index} small={12} medium={6} large={4} xlarge={3}>
                                                <Card
                                                    location={location}
                                                    slug={`/admin/location/${location.uid}`}
                                                    promoslug={`/admin/edit-promos/${location.uid}`}
                                                    title={location.name}
                                                    subTitle={location.address.neighborhood}
                                                    address={location.address.name}
                                                    images={location.images}

                                                    hideFav
                                                    isPromo
                                                    
                                                />
                                            </Column>
                                        )}

                                    

                                </Row>
                            </Column>
                        </Row>
                        <Row isExpanded>
                            <Column small={12}>
                                <Div text="center">

                                </Div>
                            </Column>
                        </Row>
                        
                    </Div>
                    
                )}
            </AuthUserContext.Consumer>
        );
    }
}

export default withFirebase(Promos);
