import React from 'react';

import { withFirebase } from '../../lib/Firebase';
import Icon from "../global/icons/icons";
import {Span} from "../global/header/headerStyles";

const SignOut = ({ firebase }) => (
    
    <a href="/" className="" onClick={firebase.doSignOut}>
        <Icon type="locked"/>
        <Span>Sign Out</Span>
    </a>
);

export default withFirebase(SignOut);
