// Imports
// ------
import styled from "styled-components";
import { Footer, Div, Span } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Footer)`
    position: fixed;
    z-index: 1000;
    bottom: 0; left: 0;
    width: 100%;
    display: block;
    padding: 2rem 0;
    background: ${props => props.theme.bc2};
`;

export const Iconwrap = styled(Div)`
    position: relative;
    display: block;

`;

export const Label = styled(Span)`
    position: relative;
    display: block;
`;

export const Bullet = styled(Div)`
    background: ${props => props.theme.bc5};
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    top: -10px;
    right: ${props => props.flush ? "0px" : "30px"};
`;

export const MenuItem = styled(Div)`
    position: relative;
    text-align: center;

    a {
        display: block;
        opacity: 0.45;

        &.active {
            opacity: 1;

            ${Iconwrap} {
                &:before {
                    opacity: 1;
                }
            }
        }
    }
`;