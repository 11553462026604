// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';
import { observer } from 'mobx-react';

// Styles
// ------
import { Jacket, Header, Close, Title, HelpTitle, ContentJacket } from './legalStyles';

// States
// ------
import { userState } from 'states/user';

// Utils
// ------
import Body from 'utils/bodylock';

// Component
// ------
class Legal extends React.Component {
    toggleClose = () => {
        userState.legalIsOpen = !userState.legalIsOpen;
    }

    stateCheck = () => {
        if (userState.legalIsOpen) {
            Body.lock();
        }

        if (!userState.legalIsOpen) {
            Body.unlock();
        }
    }

    render() {
        return (
            <Jacket isActive={this.props.isActive}>
                {/* Help header */}
                <Header>
                    <Row isExpanded>
                        <Column small={12} medium={10} pushOnMedium={1}>
                            <Row isExpanded>
                                <Column small={12}>
                                    <Div relative text="center">
                                        <Close onClick={this.toggleClose}>
                                            <Icon type="small-arrow-left" />
                                        </Close>
                                        <Title>Legal</Title>
                                    </Div>
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                </Header>

                <Row isExpanded>
                    <Column small={12} medium={10} pushOnMedium={1}>
                        <Row isExpanded>
                            <Column small={12}>
                                <HelpTitle>Privacy Policy</HelpTitle>
                            </Column>
                        </Row>
                    </Column>
                </Row>

                <ContentJacket>
                    <Row isExpanded>
                        <Column small={12} medium={10} pushOnMedium={1}>
                            <Row isExpanded>
                                <Column small={12}>
                                    <h3>Introduction</h3>
                                    <p><strong>Section:</strong> Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Separated they live in Bookmarks right at the coast.</p>
                                    <p><strong>Section:</strong> Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Separated they live in Bookmarks right at the coast.</p>
                                    <hr />
                                    <h3>Chapter</h3>
                                    <p><strong>Section:</strong> Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Separated they live in Bookmarks right at the coast.</p>
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                </ContentJacket>
            </Jacket>
        )
    }

    componentDidUpdate() {
        this.stateCheck();
    }

    componentWillUnmount() {
        this.stateCheck();
    }
}

export default observer(Legal);