// Imports
// ------
import React from 'react';
import Content from 'components/lokations/information/content/content';
import BookCta from 'components/lokations/bookcta/bookcta';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket } from './informationStyles';

// Component
// ------
export default class LokationInformation extends React.Component {
    render() {
        return (
            <Jacket>
                <Div relative mpad padBottom>
                    <Row isExpanded>
                        <Column small={12} large={7} pushOnLarge={1}>
                            <Content
                                // Maps
                                address={this.props.address}
                                mapData={this.props.mapData}
                                mapContent={this.props.mapContent}

                                // Amenities
                                amenities={this.props.amenities}

                                // Pricing
                                pricingOptions={this.props.pricingOptions}
                                pricingContent={this.props.pricingContent}
                            />
                        </Column>
                        <Column hideOnLarge small={12} large={3} pushOnLarge={1}>
                            {this.props.hasBookingButtons &&
                                false && <BookCta />
                            }
                        </Column>
                    </Row>
                </Div>
            </Jacket>
        )
    }
}