// Imports
// ------
import React from 'react';
import Date from './date/date';
import Time from './time/time';
import Seats from './seats/seats';

// Styles
// ------
import { Jacket } from './filtersStyles';
// import {scheduleFilters} from "../../../../states/lokationSidebar";

// Component
// ------
export default class Filters extends React.Component {
    render() {
        return (
            <Jacket>
                <Date onChange={this.props.onChange} />
                <Time onChange={this.props.onChange} />
                <Seats onChange={this.props.onChange} />
            </Jacket>
        )
    }
}