// Imports
// ------
import styled from 'styled-components';
// import { breakup } from 'components/global/theme/breakpoints';
import { Div, Span } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Div)`
    position: relative;
    display: block;
    cursor: pointer;

    background: ${props => props.theme.bc1};
    padding: 1rem 3rem;

    &:hover {
        text-decoration: underline;
    }

    svg {
        float: right;
        display: inline-block;
        margin-top: 1rem;

        width: 1.2rem;
        height: 1.2rem;
    }
`;

export const Text = styled(Span)`
    color: ${props => props.theme.bc3};
`;