// Imports
// ------
import styled from "styled-components";
import { Section, Gradient } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    display: block;

    .react-tabs {
        position: relative;
        display: block;

        ul.react-tabs__tab-list {
            list-style: none;
            padding: 0;
            margin: 0;
            margin-bottom: 2rem;

            li {
                position: relative;
                display: inline-block;
                padding: 1.5rem 0;
                margin-right: 3rem;

                cursor: pointer;
                opacity: 0.45;

                &:first-child {
                    padding-left: 0;
                }

                &:before {
                    content: '';
                    position: absolute;
                    bottom: 1.3rem;
                    
                    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                    height: 3px;
                    width: 0;
                    background: ${Gradient};
                }

                &.react-tabs__tab--selected {
                    cursor: default;
                    opacity: 1;

                    &:before {
                        width: 100%;
                    }
                }
            }
        }
    }
`;