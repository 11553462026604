// Imports
// ------
import styled from "styled-components";
import { Section, Div, H4, Span, GradientIcon } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    z-index: 10;
    display: block;

    background: ${props => props.theme.bc3};
    padding: 2rem 0;
`;

export const Iconwrap = styled(Div)`
    position: relative;
    display: block;

    svg {
        ${GradientIcon}
        width: 3rem;
        height: 3rem;
    }
`;

export const Amenity = styled(Div)`
    position: relative;
    display: block;
    margin-bottom: 1rem;
`;

export const Title = styled(H4)`
    color: ${props => props.theme.bc1};
`;

export const Text = styled(Span)`
    font-size: 1.4rem !important;
    color: ${props => props.theme.bc1};
`;