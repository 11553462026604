// Imports
// ------
import styled from "styled-components";
import { Section, Div, Span, H3 } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    background: ${props => props.theme.bc3};
    padding: 2rem;
    color: ${props => props.theme.bc1};
`;

export const Label = styled(Span)``;

export const Total = styled(H3)``;

export const Warning = styled(Span)`
    display: block;
    text-align: center;
    margin-bottom: 2rem;

    color: ${props => props.theme.bc5};
`;

export const Return = styled(Div)`
    margin-top: 1rem;
`;