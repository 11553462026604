// Imports
// ------
import styled from "styled-components";
import { Section, Div } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    z-index: 10;
    display: block;

    background: ${props => props.theme.bc3};
    padding: 2rem 0;
`;

export const Link = styled(Div)`
    display: block;
    margin-top: 1rem;
`;