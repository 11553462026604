// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import { Collapse } from '@material-ui/core';

// Styles
// ------
import { FaqItemJacket, Title, Content } from './listStyles';

// Component
// ------
export default class FaqItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        }

        this.toggleOpen = this.toggleOpen.bind(this)
    }

    toggleOpen() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {
        const checker = this.state.isOpen;

        return (
            <FaqItemJacket onClick={this.toggleOpen} isActive={checker}>
                <Title isActive={checker}>{this.props.question} <Icon type="small-arrow-down" /></Title>
                <Collapse in={checker} collapsedHeight="0">
                    <Content>
                        {this.props.answer}
                    </Content>
                </Collapse>
            </FaqItemJacket>
        )
    }
}