// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';

// Styles
// ------
import { Jacket, ContentJacket, Title, Content, Button, ButtonText } from './aboutStyles';

// Component
// ------
export default class SidebarAbout extends React.Component {
    render() {
        return (
            <Jacket>
                <ContentJacket>
                    <Title>{this.props.aboutTitle}</Title>
                    <Content dangerouslySetInnerHTML={{ __html: `<p>${this.props.aboutContent}</p>` }} />
                    
                  {this.props.aboutLinks.map(link => <Button href={link.text} target="_blank" rel="noopener noreferrer"><Icon type={link.icon} /><ButtonText hasGradient>{link.title}</ButtonText></Button>)}
                   
                </ContentJacket>
            </Jacket>
        )
    }
}