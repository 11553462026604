import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';

const config = {
    apiKey: "AIzaSyCb5zWvGJn20xFyTOJDVqrMV1VeJiXXI9A",
    authDomain: "lokus-mvp.firebaseapp.com",
    databaseURL: "https://lokus-mvp.firebaseio.com",
    projectId: "lokus-mvp",
    storageBucket: "lokus-mvp.appspot.com",
    messagingSenderId: "497899357735",
    appId: "1:497899357735:web:1b6a1a49cfe778ca",
    measurementId: "G-H5Z193QBXT"
};




class Firebase {
    constructor() {
        app.initializeApp(config);

        this.firestore = app.firestore;
        this.fieldValue = app.firestore.FieldValue;
        this.auth = app.auth();
        this.db = app.firestore();
        this.storage = app.storage();
        this.analytics = app.analytics();
        window.db = this;
    }

    
    
    doSignOut = () => this.auth.signOut();

    doSendEmailVerification = () =>
        this.auth.currentUser.sendEmailVerification({
            url: "https://app.booklokus.com/",
        });

    onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged(authUser => {
            
            if (authUser) {

                this.user(authUser.uid)
                    .get()
                    .then(snapshot => {
                        const dbUser = snapshot.data();

                        // default empty roles
                        if (!dbUser.roles) {
                            dbUser.roles = {};
                        }
                        
                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            emailVerified: authUser.emailVerified,
                            providerData: authUser.providerData,
                            ...dbUser,
                        };

                        next(authUser);

                    });
            } else {
                if (fallback) {
                    fallback();
                }
            }
        });

    user = uid => this.db.doc(`users/${uid}`);
    users = () => this.db.collection('users');
    feedbacks = () => this.db.collection('feedbacks');


    locations = () => this.db.collection('locations');
    location = (uid) => this.db.doc(`locations/${uid}`);

    passes = () => this.db.collection('passes');
    tables = () => this.db.collection('tables');
    bookings = () => this.db.collection('bookings');
    booking = (uid) => this.db.doc(`bookings/${uid}`);
    table = (uid) => this.db.doc(`tables/${uid}`);

    codes = () => this.db.collection('codes')
    charges = () => this.db.collection('charges');



    // actions?

    checkIn = (booking, date) => this.db.doc(`bookings/${booking.uid}`).set({
        status: 'started',
        checkin_date: app.firestore.Timestamp.fromDate(date)
    }, {merge: true})

    checkOut = (booking, date, data) => this.db.doc(`bookings/${booking.uid}`).set({
        status: 'completed',
        checkout_date: app.firestore.Timestamp.fromDate(date),
        review: data
    }, {merge: true})


    // TODO: move this out again.,
    isFavorite = (location, user) => {
        if (user && user.favs) {
            return user.favs.filter(id => id === location).length > 0
        }
        return false;
    }
    
    favToggle = (location, user) => {
        
        if (!user) {
            return Promise.resolve();
        }
        
        if (!user.favs) {
            user.favs = [];
        }

        if (!this.isFavorite(location, user)) {
            user.favs.push(location);
        } else {
            user.favs = user.favs.filter(id => id !== location);
        }

        return this.user(user.uid).set({favs: user.favs}, {merge: true});
    }
    
    
}

export default Firebase;
