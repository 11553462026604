// Imports
// ------
import React from 'react';
import LokationList from '../list/list';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket } from './contentStyles';

// Component
// ------
export default class BookingsContent extends React.Component {
    render() {
        return (
            <Jacket>
                <Div relative pad mpad>
                    <Row isExpanded>
                        <Column small={12} medium={10} pushOnMedium={1}>
                            <h1>Bookings</h1>
                        </Column>
                    </Row>
                    <Row isExpanded isCollapsed>
                        <Column small={12} medium={10} pushOnMedium={1}>
                            <Tabs defaultIndex={this.props.defaultIndex}>
                                <Row isExpanded>
                                    <Column small={12}>
                                        <TabList>
                                            <Tab>Upcoming</Tab>
                                            <Tab>Past</Tab>
                                            <Tab>Favorites</Tab>
                                        </TabList>
                                    </Column>
                                </Row>

                                <TabPanel>
                                    <LokationList
                                        authUser={this.props.authUser}
                                        list={this.props.upcoming}
                                    />
                                </TabPanel>

                                <TabPanel>
                                    <LokationList
                                        authUser={this.props.authUser}
                                        list={this.props.past}
                                    />
                                </TabPanel>

                                <TabPanel>
                                    <LokationList
                                        authUser={this.props.authUser}
                                        list={this.props.favorites}
                                        isFav={true}
                                    />
                                </TabPanel>
                            </Tabs>
                        </Column>
                    </Row>
                </Div>
            </Jacket>
        )
    }
}