// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Details, Schedule, Title, Address, ALine1, ALine2, Label, ScheduleDetailWrapper, Date, Start, End, Iconwrap, Seats, ScheduleEdit, Add, Edit, AddDetailsButton } from './detailsStyles';
import {humanDate, minutesToTime} from "../../../../lib/Util/TimeUtil";

import { lokationFilterState } from 'states/lokationFilters';
import {FavoriteWrapper} from "../../hero/heroStyles";
import Favorite from "../../../global/favorite/favorite";
// Component
// ------
export default class SidebarDetails extends React.Component {
    handleAdd = () => {
        lokationFilterState.isOpen = !lokationFilterState.isOpen;
    }
    
    
    scheduleData = () => {
        if (this.props.activeBooking) {
            
            // TODO: get the data from the booking.
            return {
                scheduleDate: this.props.activeBooking.date,
                schedulePass: this.props.activeBooking.is_table === false,
                scheduleStart: this.props.activeBooking.is_table === false ? (8 * 60) : this.props.activeBooking.start_time,
                scheduleEnd: this.props.activeBooking.is_table === false ? (18 * 60) :this.props.activeBooking.end_time,
                scheduleSeats: this.props.activeBooking.is_table === false ? 1 : this.props.activeBooking.seats,

            };
        } else {
            
            return {
                scheduleDate: this.props.scheduleDate,
                schedulePass: this.props.schedulePass,
                scheduleStart: this.props.scheduleStart,
                scheduleEnd: this.props.scheduleEnd,
                scheduleSeats: this.props.scheduleSeats,
                
            };
            
        }
        
    }
    
    scheduleDataComplete = () => {
        
        let schedule = this.scheduleData();
        
        if (!schedule.scheduleDate) {
            return false;
        }
        if (schedule.schedulePass) {
            return true;
        }
        
        return (schedule.scheduleStart > -1 && schedule.scheduleEnd > -1 && schedule.scheduleSeats > 0);
        
    }
    
    hasAnyScheduleData = () => {
        
        let schedule = this.scheduleData();
        // console.log(schedule);
        return schedule.scheduleDate || schedule.schedulePass || schedule.scheduleStart > -1 || schedule.scheduleEnd > -1 || schedule.scheduleSeats > 0
        
    }
    
    

    render() {


        /*
        
        - do we have an active booking?
            - yes: 
                - do the checkin/checkout dance...
            - no:
                - do we have any booking data?
                    - yes: 
                        - is the data complete?
                            - yes: show the info
                            - no: Complete booking details
                    - no: add booking details
        
        
         */
        
        let schedule = "";
        if (this.props.activeBooking) {
            let data = this.scheduleData();
            schedule =  <Schedule>
                {/* Time */}
                <Row isExpanded>
                    <Column small={4}>
                        <Label>
                            {
                                data.schedulePass ? "Day Pass" : "Time"
                            }</Label>
                    </Column>
                    <Column small={8}>
                        <ScheduleDetailWrapper>
                            {/*{data.schedulePass === false &&*/}
                            <>
                                <Start>{minutesToTime(data.scheduleStart)}</Start>
                                <Iconwrap>
                                    <Icon type="big-arrow-right" />
                                </Iconwrap>
                                <End>{minutesToTime(data.scheduleEnd)}</End>
                            </>
                            {/*}*/}

                            {/*{data.schedulePass === true &&*/}
                            {/*<Full hasGradient>Day Pass</Full>*/}
                            {/*}*/}
                        </ScheduleDetailWrapper>
                    </Column>
                </Row>

                {/* Date */}
                <Row isExpanded>
                    <Column small={4}>
                        <Label>Date</Label>
                    </Column>
                    <Column small={8}>
                        <ScheduleDetailWrapper>
                            {data.scheduleDate &&
                            <Date>{ humanDate(data.scheduleDate) }</Date>
                            }
                        </ScheduleDetailWrapper>
                    </Column>
                </Row>

                {/* Seats */}
                <Row isExpanded>
                    <Column small={4}>
                        <Label>Seat</Label>
                    </Column>
                    <Column small={8}>
                        <ScheduleDetailWrapper>
                            {data.scheduleSeats > 0 &&
                            <Seats>

                                {
                                    data.schedulePass ? "1 Seat @ Shared Table" :
                                    `${data.scheduleSeats} Seats @ Reserved Table`

                                }

                            </Seats>
                            }

                        </ScheduleDetailWrapper>
                    </Column>
                </Row>
            </Schedule>
        } else {
            let data = this.scheduleData()
            
            schedule = <Schedule>
                {this.hasAnyScheduleData() === false ?
                    <Add onClick={this.handleAdd}>Add Booking Details</Add>
                    :
                    <>
                        {/* Time */}
                        <Row isExpanded>
                            <Column small={4}>
                                <Label>
                                    {
                                        data.schedulePass ? "Day Pass" : "Time"
                                    }</Label>
                            </Column>
                            <Column small={8}>
                                <ScheduleDetailWrapper>
                                    {(data.scheduleStart > -1 && data.scheduleEnd > -1) &&
                                    <>
                                        <Start>{minutesToTime(data.scheduleStart)}</Start>
                                        <Iconwrap>
                                            <Icon type="big-arrow-right" />
                                        </Iconwrap>
                                        <End>{minutesToTime(data.scheduleEnd)}</End>
                                    </>
                                    }

                                    {/*{data.schedulePass === true &&*/}
                                    {/*<Full hasGradient>Full Day Pass</Full>*/}
                                    {/*}*/}

                                    {(data.scheduleStart === -1 || data.scheduleEnd === -1) && data.schedulePass === false &&
                                    <AddDetailsButton onClick={this.handleAdd}>Add</AddDetailsButton>
                                    }
                                </ScheduleDetailWrapper>
                            </Column>
                        </Row>

                        {/* Date */}
                        <Row isExpanded>
                            <Column small={4}>
                                <Label>Date</Label>
                            </Column>
                            <Column small={8}>
                                <ScheduleDetailWrapper>
                                    {data.scheduleDate &&
                                    <Date>{ humanDate(data.scheduleDate) }</Date>
                                    }

                                    {(!data.scheduleDate) &&
                                    <AddDetailsButton onClick={this.handleAdd}>Add</AddDetailsButton>
                                    }
                                </ScheduleDetailWrapper>
                            </Column>
                        </Row>

                        {/* Seats */}
                        <Row isExpanded>
                            <Column small={4}>
                                <Label>Seat</Label>
                            </Column>
                            <Column small={8}>
                                <ScheduleDetailWrapper>
                                    {data.scheduleSeats > 0 &&
                                    <Seats>
                                        {
                                            data.schedulePass ? "1 Seat @ Shared Table" :
                                                `${data.scheduleSeats} Seats @ Reserved Table`

                                        }</Seats>
                                    }

                                    {(data.scheduleSeats === 0) &&
                                    <AddDetailsButton onClick={this.handleAdd}>Add</AddDetailsButton>
                                    }
                                </ScheduleDetailWrapper>
                            </Column>
                        </Row>
                    </>
                }

                {this.hasAnyScheduleData() && this.scheduleDataComplete() && this.props.activeBooking === null &&
                <ScheduleEdit>
                    <Edit onClick={this.handleAdd}>Change Booking Details</Edit>
                </ScheduleEdit>
                }


            </Schedule>
        }
        
        
        
        return (
            <>
                <Details>
                    
                    <FavoriteWrapper hideOnXlarge posRight>
                        <Favorite isFavorite={this.props.isFavorite} hasGradient onClick={this.props.onFavToggle} />
                    </FavoriteWrapper>
                    
                    <Title><Icon type="location" />{this.props.locationTitle}</Title>
                    {this.props.hasAddress &&
                        <Address>
                            <ALine1>{this.props.addressLine1}</ALine1>
                            <ALine2>{this.props.addressLine2}</ALine2>
                        </Address>
                    }
                </Details>
                
                { schedule }

                {this.props.hasAmenities && this.props.activeBooking !== null &&
                <ScheduleEdit>
                    <Edit black noclick>Select Perk to View Details & Redeem</Edit>
                </ScheduleEdit>
                }
            </>
        )
    }
}