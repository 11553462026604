// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';

// Styles
// ------
import {Label, Input, InputJacket, CheckboxJacket, PwToggle, InputSpan, TextArea} from './inputStyles';
import PhoneInput from "react-phone-number-input";
import {Phone} from "../../user/profile/field/fieldStyles";

// Component
// ------
export default class InputType extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            pwHide: true,
        }
    }

    togglePassword = () => {
        // const el = this.passwordInput
        this.setState({pwHide: !this.state.pwHide})
    }

    handleDate = (event) => {
        
        let input = event.target.value;

        function checkValue(str, max) {
            if (str.charAt(0) !== '0' || str === '00') {
                var num = parseInt(str);
                if (isNaN(num) || num <= 0 || num > max) num = 1;
                str = num > parseInt(max.toString().charAt(0)) && num.toString().length === 1 ? '0' + num : num.toString();
            };
            return str;
        };

        if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
        var values = input.split('/').map(function(v) {
            return v.replace(/\D/g, '')
        });
        if (values[0]) values[0] = checkValue(values[0], 12);
        if (values[1]) values[1] = checkValue(values[1], 31);
        var output = values.map(function(v, i) {
            return v.length === 2 && i < 2 ? v + ' / ' : v;
        });
        input = output.join('').substr(0, 14);
        event.target.value = input;
        this.props.onChange(event);
        
    }

    componentDidMount() {
        // this.handleDate();
    }

    render() {
        const checkPw = this.state.pwHide;
        
        // Text input
        if(this.props.type === "text" && this.props.id === "date") return (
            <>
                {this.props.label &&
                <Label
                    htmlFor={this.props.name}
                    light={this.props.light}
                    dark={this.props.dark}
                >
                    {this.props.label}
                </Label>
                }
                <Input
                    type="text"
                    required={this.props.required}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    name={this.props.name}
                    id="date"
                    className={this.props.className}
                    light={this.props.light}
                    dark={this.props.dark}
                    transparent={this.props.transparent}
                    talign={this.props.talign}
                    onChange={this.handleDate}
                    disabled={this.props.disabled}
                    autocomplete={this.props.autocomplete}
                />
            </>
        )

        if(this.props.type === "text" && this.props.id !== "date") return (
            <>
                {this.props.label &&
                    <Label
                        htmlFor={this.props.name}
                        light={this.props.light}
                        dark={this.props.dark}
                    >
                        {this.props.label}
                    </Label>
                }
                <Input
                    type="text"
                    required={this.props.required}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    name={this.props.name}
                    id={this.props.id}
                    className={this.props.className}
                    light={this.props.light}
                    dark={this.props.dark}
                    transparent={this.props.transparent}
                    talign={this.props.talign}
                    onChange={this.props.onChange}
                    disabled={this.props.disabled}
                    autocomplete={this.props.autocomplete}
                />
            </>
        )

        if(this.props.type === "area") return (
            <>
                {this.props.label &&
                <Label
                    htmlFor={this.props.name}
                    light={this.props.light}
                    dark={this.props.dark}
                >
                    {this.props.label}
                </Label>
                }
                <TextArea
                    required={this.props.required}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    name={this.props.name}
                    id={this.props.id}
                    className={this.props.className}
                    light={this.props.light}
                    dark={this.props.dark}
                    transparent={this.props.transparent}
                    talign={this.props.talign}
                    onChange={this.props.onChange}
                    disabled={this.props.disabled}
                    autocomplete={this.props.autocomplete}
                />
            </>
        )

        // Email input
        if(this.props.type === "email") return (
            <>
                {this.props.label &&
                    <Label
                        htmlFor={this.props.name}
                        light={this.props.light}
                        dark={this.props.dark}
                    >
                        {this.props.label}
                    </Label>
                }
                <Input
                    type="email"
                    required={this.props.required}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    name={this.props.name}
                    id={this.props.id}
                    className={this.props.className}
                    light={this.props.light}
                    dark={this.props.dark}
                    transparent={this.props.transparent}
                    talign={this.props.talign}
                    onChange={this.props.onChange}
                    disabled={this.props.disabled}
                    autocomplete={this.props.autocomplete}
                />
            </>
        )

        // Password input
        if(this.props.type === "password") return (
            <>
                {this.props.label &&
                    <Label
                        htmlFor={this.props.name}
                        light={this.props.light}
                        dark={this.props.dark}
                    >
                        {this.props.label}
                    </Label>
                }
                <InputJacket>
                    <Input
                        type={this.state.pwHide ? 'password' : 'text'}
                        required={this.props.required}
                        placeholder={this.props.placeholder}
                        value={this.props.value}
                        name={this.props.name}
                        id={this.props.id}
                        className={this.props.className}
                        light={this.props.light}
                        dark={this.props.dark}
                        transparent={this.props.transparent}
                        talign={this.props.talign}
                        onChange={this.props.onChange}
                        ref={passwordInput => (this.passwordInput = passwordInput)}
                    />
                    {this.props.hasToggle &&
                        <PwToggle onClick={this.togglePassword} negativeMargin={this.props.negativeMargin} isHidden={checkPw}>
                            <Icon type="eye" />
                        </PwToggle>
                    }
                </InputJacket>
            </>
        )
        
        
        if (this.props.type === "phone") return (
            <Phone textAlign="left">
                <Label
                    htmlFor={this.props.name}
                    light={this.props.light}
                    dark={this.props.dark}
                >
                    {this.props.label}
                </Label>
                <PhoneInput
                    country="US"
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    name={this.props.name}
                    onChange={value => this.props.onChange({target: { name: this.props.name, value: value}})}
                />
            </Phone>
        )

        // Number input
        if(this.props.type === "number") return (
            <>
                <Label
                    htmlFor={this.props.name}
                    light={this.props.light}
                    dark={this.props.dark}
                >
                    {this.props.label}
                </Label>
                <Input
                    type="number"
                    maxLength="19"
                    required={this.props.required}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    name={this.props.name}
                    id={this.props.id}
                    className={this.props.className}
                    light={this.props.light}
                    dark={this.props.dark}
                    transparent={this.props.transparent}
                    talign={this.props.talign}
                    onChange={this.props.onChange}
                    disabled={this.props.disabled}
                    autocomplete={this.props.autocomplete}
                />
            </>
        )

        // Checkbox
        if(this.props.type === "checkbox") return (
            <CheckboxJacket>
                <Input
                    type="checkbox"
                    name={this.props.name}
                    id={this.props.id}
                    className={this.props.className}
                    light={this.props.light}
                    dark={this.props.dark}
                    transparent={this.props.transparent}
                    onChange={this.props.onChange}
                />
                <InputSpan className="checkmark" />
            </CheckboxJacket>
        )

        // Upload File
        if(this.props.type === "file") return (
            <Input
                type="file"
                name={this.props.name}
                id={this.props.id}
                className={this.props.className}
                light={this.props.light}
                dark={this.props.dark}
                transparent={this.props.transparent}
                onChange={this.props.onChange}
                accept="image/*"
            />
        )

    }
}