// Imports
// ------
import { observable } from "mobx";

// Exports
// ------
export const notificationState = observable({
    thanksOpen: true,
    checkoutOpen: true,
    bookingsOpen: false,
    emailverifyOpen: false,


    generalOpen: false,
    generalTitle: "",
    generalText: "",
    generalTinyText: "",
    generalButtons: [],
    generalOnClose: null,
});