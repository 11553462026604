import React, { Component } from 'react';

import {AuthUserContext, withAuthorization} from '../../lib/Session';
import { withFirebase } from '../../lib/Firebase';
import * as ROLES from "../../constants/roles";
import {compose} from "recompose";
import Input from "../../components/global/formfields/input";
import Button from "../../components/global/buttons/primary";
import {Column, Row} from "../../components/global/theme/grid/grid";
import {Div} from "../../components/global/theme/reusables/reusables";
import {
    Details,
    Schedule, 
    Title
} from "../../components/lokations/sidebar/details/detailsStyles";
import {dateAdjust, dateToString} from "../../lib/Util/TimeUtil";
import moment from "moment";

class EditPromos extends Component {
    constructor(props) {
        super(props);

        
        
        this.state = {
            loading: false,
            location: null,
            codes: [],
            uploading: false,
            adding: false,
            current: null,
            users: {},
            newcode: {
                code: '',
                start_date: '',
                end_date: '',
                name: '',
            },

        };
    }

    componentDidMount = () => {

        this.onListenForLocation();
        this.onListenForPromos();

    }

    onListenForPromos = () => {
        this.unsubscribe2 = this.props.firebase.codes()
            .where("location", "==", this.props.match.params.location)
            .onSnapshot(snapshot => {
                if (snapshot.size) {
                    let codes = [];
                    snapshot.forEach(doc =>
                        codes.push({ ...doc.data(), uid: doc.id }),
                    );

                    this.setState({
                        codes: codes,
                    });
                } else {
                    this.setState({ codes: [], loading: false });
                }
            });
        this.unsubscribe3 = this.props.firebase
            .users()
            .onSnapshot(snapshot => {
                if (snapshot.size) {
                    let users = {};

                    snapshot.forEach(doc => {
                            let user = {...doc.data(), uid: doc.id};
                            users[doc.id] = user;

                        }
                    );

                    this.setState({
                        users: users,
                    });
                } else {

                }
            });
    }

    onListenForLocation = () => {
        this.setState({ loading: true });

        this.unsubscribe = this.props.firebase
            .location(this.props.match.params.location)
            .onSnapshot(snapshot => {
                if (snapshot) {
                    let location = { ...snapshot.data(), uid: this.props.match.params.location };
                    this.setState({
                        location: location,
                        newlocation: {...location},
                        loading: false,
                    });
                } else {
                    console.log("No location", snapshot, this.props.match.params);
                    this.setState({
                        location: null,
                        loading: false,
                    });
                }
            });


    };


    componentWillUnmount() {
        this.unsubscribe();
        this.unsubscribe2();
        this.unsubscribe3();
        
    }


    onChange = event => {
        let name = event.target.name.split(".");
        let mod = (source, keys, value) => {
            if (keys.length === 1) {
                source[keys[0]] = value;
                return source;
            }
            let first = keys[0];
            let newkeys = keys;
            newkeys.shift();
            source[first] = mod(source[first], newkeys, value);
            return source;
        }
        let newcode = mod(this.state.newcode, name, event.target.value);
        
        this.setState({ newcode: newcode });
    };

    
    
    save = () => {
        let def = this.state.newcode;
        def.location = this.state.location.uid;
        
        this.props.firebase.codes().add(def).then(e => {
            this.setState({adding: false})
        })
        
    }

    cancel = () => {
        this.setState({adding: false})
    }

    
    
    code_exists = (code) => {
        if (!isNaN(1 * code.substr(0, 1))) {
            return true;
        }
        return this.state.codes.filter(c => c.code === code).length > 0;
    }
    
    add = () => {
        const makeid = (length) => {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }
        
        let code = makeid(6);
        while (this.code_exists(code)) {
            code = makeid(6);
        }
        let date = dateAdjust(dateToString(new Date()));
        let date2 = dateAdjust(dateToString(moment(new Date()).add(1, "day").toDate()));
        this.setState({
            adding: true,
            newcode: {
                code: code,
                start_date: date,
                end_date: date2,
                name: ''
            }
        })
    }

    revoke = (uid) => {
        this.props.firebase.codes().doc(uid).set({usages: 0}, {merge: true})
    }

    isValid = (code) => {
        if (code.usages === 0) {
            return false;
        }
        let date = moment(code.end_date);
        if (date.isBefore(new Date())) {
            return false;
        }
        return true;
    }

    render() {
        const { loading, location, codes, newcode } = this.state;

        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    <div className="">

                        {location && <Row isExpanded showOnLarge>
                            <Column small={12} large={10} pushOnLarge={1}>
                                <Div relative pad={10} padTop>
                                    <Row isExpanded isCollapsed>
                                        <Column large={12}>
                                            <Details>
                                                <div style={{float: "right", display: "flex"}}>
                                                    {this.state.adding && <>
                                                        <Button button onClick={this.cancel} fullWidth textColor="dark" type="ghost" text="Cancel" />
                                                        <Button button onClick={this.save} fullWidth type="full" text="Save" />
                                                    </>}

                                                    {!this.state.adding && <>
                                                        <Button button onClick={this.add} fullWidth type="full" text="Add Code" />
                                                    </>}
                                                </div>
                                                <Title>{location.name}</Title>
                                                
                                            </Details>
                                            <Schedule>
                                                

                                                    { this.state.adding && <div>
                                                        <Title>Add Code</Title>

                                                        <Row>
                                                            <Column><Input dark type="text" name="code" value={newcode.code} onChange={this.onChange} label="Code" placeholder="" /></Column>
                                                        </Row>

                                                        <Row>
                                                            <Column>
                                                                <Input
                                                                    label="Check-In Date"
                                                                    type="text"
                                                                    id="date"
                                                                    dark
                                                                    transparent
                                                                    required
                                                                    placeholder="MM / DD / YYYY"
                                                                    name="start_date"
                                                                    value={newcode.start_date}
                                                                    onChange={this.onChange}
                                                                />
                                                            </Column>
                                                        </Row>

                                                        <Row>
                                                            <Column>
                                                                <Input
                                                                    label="Check-Out Date"
                                                                    type="text"
                                                                    id="date2"
                                                                    dark
                                                                    transparent
                                                                    required
                                                                    placeholder="MM / DD / YYYY"
                                                                    name="end_date"
                                                                    value={newcode.end_date}
                                                                    onChange={this.onChange}
                                                                />
                                                            </Column>
                                                        </Row>

                                                        <Row>
                                                            <Column><Input dark type="text" name="name" value={newcode.name} onChange={this.onChange} label="Guest Name" placeholder="" /></Column>
                                                        </Row>

                                                        
                                                    </div> }

                                                { this.state.adding === false && codes && <div>
                                                    <Title>Codes</Title>
                                                    
                                                    <table width="100%">
                                                        <thead>
                                                        <tr style={{color: 'black'}}>
                                                            <th>Code</th>
                                                            <th>Check-in Date</th>
                                                            <th>Check-out Date</th>
                                                            <th>Guest Name</th>
                                                            <th>User</th>
                                                            <th></th>
                                                            <th></th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                    {
                                                        codes.filter(c => this.isValid(c)).map(c => (
                                                            <tr key={c.code} style={{color: 'black'}}>
                                                                <td>
                                                                   {c.code === this.state.current ? c.code : '******'}
                                                                </td>
                                                                <td>
                                                                    {c.start_date}
                                                                </td>
                                                                <td>
                                                                    {c.end_date}
                                                                </td>
                                                                <td>
                                                                    {c.name}
                                                                </td>
                                                                <td>
                                                                    {c.user ? (  this.state.users[c.user] ? this.state.users[c.user].fullName : "N/A") : "Unclaimed"}
                                                                </td>
                                                                <td>
                                                                    {c.usages > 0 && <Button button onClick={e => {this.revoke(c.uid)}} small textColor="dark" type="ghost" text="Revoke" />}
    
                                                                </td>
                                                                <td>

                                                                    <Button button onClick={e => {
                                                                        this.setState({current: (this.state.current === c.code ? null : c.code)})
                                                                    }} small textColor="dark" type="ghost" text={this.state.current === c.code ? "Hide" : "Show"} />
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }</tbody>
                                                    </table>
                                                </div>}
                                                    
                                                    
                                                    
                                            </Schedule>
                                        </Column>
                                    </Row>
                                </Div>
                            </Column>
                        </Row> }
                        

                        

                        {loading && <div>Loading ...</div>}

                    </div>
                )}
            </AuthUserContext.Consumer>
        );
    }
}

const condition = authUser => authUser && authUser.roles && !!authUser.roles[ROLES.MANAGER];

export default compose(
    withAuthorization(condition),
    withFirebase
)(EditPromos);
