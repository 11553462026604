import React, { Component } from 'react';
import {withRouter, Redirect} from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../../lib/Firebase';



import Input from '../../components/global/formfields/input';
import { Div } from '../../components/global/theme/reusables/reusables';
import { Row, Column } from '../../components/global/theme/grid/grid';
import { Link } from 'react-router-dom';


import { Jacket, Title, LoginForm, Inputwrap, Submission, Signin, Join } from '../../components/login/loginStyles';
import Header from "../../components/global/header/header";
import {notificationState} from "../../states/notifications";
import {observer} from "mobx-react";

const initial_state = {
    email: '',
    password: '',
    error: null,
    redirect: null,
};


class SignInFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...initial_state };
    }

    handleSignin = () => {
        const { email, password } = this.state;

        this.props.firebase.auth
            .signInWithEmailAndPassword(email, password)
            .then(() => {

                this.props.firebase.analytics.logEvent("login", {});
                this.setState({ ...initial_state });
            })
            .catch(error => {

                this.props.firebase.analytics.logEvent("login_failed", {});
                this.err("Error", 'This email address and password combination doesn\'t exist. Please try again.');
                // this.setState({ error });
            });

    };
    err = (title, text) => {
        notificationState.generalTitle = title;
        notificationState.generalText = text;
        notificationState.generalButtons = [];
        notificationState.generalOpen = true
    }
    
    componentDidMount() {
        this.authWatcher = this.props.firebase.onAuthUserListener(
            authUser => {
                if (authUser) {
                    
                    // update the timezone.
                    let offset = new Date().getTimezoneOffset();
                    this.props.firebase.users().doc(authUser.uid).set({tzOffset: offset}, {merge: true}).then(() => {
                        this.setState({redirect: '/lokations'});
                    })
                    
                }
            }
        );
        
    }
    
    componentWillUnmount() {
        if (this.authWatcher) {
            this.authWatcher();
        }
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, password, error } = this.state;

        const isInvalid = password === '' || email === '';

        return (
            <>
                { this.state.redirect && <Redirect to={this.state.redirect} /> }
                <Header lightLogo goHome />
            <Jacket>
                <Div pad mpad>
                    <Row isExpanded>
                        <Column small={12} medium={10} pushOnMedium={1}>
                            <Title>Drop-in Workspace<br/>for Any Workstyle</Title>
                        </Column>
                    </Row>
                    <Row isExpanded isCollapsed>
                        <Column small={12} medium={5} pushOnMedium={1} large={4}>
                            <LoginForm>
                                <Row isExpanded>
                                    <Column small={12}>
                                        
                                        { error && <div>{error.message}</div>}
                                        
                                        <Inputwrap>
                                            <Input
                                                type="text"

                                                value={email}
                                                onChange={this.onChange}
                                                light
                                                transparent
                                                required
                                                placeholder="Email Address"
                                                name="email"
                                            />
                                        </Inputwrap>
                                    </Column>
                                    <Column small={12}>
                                        <Inputwrap>
                                            <Input
                                                type="password"

                                                value={password}
                                                onChange={this.onChange}
                                                light
                                                transparent
                                                required
                                                placeholder="Password"
                                                name="password"
                                                hasToggle
                                            />
                                        </Inputwrap>
                                    </Column>
                                    <Column small={12}>
                                        <Submission>
                                            <Signin
                                                disabled={isInvalid}
                                                onClick={this.handleSignin}>Sign In</Signin>
                                            <Join>Not a Lokal yet? <Link to="/register">Join Today</Link></Join>
                                            <Join>Forgot your password? <Link to="/forgot">Reset Now</Link></Join>
                                        </Submission>
                                    </Column>
                                </Row>
                            </LoginForm>
                        </Column>
                    </Row>
                </Div>
            </Jacket>
                </>
        );
    }
}


const SignInForm = compose(
    withRouter,
    withFirebase,
    observer,
)(SignInFormBase);


export default SignInForm;

export { SignInForm };
