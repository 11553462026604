// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';

// Styles
// ------
import { Jacket, Title, SubTitle, Slider, Slide, SliderControls, Paging, CurrentSlide, SlideCount, Button } from './imagesliderStyles';

// Component
// ------
export default class ImageSlider extends React.Component {
    initiateFlickity = () => {
        const flickity = require('flickity');

        const slider = this.slider;
        const ImageCarousel = new flickity(slider, {
            contain: false,
            wrapAround: true,
            freeScroll: false,
            prevNextButtons: false,
            pageDots: false,
            dragThreshold: 30,
            infinite: true,
        });

        // Pagination
        // ------
        const currentSlide = this.counter;
        const slidesTotal = this.slideCount;

        function updateStatus() {
            if (!currentSlide) {
                return;
            }
            const slideNumber = ImageCarousel.selectedIndex + 1;
            currentSlide.textContent = slideNumber;
            slidesTotal.textContent = ImageCarousel.slides.length;
        }

        updateStatus();

        ImageCarousel.on('change', updateStatus);


        // Slider Controls
        // ------
        const nextSlide = this.nextSlide;
        if (nextSlide) {
            nextSlide.addEventListener('click', () => {
                ImageCarousel.next();
            });
        }

        const prevSlide = this.prevSlide;
        if (prevSlide) {
            prevSlide.addEventListener('click', () => {
                ImageCarousel.previous();
            });
        }
    }

    render() {
        const images = this.props.images;
        
        return (
            <Jacket>
                {this.props.heroTitle &&
                    <>
                        <Title>{this.props.heroTitle}</Title>
                        {this.props.subheroTitle && <SubTitle>{this.props.subheroTitle}</SubTitle> }
                    </>
                }
                <Slider ref={slider => (this.slider = slider)}>
                    {images.map((block, index) => (
                        <Slide key={index}>
                            <img src={block.image} alt="" />
                        </Slide>
                    ))}
                </Slider>
                { images.length > 1 && <SliderControls>
                    <Button prev ref={prevSlide => (this.prevSlide = prevSlide)}>
                        <Icon type="small-arrow-left" />
                    </Button>
                    <Paging>
                        <CurrentSlide ref={counter => (this.counter = counter)} />
                        <SlideCount ref={slideCount => (this.slideCount = slideCount)} />
                    </Paging>
                    <Button next ref={nextSlide => (this.nextSlide = nextSlide)}>
                        <Icon type="small-arrow-right" />
                    </Button>
                </SliderControls> }
            </Jacket>
        )
    }

    componentDidMount() {
        this.initiateFlickity();
        window.addEventListener('resize', this.initiateFlickity);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.initiateFlickity);
    }
}