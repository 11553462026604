// Imports
// ------
import { css } from "styled-components";
import { breakup } from '../breakpoints';
import { isDefined } from './utils';

// Exports
// ------
const push = css`
    ${props => isDefined(props.pushOnSmall) && css`
        position: relative;
        left: calc(100% / 12 * ${props.pushOnSmall});
    `}

    ${props => isDefined(props.pushOnSmedium) && css`
        ${breakup.smedium`
            position: relative;
            left: calc(100% / 12 * ${props.pushOnSmedium});
        `}
    `}

    ${props => isDefined(props.pushOnMedium) && css`
        ${breakup.medium`
            position: relative;
            left: calc(100% / 12 * ${props.pushOnMedium});
        `}
    `}
    
    ${props => isDefined(props.pushOnLarge) && css`
        ${breakup.large`
            position: relative;
            left: calc(100% / 12 * ${props.pushOnLarge});
        `}
    `}


    ${props => isDefined(props.pushOnXlarge) && css`
        ${breakup.xlarge`
            position: relative;
            left: calc(100% / 12 * ${props.pushOnXlarge});
        `}
    `}

    ${props => isDefined(props.pushOnXxlarge) && css`
        ${breakup.xxlarge`
            position: relative;
            left: calc(100% / 12 * ${props.pushOnXxlarge});
        `}
    `}

    ${props => isDefined(props.pushOnHuge) && css`
        ${breakup.huge`
            position: relative;
            left: calc(100% / 12 * ${props.pushOnHuge});
        `}
    `}

    ${props => isDefined(props.pushOnUber) && css`
        ${breakup.uber`
            position: relative;
            left: calc(100% / 12 * ${props.pushOnUber});
        `}
    `}
`;

export default push;