// Imports
// ------
import styled, { css } from 'styled-components';
import { GradientIcon } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled.svg`
    width: 2.4rem;
    height: 2.4rem;

    fill: white;

    ${props => props.hasGradient && css`
        ${GradientIcon}
    `}
`;