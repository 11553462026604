import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

// import './styles/tailwind.css';

import Firebase, { FirebaseContext } from './lib/Firebase';

if (window.location.host === 'lokus-mvp.firebaseapp.com') { // force redirect of firebaseapp url
    window.location.assign("https://app.booklokus.com/");
}

ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
        <App />
    </FirebaseContext.Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
