// Imports
// ------
import styled, { css } from 'styled-components';
// import { breakup } from 'components/global/theme/breakpoints';
import { Section, Div, Span, Gradient } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    ${props => props.theme.animate('0.4s', 'ease-in-out')}
    position: relative;
`;

export const NotificationListWrapper = styled(Div)`
    position: relative;
    display: block;

    margin-top: 4rem;
`;

export const Label = styled(Div)`
    color: ${props => props.theme.bc3};
    margin-bottom: 1rem;

    font-size: 1.4rem;
    padding: 0 3rem;
`;

export const ItemJacket = styled(Div)`
    display: block;
    margin-bottom: 1px;
    background: ${props => props.theme.bc1};

    padding: 1rem 3rem;
`;

export const ItemText = styled(Span)`
    color: ${props => props.theme.bc3};
`;

export const Switch = styled.button`
    position: relative;
    display: inline-block;
    margin-right: ${props => props.nomargin ? "0" : "1rem"};
    float: right;
    cursor: pointer;

    width: 5rem;
    height: 3rem;
    
    border-radius: 2.5rem;
    background: #4C5965;

    &:before {
        ${props => props.theme.animate('.4s', 'cubic-bezier(.54,.32,.38,1)')}
        content: '';
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        border-radius: 2.5rem;
        background: ${Gradient};

        opacity: 0;
    }

    &:after {
        ${props => props.theme.animate('.6s', 'cubic-bezier(.54,.32,.38,1)')}
        content: '';
        position: absolute;
        z-index: 5;
        top: 0.2rem;
        left: 0.2rem;
        
        display: block;
        width: 2.5rem;
        height: 2.6rem;
        border-radius: 100%;

        background: ${props => props.theme.bc3};
    }

    ${props => props.isActive && css`
        &:before {
            opacity: 1;
        }

        &:after {
            left: 2.3rem;
        }
    `}
`;