// Imports
// ------
import styled from "styled-components";
import { breakup } from 'components/global/theme/breakpoints';
import { Section, Div, H4, P } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    display: block;

    margin-bottom: 1rem;
`;

export const TitleJacket = styled(Div)`
    position: relative;
    display: block;

    background: ${props => props.theme.bc3};
    padding: 2rem 1rem;

    ${breakup.large`padding: 2rem 0;`}
`;

export const Title = styled(H4)`
    color: ${props => props.theme.bc1};
`;

export const ContentJacket = styled(Div)`
    position: relative;
    display: block;

    background: ${props => props.theme.bc4};
    padding: 2rem 1rem;

    ${breakup.large`padding: 2rem 0;`}
`;

export const Content = styled(P)`
    position: relative;

    color: ${props => props.theme.bc1};
`;