import React, { Component } from 'react';

import { AuthUserContext } from '../../lib/Session';
import { withFirebase } from '../../lib/Firebase';
import { Base64 } from 'js-base64';
import {Div} from "../global/theme/reusables/reusables";
import {Column, Row} from "../global/theme/grid/grid";
import { Title} from "../lokations/list/listStyles";
import Loader from "../global/loader";
import Card from "../lokations/list/card/card";
import Button from "../global/buttons/primary";

class AdminLocations extends Component {
    constructor(props) {
        super(props);

        let filters = {
            date: "",
            passes: 1,
            allday: false,
            start_time: 0,
            end_time: 0,
            seats: 2
        };
        if (props.match && props.match.params && props.match.params.query) {
            let query = JSON.parse(Base64.decode(props.match.params.query));
            // TODO.. cleanup
            filters = query;
        }

        this.state = {
            loading: false,
            locations: [],

            filters: filters,
            encoded_params: "",

            tables: [],
            ids: [],
            limit: 5,
            searching: false,
        };


    }

    componentDidMount = () => {

        this.onListenForLocations();

    }


    onListenForLocations = () => {

        this.setState({ loading: true });

        this.unsubscribe = this.props.firebase
            .locations()
            .onSnapshot(snapshot => {
                if (snapshot.size) {
                    let locations = [];
                    snapshot.forEach(doc =>
                        locations.push({ ...doc.data(), uid: doc.id }),
                    );

                    this.setState({
                        locations: locations.reverse(),
                        loading: false,
                    });
                } else {
                    this.setState({ locations: null, loading: false });
                }
            });

    };

    componentWillUnmount() {
        this.unsubscribe();
    }

    onNextPage = () => {
        this.setState(
            state => ({ limit: state.limit + 5 }),
            this.onListenForLocations,
        );
    };

    addLokation = () => {
        let def = {
            about: "",
            name: 'New Lokation [PLEASE EDIT]',
            active: false,
            address: {
                info: [
                    {
                        icon: "transit",
                        text: "",
                        title: ""
                    }
                ],
                mapImage: '',
                name: '',
                neighborhood: ''
            },
            amenities: [],
            connect_id: 'acct_1FNMISJjht1vctZH',
            detail: '',
            images: [],
            info: [],
            perks: [],
            website: '',
        }
        
        this.props.firebase.locations().add(def).then(e => {
            console.log(e);
        })
        
    }

    render = () => {
        const { locations, loading } = this.state;

        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    <Div relative mpad pad>
                        <Row isExpanded>
                            <Column small={12} medium={10} pushOnMedium={1}>
                                
                                <Title>
                                    <div style={{float:"right"}}><Button button onClick={this.addLokation} type="ghost" text="Add" /></div>
                                    Admin
                                </Title>
                                
                            </Column>
                        </Row>
                        <Row isExpanded isCollapsed>
                            <Column small={12} medium={10} pushOnMedium={1}>
                                <Row isExpanded equal>
                                    {loading ? <Loader/> :
                                        locations.map((location, index) =>
                                            <Column key={index} small={12} medium={6} large={4} xlarge={3}>
                                                <Card
                                                    location={location}
                                                    slug={`/admin/location/${location.uid}`}
                                                    adminslug={`/admin/edit-location/${location.uid}`}
                                                    title={location.name}
                                                    subTitle={location.address.neighborhood}
                                                    address={location.address.name}
                                                    images={location.images}

                                                    hideFav
                                                    isAdmin
                                                    
                                                />
                                            </Column>
                                        )}

                                    

                                </Row>
                            </Column>
                        </Row>
                        <Row isExpanded>
                            <Column small={12}>
                                <Div text="center">

                                </Div>
                            </Column>
                        </Row>
                        
                    </Div>
                    
                )}
            </AuthUserContext.Consumer>
        );
    }
}

export default withFirebase(AdminLocations);
