// Imports
// ------
import React from 'react';
import Button from 'components/global/buttons/primary';
import Favorite from 'components/global/favorite/favorite';
import ImageSlider from './imageslider/imageslider';
import Icon from 'components/global/icons/icons';
import Rate from 'react-rating';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';
import { observer } from 'mobx-react';

// Styles
// ------
import { Jacket, FavJacket, Content, Title, SubTitle, Address, RatingJacket, Details, Date, Timings, Start, End, Iconwrap } from './cardStyles';
import {withRouter} from "react-router-dom";
import {compose} from "recompose";
import {humanDate, isToday, timeToMinutes} from "../../../../lib/Util/TimeUtil";

// Component
// ------
class LokationCard extends React.Component {

    onFavToggle = () => {
        this.props.onFavToggle(this.props.location)
    }

    navigateToSlug = () => {
        this.props.history.push(this.props.slug);
    }


    render() {
        let canCheckIn = false;
        let now = new window.Date();
        if (isToday(this.props.scheduleDate)) {
            if (this.props.schedulePass === false) {
                let time = (now.getMinutes()) + (now.getHours() * 60);
                canCheckIn = time >= timeToMinutes(this.props.scheduleStart) - 15; // I can start checking in earlier.
            } else {
                canCheckIn = true;
            }

        }
        let title = canCheckIn ? "Check-In Now" : "View Booking";

        let buttonTitle = this.props.buttonTitle ? this.props.buttonTitle : "View Lokation";

        return (
          <Jacket>
              {!this.props.hideFav && <FavJacket>
                  <Favorite onClick={this.onFavToggle} isFavorite={this.props.isFavorite} />
              </FavJacket>}
              <ImageSlider images={this.props.images} />
              <Content>
                  <Div mpad>
                      <Row isExpanded>
                          <Column small={12}>
                              <Title>{this.props.title}</Title>
                              <SubTitle>{this.props.subTitle}</SubTitle>
                          </Column>
                          <Column small={12}>
                              <Address>{this.props.address}</Address>
                              {this.props.hasRatings &&
                              <RatingJacket>
                                  <Rate
                                    initialRating={this.props.ratingValue}
                                    emptySymbol={<Icon type="star-empty" />}
                                    fullSymbol={<Icon type="star" />}
                                    readonly
                                  />
                              </RatingJacket>
                              }
                              {(this.props.hasDetails !== true && this.props.hasPastDetails !== true && this.props.onlyFavorite !== true && !this.props.isPromo) &&
                              <Div text="center">
                                  <Button navlink fullWidth type="ghost" textColor="dark" text={this.props.isAdmin ? "View Tables" : buttonTitle} href={this.props.slug} />
                              </Div>
                              }
                              {this.props.onlyFavorite &&
                              <Div text="center">
                                  <Button navlink fullWidth type="ghost" textColor="dark" text="Book Now" href={this.props.slug} />
                              </Div>
                              }
                              {(this.props.isAdmin) &&
                              <Div text="center">
                                  <Button navlink fullWidth type="ghost" textColor="dark" text="Edit Lokation" href={this.props.adminslug} />
                              </Div>
                              }

                              {(this.props.isPromo) &&
                              <Div text="center">
                                  <Button navlink fullWidth type="ghost" textColor="dark" text="Edit Promo Codes" href={this.props.promoslug} />
                              </Div>
                              }
                          </Column>
                      </Row>
                  </Div>
              </Content>

              {/* Has Details */}
              {this.props.hasDetails &&
              <Details>
                  <Div mpad>
                      <Row isExpanded>
                          <Column small={12}>
                              <Date>{ humanDate(this.props.scheduleDate) }</Date>
                              <Timings>
                                  <Row isExpanded isCollapsed equal>
                                      {this.props.scheduleStart && this.props.scheduleEnd &&
                                      <>
                                          <Column>
                                              <Start>{this.props.scheduleStart}</Start>
                                          </Column>
                                          <Column>
                                              <Div relative text="center">
                                                  <Iconwrap><Icon type="big-arrow-right" /></Iconwrap>
                                              </Div>
                                          </Column>
                                          <Column>
                                              <Div relative text="right">
                                                  <End>{this.props.scheduleEnd}</End>
                                              </Div>
                                          </Column>
                                      </>

                                      }

                                  </Row>
                                  {this.props.scheduleStart && this.props.scheduleEnd && (this.props.schedulePass === false || this.props.scheduleSeats) && <Row isExpanded isCollapsed equal>
                                      <Column>
                                          <Date>
                                              {this.props.schedulePass ? "1 Seat @ Shared Table" :
                                                `${this.props.scheduleSeats} Seat${this.props.scheduleSeats > 1 ? 's' : ''} @ Reserved Table`
                                              }
                                          </Date>
                                      </Column>

                                  </Row>}
                              </Timings>
                          </Column>
                      </Row>
                      <Row isExpanded>
                          <Column small={12}>
                              <Div text="center">
                                  <Button button fullWidth textColor={
                                      this.props.booking.status === 'started' ? "light" :
                                        "dark"} type={
                                      this.props.booking.status === 'started' ? "full" :
                                        "ghost"} text={this.props.booking.status === 'started' ? "Active: View Check-in Details" : title} onClick={this.navigateToSlug} />
                              </Div>
                          </Column>
                      </Row>
                  </Div>
              </Details>
              }

              {/* Is a booking that was previously made */}
              {this.props.hasPastDetails &&
              <Details>
                  <Div mpad>
                      <Row isExpanded>
                          <Column small={12}>

                              <Date>{ humanDate(this.props.scheduleDate) }</Date>
                              <Timings>
                                  <Row isExpanded isCollapsed equal>
                                      {this.props.scheduleStart && this.props.scheduleEnd &&
                                      <>
                                          <Column>
                                              <Start>{this.props.scheduleStart}</Start>
                                          </Column>
                                          <Column>
                                              <Div relative text="center">
                                                  <Iconwrap><Icon type="big-arrow-right" /></Iconwrap>
                                              </Div>
                                          </Column>
                                          <Column>
                                              <Div relative text="right">
                                                  <End>{this.props.scheduleEnd}</End>
                                              </Div>
                                          </Column>
                                      </>

                                      }

                                  </Row>
                                  {this.props.scheduleStart && this.props.scheduleEnd && (this.props.schedulePass === false || this.props.scheduleSeats) && <Row isExpanded isCollapsed equal>
                                      <Column>
                                          <Date>
                                              {this.props.schedulePass ? "1 Seat @ Shared Table" :
                                                `${this.props.scheduleSeats} Seat${this.props.scheduleSeats > 1 ? 's' : ''} @ Reserved Table`
                                              }
                                          </Date>
                                      </Column></Row>}
                              </Timings>
                          </Column>
                      </Row>
                      <Row isExpanded>
                          <Column small={12}>
                              <Div text="center">
                                  <Button navlink fullWidth type="ghost" textColor="dark" text="View Booking" href={`/booking/${this.props.booking.uid}`} />
                              </Div>
                          </Column>
                      </Row>
                      <Row isExpanded>
                          <Column small={12}>
                              <Div text="center" marTopSmall>
                                  <Button navlink fullWidth type="ghost" textColor="dark" text="Book Again" href={this.props.slug} />
                              </Div>
                          </Column>
                      </Row>
                  </Div>
              </Details>
              }

          </Jacket>
        )
    }
}

export default compose(withRouter, observer)(LokationCard)