// Imports
// ------
import React from 'react';

import {Jacket} from './Styles';
import Icon from "../global/icons/icons";
import {notificationState} from "../../states/notifications";
import {observer} from "mobx-react";
import {TextArea} from "../global/formfields/inputStyles";
import {withRouter} from "react-router-dom";
import {withFirebase} from "../../lib/Firebase";

// ------
class FeedbackButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: ""
        }
    }
    feedback = () => {

        notificationState.generalTitle = "Need Help? Email Us!";
        notificationState.generalText = <>
            <TextArea withBackground onChange={e => {this.setState({message: e.target.value})}} dark placeholder="What can we help you with?" rows={5}></TextArea>
        </>;
        notificationState.generalButtons = [
            {
                title: "Submit",
                url: "#",
                onClick: () => {
                    this.sendFeedback();
                }
            }
            
            
        ]
        notificationState.generalOpen = true;
    }
    
    sendFeedback = async () => {
        let message = this.state.message;
        let auth = this.props.firebase.auth.currentUser;
        
        let name = "Anonymous User";
        let email = "no-email";
        
        if (auth) {
            let user = await this.props.firebase.user(auth.uid).get().then(result => {
                return result.data();
            });
            if (user) {
                name = user.fullName;
                email = user.email;
            }
        }
        
        if (message) {
            
            this.props.firebase.feedbacks().add({
                name: name,
                message: message,
                email: email,
                date: (new Date()).toString(),
                path: window.location.href,
            }).then(e => {

                notificationState.generalText = "We will email you with a response shortly. Thank you!";
                notificationState.generalButtons = [{
                    title: "Okay",
                    url: "#"
                }];
            });
            
        }
        
    }
    
    render() {
        return (
            <Jacket onClick={this.feedback}>
                <Icon type="info" />
            </Jacket>
        )
    }
}

export default withRouter(withFirebase(observer(FeedbackButton)));