// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';
import { observer } from 'mobx-react';

// Styles
// ------
import {Widget, WidgetJacket, WidgetTitle, Title, ArrowJacket} from '../filterModalStyles';
import {
    TimeJacket,
    Switch,
    Label,
    TimeButtonJacket,
    Time,
    TimeSelector,
    TimeList,
    TimeItem,
    TimeListInner,
    InlineBlock
} from './timeStyles';

// States
// ------
import { scheduleFilters } from 'states/lokationSidebar';
import {isToday, minutesToTime} from "../../../../lib/Util/TimeUtil";
import {Iconwrap} from "../../sidebar/details/detailsStyles";

// Component
// ------
class filterModalTime extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isActive: false,
            selectedTimeStart: scheduleFilters.fulldayPass ? this.props.location.start_time : scheduleFilters.timeStart,
            selectedTimeEnd: scheduleFilters.fulldayPass ? this.props.location.end_time : scheduleFilters.timeEnd,
            fullDayPass: scheduleFilters.fulldayPass,

            timeStartOpen: false,
            timeEndOpen: false,

            times: [],
            open: false,
        }
    }
    
    handleSwitch = () => {
        // Component state

        this.setState({selectedTimeStart: -1, selectedTimeEnd: -1, fullDayPass: !this.state.fullDayPass}, () => {
        
            scheduleFilters.fulldayPass = this.state.fullDayPass;
            if (scheduleFilters.fulldayPass) {
                scheduleFilters.seats = 1;
                scheduleFilters.timeStart = this.props.location.start_time;
                scheduleFilters.timeEnd = this.props.location.end_time;
            } else {
                scheduleFilters.seats = 0;
            }
            this.props.onChange()
            
            if (scheduleFilters.fulldayPass) {
                this.props.onNext();
            }
        
        })
        
    }

    handleTimeStart = () => {
        this.setState({timeStartOpen: !this.state.timeStartOpen})
        this.setState({timeEndOpen: false})

        // create the start times...
        let times = [];
        let now = new Date();
        let hour = now.getHours() + 1;
        hour *= 60;

        // if the date isn't today, then we allow previous dates.
        if (scheduleFilters.date === null || !isToday(scheduleFilters.date)) {
            hour = this.props.location.start_time;
        }


        let max = this.state.selectedTimeEnd === -1 ? 24 * 60 : this.state.selectedTimeEnd;
        do {
            times.push(hour);
            hour += 60;
        } while (hour < max)

        this.setState({times: times});
    }

    handleNewTimeStart = (e) => {
        this.setState({selectedTimeStart: e.target.getAttribute('value'), timeStartOpen: false}, () => {

            scheduleFilters.timeStart = this.state.selectedTimeStart;
            if (scheduleFilters.fulldayPass) {
                scheduleFilters.fulldayPass = false;
                scheduleFilters.seats = 0;
            }
            this.props.onChange();
        })
    }

    handleTimeEnd = () => {
        this.setState({timeEndOpen: !this.state.timeEndOpen})
        this.setState({timeStartOpen: false})

        // create the end times... (not armageddon)
        let times = [];

        if (this.state.selectedTimeStart === -1) {
            this.setState({times: times});
            return;
        }

        let hour = (1 * this.state.selectedTimeStart) + 60;
        do {
            times.push(hour);
            hour += 60;
        } while (hour < 24 * 60);

        this.setState({times: times});

    }

    handleNewTimeEnd = (e) => {
        this.setState({selectedTimeEnd: e.target.getAttribute('value'), timeEndOpen: false}, () => {

            scheduleFilters.timeEnd = this.state.selectedTimeEnd;
            if (scheduleFilters.fulldayPass) {
                scheduleFilters.fulldayPass = false;
                scheduleFilters.seats = 0;
            }
            this.props.onChange();
            
            this.props.onNext();
        })
    }
    
    
    timeName = () => {
        let name = "Time";
        
        if (!this.props.open) {
            if (scheduleFilters.fulldayPass) {
                name = "Day Pass";
            } else if (scheduleFilters.timeStart > -1 && scheduleFilters.timeEnd > -1) {
                name = <><InlineBlock>{minutesToTime(scheduleFilters.timeStart)}</InlineBlock>
                    <Iconwrap>
                    <Icon type="big-arrow-right" />
                    </Iconwrap>
                <InlineBlock>{minutesToTime(scheduleFilters.timeEnd)}</InlineBlock></>
            }
        }
        
        return name;
    }
    
    render() {
        const timeStartOpenChecker = this.state.timeStartOpen;
        const timeEndOpenChecker = this.state.timeEndOpen;
        
        return (
            <Widget>
                <WidgetJacket>
                    <WidgetTitle>
                        <Row isExpanded equal  onClick={e => this.props.onToggleOpen(this.props.open ? -1 : 1)}>
                            <Column small={10}>
                                <Title light={!this.props.open}>{this.timeName()}</Title>
                            </Column>

                            <Column small={2}>
                                <ArrowJacket big isOpen={this.props.open}>
                                    <Icon type="small-arrow-down" />
                                </ArrowJacket>
                            </Column>
                        </Row>
                    </WidgetTitle>
                    { this.props.open && <TimeJacket>
                        <Row isExpanded>
                            <Column small={6}>
                                <Label>Day Pass</Label>
                            </Column>
                            <Column small={6}>
                                <Div relative text="right">
                                    <Switch onClick={this.handleSwitch} isActive={scheduleFilters.fulldayPass} />
                                </Div>
                            </Column>
                        </Row>
                        {/*{!this.state.fullDayPass &&*/}
                            <>
                                <Row isExpanded equal>
                                    <Column small={6}>
                                        <Label>Start Time (EST)</Label>
                                    </Column>
                                    <Column small={6}>
                                        <TimeButtonJacket onClick={this.handleTimeStart} isOpen={timeStartOpenChecker}>
                                            <Time>{minutesToTime(scheduleFilters.timeStart)}</Time>
                                            <Icon type="small-arrow-down" />
                                        </TimeButtonJacket>
                                    </Column>
                                </Row>
                                <TimeSelector isOpen={timeStartOpenChecker}>
                                    <Row isExpanded>
                                        <Column small={12}>
                                            <TimeList>
                                                {
                                                    this.state.times.map(minutes => (
                                                        <TimeItem key={minutes} onClick={(e) => this.handleNewTimeStart(e)} value={minutes} selected={this.state.selectedTimeStart === minutes}>{minutesToTime(minutes)}</TimeItem>
                                                    ))
                                                }
                                            </TimeList>
                                        </Column>
                                    </Row>
                                    <TimeListInner />
                                </TimeSelector>
                                <Row isExpanded equal>
                                    <Column small={6}>
                                        <Label>End Time (EST)</Label>
                                    </Column>
                                    <Column small={6}>
                                        <TimeButtonJacket onClick={this.handleTimeEnd} isOpen={timeEndOpenChecker}>
                                            <Time>{minutesToTime(scheduleFilters.timeEnd)}</Time>
                                            <Icon type="small-arrow-down" />
                                        </TimeButtonJacket>
                                    </Column>
                                </Row>
                                <TimeSelector isOpen={timeEndOpenChecker}>
                                    <Row isExpanded>
                                        <Column small={12}>
                                            <TimeList>
                                                {
                                                    this.state.times.map(minutes => (
                                                        <TimeItem key={minutes} onClick={(e) => this.handleNewTimeEnd(e)} value={minutes} selected={this.state.selectedTimeEnd === minutes}>{minutesToTime(minutes)}</TimeItem>
                                                    ))
                                                }
                                            </TimeList>
                                        </Column>
                                    </Row>
                                    <TimeListInner />
                                </TimeSelector>
                            </>
                        {/*}*/}
                    </TimeJacket> }
                </WidgetJacket>
            </Widget>
        )
    }
}

export default observer(filterModalTime)