// Imports
// ------
import React from 'react';
import Button from 'components/global/buttons/primary';
import { Div } from 'components/global/theme/reusables/reusables';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket, TitleJacket, Title, ContentJacket, Content } from './noticeStyles';

// Component
// ------
export default class SidebarNotice extends React.Component {
    render() {
        return (
            <Jacket>
                <TitleJacket>
                    <Row isExpanded>
                        <Column small={12}>
                            <Title>{this.props.noticeTitle}</Title>
                        </Column>
                    </Row>
                </TitleJacket>
                <ContentJacket>
                    <Row isExpanded>
                        <Column small={12}>
                            <Content>{this.props.noticeContent}</Content>
                        </Column>
                    </Row>
                    {this.props.noticeHasLink &&
                        <Row isExpanded>
                            <Column small={12}>
                                <Div text="center">
                                    <Button navlink type="text" text={this.props.noticeLinkText} href={this.props.noticeLinkUrl} />
                                </Div>
                            </Column>
                        </Row>
                    }
                </ContentJacket>
            </Jacket>
        )
    }
}