// Imports
// ------
import React from 'react';
import Icon from 'components/global/icons/icons';
import Password from '../password/passwordButton';
import Help from '../../help/helpButton';
import Legal from '../../legal/legalButton';
import Faqs from '../../faqs/faqsButton';
import Notifications from '../../notifications/notificationsButton';
import { Link } from 'react-router-dom';

// Styles
// ------
import { Jacket, Label, ItemList, Item, Text } from './menuStyles';
import {userState} from "../../../../states/user";

import { Jacket as LinkJacket } from '../password/passwordButtonStyles';

// Component
// ------
export default class ProfileMenu extends React.Component {

    handleEdit = () => {
        userState.editProfileOpen = !userState.editProfileOpen;
    }

    render() {
        return (
            <Jacket>
                <Label>{this.props.label}</Label>
                <ItemList>
                    {this.props.list.map((block, index) =>
                        <Item key={index}>
                            {(block.isPassword === true) &&
                            <Password />
                            }

                            {(block.isHelp === true) &&
                            <Help />
                            }

                            {(block.isLegal === true) &&
                            <Legal />
                            }

                            {(block.isFaqs === true) &&
                            <Faqs />
                            }

                            {(block.isNotifications === true) &&
                            <Notifications />
                            }

                            {(block.isProfile) &&
                            <LinkJacket onClick={this.handleEdit}>
                                <Text>Personal Details</Text>
                                <Icon type="small-arrow-right" />
                            </LinkJacket>
                            }


                            {(!block.isPassword && !block.isHelp && !block.isLegal && !block.isFaqs && !block.isNotifications) && !block.isProfile &&
                            <Link to={block.link}>
                                <Text>{block.text}</Text>
                                <Icon type="small-arrow-right" />
                            </Link>
                            }
                        </Item>
                    )}
                </ItemList>
            </Jacket>
        )
    }
}