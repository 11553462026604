// Imports
// ------
import React from 'react';
import EmailNotification from './items/emailNotification';
// import PushNotification from './items/pushNotification';
// import TextNotification from './items/textNotification';
// import EmailReminder from './items/emailReminder';
// import PasswordReminder from './items/passwordReminder';
// import PhoneReminder from './items/phoneReminder';
import { Row, Column } from 'components/global/theme/grid/grid';

// Styles
// ------
import { Jacket, NotificationListWrapper, Label } from './listStyles';
import {withFirebase} from "../../../../lib/Firebase";
import {compose} from "recompose";
// import Notifications from "../notifications";

// Component
// ------
class NotificationsList extends React.Component {
    render() {
        return (
            <Jacket>
                <Row isExpanded>
                    <Column small={12} medium={10} pushOnMedium={1}>
                        <Row isExpanded>
                            <Column small={12} large={6}>
                                <NotificationListWrapper>
                                    <Label>Messages</Label>
                                    
                                    {/* Includes of toggles. Each carry their own functions. */}
                                    <EmailNotification authUser={this.props.authUser} />
                                    {/*<PushNotification authUser={this.props.authUser} />*/}
                                    {/*<TextNotification authUser={this.props.authUser} />*/}
                                </NotificationListWrapper>

                                {/*<NotificationListWrapper>*/}
                                {/*    <Label>Reminders</Label>*/}
                                {/*    */}
                                {/*    /!* Includes of toggles. Each carry their own functions. *!/*/}
                                {/*    <EmailReminder authUser={this.props.authUser} />*/}
                                {/*    <PasswordReminder authUser={this.props.authUser} />*/}
                                {/*    <PhoneReminder authUser={this.props.authUser} />*/}
                                {/*</NotificationListWrapper>*/}
                            </Column>
                        </Row>
                    </Column>
                </Row>
            </Jacket>
        )
    }
}

export default compose(
    withFirebase
)(NotificationsList)