// Imports
// ------
import styled from "styled-components";
import { Section, Div, H3, Span, P } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Promo = styled(Section)`
    position: relative;
    z-index: 10;
    display: block;

    margin-top: 1rem;
    padding: 2rem;
    background: ${props => props.theme.bc3};
`;

export const Jacket = styled(Section)`
    position: relative;
    z-index: 10;
    display: block;

    margin-top: 1rem;
    background: ${props => props.theme.bc3};
`;

export const Total = styled(Div)`
    position: relative;
    display: black;

    background: ${props => props.theme.bc4};
    padding: 1rem 2rem;
`;

export const Label = styled(Span)`
    color: ${props => props.theme.bc1};
`;

export const Cost = styled(H3)`
    color: ${props => props.theme.bc1};
`;

export const Striked = styled(Span)`
    text-decoration: line-through;
    font-size: 0.9rem;
    color: ${props => props.theme.bc7};
`;

export const PaymentJacket = styled(Div)`
    position: relative;
    display: block;

    padding: 2.5rem 2rem;
    color: ${props => props.theme.bc1};
`;

export const Terms = styled(P)`
    position: relative;
    display: block;
    margin-top: 1.5rem;

    font-size: 1.2rem !important;
    line-height: 1.8rem !important;

    a {
        font-size: 1.2rem !important;
        line-height: 1.8rem !important;
        text-decoration: underline;
    }
`;