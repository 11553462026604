// Imports
// ------
import styled from 'styled-components';
import { breakup } from 'components/global/theme/breakpoints';
import { Div, H1, P, Span } from 'components/global/theme/reusables/reusables';
import visibility from "../global/theme/grid/visibility";

// Exports
// ------
export const Jacket = styled(Div)`
    position: relative;
    display: block;
    height: 100%;
`;

export const Title = styled(H1)`
    position: relative;
    display: block;
    margin-top: 10rem;

    color: ${props => props.theme.bc3};
`;

export const Inputwrap = styled(Div)`
    margin-bottom: 2rem;
`;

export const SignupStep = styled(Div)`
    ${props => props.theme.animate('0.3s', 'ease-in-out')}
    position: relative;
    display: ${props => props.isRemoved ? "none" : "block"};
    margin-top: 10rem;

    opacity: ${props => props.isActive ? "1" : "0"};
`;

export const Submission = styled(Div)`
    position: relative;
    display: block;
    margin-top: 4rem;
`;

export const Signin = styled.button`
    display: block;
    width: 100%;
    margin-bottom: 2rem;
    cursor: pointer;

    background: transparent;
    border: 1px solid ${props => props.theme.bc3};
    padding: 1.2rem 3rem;
    text-align: center;

    font-size: 1.6rem;
    color: ${props => props.theme.bc3};

    ${breakup.medium`
        font-size: 1.8rem;

        display: inline-block;
        width: auto;
    `}

    ${breakup.large`
        padding: 1.2rem 4rem;
        font-size: 2rem;
    `}
`;

export const Text = styled(P)`
    display: block;
    margin-top: 1rem;
    
    font-size: 1.4rem !important;
    line-height: 1.8rem !important;

    a {
        font-size: 1.4rem !important;
        line-height: 1.8rem !important;
        text-decoration: underline;
    }
    
    ${visibility}
`;

export const SmallText = styled(Span)`
    font-size: 1.2rem !important;
    line-height: 1.8rem !important;
`