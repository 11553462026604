// Imports
// ------
import styled from "styled-components";
import { Section } from 'components/global/theme/reusables/reusables';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    z-index: 10;
    display: block;
`;