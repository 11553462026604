// **********************************************
// Waffl Grid 1.0
// **********************************************

// Imports
// ------
import styled, { css } from "styled-components";
import { breakup } from '../breakpoints';
import breakpoints from './breakpoints';
import push from './push';
import pull from './pull';
import offset from './offset';
import ordering from './ordering';
import mobilePadding from './mobilePadding';
import visibility from './visibility';
import collapsed from './collapsed';

// Exports
// ------
const sharedStyles = css` display: flex; `;

export const Column = styled.div`
    
    display: ${props => props.block ? "block" : "flex"};
    flex-direction: column;
    flex-grow: 1;
    
    cursor: ${props => props.clickable ? "pointer": "normal"};

    position: ${props => props.relative ? "relative" : "static"};

    padding-left: calc(${props => props.theme.gutterSmall} / 2);
    padding-right: calc(${props => props.theme.gutterSmall} / 2);

    ${breakup.large`
        padding-left: calc(${props => props.theme.gutterLarge} / 2);
        padding-right: calc(${props => props.theme.gutterLarge} / 2);
    `}

    /* MobilePadding */
    ${mobilePadding}

    /* Ordering */
    ${ordering}

    /* Breakpoints */
    ${breakpoints}

    /* Push Columns */
    ${push}

    /* Pull Columns */
    ${pull}

    /* Offset Columns */
    ${offset}

    /* Visibility */
    ${visibility}
`;

export const Row = styled.div`
    ${sharedStyles}
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    flex-flow: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: ${props => props.theme.maxGridSize ? props.theme.maxGridSize : '144rem'};
    margin: 0 auto;

    /* Want to get rid of that pesky margin? */
    ${props => props.isCollapsed && css`
        > ${Column} { padding: 0; }
    `}

    /* Want the grid full width of the screen? */
    ${props => props.isExpanded && css`
        max-width: 100%;
    `}

    /* Vertically position top content */
    ${props => props.isTop && css`
        align-items: flex-start;
        height: 100%;
    `}

    /* Vertically Center content */
    ${props => props.isCenter && css`
        align-items: center;
        height: 100%;
    `}
    /* Vertically position bottom content */
    ${props => props.isBottom && css`
        align-items: flex-end;
        height: 100%;
    `}

    /* Align content to bottom */
    ${props => props.isBottom && css`
        align-items: flex-end;
    `}

    /* Reverse ordering of content */
    ${props => props.isReversed && css`
        flex-direction: row-reverse;
    `}

    /* Make content equalised */
    ${props => props.equal && css`
        align-items: stretch;

        > ${Column} { display: flex; }
    `}

    /* Want a vertical grid? */
    ${props => props.vertical && css`
        flex-direction: column;
    `}

    /* Visibility */
    ${visibility}

    /* Collapsed */
    ${collapsed}
`;
