// Imports
// ------
import { css } from "styled-components";
import { breakup, breakdown } from '../breakpoints';

// Exports
// --------------
// 1. Margin
// --------------
const marginStyles = css`
    ${props => props.marBottom && css`
        margin-bottom: ${props.theme.padS};

        ${breakup.medium` margin-bottom: ${props.theme.padM}; `}
        ${breakup.large` margin-bottom: ${props.theme.padL}; `}
    `}

    ${props => props.marTop && css`
        margin-top: ${props.theme.padS};

        ${breakup.medium` margin-top: ${props.theme.padM}; `}
        ${breakup.large` margin-top: ${props.theme.padL}; `}
    `}
    
    ${props => props.marTopSmall && css`
        margin-top: ${props.theme.mpad};

        ${breakup.medium` margin-top: ${props.theme.mpad}; `}
        ${breakup.large` margin-top: ${props.theme.mpad}; `}
    `}
    
    ${props => props.mar && css`
        margin-top: ${props.theme.padS};
        margin-bottom: ${props.theme.padS};

        ${breakup.medium`
            margin-top: ${props.theme.padM};
            margin-bottom: ${props.theme.padM};
        `}

        ${breakup.large`
            margin-top: ${props.theme.padL};
            margin-bottom: ${props.theme.padL};
        `}
    `}
`;

// --------------
// 2. Padding
// --------------
const paddingStyles = css`
    ${props => props.mpad && css`
        padding-left: ${props.theme.mpad};
        padding-right: ${props.theme.mpad};

        ${breakup.large`
            padding-left: 0px;
            padding-right: 0px;
        `}
    `}

    ${props => props.mpadLarge && css`
        padding-left: ${props.theme.mpad};
        padding-right: ${props.theme.mpad};
    `}

    ${props => props.padBottom && css`
        padding-bottom: ${ props.theme.padS};

        ${breakup.medium` padding-bottom: ${props.theme.padM}; `}
        ${breakup.large` padding-bottom: ${props.theme.padL}; `}
    `}

    ${props => props.mpadTop && css`
        padding-top: ${props.theme.padS};

        ${breakup.medium` padding-top: 0; `}
        ${breakup.large` padding-top: 0; `}
    `}
    
    ${props => props.padTop && css`
        padding-top: ${props.theme.padS};

        ${breakup.medium` padding-top: ${props.theme.padM}; `}
        ${breakup.large` padding-top: ${props.theme.padL}; `}
    `}
    
    ${props => props.pad && css`
        padding-top: ${props.theme.padS};
        padding-bottom: ${props.theme.padS};

        ${breakup.medium`
            padding-top: ${props.theme.padM};
            padding-bottom: ${props.theme.padM};
        `}

        ${breakup.large`
            padding-top: ${props.theme.padL};
            padding-bottom: ${props.theme.padL};
        `}
    `}
`;

// --------------
// 3. Vertical Alignment
// --------------
const verticalAlignmentStyles = css`
    ${props => props.valign && props.theme.valign}
    ${props => props.valignMedium && breakup.medium` ${props.theme.valign} `}
    ${props => props.valignLarge && breakup.large` ${props.theme.valign} `}
    ${props => props.valignSmallOnly && breakdown.smedium` ${props.theme.valign} `}
`;


// --------------
// 4. Background Colours
// --------------
const backgroundColorStyles = css`
    background-color: ${props => [
        props.bc1 && props.theme.bc1,
        props.bc2 && props.theme.bc2,
        props.bc3 && props.theme.bc3,
    ]};
`;

// --------------
// 5. Fullscreen
// --------------
const fullscreenStyles = css`
    ${ props => props.fullscreen && css`
        width: 100%;
        height: 100vh;
    `}
`;


// --------------
// 6. Text Alignment
// --------------
const textAlignmentStyles = css`
    ${ props => props.text === 'center' && ` text-align: center; `}
    ${ props => props.text === 'centerMedium' && ` ${breakup.medium` text-align: center; `}`}
    ${ props => props.text === 'centerLarge' && ` ${breakup.large` text-align: center; `}`}

    ${ props => props.text === 'left' && ` text-align: left; `}
    ${ props => props.text === 'leftMedium' && ` ${breakup.medium` text-align: left; `}`}
    ${ props => props.text === 'leftLarge' && ` ${breakup.large` text-align: left; `}`}

    ${ props => props.text === 'right' && ` text-align: right; `}
    ${ props => props.text === 'rightMedium' && ` ${breakup.medium` text-align: right; `}`}
    ${ props => props.text === 'rightLarge' && ` ${breakup.large` text-align: right; `}`}
`;


// --------------
// 7. Relative
// --------------
const relativeStyles = css`
    ${ props => props.relative && css`
        position: relative;
    `}
`;



// --------------
// 8. Export: Container Styles
// --------------
export const containerStyles = css`
    ${verticalAlignmentStyles}
    ${backgroundColorStyles}
    ${paddingStyles}
    ${marginStyles}
    ${fullscreenStyles}
    ${textAlignmentStyles}
    ${relativeStyles}
`;